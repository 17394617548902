export interface ICategory {
    id: string;
    name_key: string;
    parent_id: string;
    level: number;
    name: string;
    images: any;
    created_time?: Date;
    updated_time?: Date;
}

export interface IProductAttribute {
    category_id: string;
    id: string;
    name: string;
    type: 'SALES_PROPERTY' | 'PRODUCT_PROPERTY';
    is_required: boolean;
    values: {
        id: string;
        name: string;
        is_custom?: boolean;
    }[];
    value_data_format: string;
    is_customizable: boolean;
    requirement_conditions: {
        condition_type: string;
        attribute_id: string;
        attribute_value_id: string;
    }[];
    is_multiple_selection: boolean;
}

export enum EnumCategoryType {
    FLAT = 'flat',
    TREE = 'tree'
}

export type ProductSKUType = {
    colors: string
    sizes: string
    price: number
    skus: {
        size: string
        price: number
        color?: string
        seller_sku: string
    }[]
}