export const SETTLEMENT_PERIOD = {
    eightDay: 0,
    oneDay: 1,
    fiveDay: 2,
    thirtyDay: 3,
};

export const COMMON_INFO_FIELDS = {
    shopName: 'shop_name',
    accountId: 'account_id',
    shopCode: 'shop_code',
};
export const VIOLATION_FIELDS = {
    violationScore: 'violation_score',
};

export const SELLER_CREATOR_FIELDS = {
    boundTime: 'bound_time',
    officialCreatorInfo: 'official_creator_info',
};

export const ANALYTICS_FIELDS = {
    analytic: 'analytic',
};

export const ACCOUNT_QUALIFICATION_FIELDS = {
    qualification: 'qualification',
};

export const SETTLEMENT_PERIOD_FIELDS = {
    settlementDays: 'settlement_days',
};

export const ACTION_NEEDED_FIELDS = {
    overdue: 'overdue',
};

export const BANK_FIELDS = {
    bankAccountNumber: 'bank_account_number',
};

export const ON_HOLD_FIELDS = {
    onHoldAmount: 'amount',
};

export const PAID_IN_RANGE_FIELDS = {
    paidAmount: 'paid_amount',
    processingAmount: 'processing_amount',
    totalReserveAmount: 'total_reserve_amount',
};

export const PROCESSING_FIELDS = {
    totalSettlement: 'total_settlement',
    income: 'income',
    outcome: 'outcome',
    adjustment: 'adjustment',
    sumReserveAmount: 'sum_reserve_amount',
    netTotalSettlement: 'net_total_settlement',
};

export const LISTING_COUNT_FIELDS = {
    listing: 'listing',
};

export const ORDER_FIELDS = {
    totalCount: 'total_count',
};

export const SHOP_LIMIT_STATUS_FIELDS = {
    limitedOrderCount: 'limited_order_count',
};

export const PRODUCTS_FIELD = {
    products: 'products',
};

export const TIKTOK_SHOP_COMMON_INFO_FIELDS = Object.values(COMMON_INFO_FIELDS);
export const TIKTOK_SHOP_PRODUCTS_FIELD = Object.values(PRODUCTS_FIELD);
export const TIKTOK_SHOP_SHOP_LIMIT_STATUS_FIELDS = Object.values(SHOP_LIMIT_STATUS_FIELDS);
export const TIKTOK_SHOP_SELLER_CREATOR_FIELDS = Object.values(SELLER_CREATOR_FIELDS);
export const TIKTOK_SHOP_ANALYTICS_FIELDS = Object.values(ANALYTICS_FIELDS);
export const TIKTOK_SHOP_ACCOUNT_QUALIFICATION_FIELDS = Object.values(ACCOUNT_QUALIFICATION_FIELDS);
export const TIKTOK_SHOP_SETTLEMENT_PERIOD_FIELDS = Object.values(SETTLEMENT_PERIOD_FIELDS);
export const TIKTOK_SHOP_ACTION_NEEDED_FIELDS = Object.values(ACTION_NEEDED_FIELDS);
export const TIKTOK_SHOP_PROCESSING_FIELDS = Object.values(PROCESSING_FIELDS);
export const TIKTOK_SHOP_LISTING_COUNT_FIELDS = Object.values(LISTING_COUNT_FIELDS);
export const TIKTOK_SHOP_ORDER_FIELDS = Object.values(ORDER_FIELDS);
export const TIKTOK_SHOP_VIOLATION_FIELDS = Object.values(VIOLATION_FIELDS);
export const TIKTOK_SHOP_BANK_FIELDS = Object.values(BANK_FIELDS);
export const TIKTOK_SHOP_ON_HOLD_FIELDS = Object.values(ON_HOLD_FIELDS);
export const TIKTOK_SHOP_PAID_IN_RANGE_FIELDS = Object.values(PAID_IN_RANGE_FIELDS);

export const TIKTOK_SHOP_ALL_FIELDS = [
    ...TIKTOK_SHOP_COMMON_INFO_FIELDS,
    ...TIKTOK_SHOP_VIOLATION_FIELDS,
    ...TIKTOK_SHOP_BANK_FIELDS,
    ...TIKTOK_SHOP_ON_HOLD_FIELDS,
    ...TIKTOK_SHOP_PAID_IN_RANGE_FIELDS,
    ...TIKTOK_SHOP_PROCESSING_FIELDS,
    ...TIKTOK_SHOP_LISTING_COUNT_FIELDS,
    ...TIKTOK_SHOP_ORDER_FIELDS,
    ...TIKTOK_SHOP_ACTION_NEEDED_FIELDS,
    ...TIKTOK_SHOP_SETTLEMENT_PERIOD_FIELDS,
    ...TIKTOK_SHOP_ACCOUNT_QUALIFICATION_FIELDS,
    ...TIKTOK_SHOP_SELLER_CREATOR_FIELDS,
    ...TIKTOK_SHOP_SHOP_LIMIT_STATUS_FIELDS,
    ...TIKTOK_SHOP_PRODUCTS_FIELD,
    ...TIKTOK_SHOP_ANALYTICS_FIELDS,
];

export const MAPPING_PROP_FIELDS = {
    common: TIKTOK_SHOP_COMMON_INFO_FIELDS,
    violation: TIKTOK_SHOP_VIOLATION_FIELDS,
    bank: TIKTOK_SHOP_BANK_FIELDS,
    onHold: TIKTOK_SHOP_ON_HOLD_FIELDS,
    paidInDateRange: TIKTOK_SHOP_PAID_IN_RANGE_FIELDS,
    processing: TIKTOK_SHOP_PROCESSING_FIELDS,
    listingCount: TIKTOK_SHOP_LISTING_COUNT_FIELDS,
    orders: TIKTOK_SHOP_ORDER_FIELDS,
    actionNeededCount: TIKTOK_SHOP_ACTION_NEEDED_FIELDS,
    settlementPeriod: TIKTOK_SHOP_SETTLEMENT_PERIOD_FIELDS,
    qualification: TIKTOK_SHOP_ACCOUNT_QUALIFICATION_FIELDS,
    sellerCreatorInfo: TIKTOK_SHOP_SELLER_CREATOR_FIELDS,
    shopLimitStatus: TIKTOK_SHOP_SHOP_LIMIT_STATUS_FIELDS,
    products: TIKTOK_SHOP_PRODUCTS_FIELD,
    latestProduct: TIKTOK_SHOP_PRODUCTS_FIELD,
    analytic: TIKTOK_SHOP_ANALYTICS_FIELDS,
};
