import React, { useState, useMemo, useCallback, useContext, PropsWithChildren } from 'react'
import { IPromotionProvider, ISelectProductPromotion } from 'types/IPromotion'

const PromotionContext = React.createContext<IPromotionProvider>({
    selectedProducts: [],
    setSelectedProducts: () => {},
    saveDataSource: () => {}
})

function PromotionProvider({ children }: PropsWithChildren<{}>) {
    const [selectedProducts, setSelectedProducts] = useState<ISelectProductPromotion[]>([])

    const saveDataSource = useCallback((record: ISelectProductPromotion) => {
        setSelectedProducts((prev) => prev.map((product) =>
            product.product_id === record.product_id
                ? {
                      ...record
                  }
                : product
        ) as ISelectProductPromotion[])
    }, [selectedProducts])

    const contextValue = useMemo(
        () => ({
            selectedProducts,
            setSelectedProducts,
            saveDataSource
        }),
        [selectedProducts, saveDataSource]
    )

    return <PromotionContext.Provider value={contextValue}>{children}</PromotionContext.Provider>
}

export const usePromotionContext = () => {
    const context = useContext(PromotionContext)
    if (!context) {
        throw new Error('usePromotionContext must be used within a PromotionProvider')
    }
    return context
}

export default PromotionProvider
