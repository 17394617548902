import React from 'react'
import { Button, Popconfirm, Row, Space, Table, Tabs } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, HourglassOutlined, ReloadOutlined } from '@ant-design/icons'
import Staff from './Staff'
import { useStaffManagementStore } from './store/staff-management.store'
import { useTableConfigs } from '../../hooks/useTableConfigs'
import { useGetStaffsQuery } from './queries/staff.query'
import { AUTO_IDEA_COLUMNS } from '../ListingProduct/AutoIdea/Table/_autoIdeaColumnsDefined'
import { ColumnsType } from 'antd/lib/table'
import { IUser } from '../../types/IUser'
import moment from 'moment/moment'
import { blue, red } from '@ant-design/colors'
import { useAuth } from '../../contexts/Authenticate'
import StaffManagementActionColumn from './Table/StaffManagementActionColumn'
import { useModal } from '../../contexts/Modal'
import StaffModal from './modals/StaffModal'
import ButtonPackageProtected from "../../core/ButtonProtected/ButtonPackageProtected";
import {CODE_PERMISSION, PACKAGE_PERMISSION} from "../../const/permission";
import ButtonRoleProtected from "../../core/ButtonProtected/ButtonRoleProtected";
import {EnumAction} from "../../types/IPermission";

export const STAFF_TABS = {
    Active: {
        label: 'Active',
        icon: <CheckCircleOutlined />,
    },
    Inactive: {
        label: 'Inactive',
        icon: <HourglassOutlined />,
    },
}

function StaffManagementTab() {
    const { user } = useAuth()
    const currentTab = useStaffManagementStore(state => state.currentTab)
    const setCurrentTab = useStaffManagementStore(state => state.setCurrentTab)
    const { queryResponse, pagination } = useTableConfigs(useGetStaffsQuery)
    const { openModal } = useModal()

    const onTabChange = (key: string) => {
        setCurrentTab(key)
        pagination.resetPageNumber();
    }

    const openCreateStaffModal = () => openModal(<StaffModal refetch={() => queryResponse?.refetch()} />)

    return (
        <>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space className='mr-2' size='large'>
                    <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />}>
                        Refresh
                    </Button>
                    <ButtonRoleProtected permission={{ [CODE_PERMISSION.STAFF_MANAGEMENT]: [EnumAction.Create] }}>
                        <ButtonPackageProtected type='primary' onClick={openCreateStaffModal} permission={PACKAGE_PERMISSION.MUTATE_MEMBERS}>
                            Thêm nhân viên
                        </ButtonPackageProtected>
                    </ButtonRoleProtected>
                </Space>
            </Row>
            <Tabs
                defaultActiveKey={currentTab}
                type='card'
                items={Object.values(STAFF_TABS).map((tab) => {
                    return {
                        key: tab.label,
                        label: tab.label,
                        children: (
                            <Table
                                bordered
                                dataSource={queryResponse?.data?.data ?? []}
                                columns={[
                                    ...(user.is_kbt_user ? ([
                                        {
                                            title: 'ID',
                                            dataIndex: 'partner_id',
                                            key: 'partner_id'
                                        },
                                    ] as ColumnsType<IUser>) : []),
                                    ...STAFF_COLUMNS
                                ]}
                                loading={queryResponse?.isFetching}
                                pagination={{
                                    defaultCurrent: pagination.pageNumber,
                                    current: pagination.pageNumber,
                                    pageSize: pagination.pageSize,
                                    total: pagination.total,
                                    onChange: pagination.handleChangePagination,
                                    position: ['topLeft', 'bottomRight'],
                                    showTotal: (total) => <span>Total <strong>{total}</strong> products</span>
                                }}
                                rowKey={(record) => record?._id}
                            />
                        ),
                        icon: tab.icon
                    }
                })}
                onChange={onTabChange}
            />
        </>
    )
}

export default StaffManagementTab

const STAFF_COLUMNS: ColumnsType<IUser> = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name'
    },
    {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name'
    },
    {
        title: 'Vai trò',
        dataIndex: 'role',
        key: 'role',
        render: (text) => {
            if (text && text[0]) {
                return text[0].name
            }
            return null
        }
    },
    {
        title: 'SKU Prefix',
        dataIndex: 'sku_prefix',
        key: 'sku_prefix'
    },
    {
        title: 'Ngày tạo',
        dataIndex: 'created_time',
        key: 'created_time',
        render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
        width: 150
    },
    {
        title: 'Hành động',
        key: 'action',
        render: (_, record) => {
            if (record._id === record.owner) return null
            return <StaffManagementActionColumn user={record} />
        },
        width: 150,
        fixed: 'right',
        align: 'center'
    }
]
