import React from 'react'
import { IOrder } from '../../../types/IOrder'
import { Button, Flex, Popconfirm, Tag } from 'antd'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import dayjs from 'dayjs'
import { formatDate } from '../../../utils/format'
import { LIST_ORDER_QUERY_KEY } from '../queries/order.query'
import { useUserFulfillServicesQuery } from '../../AccountManagement/queries/user-fulfill.query'
import {useModal} from "../../../contexts/Modal";
import OrderMoreActionsModal from "../modals/OrderMoreActionsModal";
import {ORDER_STATUS} from "./OrderProductInfoColumn";

function OrderPushToFulFillColumn({ order }: { order: IOrder }) {
    const queryClient = useQueryClient()
    const { openModal } = useModal()
    const pushToFulfillMutation = useMutation({
        mutationFn: OrderAPI.pushToFulfill,
        onSuccess: () => {
            notify('success', 'Push to fulfill successfully')
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })
    const { data: userFulfillServices } = useUserFulfillServicesQuery()

    const cancelFulfillMutation = useMutation({
        mutationFn: OrderAPI.cancelFulfillOrder,
        onSuccess: () => {
            notify('success', 'Cancel order successfully')
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const getFulfillOrderMutation = useMutation({
        mutationFn: OrderAPI.getFulfillOrderDetail,
        onSuccess: () => {
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const openOrderMoreActionsModal = () => openModal(<OrderMoreActionsModal order={order} title='Đẩy lại đơn hàng' type='repush' />)

    const isNotReadyToFulfill = order.items.some((item) => !item.ff_info?.service)

    const fulfillData: any[] = Object.entries(order.more_info?.ff_info ?? {})

    const showErrorLog = fulfillData.some(([_, data]) => data?.detail?.success === false)

    const blockAllActions = order.status !== ORDER_STATUS.AWAITING_SHIPMENT && order.status !== ORDER_STATUS.AWAITING_COLLECTION

    return (
        <>
            {order.cancel_fulfill_at ? (
                <>
                    <div>
                        <Tag color='red'>Đơn bị Cancel lúc {formatDate(dayjs.unix(order.cancel_fulfill_at))}</Tag>
                    </div>
                    <Button
                        type='primary'
                        disabled={isNotReadyToFulfill}
                        onClick={() => pushToFulfillMutation.mutateAsync(order._id)}
                        loading={pushToFulfillMutation.isLoading}
                        className='mt-3'
                    >
                        Đẩy sang Fulfill
                    </Button>
                </>
            ) : order.push_to_fulfill_at ? (
                <>
                    <Tag color='green'>Đẩy sang fulfill lúc {formatDate(dayjs.unix(order.push_to_fulfill_at))}</Tag>
                    <Flex className='mt-2' gap={12}>
                        <Button
                            type='primary'
                            onClick={() => getFulfillOrderMutation.mutateAsync(order.order_id)}
                            loading={getFulfillOrderMutation.isLoading}
                            disabled={blockAllActions}
                        >
                            Cập nhật trạng thái
                        </Button>
                        <Button
                            type='primary'
                            onClick={openOrderMoreActionsModal}
                            disabled={blockAllActions}
                        >
                            Đẩy lại fulfill
                        </Button>
                        <Popconfirm
                            title='Xác nhận hủy'
                            description='Đơn hàng sẽ bị hủy trên hệ thống fulfill. Bạn có chắc chắn tiếp tục?'
                            onConfirm={() => cancelFulfillMutation.mutateAsync(order.order_id)}
                            okText='Yes'
                            cancelText='No'
                        >
                            <Button type='primary' danger loading={cancelFulfillMutation.isLoading} disabled={blockAllActions}>
                                Hủy đơn hàng
                            </Button>
                        </Popconfirm>
                    </Flex>
                    {/*<Button*/}
                    {/*    disabled={isNotReadyToFulfill}*/}
                    {/*    onClick={() => pushToFulfillMutation.mutateAsync(order._id)}*/}
                    {/*    loading={pushToFulfillMutation.isLoading}*/}
                    {/*    className='mt-2'*/}
                    {/*>*/}
                    {/*    Đẩy lại Fulfill*/}
                    {/*</Button>*/}
                    {showErrorLog && (
                        <div className='mt-3 border pt-3 px-2 pb-2 rounded-lg position-relative' style={{ borderColor: '#d9d9d9' }}>
                            <p
                                style={{
                                    position: 'absolute',
                                    top: -10,
                                    left: 10,
                                    fontSize: 11,
                                    backgroundColor: 'white',
                                    padding: '0 4px'
                                }}
                            >
                                Fulfill logs
                            </p>
                            {fulfillData.map(([userFulfillId, data]) => {
                                const userFulfill = userFulfillServices?.find((s) => s._id === userFulfillId)
                                return (
                                    <Flex key={userFulfillId} gap={5} style={{ fontSize: 12 }} align='flex-start'>
                                        {userFulfill ? (
                                            <Flex gap={8} align='center'>
                                                <img src={userFulfill.service.icon} width={20} height={20} alt='service icon' className='flex-shrink-1' />
                                                <p className='text-uppercase font-weight-bolder text-sm'>{userFulfill.service.service}</p>
                                            </Flex>
                                        ) : (
                                            <p className='font-weight-bolder'>{userFulfillId}</p>
                                        )}
                                        {'-'}
                                        <p className='text-danger font-weight-bolder'>{data?.detail?.errorMessage}</p>
                                    </Flex>
                                )
                            })}
                        </div>
                    )}
                </>
            ) : (
                <Button
                    type='primary'
                    disabled={isNotReadyToFulfill}
                    onClick={() => pushToFulfillMutation.mutateAsync(order._id)}
                    loading={pushToFulfillMutation.isLoading}
                >
                    Đẩy sang Fulfill
                </Button>
            )}

        </>
    )
}

export default OrderPushToFulFillColumn
