import React, { useMemo } from 'react'
import { Select, Tag, Flex } from 'antd'
import _ from 'lodash'
import { SelectProps } from 'antd/lib'
import { ITikTokShop } from 'types/ITikTokShop'
import { DEFAULT_OPTION_ALL_VALUE, TIKTOK_SHOP_IMPORT_SOURCES } from '../../const'
import { useModal } from '../../contexts/Modal'
import UnsupportedShopModal from '../Modal/UnsupportedShopModal'
import './styles.scss'
import { useAllTikTokShopQuery } from '../../hooks/queries/tiktok-shop'

interface ShopSelectProps extends SelectProps {
    data?: ITikTokShop[]
    show_proxy_status?: boolean
    show_authorize_status?: boolean
    show_shop_status?: boolean
    showAllOption?: boolean
    supportExternalShop?: boolean
}

const ShopSelect: React.FC<ShopSelectProps> = ({
    data,
    show_proxy_status = false,
    show_authorize_status = false,
    show_shop_status = false,
    showAllOption = true,
    supportExternalShop = false,
    ...restProps
}) => {
    const { data: tiktokShops } = useAllTikTokShopQuery({
        enabled: !data
    })

    const { openModal } = useModal()

    const preCheckShop =
        ({ shop, isValidSelect }: { shop: ITikTokShop; isValidSelect: boolean }) =>
        (e: React.MouseEvent<HTMLElement>) => {
            if (!isValidSelect) {
                e.stopPropagation()
                openModal(<UnsupportedShopModal shop={shop} />)
            }
        }

    const listOptions = useMemo(() => {
        const liveColor = '#52c41a'
        const deadColor = '#f5222d'
        return _.map(data ?? tiktokShops ?? [], (item) => {
            const isImportFromKiki = item.import_source === TIKTOK_SHOP_IMPORT_SOURCES.KIKI_LOGIN
            const isValidSelect = supportExternalShop || isImportFromKiki
            return {
                value: item._id,
                label: (
                    <Flex justify='space-between' align='center'>
                        <Flex justify='space-between' align='center' style={{ opacity: isValidSelect ? 1 : 0.4 }}>
                            {item.profile_name && <Tag color='#1677ff'>{item.profile_name || ''}</Tag>}
                            {item.name}
                        </Flex>
                        {isValidSelect ? (
                            <>
                                {show_authorize_status && item.authorize_token && (
                                    <Flex justify='space-between' align='center'>
                                        <Tag color={item.cipher && item.authorize_token ? liveColor : deadColor}>
                                            {item.cipher && item.authorize_token ? 'AUTHORIZED' : 'NOT_AUTHORIZED'}
                                        </Tag>
                                    </Flex>
                                )}
                                {show_proxy_status && item.cookie_status && (
                                    <Flex justify='space-between' align='center'>
                                        <Tag color={item.cookie_status === 'LIVE' ? liveColor : deadColor}>
                                            {item.cookie_status === 'LIVE' ? 'LIVE' : 'C-DEAD'}
                                        </Tag>
                                        <Tag color={item.proxy_status === 'LIVE' ? liveColor : deadColor}>
                                            {item.proxy_status === 'LIVE' ? 'LIVE' : 'P-DEAD'}
                                        </Tag>
                                    </Flex>
                                )}
                            </>
                        ) : (
                            <>
                                <Tag color={deadColor}>Không hỗ trợ</Tag>
                            </>
                        )}
                    </Flex>
                ),
                textSearch: `${item.profile_name ?? ''} ${item.name}`,
                shop: item,
                isValidSelect
            }
        })
    }, [data, tiktokShops])

    return (
        <Select
            optionFilterProp='textSearch'
            options={[
                ...(showAllOption
                    ? [
                          {
                              value: DEFAULT_OPTION_ALL_VALUE,
                              label: 'Tất cả cửa hàng'
                          }
                      ]
                    : []),
                ...listOptions
            ]}
            optionRender={(option) => {
                return (
                    <div
                        className='px-2 py-1 flex align-items-center w-100 h-100'
                        onClick={preCheckShop({
                            shop: option.data.shop,
                            isValidSelect: option.data.isValidSelect
                        })}
                    >
                        {option.label}
                    </div>
                )
            }}
            popupClassName='select-shop'
            {...restProps}
        />
    )
}

export default ShopSelect
