import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Form, Select, Flex } from 'antd'
import { CloudSyncOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import NicheAPI from 'api/NicheAPI'
import TextArea from 'antd/es/input/TextArea'
import { INiche } from 'types/INiche'
import TikTokShopAPI from 'api/TikTokShopAPI'
import Input from 'antd/lib/input/Input'
import { useMutation } from '@tanstack/react-query'
import {useShopAuth} from "../hooks/useShopAuth";

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetchData: () => void
    item: any
}

const EditTikTokShopModal: FC<Props> = ({ visible, toggleModal, refetchData, item }) => {
    const [form] = Form.useForm()
    const [niches, setNiches] = useState<INiche[]>([])
    const [loading, setLoading] = useState(false)
    const [subNiches, setSubNiches] = useState<INiche[]>([])
    const { mutationUpdateAuthorize, getShopAuth } = useShopAuth({ shop: item, form })

    const onClose = () => {
        toggleModal(false)
        setNiches([])
        form.resetFields()
    }

    useEffect(() => {
        if (visible) {
            setLoading(true)
            NicheAPI.getAllNiches()
                .then((res: any) => {
                    setNiches(res)
                    if (item?.main_niche) {
                        form.setFieldsValue({
                            main_niche: item?.main_niche._id,
                            sub_niche_ids: item?.sub_niches.map((niche: any) => niche._id)
                        })
                        let subNiches = res.filter((niche: any) => niche?.parent?._id == item?.main_niche._id)
                        setSubNiches(subNiches)
                    }
                    if (item?.key_words) {
                        form.setFieldsValue({ key_words: item?.key_words })
                    }
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
        if (item) {
            form.setFieldsValue({
                profile_id: item.profile_id,
                authorize_token: item.authorize_token ?? '',
                authorize_app_id: item.authorize_app_id ?? '',
                authorize_app_key: item.authorize_app_key ?? '',
                authorize_app_secret: item.authorize_app_secret ?? '',
                cipher: item.cipher ?? '',
                access_token: item.access_token ?? '',
                refresh_token: item.refresh_token ?? '',
                access_token_expire_in: item.access_token_expire_in ?? '',
                refresh_token_expire_in: item.refresh_token_expire_in ?? ''
            })
        }
    }, [visible, item])

    const handleEditShop = async (values: any) => {
        console.log('vvv', values)
        if (item) {
            try {
                setLoading(true)
                await TikTokShopAPI.editShop(item._id, values)
                notify('success', 'Cập nhật thành công')
                onClose()
                refetchData()
            } catch (err: any) {
                notify('error', err.message)
            } finally {
                setLoading(false)
            }
        } else {
            notify('error', 'Vui lòng nhập đầy đủ thông tin')
        }
    }
    const onValuesChangeForm = (changedValues: any, allValues: any) => {
        if (changedValues.main_niche) {
            let subNiches = niches.filter((niche) => niche?.parent?._id === changedValues.main_niche)
            setSubNiches(subNiches)
            form.setFieldsValue({ sub_niche_ids: [] })
        }
    }
    const renderSubNiche = () => {
        return _.map(subNiches, (niche: any) => {
            return (
                <Option value={niche._id} key={niche._id}>
                    {niche?.parent?.name && niche?.parent?.name + ' - '}
                    {niche.name}
                </Option>
            )
        })
    }

    const renderMainNiches = () => {
        let mainNiches = niches.filter((niche) => !niche.parent)
        return _.map(mainNiches, (niche: any) => {
            return (
                <Option value={niche._id} key={niche._id}>
                    {niche.name}
                </Option>
            )
        })
    }

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Chỉnh sửa TikTok Shop' footer={null} width={800} centered>
            <Divider />
            <Form
                layout={'vertical'}
                form={form}
                initialValues={{ layout: 'vertical' }}
                onFinish={handleEditShop}
                style={{ maxWidth: 800 }}
                onValuesChange={onValuesChangeForm}
            >
                <Form.Item label={<b>Profile ID</b>} name='profile_id'>
                    <Input placeholder='Nhập Profile ID' />
                </Form.Item>
                <Flex gap={12}>
                    <Form.Item label={<b>Chọn Main Niche</b>} name='main_niche' className='w-100'>
                        <Select allowClear style={{ width: '100%' }} placeholder='Chọn Main Niche' size='large'>
                            {renderMainNiches()}
                        </Select>
                    </Form.Item>
                    <Form.Item label={<b>Chọn Sub Niche</b>} name='sub_niche_ids' className='w-100'>
                        <Select allowClear style={{ width: '100%' }} placeholder='Chọn Niche' size='large' mode='multiple'>
                            {renderSubNiche()}
                        </Select>
                    </Form.Item>
                </Flex>
                <Form.Item label={<b>Key Words</b>} name='key_words'>
                    <TextArea placeholder='Nhập key words' autoSize={{ minRows: 3 }} />
                </Form.Item>
                <Flex gap={12}>
                    <Form.Item label={<b>Authorize App ID</b>} name='authorize_app_id' className='w-100'>
                        <Input placeholder='Nhập App ID' />
                    </Form.Item>
                    <Form.Item label={<b>Authorize App Key</b>} name='authorize_app_key' className='w-100'>
                        <Input placeholder='Nhập App Key' />
                    </Form.Item>
                    <Form.Item label={<b>Authorize App Secret</b>} name='authorize_app_secret' className='w-100'>
                        <Input placeholder='Nhập App Secret' />
                    </Form.Item>
                </Flex>
                <Flex gap={12} justify='flex-end'>
                    <Button type='primary' onClick={getShopAuth} loading={mutationUpdateAuthorize.isLoading}>
                        Get shop auth
                    </Button>
                </Flex>
                <Flex gap={12} className='mt-2'>
                    <Form.Item label={<b>Shop cipher</b>} name='cipher' className='w-100'>
                        <Input placeholder='Shop cipher' disabled />
                    </Form.Item>
                    <Form.Item label={<b>Access token</b>} name='access_token' className='w-100'>
                        <Input placeholder='Shop access token' disabled />
                    </Form.Item>
                    <Form.Item label={<b>refresh_token</b>} name='refresh_token' className='d-none'>
                        <Input placeholder='Nhập App Secret' />
                    </Form.Item>
                    <Form.Item label={<b>access_token_expire_in</b>} name='access_token_expire_in' className='d-none'>
                        <Input placeholder='Nhập App Secret' />
                    </Form.Item>
                    <Form.Item label={<b>refresh_token_expire_in</b>} name='refresh_token_expire_in' className='d-none'>
                        <Input placeholder='Nhập App Secret' />
                    </Form.Item>
                </Flex>
                <Form.Item
                    label={
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                            <p style={{ width: 500, fontWeight: 600 }}>Authorize Token</p>
                        </div>
                    }
                    name='authorize_token'
                    className='d-none'
                >
                    <TextArea placeholder='Nhập Authorize Token' autoSize={{ minRows: 3 }} />
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' icon={<CloudSyncOutlined />} type='primary' loading={loading}>
                        Lưu thay đổi
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditTikTokShopModal
