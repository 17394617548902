import React from 'react';
import {Typography} from "antd";
import {LabelProps} from "../interface/tiktok-label.interface";

const COLORS = {
    success: {
        bg: '#08660D',
        textColor: '#45B26B',
    },
    warning: {
        bg: '#FFB90A',
        textColor: 'warning.dark',
    },
    error: {
        bg: '#FF0000',
        textColor: 'error.dark',
    },
    purple: {
        bg: '#F4F3FF',
        textColor: '#5925DC',
    },
};

function Label({ color, label }: LabelProps) {
    const { bg, textColor } = COLORS[color];
    return (
        <Typography
            style={{ fontSize: 14, backgroundColor: bg, color: textColor, padding: '4px 8px', borderRadius: 0.5, fontWeight: 700 }}
        >
            {label}
        </Typography>
    );
}

export default Label;
