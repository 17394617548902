import React, {useEffect, useState} from 'react'
import { useFulfillServiceQuery } from '../Order/queries/fulfill.query'
import { useUserFulfillServicesQuery } from './queries/user-fulfill.query'
import {Divider, Flex, Switch, Tag} from 'antd'
import { IFulfill, IUserFulfill } from '../../types/IFulfill'
import { useModal } from '../../contexts/Modal'
import UpdateFulfillServiceModal from './modals/UpdateFulfillServiceModal'
import {useMutation, useQueryClient} from "@tanstack/react-query";
import OrderAPI from "../../api/OrderAPI";
import {notify} from "../../contexts/Notification";
import {useAuth} from "../../contexts/Authenticate";
import {CODE_PERMISSION} from "../../const/permission";
import {EnumAction} from "../../types/IPermission";

const ServiceItem = (service: IFulfill & { userData?: IUserFulfill }) => {
    const { user } = useAuth()
    const [isActive, setIsActive] = useState(service.userData?.is_active ?? false)
    const { openModal } = useModal()
    const mutate = useMutation({
        mutationFn: OrderAPI.mutateUserFulfillService,
        onSuccess: () => {
            notify('success', 'Thành công')
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
                setIsActive(checked => !checked)
            }
        }
    })

    const permission = user?.permissions?.find((permission: { code_permission: string }) => permission.code_permission === CODE_PERMISSION.FULFILL_MANAGEMENT)

    useEffect(() => {
        setIsActive(service.userData?.is_active ?? false)
    }, [service.userData?.is_active]);

    const openUpdateServiceModal = () => openModal(<UpdateFulfillServiceModal service={service} />)

    const onActiveChange = (checked: boolean) => {
        setIsActive(checked)
        if (!service.userData) {
            openUpdateServiceModal()
        } else {
            void mutate.mutateAsync({
                sId: service.userData._id,
                data: {
                    is_active: checked
                }
            })
        }
    }

    return (
        <>
            <Flex gap={12} className='mb-4' justify='space-between' align='center'>
                <Flex gap={12}>
                    <img src={service.icon} width={36} height={36} alt='service icon' className='flex-shrink-1' />
                    <div>
                        <p className='text-uppercase font-weight-bolder'>{service.service}</p>
                        <p>{service.description}</p>
                    </div>
                </Flex>
                {
                    (user?.isOwner || (permission?.actions?.includes(EnumAction.Edit) || permission?.actions?.includes(EnumAction.Create))) ? (
                        <Flex vertical align='flex-end'>
                            <Switch value={isActive} onChange={onActiveChange} />
                            {service.userData && <p className='mt-2 text-sm cursor-pointer text-hover-primary' onClick={openUpdateServiceModal}>Chỉnh sửa thông tin</p>}
                        </Flex>
                    ) : (
                        <>
                            <Tag color={isActive ? '#09b412' : '#f6090e'}>{isActive ? 'Active' : 'Disabled'}</Tag>
                        </>
                    )
                }

            </Flex>
        </>
    )
}

export const mergeFulfillData = (fulfillServices: IFulfill[] = [], userFulfillServices: IUserFulfill[] = []) => {
    return fulfillServices.map((service) => {
        return {
            ...service,
            userData: userFulfillServices?.find((s) => s.service._id === service._id)
        }
    })
}

function SettingFulfill() {
    const { data: fulfillServices } = useFulfillServiceQuery()
    const { data: userFulfillServices } = useUserFulfillServicesQuery()

    const userFulfillServicesData = mergeFulfillData(fulfillServices, userFulfillServices)

    return (
        <div className='mt-3'>
            {fulfillServices && userFulfillServices && userFulfillServicesData?.length > 0 && userFulfillServicesData.map((service) => (
                <React.Fragment key={service._id}>
                    <ServiceItem {...service} />
                    <Divider />
                </React.Fragment>
            ))}
        </div>
    )
}

export default SettingFulfill
