import { ComponentType } from 'react';
import {HealthCheckRowCommon, HealthCheckRowProps} from "../interface/healthcheck.interface";
import FieldError from "./FieldError";

type WrappedComponentProps = HealthCheckRowProps &
  HealthCheckRowCommon & {
    tableField: string;
    errorField?: string;
    hasData: boolean;
    [X: string]: any;
  };
type WrappedComponentType = ComponentType<WrappedComponentProps> & {
  Error?: ComponentType<WrappedComponentProps>;
};

function withStatusCheck(WrappedComponent: WrappedComponentType) {
  return function StatusCheckComponent(row: WrappedComponentProps) {
    const errorMessage = row.errorData?.[row.errorField];

    if (!row.hasData) {
      return <div>--</div>;
    }

      if (errorMessage) {
          return WrappedComponent.Error ? <WrappedComponent.Error {...row} /> : <FieldError {...row} />;
      }

    return (
      <div className='d-flex gap-2 align-items-center'>
        <WrappedComponent {...row} />
      </div>
    );
  };
}

export default withStatusCheck;
