import ApiSender from './ApiSender'
import {IUser, IUserOrder, IUserOrderQueryParams} from "../types/IUser";
import {IListApiResponse} from "../types/IApiResponse";
import {IAffiliate} from "../pages/AccountManagement/interface/affiliate.interface";

export default class UserAPI {
    public static getOrders(params: IUserOrderQueryParams): Promise<IListApiResponse<IUserOrder>> {
        return ApiSender.get('/core/user/orders', params)
    }

    public static getAffiliateInvited(): Promise<IAffiliate> {
        return ApiSender.get('/core/user/affiliate/invited')
    }
}
