import { Button, Divider, InputNumber, Select, Space } from 'antd'
import _ from 'lodash'
import { memo, useEffect, useState } from 'react'

function SKUQuantityLimitColumn({
    sku_id,
    quantity_limit,
    is_on_sale,
    onChange
}: {
    sku_id: string
    quantity_limit: number
    is_on_sale: boolean
    onChange: (sku_id: string, quantity_limit: number) => void
}) {
    console.log('SKUQuantityLimitColumn:', sku_id, quantity_limit)
    const [itemsQuantityLimit, setItemsQuantityLimit] = useState([{ label: 'No limit', value: -1 }])
    const [quantityLimit, setQuantityLimit] = useState(-1)
    useEffect(() => {
        if (quantity_limit) {
            const limit = _.toInteger(quantity_limit || -1)
            setQuantityLimit(limit)
            if (limit !== -1) {
                const newItems = [...itemsQuantityLimit]
                if (newItems.length === 1) {
                    newItems.push({ label: `${limit}`, value: limit })
                    setItemsQuantityLimit(newItems)
                } else {
                    newItems[1] = { label: `${limit}`, value: limit }
                    setItemsQuantityLimit(newItems)
                }
            }
        }
    }, [quantity_limit])

    const applyPurchaseLimit = () => {
        if (quantityLimit) {
            const limit = _.toInteger(quantityLimit)
            setQuantityLimit(limit)
            const newItems = [...itemsQuantityLimit]
            if (newItems.length === 1) {
                newItems.push({ label: `${limit}`, value: limit })
                setItemsQuantityLimit(newItems)
            } else {
                newItems[1] = { label: `${limit}`, value: limit }
                setItemsQuantityLimit(newItems)
            }
            if (onChange) {
                onChange(sku_id, limit)
            }
        }
    }

    const onChangeQuantityLimit = (value: number | null) => {
        if (value === null) return
        setQuantityLimit(value)
        if (onChange) {
            onChange(sku_id, value)
        }
    }
    return (
        <>
            <Select
                disabled={!is_on_sale}
                placeholder='Total purchase limit'
                size='large'
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <InputNumber
                                placeholder='Please enter item'
                                value={quantityLimit}
                                onChange={(value) => setQuantityLimit(Number(value))}
                            />
                            <Button type='primary' onClick={applyPurchaseLimit}>
                                Apply
                            </Button>
                        </Space>
                    </>
                )}
                options={itemsQuantityLimit}
                value={quantityLimit}
                onChange={onChangeQuantityLimit}
            />
        </>
    )
}

export default memo(SKUQuantityLimitColumn)
