import React, { useState } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Button, Col, Flex, Modal, Row, Typography, Upload } from 'antd'
import { IOrder, OrderItem } from '../../../types/IOrder'
import './artwork.style.scss'
import type { UploadFile, UploadProps } from 'antd'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import {  ArtworkModalState } from '../interfaces/artwork.interface'
import { useMutation } from '@tanstack/react-query'
import { CloseCircleOutlined } from '@ant-design/icons'

function ArtworkUploadModal({
    product,
    onUploadArtworkSuccess,
}: {
    product: OrderItem
    order: IOrder
    onUploadArtworkSuccess: () => Promise<void>
}) {
    const { closeModal } = useModal()
    const [uploadLoading, setUploadLoading] = useState<boolean>(false)
    const [removeDirection, setRemoveDirection] = useState<string[]>([])


    const [artworks, setArtworks] = useState<ArtworkModalState[]>(() => product.artworks?.map(artwork => ({
        ...artwork,
        preview: artwork.value.preview,
        file: null
    })) ?? [])

    const handleChange =
        (item: ArtworkModalState): UploadProps['onChange'] =>
        (info) => {
            const currentArtworks = [...artworks]
            const currentAttrIdx = currentArtworks.findIndex(artwork => artwork.attribute === item.attribute)
            if (currentAttrIdx === -1) return;
            currentArtworks[currentAttrIdx] = {
                ...currentArtworks[currentAttrIdx],
                file: info.fileList.find((file) => file.uid === info.file.uid) ?? info.fileList[info.fileList.length - 1],
                preview: URL.createObjectURL(info.file as unknown as File)
            }
            setArtworks(currentArtworks)
            setRemoveDirection((prev) => prev.filter((dir) => dir !== item.attribute))
        }

    const uploadImage = async (image: ArtworkModalState) => {
        if (!image.file || !image.file.originFileObj) {
            return {
                success: false,
                message: `Upload ${image.attribute} error: Missing file upload data`
            }
        }
        try {
            const formData = new FormData()
            formData.append('file', image.file.originFileObj)
            formData.append('seller_sku', product.seller_sku)
            formData.append('item_id', product._id)
            formData.append('attribute', image.attribute)
            const response = await OrderAPI.uploadOrderItemArtwork(formData)
            return {
                success: true,
                data: response.data.preview
            }
        } catch (err) {
            return {
                success: false,
                message: `Upload ${image.attribute} error: ${(err as Error).message}`
            }
        }
    }

    const uploadImages = async () => {
        setUploadLoading(true)
        const _data = artworks.filter((item) => !!item.file)
        for (let i = 0; i < _data.length; i++) {
            const response = await uploadImage(_data[i])
            if (response.success) {
                notify('success', `Upload ${_data[i].attribute} successfully`)
            } else {
                notify('error', response.message ?? '')
            }
        }

        if (removeDirection.length > 0) {
            try {
                await OrderAPI.removeOrderItemArtwork(product._id, removeDirection)
                notify('success', `Remove ${removeDirection.join(', ')} successfully`)
            } catch (err) {
                notify('error', (err as Error).message)
            }
        }
        await onUploadArtworkSuccess()
        setUploadLoading(false)
        closeModal()
    }

    const removeImage = (item: ArtworkModalState) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        const currentArtworks = [...artworks]
        const currentAttrIdx = currentArtworks.findIndex(artwork => artwork.attribute === item.attribute)
        if (currentAttrIdx === -1) return;
        currentArtworks[currentAttrIdx] = {
            ...currentArtworks[currentAttrIdx],
            file: null,
            preview: null
        }
        setArtworks(currentArtworks)
        setRemoveDirection((prev) => Array.from(new Set([...prev, item.attribute])))
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={'Upload product artwork'}
            footer={null}
            width={800}
            className='artwork-config-modal'
        >
            <Row gutter={24}>
                {artworks.map((item) => (
                    <Col span={6} key={item.attribute} className='mb-2'>
                        <p className='text-uppercase'>{item.attribute.split('_').join(' ').replace('url', '')}</p>
                        <Upload
                            name={item.attribute}
                            listType='picture-card'
                            className='avatar-uploader position-relative mb-2'
                            showUploadList={false}
                            accept='image/*'
                            onChange={handleChange(item)}
                            beforeUpload={() => false}
                        >
                            {item.preview ? (
                                <>
                                    <img src={item.preview} alt='avatar' style={{ width: '80%' }} />
                                    <CloseCircleOutlined
                                        onClick={removeImage(item)}
                                        style={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            fontSize: 18,
                                            color: '#b9b0b0'
                                        }}
                                    />
                                </>
                            ) : (
                                <button style={{ border: 0, background: 'none' }} type='button'>
                                    {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </button>
                            )}
                        </Upload>

                        {item.value.preview && (
                            <>
                                <OriginalButton product={product} item={item} />
                            </>
                        )}
                    </Col>
                ))}
            </Row>
            <Button type='primary' className='mt-4 w-100' onClick={uploadImages} loading={uploadLoading}>
                Xác nhận
            </Button>
        </Modal>
    )
}

export default ArtworkUploadModal

const OriginalButton = ({ product, item }: { product: OrderItem; item: ArtworkModalState }) => {
    const viewOriginalMutation = useMutation({
        mutationFn: OrderAPI.getSignedImageUrl,
        onSuccess: (data) => {
            if (data?.data) {
                window.open(data.data, '_blank')
            } else {
                notify('error', 'Cannot get original image url')
            }
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    const originalUrl = item.value.original

    const viewOriginal = async () => {
        if (!originalUrl) {
            return
        }
        await viewOriginalMutation.mutateAsync({
            orderItemId: product._id,
            attribute: item.attribute
        })
    }

    if (!item.value.preview || !item.value.preview.startsWith('https')) return null

    if (viewOriginalMutation.isLoading) {
        return (
            <Typography className='mt-2 cursor-pointer' style={{ fontSize: 10 }}>
                Đang tải...
            </Typography>
        )
    }

    return (
        <Typography className='cursor-pointer text-hover-primary' style={{ fontSize: 10, width: 'fit-content' }} onClick={viewOriginal}>
            {item.value.preview.startsWith('https') || originalUrl ? 'Xem ảnh gốc' : 'Không khả dụng'}
        </Typography>
    )
}
