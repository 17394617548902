import { useQuery } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { IOrderQueryParams } from '../../../types/IOrder'
import { useOrderStore } from '../store/order.store'
import useDebounce from '../../../hooks/useDebounce'

export const LIST_ORDER_QUERY_KEY = 'LIST_ORDER_QUERY_KEY'

export const useOrderQuery = (queryParams: IOrderQueryParams) => {
    const filter = useOrderStore((state) => state.filter)
    const search = useDebounce(filter.order_id)
    const query = {
        shop: filter.shop,
        user: filter.user,
        status: filter.status,
        fulfilled_by: filter.fulfilledBy,
        date_range: filter.dateRange.from && filter.dateRange.to ? filter.dateRange : undefined,
        ...filter.others,
        ...queryParams,
        search,
    }
    return useQuery({
        queryKey: [LIST_ORDER_QUERY_KEY, query],
        queryFn: () => OrderAPI.getOrders(query)
    })
}
