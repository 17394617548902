import { useEffect, useState, FC, useCallback } from 'react'
import { Row, Col, Select, Button, Form, Input, Cascader, InputNumber, Modal, Divider, Switch, Flex } from 'antd'
import _ from 'lodash'
import { ICategory, EnumCategoryType } from 'types/ICategory'
import { notify } from 'contexts/Notification'
import { BRANDS } from 'const'
import ConfigProductAPI from 'api/ConfigProductAPI'
import { IConfigProduct, IProductProperties } from 'types/IConfigProduct'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps, UploadFile, GetProp } from 'antd'
import { message, Upload } from 'antd'
import ProductPropertiesV2 from 'core/CreateProduct/ProductProperties'
import { useCategoriesQuery } from 'hooks/queries/category'

const { Option } = Select

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item?: IConfigProduct
}

const ConfigProductModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [category, setCategory] = useState<string[]>(['824328', '839944', '601226', '1165840'])
    const [productProperties, setProductProperties] = useState<IProductProperties[]>([])
    const [type, setType] = useState('MANUAL') // MANUAL, IMPORT
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [jsonObject, setJsonObject] = useState([])
    const categoriesQueryResponse = useCategoriesQuery(EnumCategoryType.TREE)
    useEffect(() => {
        if (!visible) return
        if (item) {
            form.setFieldsValue({
                categories: item.categories,
                brand_id: item.brand_id,
                height: item.package_height,
                width: item.package_width,
                length: item.package_length,
                weight: item.package_weight,
                quantity: item.quantity,
                is_default: item.is_default,
                position: item.position || 1
            })
            setProductProperties(item.product_properties as IProductProperties[])
            setCategory(item.categories)
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        form.setFieldsValue({
            quantity: 2,
            brand: '0',
            unit: 'INCH',
            weight_unit: 'POUND',
            categories: ['824328', '839944', '601226', '1165840'],
            height: 9,
            width: 9,
            length: 1,
            weight: 0.42,
            is_default: false
        })
        setCategory(['824328', '839944', '601226', '1165840'])
        setProductProperties([])
        toggleModal(false)
        setFileList([])
        setJsonObject([])
        setType('MANUAL')
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!item) {
                setLoading(true)
                let payload = {
                    ...values
                }
                console.log('payload', payload)
                await ConfigProductAPI.createConfigProduct(payload)
                notify('success', 'Tạo cấu hình thành công')
            } else {
                setLoading(true)
                let payload = {
                    ...values,
                    product_properties: productProperties
                }
                await ConfigProductAPI.editConfigProduct(item._id, payload)
                notify('success', 'Lưu cấu hình thành công')
            }
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }
    const handleChangeProductProperties = useCallback(
        (product_properties: IProductProperties[]) => {
            console.log('handleChangeProductProperties', product_properties)
            form.setFieldValue('product_properties', product_properties)
        },
        [form]
    )

    const renderBrands = () => {
        return _.map(BRANDS, (brand: any) => {
            return (
                <Option value={brand.id} key={brand.id} textSearch={`${brand.name}`}>
                    {`${brand.name}`}
                </Option>
            )
        })
    }
    const onChangeForm = (changedValues: any) => {
        if (changedValues.categories) {
            setCategory(changedValues.categories)
            setProductProperties([])
        }
    }

    const handleFileChange = (info: any) => {
        try {
            setFileList([info.fileList[info.fileList.length - 1]])
            const reader = new FileReader()
            reader.onload = () => {
                const content = reader.result
                setJsonObject(JSON.parse(content as string))
            }
            reader.readAsText(info.fileList[info.fileList.length - 1].originFileObj)
        } catch (error) {
            message.error('Error reading file:')
        }
    }
    const handleImport = () => {
        if (!jsonObject) {
            message.error('Vui lòng chọn file cấu hình')
            return
        }
        if (!Array.isArray(jsonObject)) {
            message.error('File cấu hình không đúng định dạng')
            return
        }
        if (jsonObject.length === 0) {
            message.error('File cấu hình không có dữ liệu')
            return
        }
        setLoading(true)
        ConfigProductAPI.importConfigProduct({ configs: jsonObject as unknown as any[] })
            .then(() => {
                notify('success', 'Import cấu hình thành công')
                onClose()
                refetch()
            })
            .catch((err: any) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Cấu hình sản phẩm mẫu'
            footer={null}
            width={1280}
            centered
            // style={{ margin: '25px' }}
        >
            <Divider />
            <Select placeholder='Chọn' value={type} size='large' onChange={(val) => setType(val)} style={{ width: '100%' }} className='mb-3'>
                <Option value='MANUAL'>THỦ CÔNG</Option>
                <Option value='IMPORT'>IMPORT FILE</Option>
            </Select>
            {type === 'MANUAL' ? (
                <Form
                    name='ConfigProductModal'
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                    form={form}
                    initialValues={{
                        brand: '0',
                        unit: 'INCH',
                        weight_unit: 'POUND',
                        categories: ['824328', '839944', '601226', '1165840'],
                        height: 9,
                        width: 9,
                        length: 1,
                        weight: 0.42,
                        quantity: 2,
                        is_default: false
                    }}
                    onValuesChange={onChangeForm}
                >
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <Form.Item label={<b>Category</b>} name='categories' rules={[{ required: true, message: 'Vui lòng chọn hạng mục!' }]}>
                                <Cascader options={categoriesQueryResponse?.data ?? []} showSearch size='large' disabled={!!item} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<b>Brand</b>} name='brand_id' rules={[{ required: true, message: 'Vui lòng chọn thương hiệu!' }]}>
                                <Select placeholder='Chọn thương hiệu' showSearch optionFilterProp='textSearch' size='large'>
                                    <Option value='0'>No Brand</Option>
                                    {renderBrands()}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Form.Item name='product_properties'>
                            <div>
                                <ProductPropertiesV2 category={category} value={productProperties} onChange={handleChangeProductProperties} />
                            </div>
                        </Form.Item>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={6}>
                            <Form.Item label={<b>Height</b>} name='height' rules={[{ required: true, message: 'Vui lòng nhập Height sản phẩm!' }]}>
                                <InputNumber placeholder='Nhập Height sản phẩm' min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={<b>Width</b>} name='width' rules={[{ required: true, message: 'Vui lòng nhập Width sản phẩm!' }]}>
                                <InputNumber placeholder='Nhập Width phẩm' min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={<b>Length</b>} name='length' rules={[{ required: true, message: 'Vui lòng nhập Length sản phẩm!' }]}>
                                <InputNumber placeholder='Nhập Length sản phẩm' min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={<b>Unit</b>} name='unit' rules={[{ required: true, message: 'Vui lòng nhập Unit!' }]}>
                                <Input placeholder='Nhập Unit' readOnly />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={6}>
                            <Form.Item
                                label={<b>Package Weight</b>}
                                name='weight'
                                rules={[{ required: true, message: 'Vui lòng nhập trọng lượng sản phẩm!' }]}
                            >
                                <InputNumber addonAfter='POUND' style={{ width: '100%' }} min={0} placeholder='Nhập Trọng lượng sản phẩm' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={<b>Quantity</b>}
                                name='quantity'
                                rules={[{ required: true, message: 'Vui lòng nhập số lượng sản phẩm!' }]}
                            >
                                <InputNumber placeholder='Nhập số lượng sản phẩm' min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={6}>
                            <Form.Item label={<b>Đặt làm mặc định</b>} name='is_default' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={<b>Vị trí hiển thị</b>} name='position'>
                                <InputNumber placeholder='Nhập vị trí hiển thị' min={0} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex align='center' justify='center'>
                        <Button type='primary' htmlType='submit' loading={loading} size='large'>
                            {item ? 'Lưu cấu hình' : 'Tạo cấu hình'}
                        </Button>
                    </Flex>
                </Form>
            ) : (
                <div className='mt-2'>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Upload
                            name='file'
                            beforeUpload={() => false}
                            multiple={false}
                            fileList={fileList}
                            onChange={handleFileChange}
                            accept='.json'
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Row>
                    <p className='text-center mb-2' style={{ color: 'red' }}>
                        <i>Lưu ý: Import cấu hình này sẽ ghi đè các cấu hình có sẵn ở tài khoản của bạn</i>
                    </p>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Button type='primary' loading={loading} size='large' onClick={handleImport}>
                            Import cấu hình
                        </Button>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default ConfigProductModal
