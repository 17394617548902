import React from 'react'
import { Helmet } from 'react-helmet'
import { Card } from 'antd'
import SettingFulfill from '../AccountManagement/SettingFulfill'
import {EnumRole} from "../../types/IRole";
import WithLeader from "../../core/HOC/WithLeader";

function FulfillManagement() {
    return (
        <WithLeader addedAllowRoles={[EnumRole.ACCOUNTANT]} addedBlockRoles={[EnumRole.RESOURCE]}>
            <Card title='Quản lý fulfill'>
                <Helmet>
                    <title>Quản lý fulfill</title>
                </Helmet>
                <SettingFulfill />
            </Card>
        </WithLeader>
    )
}

export default FulfillManagement
