import React from 'react';
import {Button, Input, Modal, Typography} from "antd";
import {useModal} from "../../../../../contexts/Modal";
import {formatCurrency, numberToWords, USDvsVND, VNDvsUSD} from "../../../../../utils/format";
import useInput from "../../../../../hooks/useInput";
import PaidModal from "./PaidModal";

function DepositAmountModal({amount }: {amount: string}) {
    const {closeModal, openModal} = useModal()
    const [inputAmount, onInputAmountChange] = useInput(`${(Number(amount) / USDvsVND)}`)

    const isInvalidAmount = isNaN(Number(inputAmount)) || (Number(inputAmount) <= 0)

    const openPaidModal = () => openModal(<PaidModal amount={`${Number(inputAmount) * USDvsVND}`} />)

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Nạp tiền' footer={null} width={500}>
            <Typography className='mt-2'>Nhập số tiền muốn nạp</Typography>
            <Input value={inputAmount} onChange={onInputAmountChange} placeholder='Nhập số tiền muốn nạp' className='mt-2' />
            <p className='font-italic mt-1'>Bằng chữ: <strong>{formatCurrency(inputAmount, { rate: VNDvsUSD, suffix: 'đ' })}</strong></p>
            <Button type='primary' className='w-100 mt-3' disabled={isInvalidAmount} onClick={openPaidModal}>Xác nhận</Button>
        </Modal>
    );
}

export default DepositAmountModal;
