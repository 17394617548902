import React, { useCallback, useState } from 'react'
import { IListApiResponse } from '../types/IApiResponse'
import { UseQueryResult } from '@tanstack/react-query'
import {DEFAULT_PAGINATION_CONFIGS} from "../const";

export interface ITableParams {
    page_size: number
    page_number: number
}

export interface TableConfigs<TData> {
    queryResponse?: UseQueryResult<IListApiResponse<TData>>;
    pagination: {
        pageNumber: number;
        pageSize: number;
        total: number;
        handleChangePagination: (_pageNumber: number, _pageSize: number) => void;
        resetPageNumber: (page?: number) => void;
    },
    toolbar: {
        search: string;
        onSearchChange: (e: string | React.ChangeEvent<HTMLInputElement>) => void
    }
}

export function useTableConfigs<TData>(queryFn: <TParams extends ITableParams>(query: TParams) => UseQueryResult<IListApiResponse<TData>>, initFilter: Record<string, unknown> = {}): TableConfigs<TData> {
    const [pageSize, setPageSize] = useState(DEFAULT_PAGINATION_CONFIGS.pageSize)
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGINATION_CONFIGS.pageNumber)
    const [search, setSearch] = useState('')

    const queryResponse = queryFn({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...initFilter
    })

    const handleChangePagination = useCallback((_pageNumber: number, _pageSize: number) => {
        setPageSize(_pageSize)
        setPageNumber(_pageNumber)
    }, [])

    const onSearchChange = useCallback((e: string | React.ChangeEvent<HTMLInputElement>) => {
        if (typeof e === 'string') {
            setSearch(e)
        } else {
            setSearch(e.target.value)
        }
        resetPageNumber();
    }, [])

    const resetPageNumber = useCallback((pageTarget = 1) => {
      setPageNumber(pageTarget)
    }, [])

    return {
        queryResponse,
        pagination: {
            pageNumber,
            pageSize,
            total: queryResponse.data?.pagination?.total ?? 0,
            handleChangePagination,
            resetPageNumber
        },
        toolbar: {
            search,
            onSearchChange
        }
    }
}
