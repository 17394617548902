import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import { AuthProvider } from 'contexts/Authenticate'
import { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from 'pages/Auth/Login'
import Wrapper from 'pages/Wrapper'
import { AuthenticatedRoute } from 'core/RRoute'
import Register from './Auth/Register'
import { ModalProvider } from 'contexts/Modal'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Join from "./Auth/Join";
import Page403 from "./Page403";
import TiktokAuth from "./TiktokAuth";
import Authorization from './Auth/Authorization'

dayjs.extend(utc)
dayjs.extend(timezone)

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: false
        }
    }
})

const App = () => (
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ModalProvider>
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '50%'
                                }}
                            >
                                <Spin size='large' />
                            </div>
                        }
                    >
                        <Routes>
                            <Route path='/tiktok/auth' element={<TiktokAuth />} />
                            <Route path='/tiktok/authorize' element={<Authorization />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='/register' element={<Register />} />
                            <Route path='/join/:inviteCode' element={<Join />} />
                            <Route element={<AuthenticatedRoute />}>
                                <Route path='403' element={<Page403 />} />
                                <Route path='*' element={<Wrapper />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </ModalProvider>
            </AuthProvider>
        </QueryClientProvider>
    </BrowserRouter>
)

export default App
