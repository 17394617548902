import ApiSender from './ApiSender'
import { IListApiResponse } from '../types/IApiResponse'
import { IUser } from '../types/IUser'

export default class StaffAPI {
    public static getStaffs(params: { page_size: number; page_number: number; is_active?: boolean }): Promise<IListApiResponse<IUser>> {
        const route = `/core/user/staffs`
        return ApiSender.get(route, params)
    }

    public static getAllStaffs(params?: Record<string, any>) {
        const route = `/core/user/all-staffs`
        return ApiSender.get(route, params)
    }

    public static createStaff(payload: { name: string;}) {
        const route = `/core/user/staffs`
        return ApiSender.post(route, payload)
    }

    public static editStaff(_id: string, payload: { name: string;  }) {
        const route = `/core/user/staffs/${_id}`
        return ApiSender.put(route, payload)
    }

    public static toggleActiveStaff({_id, is_active}: {_id: string; is_active: boolean }) {
        const route = `/core/user/staffs/${_id}/toggle_active`
        return ApiSender.put(route, { is_active })
    }

    public static getPartnerStaffs() {
        const route = `/core/user/partner/staffs`
        return ApiSender.get(route)
    }

    public static getDetailStaff(id: number) {
        const route = `/core/user/partner/staffs/${id}`
        return ApiSender.get(route)
    }

    public static getRoles() {
        const route = `/core/user/staffs/roles`
        return ApiSender.get(route)
    }
}
