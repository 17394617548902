import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import dayjs from 'dayjs'
import { createRoot } from 'react-dom/client';
// import 'antd/dist/antd.css'
import './index.css'
import './styles/index.scss'
import './composeAssets'
import App from 'pages'
import reportWebVitals from './reportWebVitals'
import 'ckeditor5/ckeditor5.css';
const container = document.getElementById('dashboard-container');
// trigger build

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
