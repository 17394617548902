import {ColumnsType} from "antd/lib/table";
import {ITikTokShop} from "../../../../types/ITikTokShop";
import {formatCurrency} from "../../../../utils/format";

export const SHOP_STATEMENT_COLUMNS: ColumnsType<ITikTokShop> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Shop code',
        dataIndex: 'shop_code',
        key: 'shop_code',
    },
    {
        title: 'Tên profile',
        dataIndex: 'profile_name',
        key: 'profile_name',
    },
    {
        title: 'Tên shop',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Doanh số (Ước lượng)',
        dataIndex: ['statement', 'revenue'],
        key: 'statement.revenue',
        render: (value) => formatCurrency(value, { prefix: '$' })
    },
    {
        title: 'Tiền hold (Ước lượng)',
        dataIndex: ['statement', 'hold'],
        key: 'statement.hold',
        render: (_, record) => record.statement ? formatCurrency(record.statement.revenue - record.statement.paid_amount, { prefix: '$' }) : '-'
    },
    {
        title: 'Paid',
        dataIndex: ['statement', 'paid_amount'],
        key: 'statement.paid_amount',
        render: (value) => formatCurrency(value, { prefix: '$' })
    },
    {
        title: 'Ngày hold',
        dataIndex: ['statement', 'hold_duration'],
        key: 'statement.hold_duration',
    },
    {
        title: 'Bank account',
        dataIndex: ['statement', 'bank_account'],
        key: 'statement.bank_account',
        render: value => `****${value}`
    },
    {
        title: 'Bank get',
        dataIndex: ['statement', 'bank_get', 'account_number_last_4_digits'],
        key: 'statement.bank_get.account_number_last_4_digits',
        render: value => value ? `****${value}` : ''
    },
    {
        title: 'Seller',
        dataIndex: ['assignee', 'email'],
        key: 'assignee',
    },
]
