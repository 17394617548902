import { Flex } from 'antd'
import { ReactNode } from 'react'
import './ButtonFixedLayout.style.scss'
const ButtonFixedLayout = ({ children, collapsed }: { children: ReactNode; collapsed: boolean }) => {
    return (
        <Flex justify='center' align='center' className='layout-fixed' style={{ left: collapsed ? '80px' : '220px' }}>
            {children}
        </Flex>
    )
}

export default ButtonFixedLayout
