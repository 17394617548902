import { EnumCategoryType } from 'types/ICategory'
import ApiSender from './ApiSender'

export default class CategoryAPI {
    public static getCategories(type?: EnumCategoryType) {
        const route = `/core/user/categories?type=${type}`
        return ApiSender.get(route)
    }

    public static getProductAttributes(category_id: string) {
        const route = `/core/user/categories/${category_id}/product-attributes`
        return ApiSender.get(route)
    }
}
