import React from 'react'
import { ITikTokShop } from '../../types/ITikTokShop'
import { Alert, Modal } from 'antd'
import { useModal } from '../../contexts/Modal'

function UnsupportedShopModal({ shop }: { shop: ITikTokShop }) {
    const { closeModal } = useModal()

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Tính năng không được hỗ trợ' footer={null} width={600}>
            <Alert
                type='warning'
                message={
                    <>
                        Shop <strong>{shop.name}</strong> của bạn không hỗ trợ tính năng này. Để sử dụng đầy đủ tính năng, vui lòng chuyển shop sang
                        import từ KikiLogin
                    </>
                }
                className='mt-3'
            />
        </Modal>
    )
}

export default UnsupportedShopModal
