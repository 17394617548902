import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'
import { filterConfigByPerms, treeRoutes, treeRoutesForTikTokTester } from 'core/Routes/RoutesConfig'
import { Flex, Layout, Menu } from 'antd'
import './SideBar.style.scss'
import type { MenuProps } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import UserPackage from './UserPackage'
import { NETWORK_ASSETS } from '../../const/assets'

const ALLOWED_EMAILS = ['admin@htglobal.me', 'maiprod@gmail.com', 'mailocal@gmail.com']
const ACCOUNT_TIKTOK_TESTER = ['sales.fulfilment9@gmail.com', 'mailocal@gmail.com']
type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem => {
    if (type === 'group') {
        return {
            key,
            label,
            type,
            children: children || []
        }
    }
    return {
        key,
        icon,
        label,
        children
    }
}

const { Sider } = Layout

const SideBar = () => {
    const location = useLocation() // Lấy đường dẫn hiện tại
    const currentPath = location.pathname
    const { user, collapsed } = useAuth()
    const filteredRoutes = ACCOUNT_TIKTOK_TESTER.includes(user.email)
        ? treeRoutesForTikTokTester
        : user.isOwner
          ? treeRoutes.filter((route: any) => {
                if (route.allowed_email) {
                    return ALLOWED_EMAILS.includes(user.email)
                }
                return true
            })
          : filterConfigByPerms(treeRoutes, user?.permissions ? user?.permissions?.map((x: any) => x.code_permission) : []).filter((route: any) => {
                if (route.allowed_email) {
                    return ALLOWED_EMAILS.includes(user.email)
                }
                return true
            })

    const menuItems: MenuItem[] = filteredRoutes.map((route: any) => {
        if (route.isSidebarHeader) {
            return getItem(route.title, route.title, undefined, [], 'group')
        }
        return getItem(<Link to={route.path}>{route.title}</Link>, route.path, <i className={`fa fa-${route.icon}`} />)
    })

    return (
        <Sider width={220} trigger={null} collapsible collapsed={collapsed} className='sider'>
            <Flex vertical className='h-100'>
                {!collapsed ? (
                    <div className='demo-logo-vertical d-flex justify-content-center'>
                        <a href='/'>
                            <img src={NETWORK_ASSETS.LOGO} alt='tikcrm logo' width={140} className='px-2 pt-3 pb-2' />
                        </a>
                    </div>
                ) : (
                    <div className='demo-logo-vertical d-flex justify-content-center'>
                        <a className='sidebar-brand' href='/'>
                            <img src='/logo192.png' alt='tikcrm logo' width={40} />
                        </a>
                    </div>
                )}
                <Menu theme='dark' mode='inline' selectedKeys={[currentPath]} items={menuItems} className='flex-grow-1' />
                {!collapsed && <UserPackage />}
            </Flex>
        </Sider>
    )
}

export default SideBar
