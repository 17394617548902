import { useQuery } from '@tanstack/react-query'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { ITikTokShop, ITiktokShopQueryParams } from 'types/ITikTokShop'
import type {UseQueryOptions} from "@tanstack/react-query/src/types";

export const TIKTOK_SHOP_QUERY_KEY = 'tiktok_shop_list'

export const ALL_TIKTOK_SHOP_QUERY_KEY = 'all_tiktok_shop_list'

export const useTikTokShopQuery = (queryParams: ITiktokShopQueryParams, enabled = true) => {
    return useQuery({
        queryKey: [TIKTOK_SHOP_QUERY_KEY, queryParams],
        queryFn: () => TikTokShopAPI.getTikTokShopsWithPaging(queryParams),
        enabled
    })
}

export const useAllTikTokShopQuery = (options?: Pick<UseQueryOptions, 'enabled'>) => {
    return useQuery<ITikTokShop[]>({
        queryKey: [ALL_TIKTOK_SHOP_QUERY_KEY],
        queryFn: () => TikTokShopAPI.getTikTokShops(),
        ...options
    })
}
