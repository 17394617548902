export const CODE_PERMISSION = {
    REVIEW: 'review',
    DASH_BOARD: 'DASH_BOARD',
    PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
    TEMPLATE_MANAGEMENT: 'TEMPLATE_MANAGEMENT',
    NICHE_MANAGEMENT: 'NICHE_MANAGEMENT',
    FROZEN_MANAGEMENT: 'FROZEN_MANAGEMENT',
    LISTING_MANAGEMENT: 'LISTING_MANAGEMENT',
    FLASH_SALE_MANAGEMENT: 'FLASH_SALE_MANAGEMENT',
    ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
    SHOP_HEALTH: 'SHOP_HEALTH',
    SHOP_MANAGEMENT: 'SHOP_MANAGEMENT',
    STAFF_MANAGEMENT: 'STAFF_MANAGEMENT',
    ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
    FULFILL_MANAGEMENT: 'FULFILL_MANAGEMENT',
    PAYMENT_CENTER: 'PAYMENT_CENTER',
    BANK_MANAGEMENT: 'BANK_MANAGEMENT',
    SHOP_STATEMENT: 'SHOP_STATEMENT'
};

export const PACKAGE_PERMISSION = {
    TIKTOK_ORDERS: '0',
    PRODUCTS: '1',
    NICHE: '2',
    TEMPLATE: '3',
    LISTING: '4',
    PROMOTIONS: '5',
    FULFILL: '6',
    VIEW_MEMBERS: '7',
    MUTATE_MEMBERS: '7.1',
    VIEW_HOT_SEARCH: '8',
}
