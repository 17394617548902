import React, { useEffect, useState } from 'react'
import {Button, Card, Flex, Table} from 'antd'
import { Helmet } from 'react-helmet'
import {CheckCircleOutlined, ReloadOutlined} from '@ant-design/icons'
import { useOrderQuery } from './queries/order.query'
import { useTableConfigs } from '../../hooks/useTableConfigs'
import { ORDER_COLUMNS } from './Table/_orderColumnDefined'
import OrderRowExpandable from './Table/OrderRowExpandable'
import OrderTableToolbar from './Table/OrderTableToolbar'
import './modals/artwork.style.scss'

export const ORDER_TAB = {
    all: {
        label: 'Available',
        icon: <CheckCircleOutlined />,
        filter: {}
    }
}

function Order() {
    const { queryResponse, pagination } = useTableConfigs(useOrderQuery)
    const [expandedKeys, setExpandedKey] = useState<string[]>([])

    useEffect(() => {
        if (queryResponse?.data) {
            setExpandedKey(queryResponse.data.data?.map((d) => d._id) ?? [])
        }
    }, [queryResponse?.data])

    return (
        <Card
            title={
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 16 }}>Đơn hàng</span>
                    <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='middle'>
                        Refresh
                    </Button>
                </Flex>
            }
        >
            <Helmet>
                <title>Order</title>
            </Helmet>
            <OrderTableToolbar queryResponse={queryResponse} />
            <Table
                bordered
                dataSource={queryResponse?.data?.data ?? []}
                columns={ORDER_COLUMNS}
                loading={queryResponse?.isFetching}
                pagination={{
                    defaultCurrent: pagination.pageNumber,
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: pagination.handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Total <strong>{total}</strong> products
                        </span>
                    )
                }}
                rowKey={(record) => record._id}
                expandable={{
                    expandedRowRender: OrderRowExpandable,
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                    expandedRowKeys: expandedKeys,
                    onExpandedRowsChange: (keys) => setExpandedKey(keys as unknown as string[])
                }}
            />
        </Card>
    )
}

export default Order
