import React, { useState, FunctionComponent } from 'react'
import { notify } from 'contexts/Notification'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useAuth } from 'contexts/Authenticate'
import { Alert, Image, Button, Row } from 'antd'
import { GoogleLogin } from '@react-oauth/google'
import {Link, Navigate} from 'react-router-dom'

const Login: FunctionComponent = () => {
    const { login, isLoading, error, loginGoogleOauth, user } = useAuth()
    const [values, setValues] = useState({ username: '', password: '' })

    const onChangeForm = (key: any) => (e: any) => {
        const { value } = e.target
        setValues({ ...values, [key]: value })
    }
    const handleLogin = async (e: any) => {
        e.preventDefault()
        if (values.username && values.password) {
            await login(values.username, values.password)
        } else {
            notify('error', 'Please fill information')
        }
    }
    const onSuccess = async (credentialResponse: any) => {
        console.log(credentialResponse)
        if (credentialResponse) {
            await loginGoogleOauth(credentialResponse.credential)
        } else {
            notify('error', 'Login Failed')
        }
    }

    if (isLoading) {
        return null;
    }

    if (user) {
        return <Navigate to='/order' />
    }

    return (
        <GoogleOAuthProvider clientId='175628795265-r604d0rlpb1vlomdslejg6gd3p9u1uth.apps.googleusercontent.com'>
            <div className='container d-flex flex-column'>
                <div className='row h-100'>
                    <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
                        <div className='d-table-cell align-middle'>
                            <div className='text-center mt-5 mb-5'>
                                <Image src='' preview={false} />
                                <h1 className='h2'>Đăng nhập quản lý TikCRM</h1>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='m-sm-4'>
                                        {error && <Alert message='Error' description={error} type='error' showIcon style={{ marginBottom: '1rem' }} />}
                                        <form>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='username1'>Email</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    name='username'
                                                    onChange={onChangeForm('username')}
                                                    value={values.username}
                                                    placeholder='Email'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='password1'>Mật khẩu</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='password'
                                                    onChange={onChangeForm('password')}
                                                    value={values.password}
                                                    name='password'
                                                    placeholder='Nhập mật khẩu'
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <Button
                                                    className='btn btn-primary pl-3 pr-3'
                                                    type='primary'
                                                    onClick={handleLogin}
                                                    htmlType='submit'
                                                    loading={isLoading}
                                                    style={{ height: '38px', width: '100%' }}
                                                >Đăng nhập
                                                </Button>
                                            </div>
                                        </form>
                                        <div className='text-center mt-3'>
                                            Chưa có tài khoản? <Link to='/register'>Đăng ký</Link>
                                        </div>
                                        {/*<div className='row mt-4'>*/}
                                        {/*    <div className='col'>*/}
                                        {/*        <hr />*/}
                                        {/*    </div>*/}
                                        {/*    <div className='col-auto text-uppercase d-flex align-items-center'>Or</div>*/}
                                        {/*    <div className='col'>*/}
                                        {/*        <hr />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='row mt-4' style={{ justifyContent: 'center' }}>*/}
                                        {/*    <GoogleLogin*/}
                                        {/*        onSuccess={onSuccess}*/}
                                        {/*        onError={() => {*/}
                                        {/*            console.log('Login Failed')*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    )
}

export default Login
