import React from 'react';
import {IOrder} from "../../../types/IOrder";
import {Flex, Tag} from "antd";
import {TAG_COLOR} from "../../../utils/get-color";

function OrderSellerColumn(order: IOrder) {
    return (
        <div>
            <strong>{order.shop?.name}</strong>
            <p>{order.shop?.assignee?.full_name}</p>
            <Flex vertical gap={8} className='mt-1'>
                <Tag color='geekblue' style={{ width: 'fit-content' }}>{order.user?.full_name}</Tag>
                <Tag color='purple' style={{ width: 'fit-content' }}>{order.shop?.profile_name}</Tag>
            </Flex>
        </div>
    );
}

export default OrderSellerColumn;
