import { useEffect, useState, FC } from 'react'
import { Modal, Divider, Input, Table, Image, Tooltip, Flex, Alert } from 'antd'
import _ from 'lodash'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from 'types/IProduct'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import FailedSKUTable from './FailedSKUTable'
import { ISelectProductPromotion } from 'types/IPromotion'

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    failedProducts: {
        message: string
        failed_products: ISelectProductPromotion[]
    }
}

const FailedProductsModal: FC<Props> = ({ visible, failedProducts, toggleModal }) => {
    useEffect(() => {}, [visible])
    const onClose = () => {
        toggleModal(false)
    }
    const columns: ColumnsType<ISelectProductPromotion> = [
        {
            title: 'Product name',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: any) => {
                return (
                    <Flex justify='space-between' align='center'>
                        <Link to={record?.image && record?.image?.url_list[0]} target='_blank'>
                            <Image src={record?.image?.url_list[0]} width={40} preview={false} />
                        </Link>
                        <div>
                            <CellProductName product_id={record.product_id} product_name={text} />
                        </div>
                    </Flex>
                )
            },
            width: 260
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (text: [], record: any, index: number) => {
                return ''
            },
            width: 100,
            align: 'center'
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (text: any) => {
                return ''
            },
            width: 100,
            align: 'center'
        }
    ]

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title={`Couldn't submit products`} footer={null} width={1280} centered>
            <Divider />
            <Alert message={failedProducts?.message ?? 'These products exceed the lowest price in 30 days'} type='error' showIcon className='mb-3' />
            <Table
                bordered
                dataSource={failedProducts?.failed_products ?? []}
                columns={columns}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500,
                    y: 720
                }}
                expandable={{ expandedRowRender: FailedSKUTable }}
            />
        </Modal>
    )
}

export default FailedProductsModal
