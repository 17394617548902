import { create } from 'zustand'
import { IOrderStore } from '../interfaces/order-store.interface'
import React from "react";

export const useOrderStore = create<IOrderStore>((set) => ({
    filter: {
        shop: '',
        order_id: '',
        user: '',
        fulfilledBy: '',
        dateRange: {
          from: null,
          to: null
        },
        status: '',
        others: {},
        addOthers: (others: Record<string, any>) => set(state => ({ filter: { ...state.filter, others } })),
        addDateRange: (dateRange: { from: number | null; to: number | null }) => set((state) => ({ filter: { ...state.filter, dateRange } })),
        addFulfilledBy: (fulfilledBy: string) => set((state) => ({ filter: { ...state.filter, fulfilledBy } })),
        addShop: (shop: string) => set((state) => ({ filter: { ...state.filter, shop } })),
        addUser: (user: string) => set((state) => ({ filter: { ...state.filter, user } })),
        addStatus: (status: string) => set((state) => ({ filter: { ...state.filter, status } })),
        addOrderId: (e: React.ChangeEvent<HTMLInputElement>) => set((state) => ({ filter: { ...state.filter, order_id: e.target.value } })),
    },
    setFilter: (newFilter) => set({ filter: newFilter }),
    addFilter: (partialFilter) => set((state) => ({ filter: { ...state.filter, ...partialFilter } })),
    removeFilter: (prop) =>
        set((state) => {
            const _filter = { ...state.filter }
            delete _filter[prop]
            return { filter: _filter }
        })
}))
