import React from 'react';
import {IBankAccount} from "../interface/bank.interface";
import {Button, Tag} from "antd";
import {TAG_COLOR} from "../../../../utils/get-color";
import {useModal} from "../../../../contexts/Modal";
import BankAccountSetupShopModal from "../modals/BankAccountSetupShopModal";

const SetupShopForBank = (data: IBankAccount) => {
    const { openModal } = useModal()

    const openSetupBankModal = () => openModal(<BankAccountSetupShopModal bankAccount={data} />)

    return (
        <div>
            <Button type='primary' size='small' onClick={openSetupBankModal}>Set up shop</Button>
        </div>
    )
}

function BankAccountShopColumn(data: IBankAccount) {
    if (!data.shop) {
        return <SetupShopForBank {...data} />
    }
    return (
        <div>
            <Tag color={TAG_COLOR.SHOP_NAME}>{data.shop.name}</Tag>
        </div>
    );
}

export default BankAccountShopColumn;
