import { create } from 'zustand'
import React from 'react'
import { IShopStatementStore } from '../interface/shop-statement.interface'

export const useShopStatementStore = create<IShopStatementStore>((set) => ({
    filter: {
        shop: '',
        search: '',
        addShop: (shop: string) => set((state) => ({ filter: { ...state.filter, shop } })),
        addSearch: (e: React.ChangeEvent<HTMLInputElement>) =>
            set((state) => ({
                filter: {
                    ...state.filter,
                    search: e.target.value
                }
            }))
    },
    setFilter: (newFilter) => set({ filter: newFilter }),
    addFilter: (partialFilter) => set((state) => ({ filter: { ...state.filter, ...partialFilter } })),
    removeFilter: (prop) =>
        set((state) => {
            const _filter = { ...state.filter }
            delete _filter[prop]
            return { filter: _filter }
        })
}))

export const useShopStatementGeneralStore = create<{
    currentTab: string
    setCurrentTab: (tab: string) => void
}>((set) => ({
    currentTab: '1',
    setCurrentTab: (tab: string) => set({ currentTab: tab })
}))
