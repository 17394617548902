import { EnumAction, IPermission } from "./IPermission";

export interface PermissionOfRole {
    feature: IPermission
    actions: EnumAction[]
}

export enum EnumRole {
    OWNER = 'owner',
    LEADER_SELLER = 'leader_seller',
    SELLER = 'seller',
    ACCOUNTANT = 'accountant',
    RESOURCE = 'resource'
}

export interface IRole {
    _id: string
    id?: EnumRole;
    name?: string;
    description?: string;
    permission_of_staff?: PermissionOfRole[];
    is_active?: boolean;
    can_update?: boolean;
    created_by?: Date;
    updated_by?: Date;
    created_time?: Date;
    updated_time?: Date;
}