import { Switch, Table } from 'antd'
import { usePromotionContext } from '../PromotionProvider'
import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import { ISKUItemProductPromotion } from 'types/IPromotion'
import SKUDealPriceColumn from './ColumnsSKU/SKUDealPriceColumn'
import SKUQuantityLimitColumn from './ColumnsSKU/SKUQuantityLimitColumn'
import SKUQuantityPerUserColumn from './ColumnsSKU/SKUQuantityPerUserColumn'
import { memo, useCallback } from 'react'

const ExpandSKUTable = memo(({ product_id, skus, quantity_per_user }: { product_id: string; skus: any; quantity_per_user: number | undefined }) => {
    const { selectedProducts, saveDataSource, setSelectedProducts } = usePromotionContext()
    console.log('ExpandSKUTable Row:', product_id, skus)
    const handleOnOff = useCallback(
        (sku_id: string) => {
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            const skuIdx = record.skus.findIndex((item) => item.id === sku_id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx]
            skuTmp.is_on_sale = _.isBoolean(skuTmp.is_on_sale) ? !skuTmp.is_on_sale : false
            saveDataSource(record)
        },
        [selectedProducts, product_id]
    )

    const onPriceChange = useCallback(
        (sku_id: string, activity_price_amount: number, deal_price_percent: number) => {
            console.log('onPriceChange', sku_id, activity_price_amount, deal_price_percent)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            const skuIdx = record.skus.findIndex((item) => item.id === sku_id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx]
            skuTmp.deal_price_percent = deal_price_percent
            skuTmp.activity_price_amount = activity_price_amount
            saveDataSource(record)
        },
        [selectedProducts]
    )

    const onQuantityLimitChange = useCallback(
        (sku_id: string, quantity_limit: number) => {
            console.log('onQuantityLimitChange', sku_id, quantity_limit)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            const skuIdx = record.skus.findIndex((item) => item.id === sku_id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx]
            skuTmp.quantity_limit = quantity_limit
            saveDataSource(record)
        },
        [selectedProducts]
    )

    const onQuantityPerUserChange = useCallback(
        (sku_id: string, quantity_per_user: number) => {
            console.log('onQuantityPerUserChange', sku_id, quantity_per_user)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            const skuIdx = record.skus.findIndex((item) => item.id === sku_id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx]
            skuTmp.quantity_per_user = quantity_per_user
            saveDataSource(record)
        },
        [selectedProducts]
    )

    const columns: ColumnsType<ISKUItemProductPromotion> = [
        {
            title: 'Variant',
            dataIndex: 'properties',
            key: 'properties',
            render: (text: any[], record) => {
                let itemName = text.reduce((acc, property) => (acc += `${property.value_name} `), '')
                return (
                    <>
                        <b>Name:</b> {itemName} <br />
                        <b>SKU ID:</b> {record.id}
                    </>
                )
            },
            width: 270
        },
        {
            title: 'Giá bán',
            dataIndex: 'base_price',
            key: 'base_price',
            render: (text) => {
                return text?.sale_price_display
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Deal Price',
            dataIndex: 'activity_price_amount',
            key: 'activity_price_amount',
            width: 350,
            align: 'center',
            render: (text, record) => {
                return (
                    <SKUDealPriceColumn
                        sku_id={record.id}
                        base_price={Number(record.base_price.sale_price)}
                        deal_price_percent={record.deal_price_percent}
                        is_on_sale={record.is_on_sale ?? true}
                        onChange={onPriceChange}
                    />
                )
            }
        },
        {
            title: 'Total Purchase Limit',
            dataIndex: 'quantity_limit',
            key: 'quantity_limit',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return (
                    <SKUQuantityLimitColumn
                        quantity_limit={record.quantity_limit}
                        sku_id={record.id}
                        onChange={onQuantityLimitChange}
                        is_on_sale={record.is_on_sale ?? true}
                    />
                )
            }
        }
        // {
        //     title: 'Trạng thái',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: (text: any) => {
        //         return <CellProductProductStatus status={product.status} />
        //     },
        //     width: 100,
        //     align: 'center'
        // },
    ]
    if (!quantity_per_user || quantity_per_user < 0) {
        columns.push({
            title: 'Customer Purchase Limit',
            dataIndex: 'quantity_per_user',
            key: 'quantity_per_user',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return (
                    <SKUQuantityPerUserColumn
                        quantity_per_user={record.quantity_per_user}
                        sku_id={record.id}
                        onChange={onQuantityPerUserChange}
                        is_on_sale={record.is_on_sale ?? true}
                    />
                )
            }
        })
    }

    columns.push({
        title: 'Hành động',
        dataIndex: 'is_on_sale',
        key: 'is_on_sale',
        render: (text: boolean, record: any) => {
            return <Switch checked={record.is_on_sale ?? true} onClick={() => handleOnOff(record.id)} />
        },
        width: 150,
        fixed: 'right',
        align: 'center'
    })
    return (
        <Table
            bordered
            columns={columns}
            dataSource={skus}
            rowKey={(record) => record?.id}
            scroll={{
                x: 500,
                y: 500
            }}
            pagination={{
                pageSize: 500
            }}
        />
    )
})

export default ExpandSKUTable
