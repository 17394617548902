import React from 'react';
import {useUserFulfillServicesQuery} from "../../AccountManagement/queries/user-fulfill.query";
import {Flex, Select, Tag} from "antd";
import {DEFAULT_OPTION_ALL_VALUE} from "../../../const";
import {useOrderStore} from "../store/order.store";
import {useShallow} from "zustand/react/shallow";

function OrderTableToolbarFulfill() {
    const { data: userFulfillServices } = useUserFulfillServicesQuery()
    const { fulfilledBy, addFulfilledBy } = useOrderStore(
        useShallow((state) => ({
            fulfilledBy: state.filter.fulfilledBy,
            addFulfilledBy: state.filter.addFulfilledBy
        }))
    )

    const options = userFulfillServices?.map((item) => ({
                value: item._id,
                label: (
                    <Flex align='center' gap={8}>
                        <img src={item.service.icon} width={20} height={20} alt='service icon' className='flex-shrink-1' />
                        <span className='text-uppercase text-lg'>{item.service.service}</span>
                    </Flex>
                ),
                renderLabel: (
                    <Flex justify='space-between' align='center'>
                        <Flex align='center' gap={8}>
                            <img src={item.service.icon} width={20} height={20} alt='service icon' className='flex-shrink-1' />
                            <span className='text-uppercase'>{item.service.service}</span>
                        </Flex>
                        <Tag color={item.is_active ? '#1ace23' : '#f62727'}>{item.is_active ? 'ACTIVE' : 'DISABLED'}</Tag>
                    </Flex>
                ),
                searchTerm: `${item.service.service}`
            })) ?? []

    return (
        <Select
            showSearch
            optionFilterProp='textSearch'
            style={{ width: '100%', maxWidth: 260 }}
            placeholder='Chọn dịch vụ fulfill'
            onChange={addFulfilledBy}
            value={fulfilledBy || ''}
            allowClear
            size='large'
            onClear={() => addFulfilledBy(DEFAULT_OPTION_ALL_VALUE)}
            options={[
                {
                    value: DEFAULT_OPTION_ALL_VALUE,
                    label: 'Tất cả dịch vụ',
                    renderLabel: <span>Tất cả dịch vụ</span>,
                    searchTerm: `Tất cả dịch vụ`
                },
                ...options
            ]}
            optionRender={(option) => option.data.renderLabel}
            filterOption={(input, option) => (option?.searchTerm ?? '').toLowerCase().includes(input.toLowerCase())}
        />
    );
}

export default OrderTableToolbarFulfill;
