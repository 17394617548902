import { ITableParams } from 'hooks/useTableConfigs'
import { INiche } from './INiche'
import { ITikTokShop } from './ITikTokShop'

export enum EnumProductStatus {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    ACTIVATE = 'ACTIVATE',
    SELLER_DEACTIVATED = 'SELLER_DEACTIVATED',
    PLATFORM_DEACTIVATED = 'PLATFORM_DEACTIVATED',
    FREEZE = 'FREEZE',
    DELETED = 'DELETED'
}
interface SalePrice {
    region: string
    price_range: string
}
export interface IProduct {
    _id: string
    product_id: string
    id: string
    shop_id: string
    title: string
    description: string
    category_chains?: object[]
    brand?: object
    main_images?: object[]
    images?: object[]
    video?: object
    package_dimensions?: object
    package_weight?: object
    certifications?: object[]
    size_chart?: any
    product_attributes?: object[]
    audit_failed_reasons?: {
        position: string
        reasons: string[]
        suggestions: string[]
        listing_platform: string
    }[]
    delivery_options?: object[]
    manufacturer?: object
    recommended_categories?: object[]
    is_cod_allowed?: string
    external_product_id?: string
    product_types?: string[]
    is_not_for_sale?: string
    skus: object[]
    template_name: string
    niche: INiche
    tiktok_shop: ITikTokShop
    sale_price_ranges: SalePrice[]
    create_time: number
    update_time: number
    product_status_view: any
    status: EnumProductStatus
    listing_quality_tier: 'POOR' | 'FAIR' | 'GOOD'
    size_chart_image?: any
    created_time?: Date
    updated_time?: Date
}

export enum EnumProductTemplateTmpStatus {
    WAITING = 1,
    PROCESSING,
    SUCCESS,
    FAILED,
    RETRY,
    DRAFT
}

export interface IProductTemplateQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    status?: EnumProductTemplateTmpStatus
    tab?: boolean
}

export interface IProductQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    is_template_listing?: boolean
    status?: EnumProductStatus | EnumProductStatus[]
    assignee_id?: string
    start_time?: number
    end_time?: number
}
