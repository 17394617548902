import React, { useState } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Button, Divider, Flex, Input, Modal, Radio, RadioChangeEvent } from 'antd'
import { IFulfill, IUserFulfill } from '../../../types/IFulfill'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import { USER_FULL_FILL_SERVICE_QUERY_KEY } from '../queries/user-fulfill.query'

const AUTH_TYPES = {
    accessToken: 'ACCESS_TOKEN_',
    apiKey: 'API_KEY_'
}

function UpdateFulfillServiceModal({ service }: { service: IFulfill & { userData?: IUserFulfill } }) {
    const { closeModal } = useModal()
    const [moreInfoData, setMoreInfoData] = useState<Record<string, string>>(() =>
        service.required_data
            .filter((d) => d !== 'token')
            .reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: service.userData?.more_info?.[cur] ?? ''
                }),
                {}
            )
    )

    const [authType, setAuthType] = useState(service.auth_types[0] ?? AUTH_TYPES.accessToken)
    const [token, setToken] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [expireTokenTime, setExpireTokenTime] = useState('')
    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: OrderAPI.mutateUserFulfillService,
        onSuccess: () => {
            notify('success', 'Thành công')
            void queryClient.refetchQueries([USER_FULL_FILL_SERVICE_QUERY_KEY])
            closeModal()
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const mutateVerifyAuth = useMutation({
        mutationFn: OrderAPI.verifyFulfillAuth,
        onSuccess: (data) => {
            notify('success', 'Xác thực thành công')
            setToken(data.access_token)
            setExpireTokenTime(`${data.expire_at}`)
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const mutateVerifyApiKey = useMutation({
        mutationFn: OrderAPI.verifyFulfillApiKey,
        onSuccess: (data) => {
            if (data === true) {
                notify('success', 'Xác thực thành công')
            } else {
                notify('error', 'Xác thực thất bai. Vui lòng kiểm tra lại token')
            }
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const onChange = (e: RadioChangeEvent) => {
        setAuthType(e.target.value)
    }

    const moreInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMoreInfoData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const onSubmit = async () => {
        if (!service.userData && !token) {
            notify('error', 'Vui lòng nhập token')
            return
        }
        if (Object.keys(moreInfoData).some((key) => !moreInfoData[key])) {
            notify('error', `Vui lòng nhập đủ thông tin`)
            return
        }
        const fullAuthToken = authType + token + '|-|' + username + '|-|' + password + '|-|' + expireTokenTime
        const data = {
            service: service._id,
            moreInfo: moreInfoData,
            ...(token && {
                token: fullAuthToken
            })
        }
        await mutate.mutateAsync({
            sId: service.userData?._id,
            data
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={
                <Flex gap={12}>
                    <img src={service.icon} width={40} height={40} alt='service icon' className='flex-shrink-1' />
                    <div>
                        <p className='text-uppercase font-weight-bolder'>{service.service}</p>
                        <p style={{ fontSize: 12, fontWeight: 400 }}>{service.description}</p>
                    </div>
                </Flex>
            }
            footer={null}
            width={800}
        >
            <Divider />
            <div className='mb-3'>
                {Object.keys(moreInfoData).map((key) => (
                    <div key={key}>
                        <p className='font-weight-bold mt-3 mb-2 text-uppercase'>{key}</p>
                        <Input placeholder={`Input ${key}`} value={moreInfoData[key]} name={key} onChange={moreInfoChange} />
                    </div>
                ))}
            </div>

            <p className='font-weight-bold mb-2'>Loại xác thực</p>
            <Radio.Group onChange={onChange} value={authType}>
                {service.auth_types.map((value) => (
                    <Radio key={value} value={value}>
                        {value.split('_').join(' ')}
                    </Radio>
                ))}
            </Radio.Group>
            {authType === AUTH_TYPES.accessToken && (
                <>
                    <p className='font-weight-bold mt-3 mb-2'>Thông tin xác thực</p>
                    <Flex gap={12}>
                        <Input placeholder='Email / Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        <Input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        <Button
                            type='primary'
                            onClick={() => {
                                if (Object.keys(moreInfoData).some((key) => !moreInfoData[key])) {
                                    notify('error', `Vui lòng nhập đủ thông tin`)
                                    return
                                }
                                void mutateVerifyAuth.mutateAsync({
                                    service: service.service,
                                    username,
                                    password
                                })
                            }}
                            loading={mutateVerifyAuth.isLoading}
                            disabled={!username || !password}
                        >
                            Xác thực
                        </Button>
                    </Flex>
                </>
            )}
            <p className='font-weight-bold mt-3 mb-2'>Token mới</p>
            <Flex gap={12}>
                <Input
                    disabled={authType === AUTH_TYPES.accessToken}
                    placeholder='Input auth token'
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                />
                {authType === AUTH_TYPES.apiKey && (
                    <Button
                        type='primary'
                        onClick={() => {
                            if (Object.keys(moreInfoData).some((key) => !moreInfoData[key])) {
                                notify('error', `Vui lòng nhập đủ thông tin`)
                                return
                            }
                            void mutateVerifyApiKey.mutateAsync({
                                service: service.service,
                                token,
                                addedInfo: moreInfoData
                            })
                        }}
                        disabled={!token}
                        loading={mutateVerifyApiKey.isLoading}
                    >
                        Xác thực
                    </Button>
                )}
            </Flex>
            <Flex justify='space-between' align='center' className='mt-4'>
                {service.document ? (
                    <a href={service.document} target='_blank' rel='noopner noreferrer'>
                        <span className='text-nowrap text-info text-hover-primary'>Hướng dẫn cấu hình</span>
                    </a>
                ) : (
                    <div />
                )}
                <Button type='primary' onClick={onSubmit} loading={mutate.isLoading} className='w-100' disabled={!token} style={{ maxWidth: 120 }}>
                    Xác nhận
                </Button>
            </Flex>
        </Modal>
    )
}

export default UpdateFulfillServiceModal
