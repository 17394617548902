import { useQuery } from '@tanstack/react-query'
import UserAPI from '../../../api/UserAPI'

export const USER_INVITED_QUERY_KEY = 'USER_INVITED_QUERY_KEY'

export const useUserInvitedQuery = () => {
    return useQuery({
        queryKey: [USER_INVITED_QUERY_KEY],
        queryFn: UserAPI.getAffiliateInvited
    })
}
