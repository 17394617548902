import React from 'react'
import { IOrder } from '../../../types/IOrder'
import {Alert, Flex, Tag, Tooltip} from 'antd'

function OrderNameColumn({ order }: { order: IOrder }) {
    return (
        <div>
            <p>{order.name}</p>
            <p>{order.full_address}</p>
            <p>{order.phone_number}</p>
            <p className='mb-1'>{order.buyer_message}</p>
            <Flex gap={1}>
                <Tag color='blue'>{order.payment_method_name}</Tag>
                <Tag color='blue'>Ship by {order.order_shipping_type}</Tag>
            </Flex>
            <hr />
            {order.platform_paid_bank_account ? (
                <div>
                    Tiktok has paid{' '}
                    {/*<strong>*/}
                    {/*    {order.platform_paid_amount ?? '--'} {order.platform_paid_currency}*/}
                    {/*</strong>{' '}*/}
                    to bank account <Tag color='blue'>{order.platform_paid_bank_account}</Tag>
                    {
                        order.platform_paid_bank_account_valid === false && (
                            <div className='mt-1'>
                                <Tooltip placement='bottom'
                                         title='Ngân hàng nhận tiền từ tiktok và ngân hàng được sử dụng cho shop trên TikCRM không khớp. Vui lòng kiểm tra lại'>
                                    <Tag color='#f5222d'>Financial Fraud Detected</Tag>
                                </Tooltip>
                            </div>
                        )
                    }
                </div>
            ) : (
                <Tag color='red'>No Bank</Tag>
            )}
        </div>
    )
}

export default OrderNameColumn
