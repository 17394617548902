import React, { useState } from 'react'
import { useModal } from '../../../../../contexts/Modal'
import { Button, Card, Flex, Modal, Select, Typography } from 'antd'
import { useAuth } from '../../../../../contexts/Authenticate'
import { formatCurrency, formatDate, USDvsVND } from '../../../../../utils/format'
import { CaretRightOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import PackageAPI from '../../../../../api/PackageAPI'
import { notify } from '../../../../../contexts/Notification'
import dayjs from 'dayjs'
import DepositAmountModal from "../../Wallet/modals/DepositAmountModal";

function PackageExtendModal() {
    const { openModal, closeModal } = useModal()
    const { user } = useAuth()
    const [duration, setDuration] = useState('1')
    const extendPackageDuration = useMutation({
        mutationFn: PackageAPI.extendPackage,
        onSuccess: () => {
            notify('success', 'Gia hạn thành công')
            window.location.reload()
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const pkg = user?.package

    const onConfirm = () => extendPackageDuration.mutateAsync({ duration: Number(duration) })

    const priceNeedToPay = (pkg.price_per_shop ?? pkg.package_detail.price_per_shop) * pkg.shop_limit * Number(duration)
    const newCredit = user.credit - priceNeedToPay

    const openDepositAmountModal = () => openModal(<DepositAmountModal amount={`${Math.abs(newCredit)}`} />, { isSecondModal: true })

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Gia hạn gói cước' footer={null} width={550}>
            {pkg && (
                <Card bordered className='mt-3'>
                    <h4 className='font-weight-bold'>{pkg.package_detail.name}</h4>
                    <p className='' style={{ height: 40 }}>
                        {pkg.package_detail.description}
                    </p>
                    <h1
                        className='font-weight-bolder mt-3 text-info'
                        style={{
                            fontSize: 40,
                            lineHeight: 1
                        }}
                    >
                        ${formatCurrency(priceNeedToPay, { rate: USDvsVND })}
                        <span style={{ fontSize: 18 }}></span>
                    </h1>
                    <div className='d-flex align-items-center mt-4 mb-4'>
                        <CheckCircleTwoTone style={{ fontSize: 20 }} />
                        <p className='text-lg ml-2'>{pkg.price_per_shop === 999999999999 ? 'Infinity' : pkg.shop_limit} shops</p>
                    </div>
                    {/*<div className='d-flex align-items-center mt-2 mb-4'>*/}
                    {/*    <CheckCircleTwoTone style={{ fontSize: 20 }} />*/}
                    {/*    <p className='text-lg ml-2'>Hỗ trợ đến ngày tận thế</p>*/}
                    {/*</div>*/}
                    <Select style={{ width: '100%' }} placeholder='Chọn thời hạn' size='middle' value={duration} onChange={setDuration}>
                        <Select.Option value='1'>1 tháng</Select.Option>
                        <Select.Option value='3'>3 tháng</Select.Option>
                        <Select.Option value='6'>6 tháng</Select.Option>
                        <Select.Option value='12'>1 năm</Select.Option>
                        <Select.Option value='24'>2 năm</Select.Option>
                    </Select>

                    <Flex justify='space-between' className='mt-3'>
                        <Typography>Thời hạn:</Typography>
                        <Typography className='font-weight-bold'>
                            <span style={{ color: '#777E90' }}>{`${formatDate(dayjs(user?.package?.expire_at))}`}</span>
                            <CaretRightOutlined className='text-success' />{' '}
                            {formatDate(dayjs(user?.package?.expire_at).add(Number(duration), 'month'))}
                        </Typography>
                    </Flex>
                    <Flex justify='space-between'>
                        <Typography>Số dư mới:</Typography>
                        <Typography className='font-weight-bold'>
                            <span style={{ color: '#777E90' }}>
                                {formatCurrency(user?.credit, {
                                    rate: USDvsVND,
                                    prefix: '$'
                                })}
                            </span>
                            <CaretRightOutlined className='text-success' />{' '}
                            <span className={newCredit < 0 ? 'text-danger' : 'text-info'}>
                                {formatCurrency(newCredit, {
                                    rate: USDvsVND,
                                    prefix: '$'
                                })}
                            </span>
                        </Typography>
                    </Flex>

                    {newCredit < 0 ? (
                        <>
                            <Typography className='text-danger mt-2'>
                                Số dư hiện tại không đủ. Vui lòng nạp thêm{' '}
                                <strong>
                                    {formatCurrency(newCredit * -1, {
                                        rate: USDvsVND,
                                        prefix: '$'
                                    })}
                                </strong>{' '}
                                để tiếp tục
                            </Typography>
                            <Button type='primary' danger className='mt-2 w-100' onClick={openDepositAmountModal}>
                                Nạp tiền
                            </Button>
                        </>
                    ) : (
                        <Button type='primary' onClick={onConfirm} className='w-100 mt-3' loading={extendPackageDuration.isLoading}>
                            Gia hạn
                        </Button>
                    )}
                </Card>
            )}
        </Modal>
    )
}

export default PackageExtendModal
