import ApiSender from './ApiSender'
import { IListApiResponse } from 'types/IApiResponse'
import { IAutoActivity } from 'types/IAutoActivity'
import { IAutoPromotionQueryParams } from 'types/IAutoPromotion'

export default class AutoActivityAPI {
    public static getAutoActivities(params: IAutoPromotionQueryParams): Promise<IListApiResponse<IAutoActivity>> {
        const route = `/core/user/auto-activities`
        return ApiSender.get(route, params)
    }

    public static createAutoActivity(payload: { activity_id: string }) {
        const route = `/core/user/auto-activities`
        return ApiSender.post(route, payload)
    }

    public static editAutoActivity(_id: string, payload: { title: string; begin_time: number; end_time: number }) {
        const route = `/core/user/auto-activities/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeAutoActivity(_id: string) {
        const route = `/core/user/auto-activities/${_id}`
        return ApiSender.delete(route)
    }
}
