import React from 'react'
import { Button, Flex, Modal, Table } from 'antd'
import { useModal } from '../../../../contexts/Modal'
import { InboxOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Upload } from 'antd'
import { convertCSVToJson, downloadFile } from '../../../../utils/csv'
import { IBankAccountImportData } from '../interface/bank.interface'
import { ColumnsType } from 'antd/lib/table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import FinanceAPI from '../../../../api/FinanceAPI'
import { notify } from '../../../../contexts/Notification'
import { LIST_BANK_ACCOUNT_QUERY_KEY } from '../queries/bank.query'

const { Dragger } = Upload

const TEMPLATE = `Note,Bank Name,Routing Number,Swift Code,Account Number,Type
,"JPMorgan Chase Bank, N,A, New York Branch",,,,Checking`

function ImportBankModal() {
    const { closeModal } = useModal()
    const [importedData, setImportedData] = React.useState<IBankAccountImportData[]>([])
    const queryClient = useQueryClient()

    const importMutation = useMutation({
        mutationFn: FinanceAPI.importData,
        onSuccess: (data) => {
            console.log('ddd', data)
            notify('success', `Import thành công bank accounts`)
            void queryClient.refetchQueries([LIST_BANK_ACCOUNT_QUERY_KEY])
            closeModal()
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: () => false,
        onChange: async (info) => {
            console.log('info', info)
            if (info.fileList[0] && info.fileList[0].originFileObj) {
                const data: unknown = await convertCSVToJson(info.fileList[0].originFileObj)
                console.log('data', data)
                setImportedData(data as IBankAccountImportData[])
            }
        },
        accept: '.csv'
    }

    const removeData = () => setImportedData([])

    const onSubmit = () =>
        importMutation.mutateAsync({
            bank_accounts: importedData
        })

    const isValidData = importedData.every((data) => !!data['Account Number'] && !!data['Routing Number'] && !!data['Swift Code'])

    const downloadCsv = () => {
        downloadFile(TEMPLATE, 'import_bank_sample')
    }

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Import bank account' footer={null} width='70%'>
            <div className='mt-4' />
            {importedData.length > 0 ? (
                <>
                    <Table
                        bordered
                        dataSource={importedData}
                        columns={IMPORTED_DATA_COLUMNS}
                        pagination={false}
                        rowKey={(record) => record['Account Number']}
                    />
                    <Flex justify='end' gap={12} className='mt-4'>
                        <Button type='primary' danger onClick={removeData}>
                            Xóa dữ liệu
                        </Button>
                        <Button type='primary' disabled={!isValidData} onClick={onSubmit} loading={importMutation.isLoading}>
                            Xác nhận
                        </Button>
                    </Flex>
                </>
            ) : (
                <>
                    <Dragger {...props}>
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click hoặc kéo thả file vào khu vực này để tải file lên</p>
                        <p className='ant-upload-hint'>Chỉ hỗ trợ các file định dạng .csv</p>
                    </Dragger>
                    <p className='text-info mt-2 text-hover-primary cursor-pointer font-weight-bold w-fit' onClick={downloadCsv}>
                        Tải file mẫu
                    </p>
                </>
            )}
        </Modal>
    )
}

export default ImportBankModal

const IMPORTED_DATA_COLUMNS: ColumnsType<IBankAccountImportData> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Bank Name',
        dataIndex: 'Bank Name',
        key: 'Bank Name',
        width: 260,
        render: (value) => value || <span className='text-danger font-weight-bold font-italic'>Vui lòng điển đủ thông tin</span>
    },
    {
        title: 'Account Number',
        dataIndex: 'Account Number',
        key: 'Account Number',
        width: 260,
        render: (value) => value || <span className='text-danger font-weight-bold font-italic'>Vui lòng điển đủ thông tin</span>
    },
    {
        title: 'Routing Number',
        dataIndex: 'Routing Number',
        key: 'Routing Number',
        width: 260,
        render: (value) => value || <span className='text-danger font-weight-bold font-italic'>Vui lòng điển đủ thông tin</span>
    },
    {
        title: 'Swift Code',
        dataIndex: 'Swift Code',
        key: 'Swift Code',
        width: 260,
        render: (value) => value || <span className='text-danger font-weight-bold font-italic'>Vui lòng điển đủ thông tin</span>
    },
    {
        title: 'Note',
        dataIndex: 'Note',
        key: 'Note',
        width: 260
    },
    {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        width: 260
    }
]
