import Papa from 'papaparse';
import {RcFile} from "antd/es/upload";

export const jsonToCsv = (data: any) => {
    const headers = Object.keys(data[0]).join(','); // Lấy header từ keys
    const rows = data.map((row: any) => Object.values(row).join(',')); // Chuyển từng object thành string
    return [headers, ...rows].join('\n'); // Ghép lại thành file CSV
};

export const downloadFile = (data: any, fileName: string) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    const file = fileName + '.csv'
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const convertCSVToJson = (file: File | RcFile) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                resolve(results.data);
            },
            error: (error) => {
                reject(error);
            }
        });
    });
}
