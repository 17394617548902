import { IListApiResponse } from '../types/IApiResponse'
import ApiSender from './ApiSender'
import { IBankAccount, IBankAccountQueryParams } from '../pages/Finance/BankManagement/interface/bank.interface'
import { IShopStatementQueryParams } from '../pages/Finance/Statement/interface/shop-statement.interface'
import { ITikTokShop } from '../types/ITikTokShop'
import { IShopPaidQueryParams, ITiktokShopPaid } from '../pages/Finance/Statement/interface/shop-paid.interface'

export default class FinanceAPI {
    public static getAllBankAccounts(params: IBankAccountQueryParams): Promise<IListApiResponse<IBankAccount>> {
        return ApiSender.get('/core/finance/bank-account', params)
    }

    public static importData(payload: Record<string, any>) {
        return ApiSender.post('/core/finance/bank-account', payload)
    }

    public static assignShopForBankAccount({ bank_account_id, ...payload }: Record<string, any>) {
        return ApiSender.put(`/core/finance/bank-account/${bank_account_id}`, payload)
    }

    public static viewSensitiveData({ bankAccountId, props }: { bankAccountId: string; props: string[] }) {
        return ApiSender.post(`/core/finance/bank-account/${bankAccountId}/sensitive`, { props })
    }

    public static getShopsStatement(params: IShopStatementQueryParams): Promise<IListApiResponse<ITikTokShop>> {
        return ApiSender.get(`/core/finance/tiktok-shops/statement`, params)
    }

    public static getShopsPaid(params: IShopPaidQueryParams): Promise<IListApiResponse<ITiktokShopPaid>> {
        return ApiSender.get(`/core/finance/tiktok-shops/paid`, params)
    }
}
