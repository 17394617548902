import { Cascader, Col, Row, ConfigProvider, Form, Select } from 'antd'
import { useCategoriesQuery } from 'hooks/queries/category'
import { useAllConfigProductTemplateQuery } from 'hooks/queries/config-product'
import _ from 'lodash'
import { FC, memo, useMemo, useState } from 'react'
import { EnumCategoryType } from 'types/ICategory'
import { buildTreeCascader } from 'utils/category-utils'
type CategoriesCascaderProps = {
    onChange?: (value: string[]) => void
    value?: string[]
}
const CategoriesCascader: FC<CategoriesCascaderProps> = ({ value, onChange }) => {
    console.log('render CategoriesCascader')
    const categoriesQueryResponse = useCategoriesQuery(EnumCategoryType.TREE)
    const configProductTemplateQueryResponse = useAllConfigProductTemplateQuery()
    
    const buildTree = useMemo(() => {
        return categoriesQueryResponse?.data ?? []
    }, [categoriesQueryResponse.data])

    const templateOptions = useMemo(() => {
        return [
            ..._.map(configProductTemplateQueryResponse?.data ?? [], (des: any) => ({
                value: des._id,
                label: des.name,
                textSearch: des.name
            })),
            {
                label: 'Khác',
                value: ''
            }
        ]
    }, [configProductTemplateQueryResponse])
    
    return (
        <Row>
            <Col span={24}>
                <ConfigProvider
                    theme={{
                        components: {
                            Select: {
                                colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                colorBgContainer: '#d9d9d9 !important'
                            }
                        }
                    }}
                >
                    <Form.Item label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu Category</b>} name='category_template_id'>
                        <Select
                            placeholder='Chọn mẫu Category có sẵn'
                            showSearch
                            optionFilterProp='textSearch'
                            size='large'
                            options={templateOptions}
                        ></Select>
                    </Form.Item>
                </ConfigProvider>
            </Col>
            <Col span={24}>
                <Form.Item label={<b>Category</b>} name='category' rules={[{ required: true, message: 'Vui lòng chọn hạng mục!' }]}>
                    <Cascader options={buildTree} showSearch size='large' />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default memo(CategoriesCascader)
