import { useEffect, useState, FC, useCallback, useMemo } from 'react'
import { Row, Col, Select, Space, Button, Tag, Form, Input, Upload, InputNumber, Checkbox } from 'antd'
import { PlusOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons'
import _ from 'lodash'
import type { UploadFile, UploadProps } from 'antd'
import UploadAPI from 'api/UploadAPI'
import { notify } from 'contexts/Notification'
import ProductAPI from 'api/ProductAPI'
import { BRANDS, CATEGORY_DEFAULT, EnumTikPlatform } from 'const'
import ListingAPI from 'api/ListingAPI'
import { IConfigProduct, IProductProperties } from 'types/IConfigProduct'
import ConfigProductAPI from 'api/ConfigProductAPI'
import { CheckboxProps } from 'antd/lib'
import TextArea from 'antd/es/input/TextArea'
import CategoriesCascader from 'core/CreateProduct/CategoriesCascader'
import ProductPropertiesV2 from 'core/CreateProduct/ProductProperties'
import ProductDescription from 'core/CreateProduct/ProductDescription'
import ProductSKU from 'core/CreateProduct/ProductSKU'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import ButtonPublish from 'core/CreateProduct/ButtonPublish'
import { ProductSKUType } from 'types/ICategory'
import ButtonFixedLayout from 'core/ButtonFixedLayout'
import { useAuth } from 'contexts/Authenticate'
const { Dragger } = Upload
type Props = {
    is_template_listing?: boolean
    cb?: () => void
    tab?: number
}

const CreateProduct: FC<Props> = ({ cb, tab }) => {
    const { collapsed } = useAuth()
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [fileListSizeChart, setFileListSizeChart] = useState<UploadFile[]>([])
    const [form] = Form.useForm()
    const [description, setDescription] = useState('')
    const [productSKU, setProductSKU] = useState<ProductSKUType>()
    const [category, setCategory] = useState<string[]>(CATEGORY_DEFAULT)
    const [productProperties, setProductProperties] = useState<IProductProperties[]>([])
    const [configProducts, setConfigProducts] = useState<IConfigProduct[]>([])
    const [isTemplateListing, setTemplateListing] = useState<boolean>(true)
    const [isReset, setIsReset] = useState<boolean>(false)
    const [priceTemplateId, setPriceTemplateId] = useState<string | undefined>(undefined)
    const shopsQueryResponse = useAllTikTokShopQuery()
    useEffect(() => {
        if (tab !== 1) return
        ConfigProductAPI.getAllConfigProducts()
            .then((res) => {
                setConfigProducts(res)
                const _configProd = res.find((item) => item.is_default)
                console.log('_configProd', _configProd)
                if (_configProd) {
                    form.setFieldsValue({
                        quantity: _configProd.quantity,
                        brand_id: _configProd.brand_id,
                        height: _configProd.package_height,
                        width: _configProd.package_width,
                        length: _configProd.package_length,
                        weight: _configProd.package_weight,
                        category: _configProd.categories.map((item) => item.toString()),
                        category_template_id: _configProd._id,
                        product_properties: _configProd.product_properties
                    })
                    setProductProperties(_configProd.product_properties)
                    setCategory(_configProd.categories)
                } else {
                    form.setFieldsValue({
                        quantity: 2,
                        brand_id: '0',
                        height: 9,
                        width: 9,
                        length: 1,
                        weight: 0.42,
                        product_properties: []
                    })
                    setProductProperties([])
                    setCategory(CATEGORY_DEFAULT)
                }
            })
            .catch((err) => {
                notify('error', err.message)
            })
        onReset()
    }, [tab])
    const onReset = () => {
        console.log('onReset')
        form.resetFields()
        form.setFieldsValue({
            unit: 'INCH',
            weight_unit: 'POUND',
            category: CATEGORY_DEFAULT,
            product_properties: []
        })
        setFileList([])
        setFileListSizeChart([])
        setDescription('')
        setCategory(CATEGORY_DEFAULT)
        setProductProperties([])
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values, productSKU)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            setLoading(true)
            let resImages = await Promise.all(
                values?.images?.fileList.map(async (file: any) => {
                    const resImage = await UploadAPI.uploadImage(file.originFileObj)
                    return resImage
                })
            )
            let payload = {
                ...values,
                ...productSKU,
                images: resImages,
                description,
                is_template_listing: isTemplateListing,
                tik_platform: EnumTikPlatform.BY_COOKIE,
                price_template_id: priceTemplateId
            }
            if (payload?.size_chart?.fileList) {
                const resSizeChart = await UploadAPI.uploadImage(payload.size_chart.fileList[0].originFileObj)
                payload.size_chart_image = resSizeChart
            }
            if (payload?.colors) {
                payload.colors = payload.colors.split('|')
            }
            if (payload?.sizes) {
                payload.sizes = payload.sizes.split('|')
            }
            if (!payload.skus || payload.skus.length === 0) {
                notify('error', 'Vui lòng tạo SKU!')
                return
            }

            if (!payload.sizes && !payload.colors) {
                notify('error', 'Vui lòng nhập Size hoặc Color theo đúng định dạng mẫu!')
                return
            }
            console.log('payload', payload)
            await ProductAPI.uploadProductToTikTok(payload)
            notify('success', 'Upload sản phẩm mẫu đã được đưa vào hàng chờ!')
            onReset()
            setIsReset(true)
            if (cb) {
                cb()
            }
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const saveDraft = async () => {
        try {
            const values = await form.validateFields()
            console.log('Success:', values)
            onFinish({ ...values, is_draft: true })
        } catch (error: any) {
            notify('error', 'Vui lòng nhập đầy đủ thông tin!')
        }
    }

    const handleChangeImages: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)
    const handleChangeSizeChart: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileListSizeChart(newFileList)
    const handleChangeProductProperties = useCallback((product_properties: IProductProperties[]) => {
        console.log('handleChangeProductProperties', product_properties)
        form.setFieldValue('product_properties', product_properties)
    }, [])

    const onChangeDescription = useCallback((content: string) => {
        setDescription(content)
    }, [])

    const onChangeProductSKU = useCallback((_productSku: any) => {
        console.log('onChangeProductSKU', _productSku)
        setProductSKU(_productSku)
    }, [])

    const props: any = {
        name: 'file',
        multiple: true,
        beforeUpload(file: any) {
            return false
        },
        maxCount: 20,
        accept: 'image/*'
    }

    const listShopOptions = useMemo(() => {
        return _.map(shopsQueryResponse?.data ?? [], (shop: any) => ({
            value: shop._id,
            label: (
                <>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </>
            ),
            textSearch: `${shop.profile_name} ${shop.name}`
        }))
    }, [shopsQueryResponse?.data])

    const brandOptions = useMemo(() => {
        return [
            {
                value: '0',
                label: 'No Brand'
            },
            ..._.map(BRANDS, (brand: any) => ({
                value: brand.id,
                label: brand.name,
                textSearch: brand.name
            }))
        ]
    }, [])

    const onChangeForm = (changedValues: any) => {
        console.log('changedValues', changedValues)
        if (isReset) setIsReset(false)
        if (changedValues.category) {
            setCategory(changedValues.category)
            const _configProd = configProducts.find((item: any) => item.id === changedValues.category.join('_'))
            console.log('onChangeForm _configProd', _configProd)
            if (_configProd) {
                form.setFieldsValue({
                    quantity: _configProd.quantity,
                    brand_id: _configProd.brand_id,
                    height: _configProd.package_height,
                    width: _configProd.package_width,
                    length: _configProd.package_length,
                    weight: _configProd.package_weight,
                    category_template_id: _configProd._id,
                    product_properties: _configProd.product_properties
                })
                setProductProperties(_configProd.product_properties)
            } else {
                form.setFieldsValue({ category_template_id: '', product_properties: [] })
                setProductProperties([])
            }
        }
        if (changedValues.category_template_id) {
            const _configProd = configProducts.find((item: any) => item._id === changedValues.category_template_id)
            console.log('_configProd', _configProd)
            if (_configProd) {
                form.setFieldsValue({
                    quantity: _configProd.quantity,
                    brand_id: _configProd.brand_id,
                    height: _configProd.package_height,
                    width: _configProd.package_width,
                    length: _configProd.package_length,
                    weight: _configProd.package_weight,
                    category: _configProd.categories.map((item) => item.toString()),
                    category_template_id: _configProd._id,
                    product_properties: _configProd.product_properties
                })
                setProductProperties(_configProd.product_properties)
                setCategory(_configProd.categories)
            }
        }
    }
    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    )

    const onChangeIsTemplate: CheckboxProps['onChange'] = (e) => {
        setTemplateListing(e.target.checked)
    }

    const onChangePriceTemplate = (priceTemplateId: string) => {
        setPriceTemplateId(priceTemplateId)
    }
    const GroupButtonHandle = () => {
        return (
            <ButtonFixedLayout collapsed={collapsed}>
                <Space size='large'>
                    <ButtonPublish type='primary' htmlType='submit' loading={loading} size='large'>
                        Đăng sản phẩm
                    </ButtonPublish>
                    <Button
                        type='primary'
                        onClick={saveDraft}
                        size='large'
                        icon={<SaveOutlined />}
                        style={{ background: '#bfbfbf', color: '#000' }}
                        loading={loading}
                    >
                        Lưu nháp
                    </Button>
                </Space>
            </ButtonFixedLayout>
        )
    }
    return (
        <Form
            name='CreateProductTemplate'
            layout='vertical'
            onFinish={onFinish}
            autoComplete='off'
            form={form}
            initialValues={{
                unit: 'INCH',
                weight_unit: 'POUND',
                category: CATEGORY_DEFAULT
            }}
            onValuesChange={onChangeForm}
            className='mb-5'
        >
            <Checkbox onChange={onChangeIsTemplate} checked={isTemplateListing} className='ml-1 mt-3 mb-3' style={{ fontSize: '15px' }}>
                Chọn làm Template Product
            </Checkbox>

            <Row gutter={[20, 20]}>
                {isTemplateListing && (
                    <Col span={24}>
                        <Form.Item
                            label={<b>Template Name</b>}
                            name='template_name'
                            rules={[{ required: true, message: 'Vui lòng nhập tên Template!' }]}
                        >
                            <Input placeholder='Nhập Tên Template' size='large' />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Form.Item label={<b>Shop</b>} name='shop_ids' rules={[{ required: true, message: 'Vui lòng Chọn shop!' }]}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Vui lòng Chọn shop'
                            showSearch
                            optionFilterProp='textSearch'
                            size='large'
                            mode='multiple'
                            options={listShopOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label={<b>Hình ảnh sản phẩm</b>} name='images' rules={[{ required: true, message: 'Vui lòng chọn ảnh sản phẩm!' }]}>
                <Dragger {...props} listType='picture-circle' fileList={fileList} onChange={handleChangeImages}>
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    <p className='ant-upload-hint'>
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                    </p>
                </Dragger>
            </Form.Item>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Form.Item label={<b>Title</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}>
                        <TextArea placeholder='Nhập Tên sản phẩm' size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<b>Link Designer</b>} name='link_designer'>
                        <Input placeholder='Nhập Link Designer' size='large' />
                    </Form.Item>
                    <Form.Item label={<b>Link File In</b>} name='link_file_print'>
                        <Input placeholder='Nhập Link File In' size='large' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <CategoriesCascader />
                </Col>

                <Col span={12}>
                    <Form.Item label={<b>Brand</b>} name='brand_id' rules={[{ required: true, message: 'Vui lòng chọn thương hiệu!' }]}>
                        <Select placeholder='Chọn thương hiệu' showSearch optionFilterProp='textSearch' size='large' options={brandOptions}></Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Form.Item name='product_properties'>
                    <div>
                        <ProductPropertiesV2 category={category} value={productProperties} onChange={handleChangeProductProperties} />
                    </div>
                </Form.Item>
            </Row>
            <Form.Item label={<b>Size chart</b>} name='size_chart'>
                <Upload beforeUpload={() => false} listType='picture-circle' fileList={fileListSizeChart} onChange={handleChangeSizeChart}>
                    {fileListSizeChart.length >= 1 ? null : uploadButton}
                </Upload>
            </Form.Item>
            <ProductDescription value={description} onChange={onChangeDescription} isReset={isReset} />
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Form.Item label={<b>Height</b>} name='height' rules={[{ required: true, message: 'Vui lòng nhập Height sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Height sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Width</b>} name='width' rules={[{ required: true, message: 'Vui lòng nhập Width sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Width phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Length</b>} name='length' rules={[{ required: true, message: 'Vui lòng nhập Length sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Length sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Unit</b>} name='unit' rules={[{ required: true, message: 'Vui lòng nhập Unit!' }]}>
                        <Input placeholder='Nhập Unit' readOnly />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Form.Item
                        label={<b>Package Weight</b>}
                        name='weight'
                        rules={[{ required: true, message: 'Vui lòng nhập trọng lượng sản phẩm!' }]}
                    >
                        <InputNumber addonAfter='POUND' style={{ width: '100%' }} min={0} placeholder='Nhập Trọng lượng sản phẩm' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Quantity</b>} name='quantity' rules={[{ required: true, message: 'Vui lòng nhập số lượng sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập số lượng sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <ProductSKU value={productSKU} onChange={onChangeProductSKU} isReset={isReset} onChangePriceTemplate={onChangePriceTemplate} />
            {GroupButtonHandle()}
        </Form>
    )
}

export default CreateProduct
