import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'contexts/Authenticate'
import { Spin } from 'antd'
import React from 'react'
import DashboardHelpers from '../../pages/Dashboard/DashboardHelpers'
import { UNSTABLE_FEATURE_ALLOWED_EMAIL } from '../../pages/ListingProduct/ListingManagement'

export const AuthenticatedRoute = ({ required }: { required?: string }) => {
    const { user, token, isLoading } = useAuth()
    if (isLoading)
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '50%'
                }}
            >
                <Spin size='large' spinning={isLoading} />
            </div>
        )
    if (token) {
        return (
            <>
                {UNSTABLE_FEATURE_ALLOWED_EMAIL.includes(user?.email) && <DashboardHelpers />}
                <Outlet />
            </>
        )
    }
    return <Navigate to='/login' />
}
