import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Select} from "antd";
import {useModal} from "../../../../contexts/Modal";
import {notify} from "../../../../contexts/Notification";
import StaffAPI from "../../../../api/StaffAPI";
import {IUser} from "../../../../types/IUser";
import _ from "lodash";
import {CloudSyncOutlined} from "@ant-design/icons";
import TikTokShopAPI from "../../../../api/TikTokShopAPI";
import {useMutation} from "@tanstack/react-query";

const Option = Select.Option

function AssignTiktokShopModal({ item, refetchData }: { item: any, refetchData: () => void }) {
    const { closeModal } = useModal()
    const [form] = Form.useForm()
    const [staffs, setStaffs] = useState<IUser[]>([])
    const assignMutation = useMutation({
        mutationFn: TikTokShopAPI.assignShop,
        onSuccess: () => {
            refetchData?.()
            notify('success', 'Cập nhật thành công')
            closeModal();
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    useEffect(() => {
        StaffAPI.getAllStaffs({ is_active: true })
            .then((res) => {
                setStaffs(res)
                if (item) {
                    form.setFieldsValue({ assignee_id: item.assignee?._id })
                }
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }, [item])

    const renderStaffs = () => {
        return _.map(staffs, (staff) => {
            return (
                <Option value={staff._id} key={staff._id} textSearch={staff.full_name}>
                    {staff.full_name}
                </Option>
            )
        })
    }

    const handleEditShop = async (values: any) => {
        void assignMutation.mutateAsync({
            shop_id: item._id,
            assignee_id: values.assignee_id,
        })
    }

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Gán TikTok Shop' footer={null} width={600} centered>
            <Form
                layout={'vertical'}
                form={form}
                initialValues={{ layout: 'vertical' }}
                onFinish={handleEditShop}
                style={{ maxWidth: 600, marginTop: 24 }}
            >
                <Form.Item label={<b>Gán shop cho nhân viên</b>} name='assignee_id'>
                    <Select allowClear showSearch optionFilterProp='textSearch' style={{ width: '100%' }} placeholder='Chọn Nhân viên' size='large'>
                        {renderStaffs()}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' icon={<CloudSyncOutlined />} type='primary' loading={assignMutation.isLoading}>
                        Lưu thay đổi
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        );
}

export default AssignTiktokShopModal;
