import React from 'react'
import { HealthCheckRowProps } from '../interface/healthcheck.interface'
import withStatusCheck from './withStatusCheck'
import TiktokLabel from '../components/TiktokLabel'
import {Tag} from "antd";

export const QUALIFICATION_STATUS = {
    live: 100,
    jumio: 1,
    updoc: 5,
    die7days: 3,
    unknown: 101
}

const SUCCESS_COLOR = '#45B26B'
const WARNING_COLOR = '#FFB90A'
const ERROR_COLOR = '#FF0000'
const PURPLE_COLOR = '#6b00ff'

export const QUALIFICATION_MAPPING = {
    [QUALIFICATION_STATUS.jumio]: {
        1: {
            label: 'Jumio',
            color: WARNING_COLOR,
            moreInfo: 'Jumio Pending',
            icon: 'pending',
            value: QUALIFICATION_STATUS.jumio
        },
        2: {
            label: 'Jumio',
            color: WARNING_COLOR,
            moreInfo: 'Jumio Processing',
            icon: 'pending',
            value: QUALIFICATION_STATUS.jumio
        },
        3: {
            label: 'Jumio',
            moreInfo: 'Jumio Passed',
            color: WARNING_COLOR,
            icon: 'verify',
            value: QUALIFICATION_STATUS.jumio
        },
        4: {
            label: 'Jumio',
            moreInfo: 'Jumio Failed',
            color: WARNING_COLOR,
            icon: 'error',
            value: QUALIFICATION_STATUS.jumio
        }
    },
    [QUALIFICATION_STATUS.updoc]: {
        1: {
            color: PURPLE_COLOR,
            label: 'Up Doc',
            value: QUALIFICATION_STATUS.updoc
        },
        2: {
            label: 'Up Doc',
            moreInfo: 'Up Doc Processing',
            color: PURPLE_COLOR,
            icon: 'pending',
            value: QUALIFICATION_STATUS.updoc
        },
        3: {
            label: 'Live',
            moreInfo: 'Up Doc Success',
            color: SUCCESS_COLOR,
            icon: 'verify',
            value: QUALIFICATION_STATUS.live
        },
        4: {
            label: 'Up Doc',
            moreInfo: 'Up Doc Failed',
            color: PURPLE_COLOR,
            icon: 'error',
            value: QUALIFICATION_STATUS.updoc
        }
    }
}

function AccountQualification({ qualification, violation }: Pick<HealthCheckRowProps, 'qualification' | 'violation'>) {
    if (qualification?.tasks) {
        const taskIdx = qualification.tasks.length - 1
        const verificationType = qualification.tasks[taskIdx]?.verification_type
        const status = qualification.tasks[taskIdx]?.status
        if (!verificationType || !status) return <TiktokLabel label='Unknown' color='error' size='small' />
        // @ts-ignore
        const { color = 'black', label = 'Unknown' } = QUALIFICATION_MAPPING[verificationType][status] || {}
        return (
            <div className='flex gap-2 items-center'>
                {/*<TiktokLabel color={color as TiktokLabelColor} label={label} />*/}
                <Tag color={color}>{label}</Tag>
            </div>
        )
    }

    if (violation?.ongoing_punishment?.items?.[0]?.punishment_type === QUALIFICATION_STATUS.die7days) {
        return <Tag color={ERROR_COLOR}>Die 7 days</Tag>
    }
    return <Tag color={SUCCESS_COLOR}>Live</Tag>
}

export default React.memo(withStatusCheck(AccountQualification))

