import React from 'react'
import { Button, Flex, Tooltip } from 'antd'
import { InfoCircleOutlined, ShopOutlined } from '@ant-design/icons'
import { useModal } from '../../../../contexts/Modal'
import PackageModal from './modals/PackageModal'
import { useAuth } from '../../../../contexts/Authenticate'
import { formatDate } from '../../../../utils/format'
import dayjs from 'dayjs'
import PackageExtendModal from './modals/PackageExtendModal'
import '../../styles/package.style.scss'

function PackageBlock() {
    const { openModal } = useModal()
    const { user } = useAuth()

    const isFreePackage = user?.package?.package_detail?.slug === 'free'

    const dayRemaining = dayjs(user?.package?.expire_at).diff(dayjs(), 'day')

    const openUpgradePackageModal = () => openModal(<PackageModal />)

    const openExtendPackageModal = () => openModal(<PackageExtendModal />)

    return (
        <div className='package-card'>
            <Flex align='center'>
                <p className='w-50 font-weight-bold'>Thông tin gói cước</p>
                {!isFreePackage && (
                    <Flex align='center' gap={8}>
                        <p>{formatDate(dayjs(user.package.expire_at))}</p>
                        <Tooltip
                            arrow
                            placement='right'
                            title={
                                <Flex vertical>
                                    <p>- Ngày mua: {formatDate(dayjs(user.package.buy_at))}</p>
                                    <p>- Ngày hết hạn: {formatDate(dayjs(user.package.expire_at))}</p>
                                    <p>- {dayRemaining <= 0 ? 'Package has been expired' : `Còn ${dayRemaining} ngày`}</p>
                                </Flex>
                            }
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Flex>
                )}
            </Flex>
            <p className='package-card__name'>{user?.package?.package_detail?.name}</p>
            <Flex gap={16} align='center' className='mt-2'>
                <Flex gap={8}>
                    <Button className='upgrade-button' onClick={openUpgradePackageModal}>
                        {'Nâng cấp'}
                    </Button>
                    <Button className='extend-button' onClick={openExtendPackageModal} disabled={isFreePackage}>
                        {'Gia hạn'}
                    </Button>
                </Flex>
                <Flex gap={4}>
                    <Flex gap={8} align='center'>
                        <ShopOutlined style={{ color: 'white' }} />
                        <p className='package-card__typo'>
                            {user?.package?.shop_used}/{user?.package?.shop_limit}
                        </p>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    )
}

export default PackageBlock
