import React from 'react'
import { IBankAccount } from '../interface/bank.interface'
import { Button, Flex } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import { useModal } from '../../../../contexts/Modal'
import BankAccountHistoriesModal from './BankAccountHistoriesModal'

function BankAccountHistoriesColumn({ data }: { data: IBankAccount }) {
    const { openModal } = useModal()

    const openHistoriesModal = () => openModal(<BankAccountHistoriesModal bankAccount={data} />)

    return (
        <Flex gap={2} align='center'>
            <Button type='text' shape='circle' onClick={openHistoriesModal}>
                <HistoryOutlined />
            </Button>
        </Flex>
    )
}

export default BankAccountHistoriesColumn
