import { ColumnsType } from 'antd/lib/table'
import {formatCurrency, formatDate} from '../../../../utils/format'
import { ITiktokShopPaid } from '../interface/shop-paid.interface'
import dayjs from "dayjs";

export const SHOP_PAID_COLUMNS: ColumnsType<ITiktokShopPaid> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Ngày paid',
        dataIndex: ['payment', 'create_time'],
        key: 'payment.create_time',
        render: value => formatDate(dayjs.unix(value))
    },
    {
        title: 'Payment ID',
        dataIndex: 'payment_id',
        key: 'payment_id',
    },
    {
        title: 'Số tiền',
        dataIndex: ['payment', 'amount', 'value'],
        key: 'payment.amount',
        render: value => formatCurrency(value, { prefix: '$' })
    },
    {
        title: 'Bank account',
        dataIndex: ['payment', 'bank_account'],
        key: 'payment.bank_account',
    },
    {
        title: 'Shop code',
        dataIndex: ['shop', 'shop_code'],
        key: 'shop.shop_code',
    },
    {
        title: 'Shop name',
        dataIndex: ['shop', 'name'],
        key: 'shop.name',
    },
    {
        title: 'Profile',
        dataIndex: ['shop', 'profile_name'],
        key: 'shop.profile_name',
    },
    {
        title: 'Seller',
        dataIndex: ['shop', 'assignee', 'email'],
        key: 'shop.assignee',
    },
]
