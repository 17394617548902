import { Button, Divider, Input, Space } from 'antd'
import { Col, Form, Select, Row } from 'antd/lib'
import { CHILD_PRODUCT_COMPLAIN_IDS, PRODUCT_COMPLIANCE_IDS } from 'const'
import { useProductAttributesQuery } from 'hooks/queries/category'
import _ from 'lodash'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { IProductProperties, IValue } from 'types/IConfigProduct'

type ProductPropertiesProps = {
    value?: IProductProperties[]
    category?: string[]
    onChange?: (val: IProductProperties[]) => void
}
const ProductPropertiesV2: FC<ProductPropertiesProps> = ({ value, category, onChange }) => {
    const categoriesQueryResponse = useProductAttributesQuery((category && category[category?.length - 1]) || '')
    // const productProperties = categoriesQueryResponse.data ?? []
    const [productProperties, setProductProperties] = useState(categoriesQueryResponse.data ?? [])
    const [form] = Form.useForm()
    const [customValue, setCustomValue] = useState<string>('')
    useEffect(() => {
        if (!value || value.length === 0) {
            form.resetFields()
            return
        }

        const initialValues = value.reduce(
            (acc, item) => {
                acc[item.id] = item.is_multiple_selection ? item.values.map((v) => v.id) : item.values[0] && item.values[0]?.id
                return acc
            },
            {} as Record<string, any>
        )

        form.setFieldsValue({
            ...initialValues,
            product_properties: value
        })

        const updatedProperties = [...(categoriesQueryResponse?.data ?? [])]
        value.forEach((item: any) => {
            const existingProperty = updatedProperties.find((prop) => prop.id === item.id)
            if (existingProperty) {
                const newValues = _.uniqBy([...existingProperty.values, ...item.values], 'id')
                existingProperty.values = newValues
            }
        })

        setProductProperties(updatedProperties)
    }, [value, form, categoriesQueryResponse.data])

    useEffect(() => {
        setProductProperties(categoriesQueryResponse.data ?? [])
    }, [categoriesQueryResponse.data])

    const randomNumber = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    const getUID = () => {
        const value = Math.floor(Date.now() + Math.random())
        const lastSix = value.toString().slice(-3)
        return randomNumber(1, 4) + lastSix
    }
    const addItem = useCallback(
        (product_properties_id: string) => {
            console.log('addItem', product_properties_id)
            const idx = productProperties.findIndex((item) => item.id === product_properties_id)
            if (idx === -1) return
            const property = productProperties[idx]
            const newValues = [{ id: getUID(), name: form.getFieldValue('custom_value'), is_custom: true }, ...property.values]
            const uniqueValues = _.uniqBy(newValues, 'name')
            const updatedProperties = [...productProperties]
            updatedProperties[idx] = { ...property, values: uniqueValues }
            console.log('updatedProperties', updatedProperties)
            setProductProperties(updatedProperties)
            form.setFieldsValue({ custom_value: '' })
            setCustomValue('')
        },
        [form, productProperties]
    )

    const renderProperties = useCallback(
        (filterFn: (item: any) => boolean) => {
            // console.log('render productProperties', productProperties)
            return productProperties.filter(filterFn).map((item) => (
                <Col span={6} key={item.id} className='ml-2 mr-2'>
                    <Form.Item name={item.id} label={item.name}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={`Chọn thuộc tính ${item.name}`}
                            optionFilterProp='textSearch'
                            size='large'
                            mode={item.is_multiple_selection ? 'multiple' : undefined}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    {item.is_customizable && (
                                        <>
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 0' }}>
                                                <Form.Item name='custom_value'>
                                                    <Input
                                                        placeholder='Enter a custom value'
                                                        value={customValue}
                                                        onChange={(e) => setCustomValue(e.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item name='button-add'>
                                                    <Button type='primary' onClick={() => addItem(item.id)} className='mx-3'>
                                                        Add item
                                                    </Button>
                                                </Form.Item>
                                            </Space>
                                        </>
                                    )}
                                </>
                            )}
                            options={item.values.map((val: IValue) => ({
                                value: val.id,
                                label: val.name,
                                textSearch: val.name
                            }))}
                        />
                    </Form.Item>
                </Col>
            ))
        },
        [productProperties]
    )

    const onValuesChange = useCallback(
        (changedValues: any, allValues: any) => {
            console.log('ProductPropertiesV2 changedValues', changedValues)
            if (_.has(changedValues, 'custom_value')) {
            } else {
                const updatedProperties = Object.keys(allValues).map((key) => {
                    const property = productProperties.find((item) => item.id === key)
                    if (!property) return null

                    const selectedValues = Array.isArray(allValues[key]) ? allValues[key] : allValues[key] ? [allValues[key]] : []
                    console.log('selectedValues', selectedValues)
                    return {
                        id: key,
                        name: property.name,
                        is_multiple_selection: property.is_multiple_selection,
                        is_customizable: property.is_customizable,
                        values: selectedValues.map((val) => {
                            const matchedValue = property.values.find((v) => v.id === val)
                            return {
                                id: val,
                                name: matchedValue?.name || '',
                                is_custom: matchedValue?.is_custom ?? false
                            }
                        })
                    }
                })

                onChange?.(updatedProperties.filter(Boolean) as IProductProperties[])
            }
        },
        [productProperties, onChange]
    )
    return (
        <Form form={form} name={'ProductProperties'} onValuesChange={onValuesChange} layout='vertical'>
            <Row gutter={[20, 20]}>
                {renderProperties((item) => !PRODUCT_COMPLIANCE_IDS.includes(item.id) && !CHILD_PRODUCT_COMPLAIN_IDS.includes(item.id))}
            </Row>
            <Row gutter={[20, 20]}>{renderProperties((item) => PRODUCT_COMPLIANCE_IDS.includes(item.id))}</Row>
        </Form>
    )
}

export default memo(ProductPropertiesV2)
