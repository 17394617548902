import {IShopHealthQueryParams} from "../interface/healthcheck.interface";
import {useQuery} from "@tanstack/react-query";
import TikTokShopAPI from "../../../../api/TikTokShopAPI";

export const SHOP_HEALTH_LIST_QUERY_KEY = 'SHOP_HEALTH_LIST_QUERY_KEY'

export const useShopHealthListQuery = (query: IShopHealthQueryParams) => {
    return useQuery({
        queryKey: [SHOP_HEALTH_LIST_QUERY_KEY, query],
        queryFn: () => TikTokShopAPI.getShopsHealth(query)
    })
}
