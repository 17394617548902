import { Card } from 'antd'
import { FC } from 'react'
import _ from 'lodash'
import Staff from './Staff'
import { Helmet } from 'react-helmet'
import StaffManagementTab from './StaffManagementTab'
import WithLeader from "../../core/HOC/WithLeader";
const TITLE = 'Quản lý nhân viên'

const StaffManagement: FC = () => {
    return (
        <WithLeader>
            <Card title={TITLE}>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <StaffManagementTab />
            </Card>
        </WithLeader>
    )
}

export default StaffManagement
