import { useQuery } from '@tanstack/react-query'
import useDebounce from '../../../../hooks/useDebounce'
import {useBankAccountStore} from "../store/bank.store";
import {IBankAccountQueryParams} from "../interface/bank.interface";
import FinanceAPI from "../../../../api/FinanceAPI";

export const LIST_BANK_ACCOUNT_QUERY_KEY = 'LIST_BANK_ACCOUNT_QUERY_KEY'

export const useBankAccountQuery = (queryParams: IBankAccountQueryParams) => {
    const filter = useBankAccountStore((state) => state.filter)
    const search = useDebounce(filter.search)
    const query = {
        shop: filter.shop,
        user: filter.user,
        status: filter.status,
        ...queryParams,
        search,
    }
    return useQuery({
        queryKey: [LIST_BANK_ACCOUNT_QUERY_KEY, query],
        queryFn: () => FinanceAPI.getAllBankAccounts(query)
    })
}
