import { useEffect, useState } from 'react'
import { Button, Image, Row, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import _ from 'lodash'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import tiktokShopImg from 'assets/images/tiktok-shop-logo.png'
import CopyToClipboard from 'core/CopyToClipboard'

const AuthorizationLink = () => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        TikTokShopAPI.getAuthorizationLink()
            .then((res) => {
                setUrl(res.url)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }, [])

    const onCopy = () => {
        message.success(`Đã sao chép`)
    }
    return (
        <>
            <Row gutter={[24, 24]} justify={'center'} className='m-5'>
                <Image width={200} src={tiktokShopImg} preview={false} />
            </Row>
            <Row gutter={[24, 24]} justify={'center'} className='m-2'>
                <b>
                    Kết nối Tài khoản TikTok Shop của bạn bằng cách ủy quyền tài khoản cho chúng tôi. Chia sẻ liên kết tới Seller của bạn và họ có thể
                    nhấp vào đó để xác nhận ủy quyền dịch vụ của bạn.
                </b>
            </Row>
            <Row gutter={[24, 24]} justify={'center'} className='mt-4'>
                <CopyToClipboard text={url} onCopy={() => onCopy()}>
                    <Button size='large' icon={<CopyOutlined />} loading={loading}>
                        Copy authorization link
                    </Button>
                </CopyToClipboard>
            </Row>
        </>
    )
}

export default AuthorizationLink
