import React, { useMemo } from 'react'
import { Alert, Button, Flex, Form, Modal, Select, Tag } from 'antd'
import { useModal } from '../../../../contexts/Modal'
import { useForm } from 'antd/es/form/Form'
import { notify } from '../../../../contexts/Notification'
import { useAllNicheQuery } from '../../../../hooks/queries/niche'
import { INiche } from '../../../../types/INiche'
import Input from 'antd/lib/input/Input'
import TextArea from 'antd/es/input/TextArea'
import { CloudSyncOutlined } from '@ant-design/icons'
import { ITikTokShop } from '../../../../types/ITikTokShop'
import { useShopAuth } from '../hooks/useShopAuth'
import { useMutation } from '@tanstack/react-query'
import TikTokShopAPI from '../../../../api/TikTokShopAPI'
import { WarningFilled } from '@ant-design/icons'
import ImportKikiModal from "./ImportKikiModal";

const getNiches = (allNiches: INiche[]) => {
    const mainNiches: INiche[] = [],
        subNiches: INiche[] = []
    allNiches.forEach((niche) => {
        if (!niche.parent) {
            mainNiches.push(niche)
        } else {
            subNiches.push(niche)
        }
    })

    return {
        mainNiches,
        subNiches
    }
}

function ImportShopFromExternalPlatformModal({ shop, refetchData }: { shop?: ITikTokShop; refetchData: () => void }) {
    const { closeModal, openModal } = useModal()
    const [form] = useForm()
    const { mutationGetShopAuthData, getShopAuthWithoutShopInfo } = useShopAuth({ shop, form })
    const importShopMutation = useMutation({
        mutationFn: TikTokShopAPI.importFromOtherPlatform,
        onSuccess: () => {
            refetchData?.()
            notify('success', 'Import shop thành công')
            closeModal()
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    const { data: allNiches } = useAllNicheQuery()

    const { mainNiches, subNiches } = useMemo(() => getNiches(allNiches ?? []), [allNiches])

    const mainNiche = Form.useWatch('main_niche', form)

    const onSubmit = async (values: Partial<ITikTokShop>) => {
        console.log('values', values)
        await importShopMutation.mutateAsync(values)
    }

    const mainNicheOptions = useMemo(
        () =>
            mainNiches.map((niche) => ({
                value: niche._id,
                label: niche.name
            })),
        [mainNiches]
    )

    const subNicheOptions = useMemo(
        () =>
            subNiches
                .filter((niche) => niche.parent._id === mainNiche)
                .map((niche) => ({
                    value: niche._id,
                    label: niche.name
                })),
        [subNiches, mainNiche]
    )

    const openImportKikiLoginModal = () => openModal(<ImportKikiModal refetchData={refetchData} />)

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={'Import shop'}
            footer={null}
            width={800}
            className='artwork-config-modal'
        >
            {
                !shop && (
                    <Alert
                        message={
                            <Flex gap={4} className='w-100' justify='center'>
                                <WarningFilled style={{ color: '#f65904' }} />
                                <span className='font-weight-bold' style={{ fontSize: 16, color: '#f65904' }}>
                            Lưu ý
                        </span>
                            </Flex>
                        }
                        description={
                            <>
                                <p className='text-center px-6'>
                                    Bạn đang thực hiện import shop từ nền tảng khác và chỉ có thể sử dụng được tính năng <strong>Quản lý đơn hàng</strong>,{' '}
                                    <strong>Fulfill</strong> và <strong>Listing API</strong>.
                                </p>
                                <p className='text-center px-4'>
                                    Để đạt được hiệu suất cao nhất, chúng tôi khuyến khích seller dùng{' '}
                                    <strong className='text-info'>trọn bộ tính năng hỗ trợ đặc biệt</strong> thông qua{' '}
                                    <strong className='text-info'>Import shop bằng KikiLogin</strong>
                                </p>
                                <Flex justify='center' className='mt-2'>
                                    <Button type='primary' onClick={openImportKikiLoginModal}>Kết nối KikiLogin</Button>
                                </Flex>
                            </>
                        }
                        type='warning'
                        className='mt-3'
                    />
                )
            }
            <Form
                name='updateAutoIdeaApiProps'
                layout={'vertical'}
                form={form}
                onFinish={onSubmit}
                // style={{ maxWidth: 600 }}
                initialValues={{
                    name: shop?.name ?? '',
                    shop_code: shop?.shop_code ?? '',
                    shop_id: shop?.shop_id ?? '',
                    region: shop?.region ?? 'US',
                    main_niche: shop?.main_niche?._id,
                    sub_niches: shop?.sub_niches?.map((niche) => niche._id) ?? [],
                    authorize_app_id: shop?.authorize_app_id ?? '',
                    authorize_app_key: shop?.authorize_app_key ?? '',
                    authorize_app_secret: shop?.authorize_app_secret ?? '',
                    cipher: shop?.cipher ?? '',
                    access_token: shop?.access_token ?? '',
                    refresh_token: shop?.refresh_token ?? '',
                    access_token_expire_in: shop?.access_token_expire_in ?? '',
                    refresh_token_expire_in: shop?.refresh_token_expire_in ?? '',
                    authorize_token: shop?.authorize_token ?? ''
                }}
                className='mt-3'
            >
                <Flex gap={12} className='d-none'>
                    <Form.Item label={<b>Shop name</b>} name='name' className='w-100'>
                        <Input placeholder='Nhập Shop Name' />
                    </Form.Item>
                    <Form.Item label={<b>Shop code</b>} name='shop_code' className='w-100'>
                        <Input placeholder='Nhập Shop code' />
                    </Form.Item>
                    <Form.Item label={<b>Shop ID</b>} name='shop_id' className='w-100'>
                        <Input placeholder='Nhập Shop code' />
                    </Form.Item>
                    <Form.Item label={<b>Shop ID</b>} name='region' className='w-100'>
                        <Input placeholder='Nhập Shop code' />
                    </Form.Item>
                </Flex>
                <Flex gap={12}>
                    <Form.Item label={<b>Chọn Main Niche</b>} name='main_niche' className='w-100'>
                        <Select allowClear style={{ width: '100%' }} placeholder='Chọn Main Niche' size='middle' options={mainNicheOptions} />
                    </Form.Item>
                    <Form.Item label={<b>Chọn Sub Niche</b>} name='sub_niches' className='w-100'>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Chọn Sub Niche'
                            size='middle'
                            mode='multiple'
                            options={subNicheOptions}
                        />
                    </Form.Item>
                </Flex>
                <Form.Item
                    label={
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                            <p style={{ width: 500, fontWeight: 600 }}>Authorize Token</p>
                        </div>
                    }
                    name='authorize_token'
                >
                    <TextArea placeholder='Nhập Authorize Token' autoSize={{ minRows: 3 }} />
                </Form.Item>
                <Flex gap={12}>
                    <Form.Item label={<b>Authorize App ID</b>} name='authorize_app_id' className='w-100'>
                        <Input placeholder='Nhập App ID' />
                    </Form.Item>
                    <Form.Item label={<b>Authorize App Key</b>} name='authorize_app_key' className='w-100'>
                        <Input placeholder='Nhập App Key' />
                    </Form.Item>
                    <Form.Item label={<b>Authorize App Secret</b>} name='authorize_app_secret' className='w-100'>
                        <Input placeholder='Nhập App Secret' />
                    </Form.Item>
                </Flex>
                <div>
                    <Flex gap={12} justify='flex-end'>
                        <Button type='primary' onClick={getShopAuthWithoutShopInfo} loading={mutationGetShopAuthData.isLoading}>
                            Get shop auth
                        </Button>
                    </Flex>
                    <Flex gap={12} className='mt-2'>
                        <Form.Item label={<b>Shop cipher</b>} name='cipher' className='w-100'>
                            <Input placeholder='Shop cipher' disabled />
                        </Form.Item>
                        <Form.Item label={<b>Access token</b>} name='access_token' className='w-100'>
                            <Input placeholder='Shop access token' disabled />
                        </Form.Item>
                        <Form.Item label={<b>refresh_token</b>} name='refresh_token' className='d-none'>
                            <Input placeholder='Nhập App Secret' />
                        </Form.Item>
                        <Form.Item label={<b>access_token_expire_in</b>} name='access_token_expire_in' className='d-none'>
                            <Input placeholder='Nhập App Secret' />
                        </Form.Item>
                        <Form.Item label={<b>refresh_token_expire_in</b>} name='refresh_token_expire_in' className='d-none'>
                            <Input placeholder='Nhập App Secret' />
                        </Form.Item>
                    </Flex>
                </div>
                <Form.Item className='text-right'>
                    <Button size='large' htmlType='submit' icon={<CloudSyncOutlined />} type='primary'>
                        Lưu thay đổi
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ImportShopFromExternalPlatformModal
