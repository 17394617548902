import React from 'react';
import {Button, Flex} from "antd";
import { ExportOutlined, ImportOutlined, ReloadOutlined } from '@ant-design/icons'
import { UseQueryResult } from '@tanstack/react-query'
import {IListApiResponse} from "../../../../types/IApiResponse";
import {useModal} from "../../../../contexts/Modal";
import ImportBankModal from "../modals/ImportBankModal";
import {useAuth} from "../../../../contexts/Authenticate";
import {EnumRole} from "../../../../types/IRole";

const ALLOWED_IMPORT_BANK_ROLES = [EnumRole.OWNER, EnumRole.ACCOUNTANT]

function BankAccountHeader({queryResponse}: { queryResponse: UseQueryResult<IListApiResponse<any>> | undefined }) {
    const { openModal } = useModal()
    const { user } = useAuth()

    const openImportModal = () => openModal(<ImportBankModal />)

    return (
        <Flex justify='space-between' align='center' gap={12}>
            <span style={{ fontSize: 16, flexGrow: 1 }}>Quản Lý Bank Account</span>
            {
                ALLOWED_IMPORT_BANK_ROLES.includes(user.role[0]?.id) && (
                    <Button type='primary' onClick={openImportModal} icon={<ImportOutlined />} size='middle'>
                        Import bank
                    </Button>
                )
            }
            {/*<Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ExportOutlined />} size='middle'>*/}
            {/*    Export data*/}
            {/*</Button>*/}
            <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='middle'>
                Refresh
            </Button>
        </Flex>
    );
}

export default BankAccountHeader;
