import { useState, FunctionComponent, useEffect } from 'react'
import { Alert, Flex } from 'antd'
import { useSearchParams } from 'react-router-dom'
import TikTokShopAPI from 'api/TikTokShopAPI'
const Authorization: FunctionComponent = () => {
    const [query] = useSearchParams()
    const [msg, setMsg] = useState('')
    const [isSuccess, setSuccess] = useState(false)
    const appKey = query.get('app_key')
    const authorizedToken = query.get('code')
    const state = query.get('state')
    useEffect(() => {
        if (appKey && authorizedToken && state) {
            TikTokShopAPI.verifyAuthorizeToken({
                app_key: appKey,
                code: authorizedToken,
                state
            })
                .then((res) => {
                    setMsg('')
                    setSuccess(true)
                })
                .catch((err) => {
                    setMsg(err.message)
                    setSuccess(false)
                })
        } else {
            setMsg('Authorization link invalid. Please access https://apps.tikcrm.net to get your authorization link')
        }
    }, [query])
    return (
        <>
            <div className='m-5' style={{ flex: 1 }}>
                <h2 style={{ textAlign: 'center' }}>Authorization Response</h2>
                <Flex align='center' justify='center' className='mt-4'>
                    {msg ? (
                        <Alert message='Error' description={msg} type='error' showIcon />
                    ) : (
                        isSuccess && (
                            <Alert
                                message='Success'
                                description='Bạn đã ủy quyền shop thành công. Vui lòng truy cập TIKCRM để sử dụng shop.'
                                type='success'
                                showIcon
                            />
                        )
                    )}
                </Flex>
            </div>
        </>
    )
}

export default Authorization
