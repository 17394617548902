import { useAuth } from '../contexts/Authenticate'

export const usePackagePermission = (permission: string) => {
    const { user } = useAuth()
    const userPackage = user.package?.package_detail
        ? user.package?.package_detail?.level > 1000
            ? user.package.permission
            : user.package.package_detail.permission
        : []

    if (userPackage.includes('*')) return true

    return userPackage.includes(permission)
}
