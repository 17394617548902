import { useQuery } from '@tanstack/react-query'
import ActivityAPI from 'api/ActivityAPI'
import { IPromotionQueryParams } from 'types/IPromotion'

export const TIKTOK_ACTIVITY_KEY = 'activity_list'

export const useActivityQuery = (queryParams: IPromotionQueryParams, enabled = false) => {
    return useQuery({
        queryKey: [TIKTOK_ACTIVITY_KEY, queryParams],
        queryFn: () => ActivityAPI.getPromotions(queryParams),
        enabled
    })
}