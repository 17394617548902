import { useQuery } from '@tanstack/react-query'
import CategoryAPI from 'api/CategoryAPI.ts'
import { EnumCategoryType, ICategory, IProductAttribute } from 'types/ICategory'

export const CATEGORIES_QUERY_KEY = 'categories_list'
export const PRODUCT_ATTRIBUTES_QUERY_KEY = 'product_attributes_list'


export const useCategoriesQuery = (type?: EnumCategoryType) => {
    return useQuery<ICategory[]>({
        queryKey: [CATEGORIES_QUERY_KEY, type],
        queryFn: () => CategoryAPI.getCategories(type)
    })
}


export const useProductAttributesQuery = (category_id: string) => {
    return useQuery<IProductAttribute[]>({
        queryKey: [PRODUCT_ATTRIBUTES_QUERY_KEY, category_id],
        queryFn: () => CategoryAPI.getProductAttributes(category_id),
        enabled: !!category_id
    })
}
