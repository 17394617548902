import { useEffect, useState, FC, useRef } from 'react'
import { Button, Form, Modal, Divider, Row, Col, ConfigProvider, Select, InputNumber, Input, Space, Flex } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import Editor from 'core/Editor'
import TextArea from 'antd/es/input/TextArea'
import ProductTemplateTmpAPI from 'api/ProductTemplateTmpAPI'
import { IProduct } from 'types/IProduct'
import { useAllDescriptionTemplateQuery } from 'hooks/queries/description-template'
import { useAllPriceTemplateQuery } from 'hooks/queries/price-template'

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IProduct | any
}

const EditDescriptionDraft: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [description, setDescription] = useState('')
    const [hasColor, setHasColor] = useState(false)
    const [selectedPriceTemp, setSelectedPriceTemp] = useState<string>('')
    const priceTemplatesQueryResponse = useAllPriceTemplateQuery()
    const descriptionTemplatesQueryResponse = useAllDescriptionTemplateQuery()
    useEffect(() => {
        if (visible) {
            setDescription(item?.description)
            form.setFieldsValue({ title: item?.product_name })
        }
        if (visible && item && item.skus && item.sale_properties) {
            const checkColor = item.sale_properties.find((property: any) => property.id === '100000')
            if (checkColor) {
                setHasColor(true)
            }
            let newSkus = item.skus
            let seller_sku = newSkus[0].seller_sku
            newSkus = newSkus.map((sku: any) => {
                if (checkColor) {
                    const tmp = {
                        id: sku.id,
                        color: sku.properties[0].value_name,
                        size: sku.properties[1].value_name,
                        price: Number(sku.base_price.sale_price),
                        seller_sku: sku.seller_sku
                    }
                    return tmp
                } else {
                    const tmp = {
                        id: sku.id,
                        size: sku.properties[0].value_name,
                        price: Number(sku.base_price.sale_price),
                        seller_sku: sku.seller_sku
                    }
                    return tmp
                }
            })
            let colorString = ''
            let sizeString = ''

            for (const property of item.sale_properties) {
                if (property.text === 'Color') {
                    colorString = property.values.map((value: any) => value.name).join('|')
                } else if (property.text === 'Size') {
                    sizeString = property.values.map((value: any) => value.name).join('|')
                }
            }
            form.setFieldsValue({
                skus: newSkus,
                sku: seller_sku,
                colors: colorString,
                sizes: sizeString
            })
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        setDescription('')
        setHasColor(false)
        setSelectedPriceTemp('')
        toggleModal(false)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            const payload: any = {
                ...values,
                description
            }
            if (values?.colors) {
                payload.colors = values.colors.split('|')
            }
            if (values?.sizes) {
                payload.sizes = values.sizes.split('|')
            }
            if (!values.skus || values.skus.length === 0) {
                notify('error', 'Vui lòng tạo SKU!')
                return
            }
            ProductTemplateTmpAPI.editProductTemplateTmp(item._id, payload)
                .then((res) => {
                    notify('success', 'Cập nhật thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            setDescription(editor.getData())
        }
    }
    const generateSKUS = () => {
        let { colors, sizes, price, sku } = form.getFieldsValue(['colors', 'sizes', 'price', 'sku'])
        console.log(colors, sizes, price, sku)
        let skus: any = []
        colors = colors.split('|')
        sizes = sizes.split('|')
        if (!sizes || sizes.length === 0) {
            notify('error', 'Vui lòng nhập Sizes!')
            return
        }
        if (!price) {
            notify('error', 'Vui lòng nhập Price!')
            return
        }
        if (colors.length > 0) {
            setHasColor(true)
            for (let i = 0; i < sizes.length; i++) {
                for (let j = 0; j < colors.length; j++) {
                    skus.push({
                        size: sizes[i],
                        color: colors[j],
                        price: price,
                        seller_sku: sku
                    })
                }
            }
        } else {
            setHasColor(false)
            for (let i = 0; i < sizes.length; i++) {
                skus.push({
                    size: sizes[i],
                    price: price,
                    seller_sku: sku
                })
            }
        }

        form.setFieldValue('skus', skus)
    }
    const onChangeForm = (changedValues: any) => {
        console.log('changedValues', changedValues)
        if (changedValues.description_template_id && descriptionTemplatesQueryResponse?.data) {
            const _description = descriptionTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.description_template_id)
            if (_description) {
                setDescription(_description.description)
            }
        } else if (changedValues.description_template_id === '') {
            setDescription('')
        }
        if (changedValues.price_template_id) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.price_template_id)
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: form.getFieldValue('sku')
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }

            setSelectedPriceTemp(changedValues.price_template_id)
        } else if (changedValues.price_template_id === '') {
            form.setFieldsValue({
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL'
            })
            setHasColor(true)
            form.setFieldValue('skus', [])
            setSelectedPriceTemp(changedValues.price_template_id)
        }

        if (changedValues.sku) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === form.getFieldValue('price_template_id'))
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: changedValues.sku
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }
        }
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Sửa sản phẩm mẫu'
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form
                name='EditDescriptionModal'
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete='off'
                form={form}
                onValuesChange={onChangeForm}
            >
                <Form.Item label={<b>Title</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}>
                    <TextArea placeholder='Nhập Tên sản phẩm' size='large' />
                </Form.Item>
                <Row gutter={[20, 20]}>
                    <Col span={14}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                        colorBgContainer: '#d9d9d9 !important'
                                    }
                                }
                            }}
                        >
                            <Form.Item
                                label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu Size, Price có sẵn</b>}
                                name='price_template_id'
                            >
                                <Select placeholder='Chọn mẫu Size, Price có sẵn' showSearch optionFilterProp='textSearch' size='large'>
                                    <Option value=''>NHẬP TAY SIZE, PRICE</Option>
                                    {_.map(priceTemplatesQueryResponse?.data, (des: any) => (
                                        <Option value={des._id} key={des._id} textSearch={`${des.name}`}>
                                            {`${des.name}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </ConfigProvider>
                    </Col>
                    <Col span={8} style={{ marginTop: '30px' }}>
                        <Form.Item className='d-flex justify-content-center'>
                            <Button size='large' htmlType='submit' type='primary' loading={loading}>
                                Lưu thay đổi
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                        colorBgContainer: '#d9d9d9 !important'
                                    }
                                }
                            }}
                        >
                            <Form.Item
                                label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu mô tả có sẵn</b>}
                                name='description_template_id'
                            >
                                <Select placeholder='Chọn mẫu mô tả có sẵn' showSearch optionFilterProp='textSearch' size='large'>
                                    <Option value=''>NHẬP TAY MÔ TẢ</Option>
                                    {_.map(descriptionTemplatesQueryResponse?.data, (des: any) => (
                                        <Option value={des._id} key={des._id} textSearch={`${des.name}`}>
                                            {`${des.name}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </ConfigProvider>
                    </Col>
                </Row>
                <p className='m-2'>
                    <b>Mô tả sản phẩm</b>
                </p>
                <div className='mb-3'>
                    <Editor onChangeEditor={onChangeDescription} initialData={description} />
                </div>
                <Form.List name='skus'>
                    {(fields) => (
                        <div>
                            {fields.map(({ key, name, ...restField }, idx) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    {hasColor && (
                                        <Form.Item {...restField} name={[name, 'color']}>
                                            <Input placeholder='Color' />
                                        </Form.Item>
                                    )}
                                    <Form.Item {...restField} name={[name, 'size']} rules={[{ required: true, message: 'Missing Size Name' }]}>
                                        <Input placeholder='Size' />
                                    </Form.Item>
                                    <Form.Item {...restField} name={[name, 'price']} rules={[{ required: true, message: 'Missing Price' }]}>
                                        <InputNumber placeholder='Price' min={0} />
                                    </Form.Item>
                                    <Form.Item {...restField} name={[name, 'seller_sku']}>
                                        <Input placeholder='Seller SKU' />
                                    </Form.Item>
                                </Space>
                            ))}
                        </div>
                    )}
                </Form.List>
                <>
                    <Row gutter={[20, 20]}>
                        <Col span={6}>
                            <Form.Item label={<b>Variations 1 (Color)</b>} name='colors'>
                                <Input style={{ width: '100%' }} placeholder='Nhập Colors' size='large' readOnly={!!selectedPriceTemp} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={<b>Variations 2 (Size)</b>} name='sizes'>
                                <Input style={{ width: '100%' }} placeholder='Nhập Sizes' size='large' readOnly={!!selectedPriceTemp} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={<b>SKU</b>} name='sku'>
                                <Input style={{ width: '100%' }} placeholder='Nhập SKU' size='large' />
                            </Form.Item>
                        </Col>
                        {!selectedPriceTemp && (
                            <>
                                <Col span={3}>
                                    <Form.Item label={<b>Price</b>} name='price'>
                                        <InputNumber placeholder='Nhập giá sản phẩm' min={0} style={{ width: '100%' }} size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label={<b>Action</b>}>
                                        <Button type='primary' onClick={generateSKUS} size='large'>
                                            Generate SKUS
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                </>
                <Flex align='center' justify='center'>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        Lưu thay đổi
                    </Button>
                </Flex>
            </Form>
        </Modal>
    )
}

export default EditDescriptionDraft
