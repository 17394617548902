import { Col, Flex, Row, Tooltip } from 'antd'
import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
type Props = {
    main_images?: string[]
    size_chart_image?: string
}

const ImageColumn: FC<Props> = ({ main_images, size_chart_image }) => {
    const firstImage = main_images && main_images[0]
    const remainImages = (main_images && main_images.slice(1)) ?? []
    return (
        <Row gutter={[20, 20]}>
            <Col span={12}>
                {firstImage && (
                    <Tooltip title='Ảnh chính sản phẩm'>
                        <Link to={firstImage} target='_blank'>
                            <LazyLoadImage alt='Tik CRM' src={firstImage} width={200} style={{ border: '1px solid #f0f0f0' }} />
                        </Link>
                    </Tooltip>
                )}
            </Col>
            <Col span={12}>
                {remainImages.map((image, index) => (
                    <Tooltip key={index} title='Ảnh phụ sản phẩm'>
                        <Link to={image} target='_blank'>
                            <LazyLoadImage alt='Tik CRM' src={image} width={50} className='m-1' style={{ border: '1px solid #f0f0f0' }} />
                        </Link>
                    </Tooltip>
                ))}
                {size_chart_image && (
                    <Tooltip title='Ảnh Size chart'>
                        <Link to={size_chart_image} target='_blank'>
                            <LazyLoadImage alt='Tik CRM' src={size_chart_image} width={50} className='m-1' style={{ border: '1px solid #f0f0f0' }} />
                        </Link>
                    </Tooltip>
                )}
            </Col>
        </Row>
    )
}

export default ImageColumn
