import React from 'react';
import {useAuth} from "../../contexts/Authenticate";
import {EnumRole} from "../../types/IRole";

function WithLeader({ addedAllowRoles = [], addedBlockRoles = [], children }: React.PropsWithChildren<{ addedAllowRoles?: string[]; addedBlockRoles?: string[] }>) {
    const { user } = useAuth()

    const userRole = user?.role?.[0]?.id

    // if (addedBlockRoles.includes(userRole)) {
    //     return (
    //         <Navigate to='/403' replace />
    //     );
    // }

    const isAllowAccess = userRole === EnumRole.OWNER || addedAllowRoles.includes(userRole)

    // if (isAllowAccess) {
        return children
    // }

    // return (
    //     <Navigate to='/403' replace />
    // );
}

export default WithLeader;
