import { Col, Row, Form, Select, ConfigProvider } from 'antd'
import _ from 'lodash'
import Editor from 'core/Editor'
import { useAllDescriptionTemplateQuery } from 'hooks/queries/description-template'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

type ProductDescriptionProps = {
    value?: string
    onChange?: (value: string) => void
    isReset?: boolean
}
const ProductDescription: FC<ProductDescriptionProps> = ({ value, onChange, isReset }) => {
    const [description, setDescription] = useState('')
    const [descriptionTemplateId, setDescriptionTemplateId] = useState('')
    const descriptionTemplatesQueryResponse = useAllDescriptionTemplateQuery()

    useEffect(() => {
        setDescription(value ?? '')
        if (isReset) {
            setDescriptionTemplateId('')
        }
    }, [value, isReset])

    const onChangeDescription = useCallback(
        (content: any, editor: any) => {
            const newDescription = editor?.getData()
            if (newDescription && newDescription !== description) {
                setDescription(newDescription)
                onChange?.(newDescription)
            }
        },
        [description, onChange]
    )

    const onChangeTemplate = useCallback(
        (value: string) => {
            setDescriptionTemplateId(value)
            const template = _.find(descriptionTemplatesQueryResponse?.data ?? [], { _id: value })
            const newDescription = template ? template.description : ''
            if (newDescription !== description) {
                setDescription(newDescription)
            }
        },
        [description, descriptionTemplatesQueryResponse?.data]
    )

    const templateOptions = useMemo(() => {
        return _.map(descriptionTemplatesQueryResponse?.data ?? [], (des: any) => ({
            value: des._id,
            label: des.name
        }))
    }, [descriptionTemplatesQueryResponse?.data])

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                    colorBgContainer: '#d9d9d9 !important'
                                }
                            }
                        }}
                    >
                        <Form.Item label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu mô tả có sẵn</b>}>
                            <Select
                                placeholder='Chọn mẫu mô tả có sẵn'
                                showSearch
                                optionFilterProp='textSearch'
                                size='large'
                                value={descriptionTemplateId}
                                onChange={onChangeTemplate}
                                options={[{ value: '', label: 'NHẬP TAY MÔ TẢ' }, ...templateOptions]}
                            />
                        </Form.Item>
                    </ConfigProvider>
                </Col>
            </Row>
            <p className='m-2'>
                <b>Description</b>
            </p>
            <div className='mb-3'>
                <Editor onChangeEditor={onChangeDescription} initialData={description} />
            </div>
        </>
    )
}

export default memo(ProductDescription)
