import { ColumnsType } from 'antd/lib/table'
import { IUserOrder } from '../../../../../types/IUser'
import { formatCurrency, formatDate, USDvsVND } from '../../../../../utils/format'
import { Flex, Tooltip, Typography } from 'antd'
import { SwapRightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const ORDER_TYPE_MAPPING = {
    downgrade_package: 'Hạ gói cước',
    upgrade_package: 'Nâng cấp gói cước',
    extend_package: 'Gia hạn gói cước',
}

export const USER_ORDER_COLUMNS: ColumnsType<IUserOrder> = [
    {
        title: 'Dịch vụ',
        dataIndex: 'type',
        key: 'type',
        width: 200,
        render: value => ORDER_TYPE_MAPPING[value as keyof typeof ORDER_TYPE_MAPPING]
    },
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        key: 'amount',
        width: 200,
        render: (value, order) => {
            const amount = order.type === 'deposit' ? value : value * -1
            return (
                <div>
                    <Typography className={'font-weight-bold ' + (amount < 0 ? 'text-danger' : 'text-success')}>
                        {formatCurrency(Math.abs(amount), {
                            prefix: amount < 0 ? '-$' : '+$',
                            rate: USDvsVND
                        })}
                    </Typography>
                    <Flex className='mt-1' gap={3}>
                        <Typography style={{ fontSize: 11 }}>
                            {formatCurrency(order.old_credit, {
                                prefix: '$',
                                rate: USDvsVND
                            })}
                        </Typography>
                        <SwapRightOutlined style={{ fontSize: 12 }} />
                        <Typography style={{ fontSize: 11 }}>
                            {formatCurrency(order.new_credit, {
                                prefix: '$',
                                rate: USDvsVND
                            })}
                        </Typography>
                    </Flex>
                </div>
            )
        }
    },
    {
        title: 'Gói cũ',
        dataIndex: 'old_package',
        key: 'old_package',
        width: 140,
        render: (value) => {
            return (
                <div>
                    <Typography>{value.package_detail?.name}</Typography>
                    <Tooltip title='Ngày hết hạn gói cũ' placement='right'>
                        <Typography style={{ fontSize: 11, width: 'fit-content' }}>{formatDate(dayjs(value.expire_at))}</Typography>
                    </Tooltip>
                </div>
            )
        }
    },
    {
        title: 'Gói mới',
        dataIndex: 'new_package',
        key: 'new_package',
        width: 140,
        render: (value) => {
            return (
                <div>
                    <Typography>{value.package_detail?.name}</Typography>
                    <Tooltip title='Ngày hết hạn gói mới' placement='right'>
                        <Typography style={{ fontSize: 11, width: 'fit-content' }}>{formatDate(dayjs(value.expire_at))}</Typography>
                    </Tooltip>
                </div>
            )
        }
    },
    // {
    //     title: 'Ghi chú',
    //     dataIndex: 'description',
    //     key: 'description',
    // },
    {
        title: 'Thời gian',
        dataIndex: 'created_time',
        key: 'created_time',
        width: 180,
        render: (value) => formatDate(value)
    }
]

export const USER_DEPOSIT_COLUMNS: ColumnsType<IUserOrder> = [
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        key: 'amount',
        width: 200,
        render: (value, order) => {
            const amount = order.type === 'deposit' ? value : value * -1
            return (
                <div>
                    <Typography className={'font-weight-bold ' + (amount < 0 ? 'text-danger' : 'text-success')}>
                        {formatCurrency(Math.abs(amount), {
                            prefix: amount < 0 ? '-$' : '+$',
                            rate: USDvsVND
                        })}
                    </Typography>
                    <Flex className='mt-1' gap={3}>
                        <Typography style={{ fontSize: 12 }}>
                            {formatCurrency(order.old_credit, {
                                prefix: '$',
                                rate: USDvsVND
                            })}
                        </Typography>
                        <SwapRightOutlined style={{ fontSize: 12 }} />
                        <Typography style={{ fontSize: 12 }}>
                            {formatCurrency(order.new_credit, {
                                prefix: '$',
                                rate: USDvsVND
                            })}
                        </Typography>
                    </Flex>
                </div>
            )
        }
    },
    {
        title: 'Ghi chú',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'Thời gian',
        dataIndex: 'created_time',
        key: 'created_time',
        width: 180,
        render: (value) => formatDate(value)
    }
]
