import { Card, Tabs } from 'antd'
import { useState, FC } from 'react'
import _ from 'lodash'
import ProductTemplateWaiting from './ProductTemplateWaiting'
import { Helmet } from 'react-helmet'
import CreateProductByAPI from './CreateProductByAPI'
import ProductTemplateDraft from './ProductTemplateDraft'
const TITLE = 'Quản lý Template'

const ProductTemplateByAPI: FC = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        console.log('key', key)
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Đăng sản phẩm',
            children: <CreateProductByAPI tab={selectedTab}/>
        },
        {
            id: 2,
            label: 'Bản nháp',
            children: <ProductTemplateDraft tab={selectedTab} />
        },
        {
            id: 3,
            label: 'Danh sách Lên lịch',
            children: <ProductTemplateDraft tab={selectedTab} />
        },
        {
            id: 4,
            label: 'Tiến trình Đăng sản phẩm',
            children: <ProductTemplateWaiting tab={selectedTab} />
        }
    ]
    return (
        <Card title='Lên lịch sản phẩm'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: tab.children
                    }
                })}
                onChange={onChangeTab}
            />
        </Card>
    )
}

export default ProductTemplateByAPI
