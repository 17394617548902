import React, { createRef } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Flex, Modal, QRCode } from 'antd'
import { IUser } from '../../../types/IUser'
import { DownloadOutlined } from '@ant-design/icons'
import useScreenshot, { createFileName } from '../../../hooks/useScreenshot'
import {NETWORK_ASSETS} from "../../../const/assets";

function AffiliateQrCodeModal({ content, user }: { content: string; user: IUser }) {
    const { closeModal } = useModal()
    const ref = createRef<HTMLDivElement>();
    const [, takeScreenShot] = useScreenshot({
        type: 'image/png',
        quality: 1.0,
    });

    const download = (image: string, props: { name?: string; extension?: string } = {}) => {
        const { name = user.identifier, extension = 'png' } = props;
        const a = document.createElement('a');
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    return (
        <Modal closable open onCancel={() => closeModal()} title={undefined} footer={null} width={515} className='share-qr-modal'>
            <div ref={ref} className='share-qr-block pt-3 pb-4 overflow-hidden'>
                <div className='d-flex justify-content-center'>
                    <img src={NETWORK_ASSETS.LOGO} alt='tikcrm logo' width={160} />
                </div>
                <p className='text-center text-lg mt-2 px-5'>
                    Bộ giải pháp giúp seller POD & Dropship bán hàng hiệu quả với nhiều store trên Tiktokshop
                </p>
                <div className='qr-box'>
                    <p className='text-center qr-box__content'>
                        Bạn sẽ nhận được nhiều quyền lợi hấp dẫn khi đăng ký qua link tiếp thị của {user.email} 🎉
                    </p>
                    <QRCode value={content} bgColor='white' size={230} />
                    <p className='text-center'>Đăng ký TikCRM và mở khóa tất cả các tính năng mạnh mẽ để quản lý cửa hàng Tiktok ngay! 🚀</p>
                </div>
            </div>
            <div className='text-center w-100 flex justify-content-center'>
                <button className='confirm' onClick={downloadScreenshot}>
                    <DownloadOutlined /> Tải xuống poster
                </button>
            </div>
        </Modal>
    )
}

export default AffiliateQrCodeModal
