import {ColumnsType} from "antd/lib/table";
import {IBankAccount} from "../interface/bank.interface";
import {formatDate} from "../../../../utils/format";
import dayjs from "dayjs";
import {Tag} from "antd";
import {TAG_COLOR} from "../../../../utils/get-color";
import BankAccountShopColumn from "./BankAccountShopColumn";
import BankAccountSensitiveLabelColumn from "./BankAccountSensitiveLabelColumn";
import BankAccountHistoriesColumn from "./BankAccountHistoriesColumn";

const BANK_STATUS_MAP = {
    1: 'Chưa dùng',
    0: 'Đã dùng'
}

export const BANK_ACCOUNT_COLUMNS: ColumnsType<IBankAccount> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Ngày upload',
        dataIndex: 'created_time',
        key: 'created_time',
        render: (created_time) => formatDate(dayjs(created_time))
    },
    {
        title: 'Người upload',
        dataIndex: 'uploader',
        key: 'uploader',
        render: (_, record) => record.uploader?.full_name
    },
    {
        title: 'Bank name',
        dataIndex: 'bank_name',
        key: 'bank_name',
    },
    {
        title: 'Account number',
        dataIndex: 'account_number',
        key: 'account_number',
        render: (_, record) => <BankAccountSensitiveLabelColumn data={record} prop='account_number' />
    },
    {
        title: 'Routing number',
        dataIndex: 'routing_number',
        key: 'routing_number',
        render: (_, record) => <BankAccountSensitiveLabelColumn data={record} prop='routing_number' />
    },
    {
        title: 'Swift code',
        dataIndex: 'swift_code',
        key: 'swift_code',
        render: (_, record) => <BankAccountSensitiveLabelColumn data={record} prop='swift_code' />
    },
    {
        title: 'Ghi chú',
        dataIndex: 'note',
        key: 'note',
    },
    {
        title: 'Tình trạng',
        dataIndex: 'status',
        key: 'status',
        render: value => <Tag color={TAG_COLOR.PROFILE_NAME}>{BANK_STATUS_MAP[value as keyof typeof BANK_STATUS_MAP]}</Tag>
    },
    {
        title: 'Shop',
        dataIndex: 'shop',
        key: 'shop',
        render: (_, record) => <BankAccountShopColumn {...record} />
    },
    {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
    },
    {
        title: 'Ngày setup bank',
        dataIndex: 'get_bank_at',
        key: 'get_bank_at',
        render: (get_bank_at) => formatDate(dayjs(get_bank_at))
    },
    {
        title: 'Nhân sự',
        dataIndex: ['recipient', 'full_name'],
        key: 'recipient',
    },
    {
        title: 'Hành động',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => <BankAccountHistoriesColumn data={record} />
    },
]
