import React from 'react'
import { Button, ButtonProps } from 'antd'
import { usePackagePermission } from '../../hooks/usePackagePermission'
import { useModal } from '../../contexts/Modal'
import PleaseUpgradePackageModal from "../Modal/PleaseUpgradePackageModal";

interface ButtonPackageProtectedProps extends ButtonProps {
    permission: string
}

function ButtonPackageProtected({ permission, onClick, children, ...props }: ButtonPackageProtectedProps) {
    const isAllowed = usePackagePermission(permission)
    const { openModal } = useModal()

    const click = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!isAllowed) {
            openModal(<PleaseUpgradePackageModal />)
        } else {
            onClick?.(e)
        }
    }

    return (
        <Button {...props} onClick={click}>
            {children}
        </Button>
    )
}

export default ButtonPackageProtected
