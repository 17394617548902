import { useSearchParams } from 'react-router-dom'
import {Flex, Image, message} from 'antd'
import CopyToClipboard from 'core/CopyToClipboard'

function TiktokAuth() {
    const [query] = useSearchParams()

    const appKey = query.get('app_key')
    const authorizedToken = query.get('code')

    return (
        <div className='px-4 py-4'>
            <p style={{fontSize: 32, fontWeight: 700}} className='mb-3'>Tiktok Authorized Response</p>
            <Flex gap={6}>
                App key:{' '}
                <CopyToClipboard text={appKey ?? ''} onCopy={() => message.success(`Đã sao chép: ${appKey}`)}>
                    <strong className='clickable'>{appKey}</strong>
                </CopyToClipboard>
            </Flex>
            <Flex gap={6} className='mt-2'>
                Authorized token:{' '}
                <CopyToClipboard text={authorizedToken ?? ''}
                                 onCopy={() => message.success(`Đã sao chép: ${authorizedToken}`)}>
                    <strong className='clickable'>{authorizedToken}</strong>
                </CopyToClipboard>
            </Flex>

            <p style={{fontSize: 26, fontWeight: 700}} className='my-3'>Hướng dẫn sử dụng</p>
            <Image src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1737010705849' width={800}/>
        </div>
    )
}

export default TiktokAuth
