import { Card, Table, Row, Col, Select, Space, Button, Popconfirm, Tabs, Form, DatePicker, Tooltip, Progress } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import {
    EditOutlined,
    ReloadOutlined,
    StopOutlined,
    DeleteOutlined,
    HourglassOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    MenuUnfoldOutlined,
    RedoOutlined,
    QuestionCircleOutlined,
    SyncOutlined
} from '@ant-design/icons'
import { blue, gray, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import SyncProductModal from './modals/SyncProductModal'
import { notify } from 'contexts/Notification'
import { EnumProductStatus, IProduct } from 'types/IProduct'
import ProductAPI from 'api/ProductAPI'
import Search from 'antd/es/input/Search'
import CellProductName from 'core/Table/CellProductName'
import EditProductModal from './modals/EditProductModal'
import { Helmet } from 'react-helmet'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { ColumnsType } from 'antd/es/table'
import { useProductQuery } from 'hooks/queries/product'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import HeaderTotalTable from 'core/Table/HeaderTotalTable'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import ImageColumn from 'core/Table/ImageColumn'
import { getRangePrice } from 'utils/sku-utils'
import ShopSelect from 'core/ShopSelect'
import StaffsSelect from 'core/StaffSelect'
import dayjs from 'dayjs'
const { RangePicker } = DatePicker
const TITLE = 'Quản lý Sản phẩm'
const ProductManagement: FC = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleAddModal, setVisibleAddModal] = useState(false)
    const [visibleEditModal, setVisibleEditModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState(EnumProductStatus.ACTIVATE)
    const [selectedProduct, setSelectedProduct] = useState<IProduct>()
    const [form] = Form.useForm()
    const formWatch = Form.useWatch([], form)
    const staffsQueryResponse = useAllStaffQuery()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const productQueryResponse = useProductQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...formWatch,
        status: selectedTab,
        start_time:
            form.getFieldValue('created_time') && form.getFieldValue('created_time')[0] ? form.getFieldValue('created_time')[0].valueOf() : undefined,
        end_time:
            form.getFieldValue('created_time') && form.getFieldValue('created_time')[1] ? form.getFieldValue('created_time')[1].valueOf() : undefined
    })

    const refetchData = () => {
        productQueryResponse.refetch()
    }

    const onChangeTab = (key: any) => {
        setSelectedTab(key)
        setPageNumber(1)
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleAddModal = useCallback(
        (value: boolean) => {
            setVisibleAddModal(value)
        },
        [visibleAddModal]
    )

    const toggleEditModal = useCallback(
        (value: boolean) => {
            setVisibleEditModal(value)
        },
        [visibleEditModal]
    )

    const onEditProduct = (product: IProduct) => {
        setSelectedProduct(product)
        toggleEditModal(true)
    }
    const onRemoveProduct = (product_id: string) => {
        ProductAPI.removeProducts([product_id])
            .then((res) => {
                notify('success', 'Xóa sản phẩm thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onSyncProduct = (product_id: string) => {
        setLoading(true)
        ProductAPI.syncProductFromTikTok({ product_id })
            .then((res) => {
                notify('success', 'Đồng bộ sản phẩm thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onChangeValues = (values: any) => {
        console.log('onChangeValues', values)
        setPageNumber(1)
        if (_.has(values, 'created_time')) {
            if (values?.created_time && values?.created_time[0] && values?.created_time[1]) {
                values.created_time[1] = values.created_time[1].endOf('day')
                form.setFieldValue('created_time', values.created_time)
            }
        }
    }

    const columns: ColumnsType<IProduct> = [
        {
            title: 'Ảnh sản phẩm',
            dataIndex: 'images',
            key: 'images',
            render: (text, record, index: number) => {
                const mainImages = _.isArray(text) ? text.map((image: any) => image.thumb_url_list?.[0] || image.url || '') : []
                const sizeChartImage = record.size_chart_image?.thumb_url_list?.[0] || record.size_chart_image?.url || ''
                return <ImageColumn main_images={mainImages} size_chart_image={sizeChartImage} />
            },
            width: 230
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 300
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any, record: any, index: number) => {
                return (
                    text[0]?.base_price && (
                        <span>
                            {getRangePrice(text)}
                            <b>$</b>
                        </span>
                    )
                )
            },
            width: 80,
            align: 'center'
        },
        {
            title: (
                <span>
                    Listing Quality{' '}
                    <Tooltip placement='bottom' title='Hệ thống sẽ tự động cập nhật trạng thái Listing Quality 30 phút/lần'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'listing_quality_tier',
            key: 'listing_quality_tier',
            render: (text: string, record) => {
                let color = ''
                let percent = 0
                if (text === 'POOR') {
                    color = '#cf1322'
                    percent = 30
                } else if (text === 'FAIR') {
                    color = '#d48806'
                    percent = 60
                } else if (text === 'GOOD') {
                    color = '#237804'
                    percent = 100
                }
                return (
                    <>
                        <p>{text}</p>
                        <Progress percent={percent} steps={3} strokeColor={color} showInfo={false} />
                        <SyncOutlined
                            style={{ color: blue.primary, fontSize: '16px', marginLeft: '8px' }}
                            type='button'
                            onClick={() => onSyncProduct(record.product_id)}
                        />
                    </>
                )
            },
            width: 80,
            align: 'center'
        },
        {
            title: (
                <span>
                    Trạng thái{' '}
                    <Tooltip placement='bottom' title='Hệ thống sẽ tự động cập nhật trạng thái 30 phút/lần'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return <CellProductProductStatus status={text} audit_failed_reasons={record.audit_failed_reasons} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                if (record.status !== 'DELETED') {
                    return (
                        <Space size='large'>
                            <EditOutlined
                                style={{ color: blue.primary, fontSize: '20px' }}
                                type='button'
                                onClick={() => onEditProduct(record)}
                                title='Edit product'
                            />
                            <Tooltip placement='bottom' title={'Đồng bộ dữ liệu sản phẩm trực tiếp từ Tiktok'}>
                                <RedoOutlined style={{ color: gray.primary, fontSize: '20px' }} onClick={() => onSyncProduct(record.product_id)} />
                            </Tooltip>
                            <Popconfirm title='Xóa sản phẩm này?' onConfirm={() => onRemoveProduct(record.product_id)}>
                                <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} title='Xóa sản phẩm' />
                            </Popconfirm>
                        </Space>
                    )
                }
                return null
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    const LIST_TABS = [
        {
            id: EnumProductStatus.ACTIVATE,
            label: 'LIVE',
            icon: <CheckCircleOutlined />
        },
        {
            id: EnumProductStatus.PENDING,
            label: 'REVIEWING',
            icon: <HourglassOutlined />
        },
        {
            id: EnumProductStatus.FAILED,
            label: 'FAILED',
            icon: <CloseCircleOutlined />
        },
        {
            id: EnumProductStatus.FREEZE,
            label: 'FROZEN',
            icon: <StopOutlined />
        },
        {
            id: EnumProductStatus.DELETED,
            label: 'DELETED',
            icon: <DeleteOutlined />
        },
        {
            id: -1,
            label: 'ALL',
            icon: <MenuUnfoldOutlined />
        }
    ]

    return (
        <Card title='Danh sách sản phẩm'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form
                form={form}
                onValuesChange={onChangeValues}
                initialValues={{created_time: [dayjs().subtract(30, 'days'), dayjs().endOf('day')]}}
            >
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={6}>
                                <Form.Item name='shop_id'>
                                    <ShopSelect
                                        allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                        data={shopsQueryResponse?.data ?? []}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name='assignee_id'>
                                    <StaffsSelect
                                        allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                        data={staffsQueryResponse?.data ?? []}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name='listing_quality_tier'>
                                    <Select
                                        allowClear
                                        showSearch
                                        mode={'multiple'}
                                        style={{ width: '100%' }}
                                        placeholder='Lọc theo Listing Quality'
                                        size='large'
                                        options={[
                                            { label: 'POOR', value: 'POOR' },
                                            { label: 'FAIR', value: 'FAIR' },
                                            { label: 'GOOD', value: 'GOOD' },
                                            { label: 'UNKNOWN', value: 'UNKNOWN' }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name='created_time'>
                                    <RangePicker size='large' format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            <Button type='primary' onClick={() => toggleAddModal(true)} size='large'>
                                Đồng bộ sản phẩm
                            </Button>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: (
                            <Table
                                bordered
                                dataSource={productQueryResponse?.data?.data ?? []}
                                columns={columns}
                                loading={productQueryResponse?.isFetching || loading}
                                pagination={{
                                    defaultCurrent: pageNumber,
                                    current: pageNumber,
                                    pageSize,
                                    total: productQueryResponse?.data?.pagination?.total,
                                    onChange: handleChangePagination,
                                    position: ['topLeft', 'bottomRight'],
                                    showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số sản phẩm:' />
                                }}
                                rowKey={(record) => record._id}
                                scroll={{
                                    x: 500
                                }}
                            />
                        ),
                        icon: tab.icon
                    }
                })}
                onChange={onChangeTab}
            />

            <SyncProductModal visible={visibleAddModal} toggleModal={toggleAddModal} />
            <EditProductModal visible={visibleEditModal} toggleModal={toggleEditModal} refetch={refetchData} item={selectedProduct} />
        </Card>
    )
}

export default ProductManagement
