import { Table, Row, Select, Space, Button, Image, Tag, Tooltip, Form, Popconfirm, Col, DatePicker } from 'antd'
import { useEffect, useState, FC, useCallback } from 'react'
import { ReloadOutlined, DeleteOutlined, EditOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { EnumProductTemplateTmpStatus, IProduct } from 'types/IProduct'
import ProductTemplateTmpAPI from 'api/ProductTemplateTmpAPI'
import Search from 'antd/es/input/Search'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import { ColumnsType } from 'antd/lib/table'
import { useProductTemplateQuery } from 'hooks/queries/product-template'
import { useForm } from 'antd/es/form/Form'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import HeaderTotalTable from 'core/Table/HeaderTotalTable'
import EditDescriptionDraft from './modals/EditDescriptionDraft'
import ButtonProtected from 'core/ButtonProtected/ButtonRoleProtected'
import { CODE_PERMISSION } from 'const/permission'
import { EnumAction } from 'types/IPermission'
import { getRangePrice } from 'utils/sku-utils'

import { EnumCreatedPlatform, TIME_ZONE } from 'const'
import dayjs from 'dayjs'
import ButtonPublish from 'core/CreateProduct/ButtonPublish'
import ImageColumn from 'core/Table/ImageColumn'
const { Option } = Select
const { RangePicker } = DatePicker
const ProductTemplateDraft = ({ tab }: { tab: number }) => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [form] = useForm()
    const formWatch = Form.useWatch([], form)
    const queryResponse = useProductTemplateQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        status: tab === 2 ? EnumProductTemplateTmpStatus.DRAFT : EnumProductTemplateTmpStatus.WAITING,
        ...formWatch,
        tab: tab === 2 || tab === 3,
        created_platform: EnumCreatedPlatform.TIKTOK_API
    })
    const shopsQueryResponse = useAllTikTokShopQuery()
    const [visibleDescriptionModal, setVisibleDescriptionModal] = useState(false)
    const [selectedItemEdit, setSelectedItemEdit] = useState<IProduct>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const onRemoveProductTemplate = (_id: string) => {
        ProductTemplateTmpAPI.removeProductTemplateTmp(_id)
            .then((res) => {
                notify('success', 'Xóa thành công')
                queryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }

    const onChangeValues = (values: any) => {
        console.log('onChangeValues', values)
        setPageNumber(1)
        if (_.has(values, 'created_time')) {
            if (values?.created_time && values?.created_time[0] && values?.created_time[1]) {
                values.created_time[1] = values.created_time[1].endOf('day')
                form.setFieldValue('created_time', values.created_time)
            }
        }
    }

    const onEditDescription = (record: IProduct) => {
        setSelectedItemEdit(record)
        toggleEditModal(true)
    }

    const toggleEditModal = useCallback(
        (value: boolean) => {
            setVisibleDescriptionModal(value)
            if (!value) {
                setSelectedItemEdit(undefined)
            }
        },
        [visibleDescriptionModal, selectedItemEdit]
    )

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }

    const onPublishMulti = () => {
        setLoading(true)
        ProductTemplateTmpAPI.publishMultiProductsTemplate({ product_ids: selectedRowKeys as string[] })
            .then(() => {
                notify('success', 'Danh sách sản phẩm đã được đưa vào hàng chờ đăng!')
                queryResponse?.refetch()
                setSelectedRowKeys([])
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const columns: ColumnsType<IProduct> = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record) => {
                const mainImages = _.isArray(text) ? text.map((image: any) => image.thumb_url_list?.[0] || image.url || '') : []
                const sizeChartImage = record.size_chart_image?.thumb_url_list?.[0] || record.size_chart_image?.url || ''
                return <ImageColumn main_images={mainImages} size_chart_image={sizeChartImage} />
            },
            width: 240
        },
        {
            title: 'Thông tin sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 200
        },
        {
            title: 'Tên sản phẩm/Mô tả',
            dataIndex: 'description',
            key: 'description',
            render: (text: any, record: any) => {
                return (
                    <>
                        <p>
                            <b>Name: </b>
                            {record.product_name}
                        </p>
                        {text && text.length > 30 ? text.substring(0, 30) + '...' : text}
                    </>
                )
            },
            width: 130,
            align: 'center'
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any, record: any, index: number) => {
                return (
                    text[0]?.base_price && (
                        <span>
                            {getRangePrice(text)}
                            <b>$</b>
                        </span>
                    )
                )
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any) => {
                if (text === EnumProductTemplateTmpStatus.SUCCESS) {
                    return <Tag color='#389e0d'>SUCCESS</Tag>
                } else if (text === EnumProductTemplateTmpStatus.WAITING) {
                    return <Tag color='#d4b106'>WAITING</Tag>
                } else if (text === EnumProductTemplateTmpStatus.PROCESSING) {
                    return <Tag color='#1677ff'>PROCESSING</Tag>
                } else if (text === EnumProductTemplateTmpStatus.DRAFT) {
                    return <Tag color='#595959'>DRAFT</Tag>
                } else if (text === EnumProductTemplateTmpStatus.RETRY) {
                    return (
                        <>
                            <br />
                            <Tag color='#faad14'>WAIT RETRY</Tag>
                            <br />
                            <Tooltip placement='top' title='Nếu thử lại quá 10 lần, vui lòng bấm Đăng lại bằng tay!'>
                                <p style={{ color: 'red' }}>
                                    <i>
                                        <b>Số lần thử lại:</b> {record.retry_counter || 0}
                                    </i>
                                </p>
                            </Tooltip>
                        </>
                    )
                } else {
                    return (
                        <>
                            <br />
                            <Tag color='#cf1322'>FAIL</Tag>
                            <br />
                            <Tooltip placement='top' title={record.note}>
                                <p style={{ color: 'red' }}>
                                    <i>{record.note && record.note.length > 30 ? record.note.substring(0, 30) + '...' : record.note}</i>
                                </p>
                            </Tooltip>
                        </>
                    )
                }
            },
            width: 100,
            align: 'center'
        },
        {
            title: (
                <span>
                    Thời gian lập lịch
                    <br />
                    (TZ: {TIME_ZONE})
                </span>
            ),
            dataIndex: 'schedule_time',
            key: 'schedule_time',
            render: (text) => (
                <>
                    <b style={{ color: '#52c41a' }}>
                        <ClockCircleOutlined className='mx-2' style={{ fontSize: '18px' }} />
                        {text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm')}
                    </b>
                </>
            ),
            width: 150,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size='large'>
                        {tab === 2 && (
                            <EditOutlined
                                style={{ color: blue.primary, fontSize: '20px' }}
                                type='button'
                                onClick={() => onEditDescription(record)}
                                title='Edit product'
                            />
                        )}
                        <Popconfirm title='Bạn chắc chắn xóa Sản phẩm?' onConfirm={() => onRemoveProductTemplate(record._id)}>
                            <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} type='button' title='Remove product' />
                        </Popconfirm>
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]
    return (
        <>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-3'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={8}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name='created_time'>
                                    <RangePicker size='large' format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            {selectedRowKeys.length > 0 && (
                                <>
                                    <ButtonProtected permission={{ [CODE_PERMISSION.PRODUCT_MANAGEMENT]: [EnumAction.Create] }}>
                                        <Popconfirm
                                            placement='topLeft'
                                            title={'Bạn chắc chắn đăng Sản phẩm đã chọn?'}
                                            okText='Yes'
                                            cancelText='No'
                                            onConfirm={onPublishMulti}
                                            onCancel={() => {}}
                                        >
                                            <ButtonPublish type='primary' loading={loading} size='large'>
                                                Đăng sản phẩm
                                            </ButtonPublish>
                                        </Popconfirm>
                                    </ButtonProtected>
                                    {/* <ButtonProtected permission={{ [CODE_PERMISSION.LISTING_MANAGEMENT]: [EnumAction.Delete] }}>
                                        <Popconfirm
                                            placement='topLeft'
                                            title={'Bạn chắc chắn xóa Listing đã chọn?'}
                                            okText='Yes'
                                            cancelText='No'
                                            onConfirm={onRemoveMulti}
                                            onCancel={() => {}}
                                        >
                                            <Button type='primary' danger size='large'>
                                                Xóa
                                            </Button>
                                        </Popconfirm>
                                    </ButtonProtected> */}
                                </>
                            )}
                            <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                bordered
                dataSource={queryResponse?.data?.data ?? []}
                columns={columns}
                loading={queryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: queryResponse.data?.pagination?.total ?? 0,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số:' />
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
                rowSelection={tab === 2 ? rowSelection : undefined}
            />
            <EditDescriptionDraft
                visible={visibleDescriptionModal}
                toggleModal={toggleEditModal}
                refetch={() => queryResponse?.refetch()}
                item={selectedItemEdit}
            />
        </>
    )
}

export default ProductTemplateDraft
