import React from 'react';
import type { TimeRangePickerProps } from 'antd';
import { DatePicker } from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useOrderStore} from "../store/order.store";
import {useShallow} from "zustand/react/shallow";

const { RangePicker } = DatePicker;

function OrderTableToolbarDate() {
    const { addDateRange } = useOrderStore(
        useShallow((state) => ({
            dateRange: state.filter.dateRange,
            addDateRange: state.filter.addDateRange
        }))
    )

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates && dates[0] && dates[1]) {
            addDateRange({
                from: dates[0].startOf('day').unix(),
                to: dates[1].endOf('day').unix(),
            })
        } else {
            addDateRange({
                from: null,
                to: null
            })
        }
    };

    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Today', value: [dayjs(), dayjs()] },
        { label: 'Yesterday', value: [dayjs().subtract(1, 'd'), dayjs()] },
        { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    ];

    return (
        // @ts-ignore
        <RangePicker presets={rangePresets} onChange={onRangeChange} size='large' />
    );
}

export default OrderTableToolbarDate;
