import { create } from 'zustand'
import {IBankStore} from "../interface/bank.interface";
import React from "react";

export const useBankAccountStore = create<IBankStore>((set) => ({
    filter: {
        shop: '',
        search: '',
        user: '',
        status: '',
        addShop: (shop: string) => set((state) => ({ filter: { ...state.filter, shop } })),
        addSearch: (e: React.ChangeEvent<HTMLInputElement>) => set((state) => ({ filter: { ...state.filter, search: e.target.value } })),
        addUser: (user: string) => set((state) => ({ filter: { ...state.filter, user } })),
        addStatus: (status: string) => set((state) => ({ filter: { ...state.filter, status } })),
    },
    setFilter: (newFilter) => set({ filter: newFilter }),
    addFilter: (partialFilter) => set((state) => ({ filter: { ...state.filter, ...partialFilter } })),
    removeFilter: (prop) =>
        set((state) => {
            const _filter = { ...state.filter }
            delete _filter[prop]
            return { filter: _filter }
        })
}))
