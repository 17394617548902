import React, {useState, FC, useMemo, useEffect} from 'react'
import { notify } from 'contexts/Notification'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useAuth } from 'contexts/Authenticate'
import { Alert, Image, Button, Row } from 'antd'
// import { GoogleLogin } from '@react-oauth/google'
import {Link, Navigate, useSearchParams} from 'react-router-dom'

import dayjs from "dayjs";

const isInValidAffCode = (affCodeSavedTime: string) => {
    return dayjs().diff(dayjs(affCodeSavedTime), 'day') > 30;
};

export const setupAffCode = (affCode: string | null) => {
    if (affCode) {
        localStorage.setItem('affCode', JSON.stringify({
            code: affCode,
            time: dayjs().toISOString(),
        }));

        return affCode;
    }

    const savedAffCodeStr = localStorage.getItem('affCode');

    if (!savedAffCodeStr) return '';

    const savedAffCode = JSON.parse(savedAffCodeStr);

    if (isInValidAffCode(savedAffCode.time)) {
        localStorage.removeItem('affCode');
        return '';
    }
    return savedAffCode.code;
};


const Register: FC = () => {
    const { register, isLoading, error, loginGoogleOauth, user } = useAuth()
    const [query] = useSearchParams();
    const inviteCode = useMemo(() => setupAffCode(query.get('inviteCode')), [query]);

    const [values, setValues] = useState({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        telegram: '',
        invite_by: '',
    })

    console.log('inviteCode =', inviteCode)

    useEffect(() => {
        setValues(prev => ({ ...prev, invite_by: inviteCode }))
    }, [inviteCode]);

    const onChangeForm = (key: any) => (e: any) => {
        const { value } = e.target
        setValues({ ...values, [key]: value })
    }
    const handleRegister = async (e: any) => {
        e.preventDefault()
        if (values.email && values.password && values.first_name && values.last_name && values.telegram) {
            void register({
                email: values.email,
                password: values.password,
                first_name: values.first_name,
                last_name: values.last_name,
                telegram: values.telegram,
                invite_by: values.invite_by,
            })
        } else {
            notify('error', 'Please fill information')
        }
    }
    const onSuccess = async (credentialResponse: any) => {
        console.log(credentialResponse)
        if (credentialResponse) {
            await loginGoogleOauth(credentialResponse.credential)
        } else {
            notify('error', 'Login Failed')
        }
    }

    if (isLoading) {
        return null;
    }

    if (user) {
        return <Navigate to='/order' />
    }

    return (
        <GoogleOAuthProvider clientId='175628795265-r604d0rlpb1vlomdslejg6gd3p9u1uth.apps.googleusercontent.com'>
            <div className='container d-flex flex-column'>
                <div className='row h-100'>
                    <div className='col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100'>
                        <div className='d-table-cell align-middle'>
                            <div className='text-center mt-5 mb-5'>
                                <Image src='' preview={false} />
                                <h1 className='h2'>Đăng ký tài khoản TikCRM</h1>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='m-sm-4'>
                                        {error && (
                                            <Alert message='Error' description={error} type='error' showIcon style={{ marginBottom: '1rem' }} />
                                        )}
                                        <form>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='email'>Email</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    name='email'
                                                    onChange={onChangeForm('email')}
                                                    value={values.email}
                                                    placeholder='Nhập Email'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='password1'>Mật khẩu</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='password'
                                                    onChange={onChangeForm('password')}
                                                    value={values.password}
                                                    name='password'
                                                    placeholder='Nhập mật khẩu'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='first_name'>First Name</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    onChange={onChangeForm('first_name')}
                                                    value={values.first_name}
                                                    name='first_name'
                                                    placeholder='Nhập first name'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='last_name'>Last Name</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    onChange={onChangeForm('last_name')}
                                                    value={values.last_name}
                                                    name='last_name'
                                                    placeholder='Nhập last name'
                                                />
                                            </div>
                                            <div className={'form-group'}>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='telegram'>Telegram</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    onChange={onChangeForm('telegram')}
                                                    value={values.telegram}
                                                    name='telegram'
                                                    placeholder='Nhập Telegram'
                                                />
                                            </div>
                                            <div className={'form-group' + (inviteCode ? 'd-none' : '')}>
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor='invite_by'>Mã giới thiệu</label>
                                                <input
                                                    className='form-control form-control-lg'
                                                    type='text'
                                                    onChange={onChangeForm('invite_by')}
                                                    value={values.invite_by}
                                                    name='invite_by'
                                                    placeholder='Nhập mã giới thiệu'
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <Button
                                                    className='btn btn-primary pl-3 pr-3'
                                                    type='primary'
                                                    onClick={handleRegister}
                                                    htmlType='submit'
                                                    loading={isLoading}
                                                    style={{
                                                        height: '38px',
                                                        width: '100%'
                                                    }}
                                                >
                                                    Đăng ký
                                                </Button>
                                            </div>
                                        </form>
                                        <div className='text-center mt-3'>
                                            Đã có tài khoản? <Link to='/login'>Đăng nhập</Link>
                                        </div>
                                        {/*<div className='row mt-4'>*/}
                                        {/*    <div className='col'>*/}
                                        {/*        <hr />*/}
                                        {/*    </div>*/}
                                        {/*    <div className='col-auto text-uppercase d-flex align-items-center'>Or</div>*/}
                                        {/*    <div className='col'>*/}
                                        {/*        <hr />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='row mt-4' style={{ justifyContent: 'center' }}>*/}
                                        {/*    <GoogleLogin*/}
                                        {/*        onSuccess={onSuccess}*/}
                                        {/*        onError={() => {*/}
                                        {/*            console.log('Register Failed')*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    )
}

export default Register
