import React from 'react'
import { IUser } from '../../../types/IUser'
import { Popconfirm, Space } from 'antd'
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import { useModal } from '../../../contexts/Modal'
import StaffAPI from '../../../api/StaffAPI'
import { notify } from '../../../contexts/Notification'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import StaffModal from '../modals/StaffModal'
import { GET_STAFFS_QUERY_KEY } from '../queries/staff.query'

function StaffManagementActionColumn({ user }: { user: IUser }) {
    const queryClient = useQueryClient()
    const { openModal } = useModal()
    const removeStaffMutation = useMutation({
        mutationFn: StaffAPI.toggleActiveStaff,
        onSuccess: () => {
            notify('success', user.is_active ? 'Xóa nhân viên thành công' : 'Khôi phục thành công')
            void refetch()
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    const refetch = () => queryClient.refetchQueries([GET_STAFFS_QUERY_KEY])

    const onRemove = () => {
        void removeStaffMutation.mutateAsync({
            _id: user._id,
            is_active: !user.is_active
        })
    }

    const onEdit = () => openModal(<StaffModal item={user} refetch={refetch} />)

    return (
        <Space size='large'>
            {user.is_active ? (
                <>
                    <EditOutlined style={{ color: blue.primary, fontSize: '20px' }} type='button' onClick={onEdit} title='Chỉnh sửa nhân viên' />
                    <Popconfirm title='Nhân viên bị xoá sẽ được chuyển sang inactive và không thể đăng nhập vào hệ thống, bạn có thể kích hoạt lại tài khoản sau này nếu cần thiết' onConfirm={onRemove}>
                        <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} title='Xóa nhân viên' />
                    </Popconfirm>
                </>
            ) : (
                <Popconfirm title='Bạn chắc chắn khôi phục nhân viên này?' onConfirm={onRemove}>
                    <ReloadOutlined style={{ color: red.primary, fontSize: '20px' }} title='Khôi phục nhân viên' />
                </Popconfirm>
            )}
        </Space>
    )
}

export default StaffManagementActionColumn
