import {useMutation} from "@tanstack/react-query";
import TikTokShopAPI from "../../../../api/TikTokShopAPI";
import {notify} from "../../../../contexts/Notification";
import {ITikTokShop} from "../../../../types/ITikTokShop";
import {FormInstance} from "antd";

export function useShopAuth({ shop, form }: { shop?: ITikTokShop; form: FormInstance<Partial<ITikTokShop>> }) {
    const handleResponse = (response: { data?: Partial<ITikTokShop> }) => {
        if (response.data) {
            form.setFieldsValue({
                cipher: response.data.cipher ?? '',
                access_token: response.data.access_token ?? '',
                access_token_expire_in: response.data.access_token_expire_in,
                refresh_token: response.data.refresh_token ?? '',
                refresh_token_expire_in: response.data.refresh_token_expire_in,
                authorize_token: response.data.authorize_token ?? '',
            })
        }
    }

    const mutationUpdateAuthorize = useMutation({
        mutationFn: TikTokShopAPI.getAuthorizeToken,
        onSuccess: handleResponse,
        onError: (err) => notify('error', (err as Error).message)
    })

    const mutationGetShopAuthData = useMutation({
        mutationFn: TikTokShopAPI.getShopAuthData,
        onSuccess: handleResponse,
        onError: (err) => notify('error', (err as Error).message)
    })

    const getShopAuth = async () => {
        if (!shop) {
            notify('error', 'Missing tiktok shop')
            return;
        }

        const currentValues = form.getFieldsValue([
            'authorize_app_id',
            'authorize_app_key',
            'authorize_app_secret'
        ] as unknown as any)

        void mutationUpdateAuthorize.mutateAsync({
            _id: shop._id,
            authorize_app_id: currentValues.authorize_app_id,
            authorize_app_key: currentValues.authorize_app_key,
            authorize_app_secret: currentValues.authorize_app_secret
        })
    }

    const getShopAuthWithoutShopInfo = async () => {
        const currentValues = form.getFieldsValue([
            'authorize_token',
            'authorize_app_key',
            'authorize_app_secret'
        ] as unknown as any)

        const response = await mutationGetShopAuthData.mutateAsync({
            authorize_token: currentValues.authorize_token,
            authorize_app_key: currentValues.authorize_app_key,
            authorize_app_secret: currentValues.authorize_app_secret
        })
        console.log('response', response)
        if (response.data) {
            form.setFieldsValue({
                name: response.data.name ?? '',
                shop_code: response.data.shop_code ?? '',
                shop_id: response.data.shop_id,
                region: response.data.region ?? 'US',
            })
        }

    }

    return {
        mutationUpdateAuthorize,
        mutationGetShopAuthData,
        getShopAuth,
        getShopAuthWithoutShopInfo
    }
}
