import {useQuery} from "@tanstack/react-query";
import StaffAPI from "../../../api/StaffAPI";
import {IUser} from "../../../types/IUser";
import { useStaffManagementStore } from '../store/staff-management.store'
import { IStaffQueryParams } from '../interfaces/staff-management.interface'

export const GET_ALL_STAFFS_QUERY_KEY = 'GET_ALL_STAFFS_QUERY_KEY'
export const GET_STAFFS_QUERY_KEY = 'GET_STAFFS_QUERY_KEY'

export const useGetAllStaffsQuery = (condition?: { enabled?: boolean }) => {
    return useQuery<IUser[]>({
        queryKey: [GET_ALL_STAFFS_QUERY_KEY],
        queryFn: StaffAPI.getAllStaffs,
        ...condition
    })
}

export const useGetStaffsQuery = (queryParams: IStaffQueryParams) => {
    const isActive = useStaffManagementStore(state => state.isActive)
    return useQuery({
        queryKey: [GET_STAFFS_QUERY_KEY, { ...queryParams, isActive }],
        queryFn: () => StaffAPI.getStaffs({ ...queryParams, is_active: isActive })
    })
}
