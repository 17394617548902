import React from 'react';
import {Link, To} from 'react-router-dom';
import {Typography} from "antd";

function Page403() {
  return (
    <div className='w-100 d-flex flex-column align-items-center justify-content-center' style={{ height: '100vh' }}>
      <div className='d-flex flex-column align-items-center'>
        <Typography style={{ color: '#3B1AC7', fontWeight: 900, fontSize: 72 }}>
          403
        </Typography>
        <Typography style={{  color: '#3B1AC7', fontSize: 32 }} className='my-2 font-weight-bold'>
          Access Denied
        </Typography>
        <Typography style={{ fontSize: 16, color: '#917ee3', marginTop: 12 }}>
          Sorry, but you dont have permission to access this page.
        </Typography>
        <Typography style={{ fontSize: 16, color: '#917ee3' }}>
          You can go back to{' '}
          <Link to={-1 as To}>
            <span style={{ fontSize: 16, color: '#3B1AC7' }}>
              previous page
            </span>
          </Link>
          .
        </Typography>
      </div>
      <img src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1736146541669' alt='' />
    </div>
  );
}

export default Page403;
