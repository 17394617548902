import { useQuery } from '@tanstack/react-query'
import AutoActivityAPI from 'api/AutoActivityAPI'
import { IAutoPromotionQueryParams } from 'types/IAutoPromotion'

export const TIKTOK_AUTO_ACTIVITY_KEY = 'auto_activity_list'

export const useAutoActivityQuery = (queryParams: IAutoPromotionQueryParams) => {
    return useQuery({
        queryKey: [TIKTOK_AUTO_ACTIVITY_KEY, queryParams],
        queryFn: () => AutoActivityAPI.getAutoActivities(queryParams)
    })
}