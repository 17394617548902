import React from 'react';
import {Flex, Input} from "antd";
import {useBankAccountStore} from "../store/bank.store";

function BankAccountTableToolbar() {
    const filter = useBankAccountStore((state) => state.filter)

    return (
        <Flex className='mb-3 mt-3' gap={12} wrap>
            <Input size='large' placeholder='Search anythings' value={filter.search} onChange={filter.addSearch} style={{ maxWidth: 318 }} />
        </Flex>
    );
}

export default BankAccountTableToolbar;
