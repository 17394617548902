import React from 'react'
import { useTableConfigs } from '../../../hooks/useTableConfigs'
import { useShopStatementQuery } from './queries/shop-statement.query'
import { Table } from 'antd'
import { SHOP_STATEMENT_COLUMNS } from './components/_shopStatementColumnsDefined'

function ListShops({ tab }: { tab: string }) {
    const { queryResponse, pagination } = useTableConfigs(useShopStatementQuery)

    return (
        <>
            <Table
                bordered
                dataSource={queryResponse?.data?.data ?? []}
                columns={SHOP_STATEMENT_COLUMNS}
                loading={queryResponse?.isFetching}
                pagination={{
                    defaultCurrent: pagination.pageNumber,
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: pagination.handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Total <strong>{total}</strong> items
                        </span>
                    )
                }}
                rowKey={(record) => record._id}
            />
        </>
    )
}

export default ListShops
