import React, { useState } from 'react'
import { Card, Tabs } from 'antd'
import { Helmet } from 'react-helmet'
import ListShops from './ListShops'
import PaidHistory from './PaidHistory'
import { useShopStatementGeneralStore } from './store/shop-statement.store'

function ShopStatement() {
    const currentTab = useShopStatementGeneralStore((state) => state.currentTab)
    const setCurrentTab = useShopStatementGeneralStore((state) => state.setCurrentTab)

    const onChangeTab = (key: any) => {
        setCurrentTab(key)
    }

    const LIST_TABS = [
        {
            id: '1',
            label: 'Danh sách shop',
            children: <ListShops tab={currentTab} />
        },
        {
            id: '2',
            label: 'Lịch sử paid',
            children: <PaidHistory tab={currentTab} />
        }
    ]
    return (
        <Card title='Quản lý tiền về'>
            <Helmet>
                <title>Quản lý tiền về</title>
            </Helmet>
            <Tabs
                activeKey={currentTab}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: tab.children
                    }
                })}
                onChange={onChangeTab}
            />
        </Card>
    )
}

export default ShopStatement
