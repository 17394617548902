export const PRODUCT_PROPERTIES = {
    MEN_SLEEVELESS_TSHIRT: [
        {
            id: '100198',
            name: 'Pattern',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001189',
                    name: 'Moire'
                },
                {
                    id: '1001183',
                    name: 'Floral'
                },
                {
                    id: '1001190',
                    name: 'Paisley'
                },
                {
                    id: '1001188',
                    name: 'Herringbone'
                },
                {
                    id: '1001182',
                    name: 'Plain'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1001186',
                    name: 'Graphic'
                },
                {
                    id: '1001185',
                    name: 'Plaid'
                },
                {
                    id: '1001192',
                    name: 'Tie Dye'
                },
                {
                    id: '1001184',
                    name: 'Striped'
                },
                {
                    id: '1001191',
                    name: 'Polka Dot'
                },
                {
                    id: '1003613',
                    name: 'Figure'
                },
                {
                    id: '1005051',
                    name: 'Camouflage'
                },
                {
                    id: '1003633',
                    name: 'Slogan'
                },
                {
                    id: '1003626',
                    name: 'Marble'
                },
                {
                    id: '1003627',
                    name: 'Ombre'
                },
                {
                    id: '1003602',
                    name: 'Novelty Print'
                },
                {
                    id: '1003610',
                    name: 'Colorblock'
                },
                {
                    id: '1002395',
                    name: 'Plants'
                },
                {
                    id: '1003621',
                    name: 'Houndstooth'
                },
                {
                    id: '1007943',
                    name: 'Digital Print'
                },
                {
                    id: '1001412',
                    name: 'Cartoon'
                },
                {
                    id: '1003605',
                    name: 'Camo'
                },
                {
                    id: '1003607',
                    name: 'Chain Print'
                },
                {
                    id: '1003612',
                    name: 'Ditsy Floral'
                },
                {
                    id: '1003614',
                    name: 'Fire'
                },
                {
                    id: '1003617',
                    name: 'Galaxy'
                },
                {
                    id: '1003618',
                    name: 'Geometric'
                },
                {
                    id: '1003622',
                    name: 'Landscape Print'
                },
                {
                    id: '1003629',
                    name: 'Pop Art Print'
                },
                {
                    id: '1003630',
                    name: 'Rainbow Stripe'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003727',
                    name: 'Feather'
                },
                {
                    id: '1004311',
                    name: 'Letters'
                },
                {
                    id: '1019965',
                    name: 'Flag'
                },
                {
                    id: '1019966',
                    name: 'Waffle Knit'
                },
                {
                    id: '1019967',
                    name: 'Cable-knit'
                }
            ],
            is_number: false
        },
        {
            id: '100347',
            name: 'Quantity per Pack',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000639',
                    name: '8'
                },
                {
                    id: '1000638',
                    name: '6'
                },
                {
                    id: '1000355',
                    name: '7'
                },
                {
                    id: '1000354',
                    name: '5'
                },
                {
                    id: '1001202',
                    name: '12'
                },
                {
                    id: '1000347',
                    name: '4'
                },
                {
                    id: '1001201',
                    name: '11'
                },
                {
                    id: '1000256',
                    name: '1'
                },
                {
                    id: '1000258',
                    name: '3'
                },
                {
                    id: '1000357',
                    name: '10'
                },
                {
                    id: '1000257',
                    name: '2'
                },
                {
                    id: '1000356',
                    name: '9'
                }
            ],
            is_number: false
        },
        {
            id: '100392',
            name: 'Occasion',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1001122',
                    name: 'Business'
                },
                {
                    id: '1001123',
                    name: 'Wedding'
                },
                {
                    id: '1001125',
                    name: 'Evening'
                },
                {
                    id: '1001335',
                    name: 'Indoors'
                },
                {
                    id: '1001336',
                    name: 'Outdoors'
                },
                {
                    id: '1002665',
                    name: 'Party'
                }
            ],
            is_number: false
        },
        {
            id: '100393',
            name: 'Neckline',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001133',
                    name: 'Halter Neck'
                },
                {
                    id: '1001131',
                    name: 'Square Neck'
                },
                {
                    id: '1001126',
                    name: 'Crew Neck'
                },
                {
                    id: '1001132',
                    name: 'Collared Neck'
                },
                {
                    id: '1001130',
                    name: 'High Neck'
                },
                {
                    id: '1001137',
                    name: 'Sweetheart Neck'
                },
                {
                    id: '1001129',
                    name: 'Mock Neck'
                },
                {
                    id: '1001136',
                    name: 'Scoop Neck'
                },
                {
                    id: '1001128',
                    name: 'Boat Neck'
                },
                {
                    id: '1001135',
                    name: 'One Shoulder'
                },
                {
                    id: '1001127',
                    name: 'V-Neck'
                },
                {
                    id: '1001134',
                    name: 'Off Shoulder'
                },
                {
                    id: '1003660',
                    name: 'Tie Neck'
                },
                {
                    id: '1003642',
                    name: 'Cowl Neck'
                },
                {
                    id: '1003647',
                    name: 'Keyhole Neckline'
                },
                {
                    id: '1003640',
                    name: 'Asymmetrical Neck'
                },
                {
                    id: '1003650',
                    name: 'Notched'
                },
                {
                    id: '1003662',
                    name: 'U Neck'
                },
                {
                    id: '1003643',
                    name: 'Deep V Neck'
                },
                {
                    id: '1001260',
                    name: 'Bandeau'
                },
                {
                    id: '1019999',
                    name: 'Criss cross neck'
                }
            ],
            is_number: false
        },
        {
            id: '100394',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1001138',
                    name: 'Short'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                }
            ],
            is_number: false
        },
        {
            id: '100395',
            name: 'Sleeve Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001142',
                    name: 'Half Sleeve'
                },
                {
                    id: '1001141',
                    name: 'Short Sleeve'
                },
                {
                    id: '1001143',
                    name: '3/4 Sleeve'
                },
                {
                    id: '1001144',
                    name: 'Long Sleeve'
                }
            ],
            is_number: false
        },
        {
            id: '100396',
            name: 'Sleeve Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001156',
                    name: 'Cuff Sleeve'
                },
                {
                    id: '1001159',
                    name: 'Kimono Sleeve'
                },
                {
                    id: '1001152',
                    name: 'Bishop Sleeve'
                },
                {
                    id: '1001149',
                    name: 'Raglan Sleeve'
                },
                {
                    id: '1005904',
                    name: 'Regular'
                }
            ],
            is_number: false
        },
        {
            id: '100397',
            name: 'Season',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001161',
                    name: 'Spring'
                },
                {
                    id: '1001162',
                    name: 'Summer'
                },
                {
                    id: '1001163',
                    name: 'Winter'
                },
                {
                    id: '1000905',
                    name: 'Fall'
                },
                {
                    id: '1005840',
                    name: 'All Seasons'
                }
            ],
            is_number: false
        },
        {
            id: '100398',
            name: 'Style',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001166',
                    name: 'Boho'
                },
                {
                    id: '1001173',
                    name: 'Preppy'
                },
                {
                    id: '1001171',
                    name: 'Cute'
                },
                {
                    id: '1001178',
                    name: 'Vacation'
                },
                {
                    id: '1001165',
                    name: 'Basic'
                },
                {
                    id: '1001172',
                    name: 'Minimalist'
                },
                {
                    id: '1001170',
                    name: 'Korean'
                },
                {
                    id: '1001177',
                    name: 'Sweet'
                },
                {
                    id: '1001164',
                    name: 'Athletic'
                },
                {
                    id: '1001169',
                    name: 'Japanese'
                },
                {
                    id: '1001176',
                    name: 'Street'
                },
                {
                    id: '1001168',
                    name: 'Elegant'
                },
                {
                    id: '1001175',
                    name: 'Sexy'
                },
                {
                    id: '1001167',
                    name: 'Chic'
                },
                {
                    id: '1001174',
                    name: 'Retro'
                },
                {
                    id: '1002665',
                    name: 'Party'
                },
                {
                    id: '1003597',
                    name: 'Modest'
                },
                {
                    id: '1005406',
                    name: 'Office Lady'
                },
                {
                    id: '1003594',
                    name: 'Basics'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1002667',
                    name: 'Club'
                },
                {
                    id: '1001524',
                    name: 'Fashion'
                },
                {
                    id: '1019938',
                    name: 'Mature'
                },
                {
                    id: '1000520',
                    name: 'Sports'
                },
                {
                    id: '1003601',
                    name: 'Vacation'
                },
                {
                    id: '1003600',
                    name: 'Vintage'
                },
                {
                    id: '1019937',
                    name: 'Work'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003675',
                    name: 'Draped'
                },
                {
                    id: '1004424',
                    name: 'Classic'
                },
                {
                    id: '1004438',
                    name: 'Romance'
                },
                {
                    id: '1019936',
                    name: 'Gothic'
                },
                {
                    id: '1019940',
                    name: 'Biker'
                },
                {
                    id: '1019941',
                    name: 'Bomber'
                },
                {
                    id: '1019943',
                    name: 'Y2K'
                },
                {
                    id: '1019945',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '100399',
            name: 'Fit',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001180',
                    name: 'Fitted'
                },
                {
                    id: '1001181',
                    name: 'Loose Fit'
                },
                {
                    id: '1001179',
                    name: 'Slim Fit'
                }
            ],
            is_number: false
        },
        {
            id: '100401',
            name: 'Care Instructions',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001200',
                    name: 'Cold Wash'
                },
                {
                    id: '1001199',
                    name: 'Machine Washable'
                },
                {
                    id: '1001195',
                    name: 'Hand Wash'
                },
                {
                    id: '1001198',
                    name: 'Do Not Dry Clean'
                },
                {
                    id: '1001197',
                    name: 'Do Not Wash'
                },
                {
                    id: '1001196',
                    name: 'Dry Clean'
                }
            ],
            is_number: false
        },
        {
            id: '100406',
            name: 'Embellishment',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003700',
                    name: 'Fake Pockets'
                },
                {
                    id: '1001225',
                    name: 'Beads'
                },
                {
                    id: '1003682',
                    name: 'Belted'
                },
                {
                    id: '1001224',
                    name: 'Bow'
                },
                {
                    id: '1003604',
                    name: 'Butterfly'
                },
                {
                    id: '1001226',
                    name: 'Buttons'
                },
                {
                    id: '1003705',
                    name: 'Chain'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1003685',
                    name: 'Faux Pearls'
                },
                {
                    id: '1001227',
                    name: 'Feathers'
                },
                {
                    id: '1001228',
                    name: 'Fringe'
                },
                {
                    id: '1003686',
                    name: 'Knot'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1001229',
                    name: 'Piping'
                },
                {
                    id: '1001230',
                    name: 'Rhinestones'
                },
                {
                    id: '1001231',
                    name: 'Sequins'
                },
                {
                    id: '1003830',
                    name: 'Texture Metal'
                },
                {
                    id: '1001402',
                    name: 'Ring'
                },
                {
                    id: '1003689',
                    name: 'Beaded'
                },
                {
                    id: '1003724',
                    name: 'Pom Pom'
                },
                {
                    id: '1019996',
                    name: 'Fake Drawstring'
                }
            ],
            is_number: false
        },
        {
            id: '100443',
            name: 'Feature',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000330',
                    name: 'Waterproof'
                },
                {
                    id: '1000935',
                    name: 'Multi-Function'
                },
                {
                    id: '1001438',
                    name: 'Expandable'
                },
                {
                    id: '1001439',
                    name: 'Lightweight'
                },
                {
                    id: '1002263',
                    name: 'Foldable'
                },
                {
                    id: '1008073',
                    name: 'Multi-Compartment'
                },
                {
                    id: '1008074',
                    name: 'RFID'
                },
                {
                    id: '1008076',
                    name: 'Hangable'
                }
            ],
            is_number: false
        },
        {
            id: '100701',
            name: 'Material',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001107',
                    name: 'Rayon'
                },
                {
                    id: '1000014',
                    name: 'Acrylic'
                },
                {
                    id: '1000013',
                    name: 'Linen'
                },
                {
                    id: '1000042',
                    name: 'Leather'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1000038',
                    name: 'Cashmere'
                },
                {
                    id: '1000015',
                    name: 'Nylon'
                },
                {
                    id: '1001116',
                    name: 'Corduroy'
                },
                {
                    id: '1000039',
                    name: 'Cotton'
                },
                {
                    id: '1001110',
                    name: 'Mohair'
                },
                {
                    id: '1001115',
                    name: 'Faux Fur'
                },
                {
                    id: '1001121',
                    name: 'Knit'
                },
                {
                    id: '1000044',
                    name: 'Silk'
                },
                {
                    id: '1000045',
                    name: 'Wool'
                },
                {
                    id: '1001114',
                    name: 'Down'
                },
                {
                    id: '1001120',
                    name: 'Chiffon'
                },
                {
                    id: '1001109',
                    name: 'Cupro'
                },
                {
                    id: '1001113',
                    name: 'Modal'
                },
                {
                    id: '1001119',
                    name: 'Fleece'
                },
                {
                    id: '1001108',
                    name: 'Tencel'
                },
                {
                    id: '1001112',
                    name: 'Elastane1'
                },
                {
                    id: '1001118',
                    name: 'Felt'
                },
                {
                    id: '1001117',
                    name: 'Denim'
                },
                {
                    id: '1003739',
                    name: 'Rib'
                },
                {
                    id: '1005699',
                    name: 'Crepe'
                },
                {
                    id: '1005715',
                    name: 'French Terry'
                },
                {
                    id: '1000918',
                    name: 'Metallic'
                },
                {
                    id: '1003756',
                    name: 'Mesh'
                },
                {
                    id: '1003738',
                    name: 'Satin'
                },
                {
                    id: '1003908',
                    name: 'Twill'
                },
                {
                    id: '1002347',
                    name: 'Velvet'
                },
                {
                    id: '1005910',
                    name: 'Jacquard'
                },
                {
                    id: '1019985',
                    name: 'Recycle Polyester'
                },
                {
                    id: '1019984',
                    name: 'Recycled Polyester Blends'
                },
                {
                    id: '1019324',
                    name: 'Textured Woven Fabric'
                },
                {
                    id: '1003284',
                    name: 'Aluminum Alloy'
                },
                {
                    id: '1003758',
                    name: 'Shearling'
                },
                {
                    id: '1003759',
                    name: 'Tweed'
                },
                {
                    id: '1003900',
                    name: 'Polyamide'
                },
                {
                    id: '1005837',
                    name: 'Leather \u0026 Faux Leather Jackets'
                },
                {
                    id: '1005846',
                    name: 'Woven'
                },
                {
                    id: '1019322',
                    name: 'Jersey Spandex'
                },
                {
                    id: '1019975',
                    name: 'Wool-like Fabric'
                }
            ],
            is_number: false
        },
        {
            id: '100757',
            name: 'Design',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003688',
                    name: 'Split'
                },
                {
                    id: '1003897',
                    name: 'Adjustable Strap'
                },
                {
                    id: '1003691',
                    name: 'Sheer'
                },
                {
                    id: '1003699',
                    name: 'Asymmetrical'
                },
                {
                    id: '1003678',
                    name: 'Contrast Lace'
                },
                {
                    id: '1003706',
                    name: 'Raw Hem'
                },
                {
                    id: '1003713',
                    name: 'Criss Cross'
                },
                {
                    id: '1003684',
                    name: 'Twist'
                },
                {
                    id: '1003702',
                    name: 'Ripped'
                },
                {
                    id: '1003707',
                    name: 'Shirred'
                },
                {
                    id: '1003692',
                    name: 'Lettuce Trim'
                },
                {
                    id: '1003681',
                    name: 'Ruffle'
                },
                {
                    id: '1003721',
                    name: 'Pleated'
                },
                {
                    id: '1003837',
                    name: 'Crochet'
                },
                {
                    id: '1003698',
                    name: 'Frill'
                },
                {
                    id: '1003704',
                    name: 'Washed'
                },
                {
                    id: '1003715',
                    name: 'Tiered Layer'
                },
                {
                    id: '1003712',
                    name: 'Contrast Binding'
                },
                {
                    id: '1003676',
                    name: 'Button Front'
                },
                {
                    id: '1003687',
                    name: 'Scallop'
                },
                {
                    id: '1003703',
                    name: 'Tie Front'
                },
                {
                    id: '1003718',
                    name: 'Contrast Sequin'
                },
                {
                    id: '1003719',
                    name: 'Raw Wash'
                },
                {
                    id: '1003725',
                    name: 'Contrast Faux Fur'
                },
                {
                    id: '1019992',
                    name: 'Cross'
                },
                {
                    id: '1019995',
                    name: 'Quilted'
                }
            ],
            is_number: false
        },
        {
            id: '100898',
            name: 'Holiday',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1004734',
                    name: 'Childrens Day'
                },
                {
                    id: '1004735',
                    name: 'Chinese New Year'
                },
                {
                    id: '1003609',
                    name: 'Christmas'
                },
                {
                    id: '1004736',
                    name: 'Easter'
                },
                {
                    id: '1003620',
                    name: 'Halloween'
                },
                {
                    id: '1004737',
                    name: 'Mid-Autumn Festival'
                },
                {
                    id: '1004738',
                    name: 'New Year'
                },
                {
                    id: '1004739',
                    name: 'Nyepi'
                },
                {
                    id: '1004740',
                    name: 'Rambu Solo'
                },
                {
                    id: '1004741',
                    name: 'Songkran'
                },
                {
                    id: '1004742',
                    name: 'Thanksgiving'
                },
                {
                    id: '1004743',
                    name: 'Valentines Day'
                }
            ],
            is_number: false
        },
        {
            id: '101127',
            name: 'Size Type',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1005904',
                    name: 'Regular'
                },
                {
                    id: '1006947',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '101172',
            name: 'Closure Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001323',
                    name: 'Buckle'
                },
                {
                    id: '1002660',
                    name: 'Button'
                },
                {
                    id: '1001459',
                    name: 'Drawstring'
                },
                {
                    id: '1007452',
                    name: 'Hook And Bar'
                },
                {
                    id: '1007451',
                    name: 'Hook And Eye'
                },
                {
                    id: '1005816',
                    name: 'Hook And Loop'
                },
                {
                    id: '1003690',
                    name: 'Lace Up'
                },
                {
                    id: '1001891',
                    name: 'Magnetic'
                },
                {
                    id: '1007450',
                    name: 'Pull On'
                },
                {
                    id: '1005819',
                    name: 'Snap'
                },
                {
                    id: '1001482',
                    name: 'Tie'
                },
                {
                    id: '1001491',
                    name: 'Toggle'
                },
                {
                    id: '1001319',
                    name: 'Zip'
                }
            ],
            is_number: false
        },
        {
            id: '101175',
            name: 'Back Style',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003695',
                    name: 'Tie Back'
                },
                {
                    id: '1003711',
                    name: 'Bow Back'
                },
                {
                    id: '1003729',
                    name: 'Backless'
                },
                {
                    id: '1007453',
                    name: 'Strappy Back'
                },
                {
                    id: '1007454',
                    name: 'Racerback'
                },
                {
                    id: '1007455',
                    name: 'V Back'
                },
                {
                    id: '1007456',
                    name: 'Keyhole Back'
                },
                {
                    id: '1007457',
                    name: 'Lace Back'
                }
            ],
            is_number: false
        },
        {
            id: '101178',
            name: 'Collar Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1007470',
                    name: 'Bib Collar'
                },
                {
                    id: '1007468',
                    name: 'Chelsea Collar'
                },
                {
                    id: '1019990',
                    name: 'Contrast Collar'
                },
                {
                    id: '1007472',
                    name: 'Funnel Collar'
                },
                {
                    id: '1007475',
                    name: 'Gothic Pointed Collar'
                },
                {
                    id: '1007469',
                    name: 'Jabot Collar'
                },
                {
                    id: '1003648',
                    name: 'Lapel'
                },
                {
                    id: '1003649',
                    name: 'Mandarin Collar'
                },
                {
                    id: '1003652',
                    name: 'Peter Pan Collar'
                },
                {
                    id: '1007473',
                    name: 'Polo Collar'
                },
                {
                    id: '1007474',
                    name: 'Roll Collar'
                },
                {
                    id: '1003657',
                    name: 'Stand Collar'
                },
                {
                    id: '1007471',
                    name: 'Swallow Collar'
                },
                {
                    id: '1019997',
                    name: 'Baseball Collar'
                },
                {
                    id: '1019998',
                    name: 'Cardigan Collar'
                }
            ],
            is_number: false
        },
        {
            id: '101269',
            name: 'Sheer',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000059',
                    name: 'No'
                },
                {
                    id: '1019955',
                    name: 'Semi-Sheer'
                },
                {
                    id: '1000058',
                    name: 'Yes'
                }
            ],
            is_number: false
        }
    ],
    MEN_HOODIES_SWEATER: [
        {
            id: '100198',
            name: 'Pattern',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001184',
                    name: 'Striped'
                },
                {
                    id: '1001191',
                    name: 'Polka Dot'
                },
                {
                    id: '1001183',
                    name: 'Floral'
                },
                {
                    id: '1001190',
                    name: 'Paisley'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1001182',
                    name: 'Plain'
                },
                {
                    id: '1001188',
                    name: 'Herringbone'
                },
                {
                    id: '1001186',
                    name: 'Graphic'
                },
                {
                    id: '1001185',
                    name: 'Plaid'
                },
                {
                    id: '1001192',
                    name: 'Tie Dye'
                },
                {
                    id: '1005051',
                    name: 'Camouflage'
                },
                {
                    id: '1003633',
                    name: 'Slogan'
                },
                {
                    id: '1003602',
                    name: 'Novelty Print'
                },
                {
                    id: '1003626',
                    name: 'Marble'
                },
                {
                    id: '1003627',
                    name: 'Ombre'
                },
                {
                    id: '1003610',
                    name: 'Colorblock'
                },
                {
                    id: '1002395',
                    name: 'Plants'
                },
                {
                    id: '1003621',
                    name: 'Houndstooth'
                },
                {
                    id: '1007943',
                    name: 'Digital Print'
                },
                {
                    id: '1001412',
                    name: 'Cartoon'
                },
                {
                    id: '1003605',
                    name: 'Camo'
                },
                {
                    id: '1003607',
                    name: 'Chain Print'
                },
                {
                    id: '1003612',
                    name: 'Ditsy Floral'
                },
                {
                    id: '1003614',
                    name: 'Fire'
                },
                {
                    id: '1003617',
                    name: 'Galaxy'
                },
                {
                    id: '1003618',
                    name: 'Geometric'
                },
                {
                    id: '1003622',
                    name: 'Landscape Print'
                },
                {
                    id: '1003629',
                    name: 'Pop Art Print'
                },
                {
                    id: '1003630',
                    name: 'Rainbow Stripe'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003727',
                    name: 'Feather'
                },
                {
                    id: '1004311',
                    name: 'Letters'
                },
                {
                    id: '1019965',
                    name: 'Flag'
                },
                {
                    id: '1019966',
                    name: 'Waffle Knit'
                },
                {
                    id: '1019967',
                    name: 'Cable-knit'
                }
            ],
            is_number: false
        },
        {
            id: '100392',
            name: 'Occasion',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001123',
                    name: 'Wedding'
                },
                {
                    id: '1001122',
                    name: 'Business'
                },
                {
                    id: '1001125',
                    name: 'Evening'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1001335',
                    name: 'Indoors'
                },
                {
                    id: '1001336',
                    name: 'Outdoors'
                },
                {
                    id: '1002665',
                    name: 'Party'
                }
            ],
            is_number: false
        },
        {
            id: '100393',
            name: 'Neckline',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001132',
                    name: 'Collared Neck'
                },
                {
                    id: '1001126',
                    name: 'Crew Neck'
                },
                {
                    id: '1001129',
                    name: 'Mock Neck'
                },
                {
                    id: '1001136',
                    name: 'Scoop Neck'
                },
                {
                    id: '1001131',
                    name: 'Square Neck'
                },
                {
                    id: '1001130',
                    name: 'High Neck'
                },
                {
                    id: '1001137',
                    name: 'Sweetheart Neck'
                },
                {
                    id: '1001128',
                    name: 'Boat Neck'
                },
                {
                    id: '1001135',
                    name: 'One Shoulder'
                },
                {
                    id: '1001127',
                    name: 'V-Neck'
                },
                {
                    id: '1001134',
                    name: 'Off Shoulder'
                },
                {
                    id: '1003660',
                    name: 'Tie Neck'
                },
                {
                    id: '1003642',
                    name: 'Cowl Neck'
                },
                {
                    id: '1003647',
                    name: 'Keyhole Neckline'
                },
                {
                    id: '1003640',
                    name: 'Asymmetrical Neck'
                },
                {
                    id: '1003650',
                    name: 'Notched'
                },
                {
                    id: '1003662',
                    name: 'U Neck'
                },
                {
                    id: '1003643',
                    name: 'Deep V Neck'
                },
                {
                    id: '1001260',
                    name: 'Bandeau'
                },
                {
                    id: '1019999',
                    name: 'Criss cross neck'
                }
            ],
            is_number: false
        },
        {
            id: '100394',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1001138',
                    name: 'Short'
                },
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                }
            ],
            is_number: false
        },
        {
            id: '100395',
            name: 'Sleeve Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001142',
                    name: 'Half Sleeve'
                },
                {
                    id: '1001144',
                    name: 'Long Sleeve'
                },
                {
                    id: '1001143',
                    name: '3/4 Sleeve'
                },
                {
                    id: '1001141',
                    name: 'Short Sleeve'
                }
            ],
            is_number: false
        },
        {
            id: '100397',
            name: 'Season',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001161',
                    name: 'Spring'
                },
                {
                    id: '1001162',
                    name: 'Summer'
                },
                {
                    id: '1001163',
                    name: 'Winter'
                },
                {
                    id: '1000905',
                    name: 'Fall'
                },
                {
                    id: '1005840',
                    name: 'All Seasons'
                }
            ],
            is_number: false
        },
        {
            id: '100398',
            name: 'Style',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001167',
                    name: 'Chic'
                },
                {
                    id: '1001174',
                    name: 'Retro'
                },
                {
                    id: '1001164',
                    name: 'Athletic'
                },
                {
                    id: '1001168',
                    name: 'Elegant'
                },
                {
                    id: '1001175',
                    name: 'Sexy'
                },
                {
                    id: '1001166',
                    name: 'Boho'
                },
                {
                    id: '1001173',
                    name: 'Preppy'
                },
                {
                    id: '1001171',
                    name: 'Cute'
                },
                {
                    id: '1001178',
                    name: 'Vacation'
                },
                {
                    id: '1001176',
                    name: 'Street'
                },
                {
                    id: '1001165',
                    name: 'Basic'
                },
                {
                    id: '1001172',
                    name: 'Minimalist'
                },
                {
                    id: '1001177',
                    name: 'Sweet'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1000520',
                    name: 'Sports'
                },
                {
                    id: '1019937',
                    name: 'Work'
                },
                {
                    id: '1003594',
                    name: 'Basics'
                },
                {
                    id: '1003600',
                    name: 'Vintage'
                },
                {
                    id: '1001524',
                    name: 'Fashion'
                },
                {
                    id: '1002665',
                    name: 'Party'
                },
                {
                    id: '1003597',
                    name: 'Modest'
                },
                {
                    id: '1003601',
                    name: 'Vacation'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003675',
                    name: 'Draped'
                },
                {
                    id: '1004424',
                    name: 'Classic'
                },
                {
                    id: '1004438',
                    name: 'Romance'
                },
                {
                    id: '1019936',
                    name: 'Gothic'
                },
                {
                    id: '1019940',
                    name: 'Biker'
                },
                {
                    id: '1019941',
                    name: 'Bomber'
                },
                {
                    id: '1019943',
                    name: 'Y2K'
                },
                {
                    id: '1019945',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '100399',
            name: 'Fit',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001179',
                    name: 'Slim Fit'
                },
                {
                    id: '1001181',
                    name: 'Loose Fit'
                },
                {
                    id: '1001180',
                    name: 'Fitted'
                }
            ],
            is_number: false
        },
        {
            id: '100401',
            name: 'Care Instructions',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001198',
                    name: 'Do Not Dry Clean'
                },
                {
                    id: '1001197',
                    name: 'Do Not Wash'
                },
                {
                    id: '1001196',
                    name: 'Dry Clean'
                },
                {
                    id: '1001195',
                    name: 'Hand Wash'
                },
                {
                    id: '1001200',
                    name: 'Cold Wash'
                },
                {
                    id: '1001199',
                    name: 'Machine Washable'
                }
            ],
            is_number: false
        },
        {
            id: '100406',
            name: 'Embellishment',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001225',
                    name: 'Beads'
                },
                {
                    id: '1003682',
                    name: 'Belted'
                },
                {
                    id: '1001224',
                    name: 'Bow'
                },
                {
                    id: '1003604',
                    name: 'Butterfly'
                },
                {
                    id: '1001226',
                    name: 'Buttons'
                },
                {
                    id: '1003705',
                    name: 'Chain'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1003685',
                    name: 'Faux Pearls'
                },
                {
                    id: '1001227',
                    name: 'Feathers'
                },
                {
                    id: '1001228',
                    name: 'Fringe'
                },
                {
                    id: '1003686',
                    name: 'Knot'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1001229',
                    name: 'Piping'
                },
                {
                    id: '1001230',
                    name: 'Rhinestones'
                },
                {
                    id: '1001231',
                    name: 'Sequins'
                },
                {
                    id: '1003700',
                    name: 'Fake Pockets'
                },
                {
                    id: '1001402',
                    name: 'Ring'
                },
                {
                    id: '1003689',
                    name: 'Beaded'
                },
                {
                    id: '1003724',
                    name: 'Pom Pom'
                },
                {
                    id: '1019996',
                    name: 'Fake Drawstring'
                }
            ],
            is_number: false
        },
        {
            id: '100409',
            name: 'Clothing Styles',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1007463',
                    name: 'Pullover Hoodie'
                },
                {
                    id: '1007466',
                    name: 'Full Zip Sweatshirt'
                },
                {
                    id: '1007464',
                    name: 'Full Zip Hoodie'
                },
                {
                    id: '1007447',
                    name: 'Sweatshirt'
                },
                {
                    id: '1007465',
                    name: 'Half Zip Hoodie'
                },
                {
                    id: '1007467',
                    name: 'Half Zip Sweatshirt'
                }
            ],
            is_number: false
        },
        {
            id: '100443',
            name: 'Feature',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000330',
                    name: 'Waterproof'
                },
                {
                    id: '1000935',
                    name: 'Multi-Function'
                },
                {
                    id: '1001438',
                    name: 'Expandable'
                },
                {
                    id: '1001439',
                    name: 'Lightweight'
                },
                {
                    id: '1002263',
                    name: 'Foldable'
                },
                {
                    id: '1008073',
                    name: 'Multi-Compartment'
                },
                {
                    id: '1008074',
                    name: 'RFID'
                },
                {
                    id: '1008076',
                    name: 'Hangable'
                }
            ],
            is_number: false
        },
        {
            id: '100701',
            name: 'Material',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000038',
                    name: 'Cashmere'
                },
                {
                    id: '1000015',
                    name: 'Nylon'
                },
                {
                    id: '1001116',
                    name: 'Corduroy'
                },
                {
                    id: '1000014',
                    name: 'Acrylic'
                },
                {
                    id: '1000013',
                    name: 'Linen'
                },
                {
                    id: '1000042',
                    name: 'Leather'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1001109',
                    name: 'Cupro'
                },
                {
                    id: '1001113',
                    name: 'Modal'
                },
                {
                    id: '1001119',
                    name: 'Fleece'
                },
                {
                    id: '1001110',
                    name: 'Mohair'
                },
                {
                    id: '1001115',
                    name: 'Faux Fur'
                },
                {
                    id: '1001121',
                    name: 'Knit'
                },
                {
                    id: '1000039',
                    name: 'Cotton'
                },
                {
                    id: '1000044',
                    name: 'Silk'
                },
                {
                    id: '1000045',
                    name: 'Wool'
                },
                {
                    id: '1001114',
                    name: 'Down'
                },
                {
                    id: '1001120',
                    name: 'Chiffon'
                },
                {
                    id: '1001108',
                    name: 'Tencel'
                },
                {
                    id: '1001112',
                    name: 'Elastane1'
                },
                {
                    id: '1001118',
                    name: 'Felt'
                },
                {
                    id: '1001107',
                    name: 'Rayon'
                },
                {
                    id: '1001117',
                    name: 'Denim'
                },
                {
                    id: '1003739',
                    name: 'Rib'
                },
                {
                    id: '1005699',
                    name: 'Crepe'
                },
                {
                    id: '1005715',
                    name: 'French Terry'
                },
                {
                    id: '1000918',
                    name: 'Metallic'
                },
                {
                    id: '1002347',
                    name: 'Velvet'
                },
                {
                    id: '1003756',
                    name: 'Mesh'
                },
                {
                    id: '1003738',
                    name: 'Satin'
                },
                {
                    id: '1003908',
                    name: 'Twill'
                },
                {
                    id: '1005910',
                    name: 'Jacquard'
                },
                {
                    id: '1003900',
                    name: 'Polyamide'
                },
                {
                    id: '1019984',
                    name: 'Recycled Polyester Blends'
                },
                {
                    id: '1003284',
                    name: 'Aluminum Alloy'
                },
                {
                    id: '1003758',
                    name: 'Shearling'
                },
                {
                    id: '1003759',
                    name: 'Tweed'
                },
                {
                    id: '1005837',
                    name: 'Leather \u0026 Faux Leather Jackets'
                },
                {
                    id: '1005846',
                    name: 'Woven'
                },
                {
                    id: '1019322',
                    name: 'Jersey Spandex'
                },
                {
                    id: '1019324',
                    name: 'Textured Woven Fabric'
                },
                {
                    id: '1019975',
                    name: 'Wool-like Fabric'
                },
                {
                    id: '1019985',
                    name: 'Recycle Polyester'
                }
            ],
            is_number: false
        },
        {
            id: '100757',
            name: 'Design',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003897',
                    name: 'Adjustable Strap'
                },
                {
                    id: '1003691',
                    name: 'Sheer'
                },
                {
                    id: '1003681',
                    name: 'Ruffle'
                },
                {
                    id: '1003699',
                    name: 'Asymmetrical'
                },
                {
                    id: '1003678',
                    name: 'Contrast Lace'
                },
                {
                    id: '1003706',
                    name: 'Raw Hem'
                },
                {
                    id: '1003688',
                    name: 'Split'
                },
                {
                    id: '1003712',
                    name: 'Contrast Binding'
                },
                {
                    id: '1003707',
                    name: 'Shirred'
                },
                {
                    id: '1003692',
                    name: 'Lettuce Trim'
                },
                {
                    id: '1003698',
                    name: 'Frill'
                },
                {
                    id: '1003715',
                    name: 'Tiered Layer'
                },
                {
                    id: '1003713',
                    name: 'Criss Cross'
                },
                {
                    id: '1003684',
                    name: 'Twist'
                },
                {
                    id: '1003702',
                    name: 'Ripped'
                },
                {
                    id: '1003676',
                    name: 'Button Front'
                },
                {
                    id: '1019992',
                    name: 'Cross'
                },
                {
                    id: '1003687',
                    name: 'Scallop'
                },
                {
                    id: '1003703',
                    name: 'Tie Front'
                },
                {
                    id: '1003718',
                    name: 'Contrast Sequin'
                },
                {
                    id: '1003719',
                    name: 'Raw Wash'
                },
                {
                    id: '1003725',
                    name: 'Contrast Faux Fur'
                },
                {
                    id: '1019995',
                    name: 'Quilted'
                }
            ],
            is_number: false
        },
        {
            id: '100898',
            name: 'Holiday',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003609',
                    name: 'Christmas'
                },
                {
                    id: '1004736',
                    name: 'Easter'
                },
                {
                    id: '1003620',
                    name: 'Halloween'
                },
                {
                    id: '1004738',
                    name: 'New Year'
                },
                {
                    id: '1004742',
                    name: 'Thanksgiving'
                },
                {
                    id: '1004743',
                    name: 'Valentines Day'
                }
            ],
            is_number: false
        },
        {
            id: '101127',
            name: 'Size Type',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1005904',
                    name: 'Regular'
                },
                {
                    id: '1006947',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '101172',
            name: 'Closure Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1002660',
                    name: 'Button'
                },
                {
                    id: '1001459',
                    name: 'Drawstring'
                },
                {
                    id: '1007450',
                    name: 'Pull On'
                },
                {
                    id: '1001482',
                    name: 'Tie'
                },
                {
                    id: '1001319',
                    name: 'Zip'
                }
            ],
            is_number: false
        },
        {
            id: '101175',
            name: 'Back Style',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003695',
                    name: 'Tie Back'
                },
                {
                    id: '1003711',
                    name: 'Bow Back'
                },
                {
                    id: '1003729',
                    name: 'Backless'
                },
                {
                    id: '1007453',
                    name: 'Strappy Back'
                },
                {
                    id: '1007454',
                    name: 'Racerback'
                },
                {
                    id: '1007455',
                    name: 'V Back'
                },
                {
                    id: '1007456',
                    name: 'Keyhole Back'
                },
                {
                    id: '1007457',
                    name: 'Lace Back'
                }
            ],
            is_number: false
        },
        {
            id: '101178',
            name: 'Collar Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1007470',
                    name: 'Bib Collar'
                },
                {
                    id: '1007468',
                    name: 'Chelsea Collar'
                },
                {
                    id: '1007472',
                    name: 'Funnel Collar'
                },
                {
                    id: '1007475',
                    name: 'Gothic Pointed Collar'
                },
                {
                    id: '1007469',
                    name: 'Jabot Collar'
                },
                {
                    id: '1003648',
                    name: 'Lapel'
                },
                {
                    id: '1003649',
                    name: 'Mandarin Collar'
                },
                {
                    id: '1003652',
                    name: 'Peter Pan Collar'
                },
                {
                    id: '1007473',
                    name: 'Polo Collar'
                },
                {
                    id: '1007474',
                    name: 'Roll Collar'
                },
                {
                    id: '1003655',
                    name: 'Shawl Collar'
                },
                {
                    id: '1007471',
                    name: 'Swallow Collar'
                },
                {
                    id: '1019990',
                    name: 'Contrast Collar'
                },
                {
                    id: '1019997',
                    name: 'Baseball Collar'
                },
                {
                    id: '1019998',
                    name: 'Cardigan Collar'
                }
            ],
            is_number: false
        }
    ],
    WOMEN_TOP_TSHIRT: [
        {
            id: '100397',
            name: 'Season',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001162',
                    name: 'Summer'
                },
                {
                    id: '1000905',
                    name: 'Fall'
                },
                {
                    id: '1001161',
                    name: 'Spring'
                },
                {
                    id: '1001163',
                    name: 'Winter'
                },
                {
                    id: '1005840',
                    name: 'All Seasons'
                }
            ],
            is_number: false
        },
        {
            id: '100347',
            name: 'Quantity per Pack',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000258',
                    name: '3'
                },
                {
                    id: '1000357',
                    name: '10'
                },
                {
                    id: '1000256',
                    name: '1'
                },
                {
                    id: '1000638',
                    name: '6'
                },
                {
                    id: '1000347',
                    name: '4'
                },
                {
                    id: '1001201',
                    name: '11'
                },
                {
                    id: '1000354',
                    name: '5'
                },
                {
                    id: '1001202',
                    name: '12'
                },
                {
                    id: '1000355',
                    name: '7'
                },
                {
                    id: '1000639',
                    name: '8'
                },
                {
                    id: '1000257',
                    name: '2'
                },
                {
                    id: '1000356',
                    name: '9'
                }
            ],
            is_number: false
        },
        {
            id: '100401',
            name: 'Care Instructions',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001196',
                    name: 'Dry Clean'
                },
                {
                    id: '1001195',
                    name: 'Hand Wash'
                },
                {
                    id: '1001197',
                    name: 'Do Not Wash'
                },
                {
                    id: '1001198',
                    name: 'Do Not Dry Clean'
                },
                {
                    id: '1001199',
                    name: 'Machine Washable'
                },
                {
                    id: '1001200',
                    name: 'Cold Wash'
                }
            ],
            is_number: false
        },
        {
            id: '100394',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                },
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1001138',
                    name: 'Short'
                }
            ],
            is_number: false
        },
        {
            id: '100392',
            name: 'Occasion',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1001123',
                    name: 'Wedding'
                },
                {
                    id: '1001122',
                    name: 'Business'
                },
                {
                    id: '1001125',
                    name: 'Evening'
                },
                {
                    id: '1001335',
                    name: 'Indoors'
                },
                {
                    id: '1001336',
                    name: 'Outdoors'
                },
                {
                    id: '1002665',
                    name: 'Party'
                }
            ],
            is_number: false
        },
        {
            id: '100395',
            name: 'Sleeve Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001141',
                    name: 'Short Sleeve'
                },
                {
                    id: '1001144',
                    name: 'Long Sleeve'
                },
                {
                    id: '1001142',
                    name: 'Half Sleeve'
                },
                {
                    id: '1001143',
                    name: '3/4 Sleeve'
                },
                {
                    id: '1003673',
                    name: 'Extra-Long Sleeve'
                }
            ],
            is_number: false
        },
        {
            id: '100399',
            name: 'Fit',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001179',
                    name: 'Slim Fit'
                },
                {
                    id: '1001180',
                    name: 'Fitted'
                },
                {
                    id: '1001181',
                    name: 'Loose Fit'
                }
            ],
            is_number: false
        },
        {
            id: '100198',
            name: 'Pattern',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001186',
                    name: 'Graphic'
                },
                {
                    id: '1001189',
                    name: 'Moire'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1001182',
                    name: 'Plain'
                },
                {
                    id: '1001188',
                    name: 'Herringbone'
                },
                {
                    id: '1001183',
                    name: 'Floral'
                },
                {
                    id: '1001190',
                    name: 'Paisley'
                },
                {
                    id: '1001184',
                    name: 'Striped'
                },
                {
                    id: '1001191',
                    name: 'Polka Dot'
                },
                {
                    id: '1001185',
                    name: 'Plaid'
                },
                {
                    id: '1001192',
                    name: 'Tie Dye'
                },
                {
                    id: '1003633',
                    name: 'Slogan'
                },
                {
                    id: '1003613',
                    name: 'Figure'
                },
                {
                    id: '1005051',
                    name: 'Camouflage'
                },
                {
                    id: '1003623',
                    name: 'Leopard'
                },
                {
                    id: '1003602',
                    name: 'Novelty Print'
                },
                {
                    id: '1002395',
                    name: 'Plants'
                },
                {
                    id: '1003621',
                    name: 'Houndstooth'
                },
                {
                    id: '1003626',
                    name: 'Marble'
                },
                {
                    id: '1003627',
                    name: 'Ombre'
                },
                {
                    id: '1003610',
                    name: 'Colorblock'
                },
                {
                    id: '1007943',
                    name: 'Digital Print'
                },
                {
                    id: '1001412',
                    name: 'Cartoon'
                },
                {
                    id: '1003605',
                    name: 'Camo'
                },
                {
                    id: '1003607',
                    name: 'Chain Print'
                },
                {
                    id: '1003612',
                    name: 'Ditsy Floral'
                },
                {
                    id: '1003614',
                    name: 'Fire'
                },
                {
                    id: '1003617',
                    name: 'Galaxy'
                },
                {
                    id: '1003618',
                    name: 'Geometric'
                },
                {
                    id: '1003622',
                    name: 'Landscape Print'
                },
                {
                    id: '1003629',
                    name: 'Pop Art Print'
                },
                {
                    id: '1003630',
                    name: 'Rainbow Stripe'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003727',
                    name: 'Feather'
                },
                {
                    id: '1004311',
                    name: 'Letters'
                },
                {
                    id: '1019965',
                    name: 'Flag'
                },
                {
                    id: '1019966',
                    name: 'Waffle Knit'
                },
                {
                    id: '1019967',
                    name: 'Cable-knit'
                }
            ],
            is_number: false
        },
        {
            id: '100396',
            name: 'Sleeve Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001146',
                    name: 'Batwing Sleeve'
                },
                {
                    id: '1001153',
                    name: 'Cap Sleeve'
                },
                {
                    id: '1001160',
                    name: 'Split Sleeve'
                },
                {
                    id: '1001149',
                    name: 'Raglan Sleeve'
                },
                {
                    id: '1001156',
                    name: 'Cuff Sleeve'
                },
                {
                    id: '1001147',
                    name: 'Lantern Sleeve'
                },
                {
                    id: '1001154',
                    name: 'Cape Sleeve'
                },
                {
                    id: '1001148',
                    name: 'Puff Sleeve'
                },
                {
                    id: '1001155',
                    name: 'Cold Shoulder Sleeve'
                },
                {
                    id: '1001150',
                    name: 'Ruffle Sleeve'
                },
                {
                    id: '1001157',
                    name: 'Flare Sleeve'
                },
                {
                    id: '1001151',
                    name: 'Bell Sleeve'
                },
                {
                    id: '1001158',
                    name: 'Flutter Sleeve'
                },
                {
                    id: '1001145',
                    name: 'Balloon Sleeve'
                },
                {
                    id: '1001152',
                    name: 'Bishop Sleeve'
                },
                {
                    id: '1001159',
                    name: 'Kimono Sleeve'
                },
                {
                    id: '1003668',
                    name: 'Leg-Of-Mutton Sleeve'
                },
                {
                    id: '1003671',
                    name: 'Roll Up Sleeve'
                },
                {
                    id: '1003665',
                    name: 'Drop Shoulder'
                },
                {
                    id: '1003667',
                    name: 'Layered Sleeve'
                },
                {
                    id: '1005904',
                    name: 'Regular'
                }
            ],
            is_number: false
        },
        {
            id: '100398',
            name: 'Style',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001166',
                    name: 'Boho'
                },
                {
                    id: '1001173',
                    name: 'Preppy'
                },
                {
                    id: '1001164',
                    name: 'Athletic'
                },
                {
                    id: '1001167',
                    name: 'Chic'
                },
                {
                    id: '1001174',
                    name: 'Retro'
                },
                {
                    id: '1001168',
                    name: 'Elegant'
                },
                {
                    id: '1001175',
                    name: 'Sexy'
                },
                {
                    id: '1001171',
                    name: 'Cute'
                },
                {
                    id: '1001178',
                    name: 'Vacation'
                },
                {
                    id: '1001169',
                    name: 'Japanese'
                },
                {
                    id: '1001176',
                    name: 'Street'
                },
                {
                    id: '1001170',
                    name: 'Korean'
                },
                {
                    id: '1001177',
                    name: 'Sweet'
                },
                {
                    id: '1001165',
                    name: 'Basic'
                },
                {
                    id: '1001172',
                    name: 'Minimalist'
                },
                {
                    id: '1005406',
                    name: 'Office Lady'
                },
                {
                    id: '1003597',
                    name: 'Modest'
                },
                {
                    id: '1002665',
                    name: 'Party'
                },
                {
                    id: '1000520',
                    name: 'Sports'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1001524',
                    name: 'Fashion'
                },
                {
                    id: '1003594',
                    name: 'Basics'
                },
                {
                    id: '1003600',
                    name: 'Vintage'
                },
                {
                    id: '1003601',
                    name: 'Vacation'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003675',
                    name: 'Draped'
                },
                {
                    id: '1004424',
                    name: 'Classic'
                },
                {
                    id: '1004438',
                    name: 'Romance'
                },
                {
                    id: '1019936',
                    name: 'Gothic'
                },
                {
                    id: '1019937',
                    name: 'Work'
                },
                {
                    id: '1019940',
                    name: 'Biker'
                },
                {
                    id: '1019941',
                    name: 'Bomber'
                },
                {
                    id: '1019943',
                    name: 'Y2K'
                },
                {
                    id: '1019945',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '100406',
            name: 'Embellishment',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001224',
                    name: 'Bow'
                },
                {
                    id: '1001225',
                    name: 'Beads'
                },
                {
                    id: '1001227',
                    name: 'Feathers'
                },
                {
                    id: '1001230',
                    name: 'Rhinestones'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1001228',
                    name: 'Fringe'
                },
                {
                    id: '1001229',
                    name: 'Piping'
                },
                {
                    id: '1001231',
                    name: 'Sequins'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1001226',
                    name: 'Buttons'
                },
                {
                    id: '1003682',
                    name: 'Belted'
                },
                {
                    id: '1003686',
                    name: 'Knot'
                },
                {
                    id: '1003705',
                    name: 'Chain'
                },
                {
                    id: '1003604',
                    name: 'Butterfly'
                },
                {
                    id: '1003830',
                    name: 'Texture Metal'
                },
                {
                    id: '1003685',
                    name: 'Faux Pearls'
                },
                {
                    id: '1001402',
                    name: 'Ring'
                },
                {
                    id: '1003689',
                    name: 'Beaded'
                },
                {
                    id: '1003700',
                    name: 'Fake Pockets'
                },
                {
                    id: '1003724',
                    name: 'Pom Pom'
                },
                {
                    id: '1019996',
                    name: 'Fake Drawstring'
                }
            ],
            is_number: false
        },
        {
            id: '101127',
            name: 'Size Type',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1006946',
                    name: 'Juniors'
                },
                {
                    id: '1002680',
                    name: 'Plus Size'
                },
                {
                    id: '1005904',
                    name: 'Regular'
                },
                {
                    id: '1002679',
                    name: 'Petite'
                }
            ],
            is_number: false
        },
        {
            id: '100757',
            name: 'Design',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003897',
                    name: 'Adjustable Strap'
                },
                {
                    id: '1003691',
                    name: 'Sheer'
                },
                {
                    id: '1003681',
                    name: 'Ruffle'
                },
                {
                    id: '1003721',
                    name: 'Pleated'
                },
                {
                    id: '1003837',
                    name: 'Crochet'
                },
                {
                    id: '1003699',
                    name: 'Asymmetrical'
                },
                {
                    id: '1003678',
                    name: 'Contrast Lace'
                },
                {
                    id: '1003706',
                    name: 'Raw Hem'
                },
                {
                    id: '1003688',
                    name: 'Split'
                },
                {
                    id: '1003712',
                    name: 'Contrast Binding'
                },
                {
                    id: '1003707',
                    name: 'Shirred'
                },
                {
                    id: '1003692',
                    name: 'Lettuce Trim'
                },
                {
                    id: '1003698',
                    name: 'Frill'
                },
                {
                    id: '1003704',
                    name: 'Washed'
                },
                {
                    id: '1003715',
                    name: 'Tiered Layer'
                },
                {
                    id: '1003713',
                    name: 'Criss Cross'
                },
                {
                    id: '1003684',
                    name: 'Twist'
                },
                {
                    id: '1003702',
                    name: 'Ripped'
                },
                {
                    id: '1003676',
                    name: 'Button Front'
                },
                {
                    id: '1003687',
                    name: 'Scallop'
                },
                {
                    id: '1003703',
                    name: 'Tie Front'
                },
                {
                    id: '1003718',
                    name: 'Contrast Sequin'
                },
                {
                    id: '1003719',
                    name: 'Raw Wash'
                },
                {
                    id: '1003725',
                    name: 'Contrast Faux Fur'
                },
                {
                    id: '1019992',
                    name: 'Cross'
                },
                {
                    id: '1019995',
                    name: 'Quilted'
                }
            ],
            is_number: false
        },
        {
            id: '100443',
            name: 'Feature',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000330',
                    name: 'Waterproof'
                },
                {
                    id: '1000935',
                    name: 'Multi-Function'
                },
                {
                    id: '1001438',
                    name: 'Expandable'
                },
                {
                    id: '1001439',
                    name: 'Lightweight'
                },
                {
                    id: '1002263',
                    name: 'Foldable'
                },
                {
                    id: '1008073',
                    name: 'Multi-Compartment'
                },
                {
                    id: '1008074',
                    name: 'RFID'
                },
                {
                    id: '1008076',
                    name: 'Hangable'
                }
            ],
            is_number: false
        },
        {
            id: '100898',
            name: 'Holiday',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003609',
                    name: 'Christmas'
                },
                {
                    id: '1003620',
                    name: 'Halloween'
                },
                {
                    id: '1004736',
                    name: 'Easter'
                },
                {
                    id: '1004738',
                    name: 'New Year'
                },
                {
                    id: '1004742',
                    name: 'Thanksgiving'
                },
                {
                    id: '1004743',
                    name: 'Valentines Day'
                }
            ],
            is_number: false
        },
        {
            id: '101172',
            name: 'Closure Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001319',
                    name: 'Zip'
                },
                {
                    id: '1001459',
                    name: 'Drawstring'
                },
                {
                    id: '1001482',
                    name: 'Tie'
                },
                {
                    id: '1002660',
                    name: 'Button'
                },
                {
                    id: '1007450',
                    name: 'Pull On'
                }
            ],
            is_number: false
        },
        {
            id: '101175',
            name: 'Back Style',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003695',
                    name: 'Tie Back'
                },
                {
                    id: '1003711',
                    name: 'Bow Back'
                },
                {
                    id: '1003729',
                    name: 'Backless'
                },
                {
                    id: '1007453',
                    name: 'Strappy Back'
                },
                {
                    id: '1007454',
                    name: 'Racerback'
                },
                {
                    id: '1007455',
                    name: 'V Back'
                },
                {
                    id: '1007456',
                    name: 'Keyhole Back'
                },
                {
                    id: '1007457',
                    name: 'Lace Back'
                }
            ],
            is_number: false
        },
        {
            id: '101178',
            name: 'Collar Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003649',
                    name: 'Mandarin Collar'
                },
                {
                    id: '1007473',
                    name: 'Polo Collar'
                },
                {
                    id: '1019990',
                    name: 'Contrast Collar'
                },
                {
                    id: '1019997',
                    name: 'Baseball Collar'
                },
                {
                    id: '1019998',
                    name: 'Cardigan Collar'
                }
            ],
            is_number: false
        },
        {
            id: '100393',
            name: 'Neckline',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001133',
                    name: 'Halter Neck'
                },
                {
                    id: '1001129',
                    name: 'Mock Neck'
                },
                {
                    id: '1001136',
                    name: 'Scoop Neck'
                },
                {
                    id: '1001134',
                    name: 'Off Shoulder'
                },
                {
                    id: '1001128',
                    name: 'Boat Neck'
                },
                {
                    id: '1001135',
                    name: 'One Shoulder'
                },
                {
                    id: '1001130',
                    name: 'High Neck'
                },
                {
                    id: '1001137',
                    name: 'Sweetheart Neck'
                },
                {
                    id: '1001131',
                    name: 'Square Neck'
                },
                {
                    id: '1001132',
                    name: 'Collared Neck'
                },
                {
                    id: '1003660',
                    name: 'Tie Neck'
                },
                {
                    id: '1003642',
                    name: 'Cowl Neck'
                },
                {
                    id: '1003647',
                    name: 'Keyhole Neckline'
                },
                {
                    id: '1003640',
                    name: 'Asymmetrical Neck'
                },
                {
                    id: '1003650',
                    name: 'Notched'
                },
                {
                    id: '1003662',
                    name: 'U Neck'
                },
                {
                    id: '1003643',
                    name: 'Deep V Neck'
                },
                {
                    id: '1001260',
                    name: 'Bandeau'
                },
                {
                    id: '1019999',
                    name: 'Criss cross neck'
                },
                {
                    id: '1001127',
                    name: 'V-Neck'
                },
                {
                    id: '1001126',
                    name: 'Crew Neck'
                },
                {
                    id: '1003653',
                    name: 'Round Neck'
                }
            ],
            is_number: false
        },
        {
            id: '100701',
            name: 'Material',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001107',
                    name: 'Rayon'
                },
                {
                    id: '1001108',
                    name: 'Tencel'
                },
                {
                    id: '1001112',
                    name: 'Elastane1'
                },
                {
                    id: '1000039',
                    name: 'Cotton'
                },
                {
                    id: '1001109',
                    name: 'Cupro'
                },
                {
                    id: '1001113',
                    name: 'Modal'
                },
                {
                    id: '1001119',
                    name: 'Fleece'
                },
                {
                    id: '1000014',
                    name: 'Acrylic'
                },
                {
                    id: '1000013',
                    name: 'Linen'
                },
                {
                    id: '1000042',
                    name: 'Leather'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1000044',
                    name: 'Silk'
                },
                {
                    id: '1000045',
                    name: 'Wool'
                },
                {
                    id: '1001114',
                    name: 'Down'
                },
                {
                    id: '1001120',
                    name: 'Chiffon'
                },
                {
                    id: '1001110',
                    name: 'Mohair'
                },
                {
                    id: '1001115',
                    name: 'Faux Fur'
                },
                {
                    id: '1001121',
                    name: 'Knit'
                },
                {
                    id: '1000038',
                    name: 'Cashmere'
                },
                {
                    id: '1000015',
                    name: 'Nylon'
                },
                {
                    id: '1001116',
                    name: 'Corduroy'
                },
                {
                    id: '1001117',
                    name: 'Denim'
                },
                {
                    id: '1001118',
                    name: 'Felt'
                },
                {
                    id: '1003739',
                    name: 'Rib'
                },
                {
                    id: '1005699',
                    name: 'Crepe'
                },
                {
                    id: '1005715',
                    name: 'French Terry'
                },
                {
                    id: '1000918',
                    name: 'Metallic'
                },
                {
                    id: '1003756',
                    name: 'Mesh'
                },
                {
                    id: '1003738',
                    name: 'Satin'
                },
                {
                    id: '1003908',
                    name: 'Twill'
                },
                {
                    id: '1002347',
                    name: 'Velvet'
                },
                {
                    id: '1005910',
                    name: 'Jacquard'
                },
                {
                    id: '1003284',
                    name: 'Aluminum Alloy'
                },
                {
                    id: '1003758',
                    name: 'Shearling'
                },
                {
                    id: '1003759',
                    name: 'Tweed'
                },
                {
                    id: '1003900',
                    name: 'Polyamide'
                },
                {
                    id: '1005837',
                    name: 'Leather \u0026 Faux Leather Jackets'
                },
                {
                    id: '1005846',
                    name: 'Woven'
                },
                {
                    id: '1019322',
                    name: 'Jersey Spandex'
                },
                {
                    id: '1019324',
                    name: 'Textured Woven Fabric'
                },
                {
                    id: '1019975',
                    name: 'Wool-like Fabric'
                },
                {
                    id: '1019984',
                    name: 'Recycled Polyester Blends'
                },
                {
                    id: '1019985',
                    name: 'Recycle Polyester'
                }
            ],
            is_number: false
        },
        {
            id: '100149',
            name: 'Country of Origin',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000850',
                    name: 'China'
                },
                {
                    id: '1089295',
                    name: 'USA'
                }
            ],
            is_number: false
        },
        {
            id: '101761',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                },
                {
                    id: '1001138',
                    name: 'Short'
                }
            ],
            is_number: false
        }
    ],
    WOMEN_TOPS_HOODIES: [
        {
            id: '100198',
            name: 'Pattern',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001188',
                    name: 'Herringbone'
                },
                {
                    id: '1001189',
                    name: 'Moire'
                },
                {
                    id: '1001183',
                    name: 'Floral'
                },
                {
                    id: '1001190',
                    name: 'Paisley'
                },
                {
                    id: '1001185',
                    name: 'Plaid'
                },
                {
                    id: '1001192',
                    name: 'Tie Dye'
                },
                {
                    id: '1001184',
                    name: 'Striped'
                },
                {
                    id: '1001191',
                    name: 'Polka Dot'
                },
                {
                    id: '1001186',
                    name: 'Graphic'
                },
                {
                    id: '1001182',
                    name: 'Plain'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1003613',
                    name: 'Figure'
                },
                {
                    id: '1005051',
                    name: 'Camouflage'
                },
                {
                    id: '1003633',
                    name: 'Slogan'
                },
                {
                    id: '1003627',
                    name: 'Ombre'
                },
                {
                    id: '1003602',
                    name: 'Novelty Print'
                },
                {
                    id: '1003610',
                    name: 'Colorblock'
                },
                {
                    id: '1002395',
                    name: 'Plants'
                },
                {
                    id: '1003621',
                    name: 'Houndstooth'
                },
                {
                    id: '1003626',
                    name: 'Marble'
                },
                {
                    id: '1007943',
                    name: 'Digital Print'
                },
                {
                    id: '1001412',
                    name: 'Cartoon'
                },
                {
                    id: '1003605',
                    name: 'Camo'
                },
                {
                    id: '1003607',
                    name: 'Chain Print'
                },
                {
                    id: '1003612',
                    name: 'Ditsy Floral'
                },
                {
                    id: '1003614',
                    name: 'Fire'
                },
                {
                    id: '1003617',
                    name: 'Galaxy'
                },
                {
                    id: '1003618',
                    name: 'Geometric'
                },
                {
                    id: '1003622',
                    name: 'Landscape Print'
                },
                {
                    id: '1003629',
                    name: 'Pop Art Print'
                },
                {
                    id: '1003630',
                    name: 'Rainbow Stripe'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003727',
                    name: 'Feather'
                },
                {
                    id: '1004311',
                    name: 'Letters'
                },
                {
                    id: '1019965',
                    name: 'Flag'
                },
                {
                    id: '1019966',
                    name: 'Waffle Knit'
                },
                {
                    id: '1019967',
                    name: 'Cable-knit'
                }
            ],
            is_number: false
        },
        {
            id: '100392',
            name: 'Occasion',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001123',
                    name: 'Wedding'
                },
                {
                    id: '1001122',
                    name: 'Business'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1001125',
                    name: 'Evening'
                },
                {
                    id: '1001335',
                    name: 'Indoors'
                },
                {
                    id: '1001336',
                    name: 'Outdoors'
                },
                {
                    id: '1002665',
                    name: 'Party'
                }
            ],
            is_number: false
        },
        {
            id: '100393',
            name: 'Neckline',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001135',
                    name: 'One Shoulder'
                },
                {
                    id: '1001129',
                    name: 'Mock Neck'
                },
                {
                    id: '1001136',
                    name: 'Scoop Neck'
                },
                {
                    id: '1001137',
                    name: 'Sweetheart Neck'
                },
                {
                    id: '1001132',
                    name: 'Collared Neck'
                },
                {
                    id: '1001131',
                    name: 'Square Neck'
                },
                {
                    id: '1001133',
                    name: 'Halter Neck'
                },
                {
                    id: '1001127',
                    name: 'V-Neck'
                },
                {
                    id: '1001134',
                    name: 'Off Shoulder'
                },
                {
                    id: '1003660',
                    name: 'Tie Neck'
                },
                {
                    id: '1003642',
                    name: 'Cowl Neck'
                },
                {
                    id: '1003647',
                    name: 'Keyhole Neckline'
                },
                {
                    id: '1003640',
                    name: 'Asymmetrical Neck'
                },
                {
                    id: '1003650',
                    name: 'Notched'
                },
                {
                    id: '1003662',
                    name: 'U Neck'
                },
                {
                    id: '1003643',
                    name: 'Deep V Neck'
                },
                {
                    id: '1001260',
                    name: 'Bandeau'
                },
                {
                    id: '1019999',
                    name: 'Criss cross neck'
                },
                {
                    id: '1001126',
                    name: 'Crew Neck'
                },
                {
                    id: '1001128',
                    name: 'Boat Neck'
                },
                {
                    id: '1001130',
                    name: 'High Neck'
                },
                {
                    id: '1135173',
                    name: 'Choker Neck'
                },
                {
                    id: '1135174',
                    name: 'Hooded Neck'
                }
            ],
            is_number: false
        },
        {
            id: '100394',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1001138',
                    name: 'Short'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                }
            ],
            is_number: false
        },
        {
            id: '100395',
            name: 'Sleeve Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001141',
                    name: 'Short Sleeve'
                },
                {
                    id: '1001143',
                    name: '3/4 Sleeve'
                },
                {
                    id: '1001142',
                    name: 'Half Sleeve'
                },
                {
                    id: '1001144',
                    name: 'Long Sleeve'
                },
                {
                    id: '1003673',
                    name: 'Extra-Long Sleeve'
                }
            ],
            is_number: false
        },
        {
            id: '100397',
            name: 'Season',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001162',
                    name: 'Summer'
                },
                {
                    id: '1000905',
                    name: 'Fall'
                },
                {
                    id: '1001161',
                    name: 'Spring'
                },
                {
                    id: '1001163',
                    name: 'Winter'
                },
                {
                    id: '1005840',
                    name: 'All Seasons'
                }
            ],
            is_number: false
        },
        {
            id: '100398',
            name: 'Style',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001164',
                    name: 'Athletic'
                },
                {
                    id: '1001171',
                    name: 'Cute'
                },
                {
                    id: '1001178',
                    name: 'Vacation'
                },
                {
                    id: '1001165',
                    name: 'Basic'
                },
                {
                    id: '1001172',
                    name: 'Minimalist'
                },
                {
                    id: '1001166',
                    name: 'Boho'
                },
                {
                    id: '1001173',
                    name: 'Preppy'
                },
                {
                    id: '1001168',
                    name: 'Elegant'
                },
                {
                    id: '1001175',
                    name: 'Sexy'
                },
                {
                    id: '1001167',
                    name: 'Chic'
                },
                {
                    id: '1001174',
                    name: 'Retro'
                },
                {
                    id: '1001169',
                    name: 'Japanese'
                },
                {
                    id: '1001176',
                    name: 'Street'
                },
                {
                    id: '1001170',
                    name: 'Korean'
                },
                {
                    id: '1001177',
                    name: 'Sweet'
                },
                {
                    id: '1005406',
                    name: 'Office Lady'
                },
                {
                    id: '1003597',
                    name: 'Modest'
                },
                {
                    id: '1002665',
                    name: 'Party'
                },
                {
                    id: '1003594',
                    name: 'Basics'
                },
                {
                    id: '1019938',
                    name: 'Mature'
                },
                {
                    id: '1000520',
                    name: 'Sports'
                },
                {
                    id: '1019943',
                    name: 'Y2K'
                },
                {
                    id: '1001124',
                    name: 'Casual'
                },
                {
                    id: '1003600',
                    name: 'Vintage'
                },
                {
                    id: '1001524',
                    name: 'Fashion'
                },
                {
                    id: '1003601',
                    name: 'Vacation'
                },
                {
                    id: '1003636',
                    name: 'Tribal'
                },
                {
                    id: '1003675',
                    name: 'Draped'
                },
                {
                    id: '1004424',
                    name: 'Classic'
                },
                {
                    id: '1004438',
                    name: 'Romance'
                },
                {
                    id: '1019936',
                    name: 'Gothic'
                },
                {
                    id: '1019937',
                    name: 'Work'
                },
                {
                    id: '1019940',
                    name: 'Biker'
                },
                {
                    id: '1019941',
                    name: 'Bomber'
                },
                {
                    id: '1019945',
                    name: 'Big \u0026 Tall'
                }
            ],
            is_number: false
        },
        {
            id: '100399',
            name: 'Fit',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1001179',
                    name: 'Slim Fit'
                },
                {
                    id: '1001180',
                    name: 'Fitted'
                },
                {
                    id: '1001181',
                    name: 'Loose Fit'
                },
                {
                    id: '1135169',
                    name: 'Athletic-Fitting'
                }
            ],
            is_number: false
        },
        {
            id: '100401',
            name: 'Care Instructions',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001196',
                    name: 'Dry Clean'
                },
                {
                    id: '1001197',
                    name: 'Do Not Wash'
                },
                {
                    id: '1001198',
                    name: 'Do Not Dry Clean'
                },
                {
                    id: '1001200',
                    name: 'Cold Wash'
                },
                {
                    id: '1001199',
                    name: 'Machine Washable'
                },
                {
                    id: '1001195',
                    name: 'Hand Wash'
                }
            ],
            is_number: false
        },
        {
            id: '100406',
            name: 'Embellishment',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001224',
                    name: 'Bow'
                },
                {
                    id: '1001228',
                    name: 'Fringe'
                },
                {
                    id: '1001230',
                    name: 'Rhinestones'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1001187',
                    name: 'Embroidered'
                },
                {
                    id: '1001225',
                    name: 'Beads'
                },
                {
                    id: '1001227',
                    name: 'Feathers'
                },
                {
                    id: '1001226',
                    name: 'Buttons'
                },
                {
                    id: '1001229',
                    name: 'Piping'
                },
                {
                    id: '1001231',
                    name: 'Sequins'
                },
                {
                    id: '1003705',
                    name: 'Chain'
                },
                {
                    id: '1003830',
                    name: 'Texture Metal'
                },
                {
                    id: '1003604',
                    name: 'Butterfly'
                },
                {
                    id: '1003682',
                    name: 'Belted'
                },
                {
                    id: '1003686',
                    name: 'Knot'
                },
                {
                    id: '1003685',
                    name: 'Faux Pearls'
                },
                {
                    id: '1001402',
                    name: 'Ring'
                },
                {
                    id: '1003689',
                    name: 'Beaded'
                },
                {
                    id: '1003700',
                    name: 'Fake Pockets'
                },
                {
                    id: '1003724',
                    name: 'Pom Pom'
                },
                {
                    id: '1019996',
                    name: 'Fake Drawstring'
                }
            ],
            is_number: false
        },
        {
            id: '100409',
            name: 'Clothing Styles',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1007466',
                    name: 'Full Zip Sweatshirt'
                },
                {
                    id: '1007447',
                    name: 'Sweatshirt'
                },
                {
                    id: '1007467',
                    name: 'Half Zip Sweatshirt'
                },
                {
                    id: '1007463',
                    name: 'Pullover Hoodie'
                },
                {
                    id: '1007465',
                    name: 'Half Zip Hoodie'
                },
                {
                    id: '1007464',
                    name: 'Full Zip Hoodie'
                }
            ],
            is_number: false
        },
        {
            id: '100443',
            name: 'Feature',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1019989',
                    name: 'Creux'
                },
                {
                    id: '1000330',
                    name: 'Waterproof'
                },
                {
                    id: '1000935',
                    name: 'Multi-Function'
                },
                {
                    id: '1001438',
                    name: 'Expandable'
                },
                {
                    id: '1001439',
                    name: 'Lightweight'
                },
                {
                    id: '1002263',
                    name: 'Foldable'
                },
                {
                    id: '1008073',
                    name: 'Multi-Compartment'
                },
                {
                    id: '1008074',
                    name: 'RFID'
                },
                {
                    id: '1008076',
                    name: 'Hangable'
                }
            ],
            is_number: false
        },
        {
            id: '100701',
            name: 'Material',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000014',
                    name: 'Acrylic'
                },
                {
                    id: '1000013',
                    name: 'Linen'
                },
                {
                    id: '1000042',
                    name: 'Leather'
                },
                {
                    id: '1000041',
                    name: 'Lace'
                },
                {
                    id: '1000039',
                    name: 'Cotton'
                },
                {
                    id: '1000038',
                    name: 'Cashmere'
                },
                {
                    id: '1000015',
                    name: 'Nylon'
                },
                {
                    id: '1001116',
                    name: 'Corduroy'
                },
                {
                    id: '1001107',
                    name: 'Rayon'
                },
                {
                    id: '1001117',
                    name: 'Denim'
                },
                {
                    id: '1001109',
                    name: 'Cupro'
                },
                {
                    id: '1001113',
                    name: 'Modal'
                },
                {
                    id: '1001119',
                    name: 'Fleece'
                },
                {
                    id: '1001108',
                    name: 'Tencel'
                },
                {
                    id: '1001112',
                    name: 'Elastane1'
                },
                {
                    id: '1001118',
                    name: 'Felt'
                },
                {
                    id: '1000044',
                    name: 'Silk'
                },
                {
                    id: '1000045',
                    name: 'Wool'
                },
                {
                    id: '1001114',
                    name: 'Down'
                },
                {
                    id: '1001120',
                    name: 'Chiffon'
                },
                {
                    id: '1001110',
                    name: 'Mohair'
                },
                {
                    id: '1001115',
                    name: 'Faux Fur'
                },
                {
                    id: '1001121',
                    name: 'Knit'
                },
                {
                    id: '1003739',
                    name: 'Rib'
                },
                {
                    id: '1005699',
                    name: 'Crepe'
                },
                {
                    id: '1002347',
                    name: 'Velvet'
                },
                {
                    id: '1005715',
                    name: 'French Terry'
                },
                {
                    id: '1000918',
                    name: 'Metallic'
                },
                {
                    id: '1003756',
                    name: 'Mesh'
                },
                {
                    id: '1003738',
                    name: 'Satin'
                },
                {
                    id: '1003908',
                    name: 'Twill'
                },
                {
                    id: '1005910',
                    name: 'Jacquard'
                },
                {
                    id: '1019322',
                    name: 'Jersey Spandex'
                },
                {
                    id: '1019985',
                    name: 'Recycle Polyester'
                },
                {
                    id: '1019324',
                    name: 'Textured Woven Fabric'
                },
                {
                    id: '1003284',
                    name: 'Aluminum Alloy'
                },
                {
                    id: '1003758',
                    name: 'Shearling'
                },
                {
                    id: '1003759',
                    name: 'Tweed'
                },
                {
                    id: '1003900',
                    name: 'Polyamide'
                },
                {
                    id: '1005837',
                    name: 'Leather \u0026 Faux Leather Jackets'
                },
                {
                    id: '1005846',
                    name: 'Woven'
                },
                {
                    id: '1019975',
                    name: 'Wool-like Fabric'
                },
                {
                    id: '1019984',
                    name: 'Recycled Polyester Blends'
                }
            ],
            is_number: false
        },
        {
            id: '100757',
            name: 'Design',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003688',
                    name: 'Split'
                },
                {
                    id: '1003712',
                    name: 'Contrast Binding'
                },
                {
                    id: '1003713',
                    name: 'Criss Cross'
                },
                {
                    id: '1003684',
                    name: 'Twist'
                },
                {
                    id: '1003702',
                    name: 'Ripped'
                },
                {
                    id: '1003897',
                    name: 'Adjustable Strap'
                },
                {
                    id: '1003691',
                    name: 'Sheer'
                },
                {
                    id: '1003681',
                    name: 'Ruffle'
                },
                {
                    id: '1003721',
                    name: 'Pleated'
                },
                {
                    id: '1003837',
                    name: 'Crochet'
                },
                {
                    id: '1003699',
                    name: 'Asymmetrical'
                },
                {
                    id: '1003678',
                    name: 'Contrast Lace'
                },
                {
                    id: '1003706',
                    name: 'Raw Hem'
                },
                {
                    id: '1003707',
                    name: 'Shirred'
                },
                {
                    id: '1003692',
                    name: 'Lettuce Trim'
                },
                {
                    id: '1003698',
                    name: 'Frill'
                },
                {
                    id: '1003704',
                    name: 'Washed'
                },
                {
                    id: '1003715',
                    name: 'Tiered Layer'
                },
                {
                    id: '1003676',
                    name: 'Button Front'
                },
                {
                    id: '1003687',
                    name: 'Scallop'
                },
                {
                    id: '1003703',
                    name: 'Tie Front'
                },
                {
                    id: '1003718',
                    name: 'Contrast Sequin'
                },
                {
                    id: '1003719',
                    name: 'Raw Wash'
                },
                {
                    id: '1003725',
                    name: 'Contrast Faux Fur'
                },
                {
                    id: '1019992',
                    name: 'Cross'
                },
                {
                    id: '1019995',
                    name: 'Quilted'
                }
            ],
            is_number: false
        },
        {
            id: '100898',
            name: 'Holiday',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1004734',
                    name: 'Childrens Day'
                },
                {
                    id: '1004735',
                    name: 'Chinese New Year'
                },
                {
                    id: '1003609',
                    name: 'Christmas'
                },
                {
                    id: '1004736',
                    name: 'Easter'
                },
                {
                    id: '1003620',
                    name: 'Halloween'
                },
                {
                    id: '1004737',
                    name: 'Mid-Autumn Festival'
                },
                {
                    id: '1004738',
                    name: 'New Year'
                },
                {
                    id: '1004739',
                    name: 'Nyepi'
                },
                {
                    id: '1004740',
                    name: 'Rambu Solo'
                },
                {
                    id: '1004741',
                    name: 'Songkran'
                },
                {
                    id: '1004742',
                    name: 'Thanksgiving'
                },
                {
                    id: '1004743',
                    name: 'Valentines Day'
                }
            ],
            is_number: false
        },
        {
            id: '101127',
            name: 'Size Type',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: true,
                legal_property: false
            },
            values: [
                {
                    id: '1002679',
                    name: 'Petite'
                },
                {
                    id: '1005904',
                    name: 'Regular'
                },
                {
                    id: '1002680',
                    name: 'Plus Size'
                },
                {
                    id: '1006946',
                    name: 'Juniors'
                }
            ],
            is_number: false
        },
        {
            id: '101172',
            name: 'Closure Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001323',
                    name: 'Buckle'
                },
                {
                    id: '1002660',
                    name: 'Button'
                },
                {
                    id: '1001459',
                    name: 'Drawstring'
                },
                {
                    id: '1007452',
                    name: 'Hook And Bar'
                },
                {
                    id: '1007451',
                    name: 'Hook And Eye'
                },
                {
                    id: '1005816',
                    name: 'Hook And Loop'
                },
                {
                    id: '1003690',
                    name: 'Lace Up'
                },
                {
                    id: '1001891',
                    name: 'Magnetic'
                },
                {
                    id: '1007450',
                    name: 'Pull On'
                },
                {
                    id: '1005819',
                    name: 'Snap'
                },
                {
                    id: '1001482',
                    name: 'Tie'
                },
                {
                    id: '1001491',
                    name: 'Toggle'
                },
                {
                    id: '1001319',
                    name: 'Zip'
                }
            ],
            is_number: false
        },
        {
            id: '101175',
            name: 'Back Style',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1003695',
                    name: 'Tie Back'
                },
                {
                    id: '1003711',
                    name: 'Bow Back'
                },
                {
                    id: '1003729',
                    name: 'Backless'
                },
                {
                    id: '1007453',
                    name: 'Strappy Back'
                },
                {
                    id: '1007454',
                    name: 'Racerback'
                },
                {
                    id: '1007455',
                    name: 'V Back'
                },
                {
                    id: '1007456',
                    name: 'Keyhole Back'
                },
                {
                    id: '1007457',
                    name: 'Lace Back'
                }
            ],
            is_number: false
        },
        {
            id: '101178',
            name: 'Collar Type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1007470',
                    name: 'Bib Collar'
                },
                {
                    id: '1007468',
                    name: 'Chelsea Collar'
                },
                {
                    id: '1019990',
                    name: 'Contrast Collar'
                },
                {
                    id: '1007472',
                    name: 'Funnel Collar'
                },
                {
                    id: '1007475',
                    name: 'Gothic Pointed Collar'
                },
                {
                    id: '1007469',
                    name: 'Jabot Collar'
                },
                {
                    id: '1003648',
                    name: 'Lapel'
                },
                {
                    id: '1003649',
                    name: 'Mandarin Collar'
                },
                {
                    id: '1003652',
                    name: 'Peter Pan Collar'
                },
                {
                    id: '1007473',
                    name: 'Polo Collar'
                },
                {
                    id: '1007474',
                    name: 'Roll Collar'
                },
                {
                    id: '1003655',
                    name: 'Shawl Collar'
                },
                {
                    id: '1003657',
                    name: 'Stand Collar'
                },
                {
                    id: '1007471',
                    name: 'Swallow Collar'
                },
                {
                    id: '1019997',
                    name: 'Baseball Collar'
                },
                {
                    id: '1019998',
                    name: 'Cardigan Collar'
                }
            ],
            is_number: false
        },
        {
            id: '100149',
            name: 'Country of Origin',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1000850',
                    name: 'China'
                },
                {
                    id: '1089295',
                    name: 'USA'
                }
            ],
            is_number: false
        },
        {
            id: '101764',
            name: 'Pocket Description',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1135147',
                    name: 'Cargo Pocket'
                },
                {
                    id: '1021315',
                    name: 'Flap Pocket'
                },
                {
                    id: '1135171',
                    name: 'Kangaroo Pocket'
                },
                {
                    id: '1135172',
                    name: 'Patch Pocket'
                },
                {
                    id: '1135145',
                    name: 'Slant Pocket'
                }
            ],
            is_number: false
        },
        {
            id: '101761',
            name: 'Top Length',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: false
            },
            values: [
                {
                    id: '1001139',
                    name: '3/4 Length'
                },
                {
                    id: '1001140',
                    name: 'Long'
                },
                {
                    id: '1000907',
                    name: 'Medium'
                },
                {
                    id: '1001138',
                    name: 'Short'
                }
            ],
            is_number: false
        }
    ]
}

export const BRANDS = [
    {
        id: '7184176719711028997',
        name: 'GILDAN',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7265141763801646854',
        name: '\u0008Gozen',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7231150025261319941',
        name: '\u0008OCOP HUYỆN LAI VUNG',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7238550132806174469',
        name: '"1K" [satu keluarga]',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7404697919097177861',
        name: '"Bebek Goreng Rempah Mbah Tompo" "Gurihnya sampe ketulang tulangnya"',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7402797649226893061',
        name: '"IMNOTAMORNINGPERSON"',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7342444266138027781',
        name: '#1 All Systems',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7111595400213071622',
        name: '#arjdbb',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7380161407467063045',
        name: '#DABOBOX',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7280812821032732421',
        name: '#EverydayHustle',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7158094830659340038',
        name: '#ohmymats',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7318282795423057669',
        name: '#STANLEYNESS',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7389895792448030469',
        name: '#Z. BEAUTY',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7345632091196999430',
        name: '\u0026 1',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7348860646622021382',
        name: '\u0026 2024',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7343255300599760646',
        name: '\u0026 arisgame',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7313916609314473733',
        name: '\u0026 aully',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7349528323313125126',
        name: '\u0026 baju',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7322515776202819333',
        name: '\u0026 Be Sweet Hair',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7344319407962801926',
        name: '\u0026 BELUM',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7311537967741568773',
        name: '\u0026 Berkat',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7347216618973497093',
        name: "\u0026 Bird's Custard",
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7334558460467758853',
        name: '\u0026 body washer',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7332221211633960709',
        name: '\u0026 Carolina Creations',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7329992820297926405',
        name: '\u0026 Freedom Tees',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7325013497153603333',
        name: '\u0026 Giangmatna',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7321343140336748293',
        name: '\u0026 kensy',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7323153731556656901',
        name: '\u0026 konco',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338354037013808902',
        name: '\u0026 Kxuhivc',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7325686650565265158',
        name: '\u0026 LIFEZIG',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7327915111136970501',
        name: '\u0026 lnjklkn',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7316127686261556998',
        name: '\u0026 mademix',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340869543600047877',
        name: '\u0026 Microlab',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340001469615343365',
        name: '\u0026 nazmi fashion',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7332076753810114309',
        name: '\u0026 PreciousCollection',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7320798563552790278',
        name: '\u0026 Preggoplush',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7326105039094040325',
        name: '\u0026 rakyat',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7333094561478362885',
        name: '\u0026 Skorpio',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7331121324900927238',
        name: '\u0026 Snacks Therapy',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7351594550936946437',
        name: '\u0026 snapy',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340440527516763910',
        name: '\u0026 TYYPJJ',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340436722687837958',
        name: '\u0026 TYYYUG',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340845919524357894',
        name: '\u0026 Vertac',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7311309463130736389',
        name: '\u0026 vhane',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7342778599096944389',
        name: '\u0026 YBUETE',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7324632722134730502',
        name: '\u0026 Zelscentfashion',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7327904300542134022',
        name: '\u0026\u0026chvjjn',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7326419501136873221',
        name: '\u002600',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7343199507951257350',
        name: '\u002612',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7337924616777729797',
        name: '\u0026168',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7336936537399215878',
        name: '\u00261688',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7323615462322226949',
        name: '\u00261977',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7339744572689745670',
        name: '\u00261993',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338259214445774598',
        name: '\u00261994',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7330276141854951174',
        name: '\u00261999',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7337705805854410502',
        name: '\u00262001',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340985225618196230',
        name: '\u00262003',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7335215027735578374',
        name: '\u00262023',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7277538965094237957',
        name: '\u00263158',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338433270818686726',
        name: '\u0026606',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7340894067913590533',
        name: '\u00266999',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7327145182971971334',
        name: '\u0026abstrak',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7315199486807312133',
        name: '\u0026achlim',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7312747670095382278',
        name: '\u0026aircondition',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7329088421899798277',
        name: '\u0026Alltripal',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7312355718672353030',
        name: '\u0026ALTHAF',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7313615647143462661',
        name: '\u0026AMPD',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7335684012839028485',
        name: '\u0026Andy Tauer',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7305682585894848262',
        name: '\u0026AQ',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338661438887757574',
        name: '\u0026Arbenefe',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7316072203890640645',
        name: '\u0026Armorfusion',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7303354589796550405',
        name: '\u0026Artistrybazaar',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7319525380224780038',
        name: '\u0026ASTY',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7300069792474777350',
        name: '\u0026autorock',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7310559871429363461',
        name: '\u0026Ayuan',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7322066645300365062',
        name: '\u0026AYULU',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7334692202301687557',
        name: '\u0026AzielClo.',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7314678229565572869',
        name: '\u0026BABAOFARM',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7312818980203742982',
        name: '\u0026BABYB',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7337314688793298693',
        name: '\u0026BabyNassal',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7312966696866973446',
        name: '\u0026baksosony',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7311621431335094021',
        name: '\u0026BANCETCOLLETION',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7307850011516241670',
        name: '\u0026baotaybaracuda',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7311343119500314373',
        name: '\u0026batik bagan',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7267019421540419333',
        name: '\u0026BAYLIS',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7306051056071804677',
        name: '\u0026bbb',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7300866797426837253',
        name: '\u0026bellhome',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7322309271655679749',
        name: '\u0026beltbag',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7311910696126924549',
        name: '\u0026Biasstore',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7341017710128629510',
        name: '\u0026Binoteck',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338329068913411846',
        name: '\u0026BiO 08',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7277940080407480069',
        name: '\u0026blessery',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7320717782138750726',
        name: '\u0026BnCH',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7338657549627148038',
        name: '\u0026Bntuk',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7323848540680570629',
        name: '\u0026BongBrand',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7235284621292914437',
        name: '\u0026bunya brand',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7313820812015748870',
        name: '\u0026cantikbysusant',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7312608287076189958',
        name: '\u0026Cariva',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7329373687588030214',
        name: '\u0026cCOQWFJ',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    },
    {
        id: '7313953307482228486',
        name: '\u0026changkel',
        is_authorized: false,
        is_comply_nice_classification: false,
        is_t1_brand: false,
        is_standard: true,
        is_key_property: false
    }
]

export const PRODUCT_COMPLIANCE = {
    MEN_SLEEVELESS_TSHIRT: [
        {
            id: '101395',
            name: 'CA Prop 65: Repro. Chems',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601226_101395',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101398',
            name: 'Reprotoxic Chemicals',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022353',
                    name: 'Abiraterone acetate'
                },
                {
                    id: '1022354',
                    name: 'Acetazolamide'
                },
                {
                    id: '1022355',
                    name: 'Acetohydroxamic acid'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022357',
                    name: 'Actinomycin D'
                },
                {
                    id: '1022358',
                    name: 'All-trans retinoic acid'
                },
                {
                    id: '1022359',
                    name: 'Alprazolam'
                },
                {
                    id: '1022360',
                    name: 'Altretamine'
                },
                {
                    id: '1022361',
                    name: 'Amantadine hydrochloride'
                },
                {
                    id: '1022362',
                    name: 'Amikacin sulfate'
                },
                {
                    id: '1022363',
                    name: 'Aminoglutethimide'
                },
                {
                    id: '1022364',
                    name: 'Aminoglycosides'
                },
                {
                    id: '1022365',
                    name: 'Aminopterin'
                },
                {
                    id: '1022366',
                    name: 'Amiodarone hydrochloride'
                },
                {
                    id: '1022367',
                    name: 'Amitraz'
                },
                {
                    id: '1022368',
                    name: 'Amoxapine'
                },
                {
                    id: '1022369',
                    name: 'Anabolic steroids'
                },
                {
                    id: '1022370',
                    name: 'Angiotensin converting enzyme (ACE) inhibitors'
                },
                {
                    id: '1022371',
                    name: 'Anisindione'
                },
                {
                    id: '1022372',
                    name: 'Arsenic (inorganic oxides)'
                },
                {
                    id: '1022610',
                    name: 'Aspirin'
                },
                {
                    id: '1022373',
                    name: 'Atenolol'
                },
                {
                    id: '1022374',
                    name: 'Atrazine'
                },
                {
                    id: '1022375',
                    name: 'Auranofin'
                },
                {
                    id: '1022376',
                    name: 'Avermectin B1 (Abamectin)'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1022377',
                    name: 'Barbiturates'
                },
                {
                    id: '1022378',
                    name: 'Beclomethasone dipropionate'
                },
                {
                    id: '1022379',
                    name: 'Benomyl'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1022380',
                    name: 'Benzodiazepines'
                },
                {
                    id: '1022381',
                    name: 'Benzphetamine hydrochloride'
                },
                {
                    id: '1022382',
                    name: 'Bevacizumab'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1022383',
                    name: 'Bisphenol A (BPA)'
                },
                {
                    id: '1022384',
                    name: 'Bromacil lithium salt'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1022385',
                    name: 'Bromoxynil'
                },
                {
                    id: '1022386',
                    name: 'Bromoxynil octanoate'
                },
                {
                    id: '1022387',
                    name: 'Butabarbital sodium'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1022388',
                    name: 'Butyl benzyl phthalate (BBP)d'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1022389',
                    name: 'Cannabis (marijuana) smoke'
                },
                {
                    id: '1022390',
                    name: 'Carbamazepine'
                },
                {
                    id: '1022391',
                    name: 'Carbaryl [Basis for listing changed effective December 27, 2013]'
                },
                {
                    id: '1022392',
                    name: 'Carbon disulfide'
                },
                {
                    id: '1022393',
                    name: 'Carbon monoxide'
                },
                {
                    id: '1022394',
                    name: 'Carboplatin'
                },
                {
                    id: '1022395',
                    name: 'Chenodiol'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1022396',
                    name: 'Chlorcyclizine hydrochloride'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1022397',
                    name: 'Chlordiazepoxide'
                },
                {
                    id: '1022398',
                    name: 'Chlordiazepoxide hydrochloride'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1022399',
                    name: 'Chloroform [Basis for listing changed effective December 23, 2016]'
                },
                {
                    id: '1022400',
                    name: '2-Chloropropionic acid [Basis for listing changed effective December 20, 2013]'
                },
                {
                    id: '1022401',
                    name: 'Chlorpyrifos'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1022402',
                    name: 'Cladribine'
                },
                {
                    id: '1022403',
                    name: 'Clarithromycin'
                },
                {
                    id: '1022404',
                    name: 'Clobetasol propionate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1022405',
                    name: 'Clorazepate dipotassium'
                },
                {
                    id: '1022406',
                    name: 'Cocaine'
                },
                {
                    id: '1022407',
                    name: 'Codeine phosphate'
                },
                {
                    id: '1022408',
                    name: 'Colchicine'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1022409',
                    name: 'Cyanazine'
                },
                {
                    id: '1022410',
                    name: 'Cycloate'
                },
                {
                    id: '1022411',
                    name: 'Cycloheximide'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1022412',
                    name: 'Cyhexatin'
                },
                {
                    id: '1022413',
                    name: 'Cytarabine'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1022414',
                    name: 'Danazol'
                },
                {
                    id: '1022415',
                    name: 'Daunorubicin hydrochloride'
                },
                {
                    id: '1022416',
                    name: '2,4-D butyric acid'
                },
                {
                    id: '1022417',
                    name: "o,p'-DDT"
                },
                {
                    id: '1022418',
                    name: "p,p'-DDT"
                },
                {
                    id: '1022419',
                    name: 'Des-ethyl atrazine (DEA)'
                },
                {
                    id: '1022420',
                    name: 'Des-isopropyl atrazine (DIA)'
                },
                {
                    id: '1022421',
                    name: '2,4-Diamino-6-chloro-s-triazine (DACT)'
                },
                {
                    id: '1022422',
                    name: 'Diazepam'
                },
                {
                    id: '1022423',
                    name: 'Diazoxide'
                },
                {
                    id: '1022424',
                    name: '1,2-Dibromo-3-chloropropane (DBCP) [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1022425',
                    name: '1,1-Dichloro-2,2-bis(p-chloropheny)ethylene (DDE)'
                },
                {
                    id: '1022426',
                    name: 'Dichlorophene'
                },
                {
                    id: '1022427',
                    name: 'Dichlorphenamide'
                },
                {
                    id: '1022428',
                    name: 'Diclofop methyl'
                },
                {
                    id: '1022429',
                    name: 'Dicumarol'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1022430',
                    name: 'Diflunisal'
                },
                {
                    id: '1022431',
                    name: 'Dihydroergotamine mesylate'
                },
                {
                    id: '1022432',
                    name: 'Di-isodecyl phthalate (DIDP)'
                },
                {
                    id: '1022433',
                    name: 'Diltiazem hydrochloride'
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1022434',
                    name: 'Di-n-butyl phthalate (DBP)'
                },
                {
                    id: '1022435',
                    name: 'Di-n-hexyl phthalate (DnHP)'
                },
                {
                    id: '1022436',
                    name: 'm-Dinitrobenzene'
                },
                {
                    id: '1022437',
                    name: 'o-Dinitrobenzene'
                },
                {
                    id: '1022438',
                    name: 'p-Dinitrobenzene'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022439',
                    name: 'Dinitrotoluene (technical grade)'
                },
                {
                    id: '1022440',
                    name: 'Dinocap'
                },
                {
                    id: '1022441',
                    name: 'Dinoseb'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022442',
                    name: 'Disodium cyanodithioimidocarbonate'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022443',
                    name: 'Endrin'
                },
                {
                    id: '1022444',
                    name: 'Environmental tobacco smoke (ETS)'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022445',
                    name: 'Ergotamine tartrate'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022446',
                    name: 'Ethionamide'
                },
                {
                    id: '1022447',
                    name: 'Ethyl alcohol in alcoholic beverages'
                },
                {
                    id: '1022448',
                    name: 'Ethyl dipropylthiocarbamate'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022449',
                    name: 'Ethylene glycol (ingested)'
                },
                {
                    id: '1022450',
                    name: 'Ethylene glycol monoethyl ether'
                },
                {
                    id: '1022451',
                    name: 'Ethylene glycol monoethyl ether acetate'
                },
                {
                    id: '1022452',
                    name: 'Ethylene glycol monomethyl ether'
                },
                {
                    id: '1022453',
                    name: 'Ethylene glycol monomethyl ether acetate'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022454',
                    name: 'Etodolac'
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022455',
                    name: 'Etretinate'
                },
                {
                    id: '1022456',
                    name: 'Fenoxaprop ethyl'
                },
                {
                    id: '1022457',
                    name: 'Filgrastim'
                },
                {
                    id: '1022458',
                    name: 'Fluazifop butyl'
                },
                {
                    id: '1022459',
                    name: 'Flunisolide'
                },
                {
                    id: '1022460',
                    name: 'Fluorouracil'
                },
                {
                    id: '1022461',
                    name: 'Fluoxymesterone'
                },
                {
                    id: '1022462',
                    name: 'Flurazepam hydrochloride'
                },
                {
                    id: '1022463',
                    name: 'Flurbiprofen'
                },
                {
                    id: '1022464',
                    name: 'Flutamide'
                },
                {
                    id: '1022465',
                    name: 'Fluticasone propionate'
                },
                {
                    id: '1022466',
                    name: 'Fluvalinate'
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1022467',
                    name: 'Ganciclovir sodium'
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1022468',
                    name: 'Goserelin acetate'
                },
                {
                    id: '1022469',
                    name: 'Halazepam'
                },
                {
                    id: '1022470',
                    name: 'Halobetasol propionate'
                },
                {
                    id: '1022471',
                    name: 'Haloperidol'
                },
                {
                    id: '1022472',
                    name: 'Halothane'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1022473',
                    name: 'Hexafluoroacetone [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1022474',
                    name: 'n-Hexane'
                },
                {
                    id: '1022475',
                    name: '2,5-Hexanedione'
                },
                {
                    id: '1022476',
                    name: 'Histrelin acetate'
                },
                {
                    id: '1022477',
                    name: 'Hydramethylnon'
                },
                {
                    id: '1022478',
                    name: 'Hydrogen cyanide (HCN) and cyanide salts (CN salts)'
                },
                {
                    id: '1022479',
                    name: 'Cyanide salts that readily dissociate in solution (expressed as cyanide)f'
                },
                {
                    id: '1022480',
                    name: 'Hydrogen cyanidef'
                },
                {
                    id: '1022481',
                    name: 'Sodium cyanidef'
                },
                {
                    id: '1022482',
                    name: 'Potassium cyanidef'
                },
                {
                    id: '1022483',
                    name: 'Hydroxyurea'
                },
                {
                    id: '1022484',
                    name: 'Idarubicin hydrochloride'
                },
                {
                    id: '1022485',
                    name: 'Ifosfamide'
                },
                {
                    id: '1022486',
                    name: 'Iodine-131'
                },
                {
                    id: '1022487',
                    name: 'Isotretinoin'
                },
                {
                    id: '1022488',
                    name: 'Lead [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1022489',
                    name: 'Leuprolide acetate'
                },
                {
                    id: '1022490',
                    name: 'Levodopa'
                },
                {
                    id: '1022491',
                    name: 'Levonorgestrel implants'
                },
                {
                    id: '1022492',
                    name: 'Linuron'
                },
                {
                    id: '1022493',
                    name: 'Lithium carbonate'
                },
                {
                    id: '1022494',
                    name: 'Lithium citrate'
                },
                {
                    id: '1022495',
                    name: 'Lorazepam'
                },
                {
                    id: '1022496',
                    name: 'Lovastatin'
                },
                {
                    id: '1022497',
                    name: 'Mebendazole'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1022498',
                    name: 'Menotropins'
                },
                {
                    id: '1022499',
                    name: 'Meprobamate'
                },
                {
                    id: '1022500',
                    name: 'Mercaptopurine'
                },
                {
                    id: '1022501',
                    name: 'Mercury and mercury compounds'
                },
                {
                    id: '1022502',
                    name: 'Methacycline hydrochloride'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1022503',
                    name: 'Methanol'
                },
                {
                    id: '1022504',
                    name: 'Methazole'
                },
                {
                    id: '1022505',
                    name: 'Methimazole'
                },
                {
                    id: '1022506',
                    name: 'Methotrexate'
                },
                {
                    id: '1022507',
                    name: 'Methotrexate sodium'
                },
                {
                    id: '1022508',
                    name: 'Methyl bromide, as a structural fumigant'
                },
                {
                    id: '1022509',
                    name: 'Methyl chloride'
                },
                {
                    id: '1022510',
                    name: 'Methyl isobutyl ketone (MIBK)'
                },
                {
                    id: '1022511',
                    name: 'Methyl isocyanate (MIC)'
                },
                {
                    id: '1022512',
                    name: 'Methyl mercury'
                },
                {
                    id: '1022513',
                    name: 'Methyl-n-butyl ketone'
                },
                {
                    id: '1022514',
                    name: 'N-Methylpyrrolidone'
                },
                {
                    id: '1022515',
                    name: 'Methyltestosterone'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022516',
                    name: 'Midazolam hydrochloride'
                },
                {
                    id: '1022517',
                    name: 'Misoprostol'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022518',
                    name: 'Molinate'
                },
                {
                    id: '1022519',
                    name: 'Myclobutanil'
                },
                {
                    id: '1022520',
                    name: 'Nabam'
                },
                {
                    id: '1022521',
                    name: 'Nafarelin acetate'
                },
                {
                    id: '1022522',
                    name: 'Netilmicin sulfate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022523',
                    name: 'Nickel (soluble compounds)'
                },
                {
                    id: '1022524',
                    name: 'Nicotine'
                },
                {
                    id: '1022525',
                    name: 'Nifedipine'
                },
                {
                    id: '1022526',
                    name: 'Nimodipine'
                },
                {
                    id: '1022527',
                    name: 'Nitrapyrin'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022528',
                    name: 'Nitrofurantoin'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022529',
                    name: 'Nitrous oxide [Basis for listing changed effective November 8, 2013]'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022530',
                    name: 'Norethisterone acetate (Norethindrone acetate)'
                },
                {
                    id: '1022531',
                    name: 'Norethisterone (Norethindrone) /Ethinyl estradiol'
                },
                {
                    id: '1022532',
                    name: 'Norethisterone (Norethindrone) /Mestranol'
                },
                {
                    id: '1022533',
                    name: 'Norgestrel'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022534',
                    name: 'Oxydemeton methyl'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022535',
                    name: 'Oxytetracycline hydrochloride'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022536',
                    name: 'Paclitaxel'
                },
                {
                    id: '1022537',
                    name: 'Paramethadione'
                },
                {
                    id: '1022538',
                    name: 'Penicillamine'
                },
                {
                    id: '1022539',
                    name: 'Pentobarbital sodium'
                },
                {
                    id: '1022540',
                    name: 'Pentostatin'
                },
                {
                    id: '1022541',
                    name: 'Perfluorononanoic acid (PFNA) and its salts'
                },
                {
                    id: '1022542',
                    name: 'Perfluorooctane sulfonate (PFOS)'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022543',
                    name: 'Pertuzumab'
                },
                {
                    id: '1022544',
                    name: 'Phenacemide'
                },
                {
                    id: '1022545',
                    name: 'Phenprocoumon'
                },
                {
                    id: '1022546',
                    name: 'Phenylphosphine [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022547',
                    name: 'Pimozide'
                },
                {
                    id: '1022548',
                    name: 'Pipobroman'
                },
                {
                    id: '1022549',
                    name: 'Plicamycin'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022550',
                    name: 'Potassium dimethyldithiocarbamate'
                },
                {
                    id: '1022551',
                    name: 'Pravastatin sodium'
                },
                {
                    id: '1022552',
                    name: 'Prednisolone sodium phosphate'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022553',
                    name: 'Propazine'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022554',
                    name: 'Pyrimethamine'
                },
                {
                    id: '1022555',
                    name: 'Quazepam'
                },
                {
                    id: '1022556',
                    name: 'Quizalofop-ethyl'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022611',
                    name: 'Retinol/retinyl esters, when in daily dosages in excess of 10,000 IU, or 3,000 retinol equivalents.'
                },
                {
                    id: '1022557',
                    name: 'Ribavirin'
                },
                {
                    id: '1022558',
                    name: 'Rifampin'
                },
                {
                    id: '1022559',
                    name: 'Secobarbital sodium'
                },
                {
                    id: '1022560',
                    name: 'Sermorelin acetate'
                },
                {
                    id: '1022561',
                    name: 'Simazine'
                },
                {
                    id: '1022562',
                    name: 'Sodium dimethyldithiocarbamate'
                },
                {
                    id: '1022563',
                    name: 'Sodium fluoroacetate'
                },
                {
                    id: '1022564',
                    name: 'Streptomycin sulfate'
                },
                {
                    id: '1022565',
                    name: 'Streptozocin (streptozotocin)'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022566',
                    name: 'Sulfur dioxidee'
                },
                {
                    id: '1022567',
                    name: 'Sulindac'
                },
                {
                    id: '1022568',
                    name: 'Tamoxifen citrate'
                },
                {
                    id: '1022569',
                    name: 'Temazepam'
                },
                {
                    id: '1022570',
                    name: 'Teniposide'
                },
                {
                    id: '1022571',
                    name: 'Terbacil'
                },
                {
                    id: '1022572',
                    name: 'Testosterone cypionate'
                },
                {
                    id: '1022573',
                    name: 'Testosterone enanthate'
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022574',
                    name: 'Δ9-Tetrahydrocannabinol (Δ9-THC)'
                },
                {
                    id: '1022575',
                    name: 'Thalidomide'
                },
                {
                    id: '1022576',
                    name: 'Thioguanine'
                },
                {
                    id: '1022577',
                    name: 'Thiophanate methyl'
                },
                {
                    id: '1022578',
                    name: 'Tobacco smoke (primary)'
                },
                {
                    id: '1022579',
                    name: 'Tobramycin sulfate'
                },
                {
                    id: '1022580',
                    name: 'Toluene'
                },
                {
                    id: '1022581',
                    name: 'Topiramate'
                },
                {
                    id: '1022582',
                    name: 'Triadimefon'
                },
                {
                    id: '1022583',
                    name: 'Triazolam'
                },
                {
                    id: '1022584',
                    name: 'Tributyltin methacrylate'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022585',
                    name: 'Trientine hydrochloride'
                },
                {
                    id: '1022586',
                    name: 'Triforine'
                },
                {
                    id: '1022587',
                    name: 'Trilostane'
                },
                {
                    id: '1022588',
                    name: 'Trimethadione'
                },
                {
                    id: '1022589',
                    name: 'Trimetrexate glucuronate'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022590',
                    name: 'Urofollitropin'
                },
                {
                    id: '1022591',
                    name: 'Valproate (Valproic acid)'
                },
                {
                    id: '1022592',
                    name: 'Vinblastine sulfate'
                },
                {
                    id: '1022593',
                    name: 'Vinclozolin'
                },
                {
                    id: '1022594',
                    name: 'Vincristine sulfate'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022595',
                    name: 'Vinyl cyclohexene dioxide (4-Vinyl-1-cyclohexene diepoxide)'
                },
                {
                    id: '1022596',
                    name: 'Vismodegib'
                },
                {
                    id: '1022597',
                    name: 'Warfarin'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        },
        {
            id: '101400',
            name: 'CA Prop 65: Carcinogens',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601226_101400',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101397',
            name: 'Carcinogen',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022600',
                    name: 'A-alpha-C (2-Amino-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1022605',
                    name: 'Acetaldehyde'
                },
                {
                    id: '1022601',
                    name: 'Acetamide'
                },
                {
                    id: '1022602',
                    name: 'Acetochlor'
                },
                {
                    id: '1022603',
                    name: '2-Acetylaminofluorene'
                },
                {
                    id: '1022604',
                    name: 'Acifluorfen sodium'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022606',
                    name: 'Acrylonitrile'
                },
                {
                    id: '1022607',
                    name: 'Actinomycin D [Basis for listing changed effective February 22, 2013]'
                },
                {
                    id: '1022608',
                    name: 'AF-2;[2-(2-furyl)-3-(5-nitro-2-furyl)]acrylamide'
                },
                {
                    id: '1022609',
                    name: 'Aflatoxins'
                },
                {
                    id: '1021717',
                    name: 'Alachlor'
                },
                {
                    id: '1021718',
                    name: 'Alcoholic beverages'
                },
                {
                    id: '1021719',
                    name: 'Alcoholic beverages, when associated with alcohol abuse'
                },
                {
                    id: '1021720',
                    name: 'Aldrin'
                },
                {
                    id: '1021721',
                    name: 'Aloe vera, non-decolorized whole leaf extract'
                },
                {
                    id: '1021727',
                    name: '2-Aminoanthraquinone'
                },
                {
                    id: '1021722',
                    name: 'p-Aminoazobenzene'
                },
                {
                    id: '1021723',
                    name: 'o-Aminoazotoluene'
                },
                {
                    id: '1021724',
                    name: '4-Aminobiphenyl (4-aminodiphenyl)'
                },
                {
                    id: '1021725',
                    name: '2-Amino-4-chlorophenol'
                },
                {
                    id: '1021726',
                    name: '1-Amino-2,4-dibromoanthraquinone'
                },
                {
                    id: '1021728',
                    name: '3-Amino-9-ethylcarbazole hydrochloride'
                },
                {
                    id: '1021729',
                    name: '2-Aminofluorene'
                },
                {
                    id: '1021730',
                    name: '1-Amino-2-methylanthraquinone'
                },
                {
                    id: '1021731',
                    name: '2-Amino-5-(5-nitro-2-furyl)-1,3,4-thiadiazole'
                },
                {
                    id: '1021732',
                    name: '4-Amino-2-nitrophenol'
                },
                {
                    id: '1021733',
                    name: 'Amitrole'
                },
                {
                    id: '1021734',
                    name: 'Amsacrine'
                },
                {
                    id: '1021735',
                    name: 'Analgesic mixtures containing Phenacetin'
                },
                {
                    id: '1021736',
                    name: 'Androstenedione'
                },
                {
                    id: '1021737',
                    name: 'Aniline'
                },
                {
                    id: '1021738',
                    name: 'Aniline hydrochloride'
                },
                {
                    id: '1021739',
                    name: 'o-Anisidine'
                },
                {
                    id: '1021740',
                    name: 'o-Anisidine hydrochloride'
                },
                {
                    id: '1021741',
                    name: 'Anthracene'
                },
                {
                    id: '1021742',
                    name: 'Anthraquinone'
                },
                {
                    id: '1021743',
                    name: 'Antimony oxide (Antimony trioxide)'
                },
                {
                    id: '1021744',
                    name: 'Aramite'
                },
                {
                    id: '1021745',
                    name: 'Areca nut'
                },
                {
                    id: '1021746',
                    name: 'Aristolochic acids'
                },
                {
                    id: '1021747',
                    name: 'Arsenic (inorganic arsenic compounds)'
                },
                {
                    id: '1021748',
                    name: 'Asbestos'
                },
                {
                    id: '1021749',
                    name: 'Auramine'
                },
                {
                    id: '1021750',
                    name: 'Azacitidine'
                },
                {
                    id: '1021751',
                    name: 'Azaserine'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1021753',
                    name: 'Azobenzene'
                },
                {
                    id: '1021754',
                    name: 'Benthiavalicarb-isopropyl'
                },
                {
                    id: '1021755',
                    name: 'Benz[a]anthracene'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1021757',
                    name: 'Benzidine [and its salts]'
                },
                {
                    id: '1021758',
                    name: 'Benzidine-based dyes'
                },
                {
                    id: '1021759',
                    name: 'Benzo[b]fluoranthene'
                },
                {
                    id: '1021760',
                    name: 'Benzo[j]fluoranthene'
                },
                {
                    id: '1021761',
                    name: 'Benzo[k]fluoranthene'
                },
                {
                    id: '1021762',
                    name: 'Benzofuran'
                },
                {
                    id: '1021763',
                    name: 'Benzophenone'
                },
                {
                    id: '1021764',
                    name: 'Benzo[a]pyrene'
                },
                {
                    id: '1021765',
                    name: 'Benzotrichloride'
                },
                {
                    id: '1021766',
                    name: 'Benzyl chloride'
                },
                {
                    id: '1021767',
                    name: 'Benzyl violet 4B'
                },
                {
                    id: '1021768',
                    name: 'Beryllium and beryllium compounds'
                },
                {
                    id: '1021769',
                    name: 'Beryllium'
                },
                {
                    id: '1021770',
                    name: 'Beryllium oxide'
                },
                {
                    id: '1021771',
                    name: 'Beryllium sulfate'
                },
                {
                    id: '1021772',
                    name: 'Betel quid with tobacco'
                },
                {
                    id: '1021773',
                    name: 'Betel quid without tobacco'
                },
                {
                    id: '1021774',
                    name: '2,2-Bis(bromomethyl)-1,3-propanediol'
                },
                {
                    id: '1021775',
                    name: 'Bis(2-chloroethyl)ether'
                },
                {
                    id: '1021776',
                    name: 'N,N-Bis(2-chloroethyl)-2-naphthylamine (Chlornapazine)'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1021778',
                    name: 'Bis(chloromethyl)ether'
                },
                {
                    id: '1021779',
                    name: 'Bis(2-chloro-1-methylethyl)ether, technical grade'
                },
                {
                    id: '1021780',
                    name: 'Bitumens, extracts of steam-refined and air refined'
                },
                {
                    id: '1021781',
                    name: 'Bracken fern'
                },
                {
                    id: '1021782',
                    name: 'Bromate'
                },
                {
                    id: '1021783',
                    name: 'Bromochloroacetic acid'
                },
                {
                    id: '1021784',
                    name: '1-Bromo-3-chloropropane'
                },
                {
                    id: '1021785',
                    name: 'Bromodichloroacetic acid'
                },
                {
                    id: '1021786',
                    name: 'Bromodichloromethane'
                },
                {
                    id: '1021787',
                    name: 'Bromoethane'
                },
                {
                    id: '1021788',
                    name: 'Bromoform'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1021793',
                    name: 'Butylated hydroxyanisole'
                },
                {
                    id: '1021794',
                    name: '1-Butyl glycidyl ether'
                },
                {
                    id: '1021795',
                    name: 'beta-Butyrolactone'
                },
                {
                    id: '1021796',
                    name: 'Cacodylic acid'
                },
                {
                    id: '1021797',
                    name: 'Cadmium and cadmium compounds'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1021799',
                    name: 'Caffeic acid'
                },
                {
                    id: '1021800',
                    name: 'Captafol'
                },
                {
                    id: '1021801',
                    name: 'Captan'
                },
                {
                    id: '1021802',
                    name: 'Carbaryl'
                },
                {
                    id: '1021803',
                    name: 'Carbazole'
                },
                {
                    id: '1021804',
                    name: 'Carbon black (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1021805',
                    name: 'Carbon-black extracts'
                },
                {
                    id: '1021806',
                    name: 'Carbon tetrachloride'
                },
                {
                    id: '1021807',
                    name: 'N-Carboxymethyl-N-nitrosourea'
                },
                {
                    id: '1021808',
                    name: 'Catechol'
                },
                {
                    id: '1021809',
                    name: 'Ceramic fibers (airborne particles of respirable size)'
                },
                {
                    id: '1021810',
                    name: 'Certain combined chemotherapy for lymphomas'
                },
                {
                    id: '1021811',
                    name: 'Chloral'
                },
                {
                    id: '1021812',
                    name: 'Chloral hydrate'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1021814',
                    name: 'Chloramphenicol sodium succinate'
                },
                {
                    id: '1021815',
                    name: 'Chlordane'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1021817',
                    name: 'Chlordimeform'
                },
                {
                    id: '1021818',
                    name: 'Chlorendic acid'
                },
                {
                    id: '1021819',
                    name: 'Chlorinated paraffins (Average chain length, C12;approximately 60 percent chlorine by weight)'
                },
                {
                    id: '1021820',
                    name: 'p-Chloroaniline'
                },
                {
                    id: '1021821',
                    name: 'p-Chloroaniline hydrochloride'
                },
                {
                    id: '1021822',
                    name: 'Chloroethane (Ethyl chloride)'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1021824',
                    name: '1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea (Methyl-CCNU)'
                },
                {
                    id: '1021825',
                    name: 'Chloroform'
                },
                {
                    id: '1021826',
                    name: 'Chloromethyl methyl ether (technical grade)'
                },
                {
                    id: '1021827',
                    name: '3-Chloro-2-methylpropene'
                },
                {
                    id: '1021828',
                    name: '1-Chloro-4-nitrobenzene'
                },
                {
                    id: '1021829',
                    name: '2-Chloronitrobenzene'
                },
                {
                    id: '1021830',
                    name: '4-Chloro-o-phenylenediamine'
                },
                {
                    id: '1021831',
                    name: 'Chloroprene'
                },
                {
                    id: '1021832',
                    name: 'Chlorothalonil'
                },
                {
                    id: '1021833',
                    name: 'p-Chloro-o-toluidine'
                },
                {
                    id: '1021834',
                    name: 'p-Chloro-o-toluidine, strong acid salts of'
                },
                {
                    id: '1021835',
                    name: 'p-Chloro-o-toluidine, hydrochloride'
                },
                {
                    id: '1021836',
                    name: '5-Chloro-o-toluidine and its strong acid salts'
                },
                {
                    id: '1021837',
                    name: 'Chlorotrianisene'
                },
                {
                    id: '1021838',
                    name: 'p-chloro-α,α,α-trifluorotoluene (para-Chlorobenzotrifluoride, PCBTF)'
                },
                {
                    id: '1021839',
                    name: 'Chlorozotocin'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021841',
                    name: 'Chrysene'
                },
                {
                    id: '1021842',
                    name: 'C.I. Acid Red 114'
                },
                {
                    id: '1021843',
                    name: 'C.I. Basic Red 9 monohydrochloride'
                },
                {
                    id: '1021844',
                    name: 'C.I. Direct Blue 15'
                },
                {
                    id: '1021845',
                    name: 'C.I. Direct Blue 218'
                },
                {
                    id: '1021846',
                    name: 'C.I. Disperse Yellow 3'
                },
                {
                    id: '1021847',
                    name: 'C.I. Solvent Yellow 14'
                },
                {
                    id: '1021848',
                    name: 'Ciclosporin (Cyclosporin A; Cyclosporine)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1021850',
                    name: 'Cinnamyl anthranilate'
                },
                {
                    id: '1021851',
                    name: 'Cisplatin'
                },
                {
                    id: '1021852',
                    name: 'Citrus Red No. 2'
                },
                {
                    id: '1021853',
                    name: 'Clofibrate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1021855',
                    name: 'CMNP (pyrazachlor)'
                },
                {
                    id: '1021856',
                    name: 'Coal-tar pitch'
                },
                {
                    id: '1021857',
                    name: 'Cobalt metal powder'
                },
                {
                    id: '1021858',
                    name: 'Cobalt [II] oxide'
                },
                {
                    id: '1021859',
                    name: 'Cobalt sulfate'
                },
                {
                    id: '1021860',
                    name: 'Cobalt sulfate heptahydrate'
                },
                {
                    id: '1021861',
                    name: 'Coconut oil diethanolamine condensate (cocamide diethanolamine)'
                },
                {
                    id: '1021862',
                    name: 'Coke oven emissions'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1021864',
                    name: 'Creosotes'
                },
                {
                    id: '1021865',
                    name: 'p-Cresidine'
                },
                {
                    id: '1021866',
                    name: 'Cumene'
                },
                {
                    id: '1021867',
                    name: 'Cupferron'
                },
                {
                    id: '1021868',
                    name: 'Cycasin'
                },
                {
                    id: '1021869',
                    name: 'Cyclopenta[cd]pyrene'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1021872',
                    name: 'Cytembena'
                },
                {
                    id: '1021873',
                    name: 'D&C Orange No. 17'
                },
                {
                    id: '1021874',
                    name: 'D&C Red No. 8'
                },
                {
                    id: '1021875',
                    name: 'D&C Red No. 9'
                },
                {
                    id: '1021876',
                    name: 'D&C Red No. 19'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1021878',
                    name: 'Daminozide'
                },
                {
                    id: '1021879',
                    name: 'Dantron (Chrysazin; 1,8-Dihydroxyanthraquinone)'
                },
                {
                    id: '1021880',
                    name: 'Daunomycin'
                },
                {
                    id: '1021881',
                    name: 'DDD (Dichlorodiphenyl-dichloroethane)'
                },
                {
                    id: '1021882',
                    name: 'DDE (Dichlorodiphenyl-dichloroethylene)'
                },
                {
                    id: '1021883',
                    name: 'DDT (Dichlorodiphenyl-trichloroethane)'
                },
                {
                    id: '1021884',
                    name: 'DDVP (Dichlorvos)'
                },
                {
                    id: '1021885',
                    name: "N,N'-Diacetylbenzidine"
                },
                {
                    id: '1021886',
                    name: '2,4-Diaminoanisole'
                },
                {
                    id: '1021887',
                    name: '2,4-Diaminoanisole sulfate'
                },
                {
                    id: '1021888',
                    name: "4,4'-Diaminodiphenyl ether (4,4'-Oxydianiline)"
                },
                {
                    id: '1021889',
                    name: '2,4-Diaminotoluene'
                },
                {
                    id: '1021890',
                    name: 'Diazoaminobenzene'
                },
                {
                    id: '1021891',
                    name: 'Dibenz[a,h]acridine'
                },
                {
                    id: '1021892',
                    name: 'Dibenz[a,j]acridine'
                },
                {
                    id: '1021893',
                    name: 'Dibenzanthracenes'
                },
                {
                    id: '1021894',
                    name: 'Dibenz[a,c]anthracene'
                },
                {
                    id: '1021895',
                    name: 'Dibenz[a,h]anthracene'
                },
                {
                    id: '1021896',
                    name: 'Dibenz[a,j]anthracene'
                },
                {
                    id: '1021897',
                    name: '7H-Dibenzo[c,g]carbazole'
                },
                {
                    id: '1021898',
                    name: 'Dibenzo[a,e]pyrene'
                },
                {
                    id: '1021899',
                    name: 'Dibenzo[a,h]pyrene'
                },
                {
                    id: '1021900',
                    name: 'Dibenzo[a,i]pyrene'
                },
                {
                    id: '1021901',
                    name: 'Dibenzo[a,l]pyrene'
                },
                {
                    id: '1021998',
                    name: '2-(2-Formylhydrazino)-4-(5-nitro-2-furyl)thiazole'
                },
                {
                    id: '1021902',
                    name: 'Dibromoacetic acid'
                },
                {
                    id: '1021999',
                    name: 'Fumonisin B1'
                },
                {
                    id: '1021903',
                    name: 'Dibromoacetonitrile'
                },
                {
                    id: '1022000',
                    name: 'Furan'
                },
                {
                    id: '1021904',
                    name: '1,2-Dibromo-3-chloropropane (DBCP)'
                },
                {
                    id: '1022001',
                    name: 'Furazolidone'
                },
                {
                    id: '1021905',
                    name: '2,3-Dibromo-1-propanol'
                },
                {
                    id: '1022002',
                    name: 'Furfuryl alcohol'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1022003',
                    name: 'Furmecyclox'
                },
                {
                    id: '1021907',
                    name: 'p-Dichlorobenzene'
                },
                {
                    id: '1022004',
                    name: 'Fusarin C'
                },
                {
                    id: '1021908',
                    name: "3,3'-Dichlorobenzidine"
                },
                {
                    id: '1022005',
                    name: 'Gallium arsenide'
                },
                {
                    id: '1021909',
                    name: "3,3'-Dichlorobenzidine dihydrochloride"
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1021910',
                    name: '1,4-Dichloro-2-butene'
                },
                {
                    id: '1022007',
                    name: 'Gasoline engine exhaust (condensates/extracts)'
                },
                {
                    id: '1021911',
                    name: "3,3'-Dichloro-4,4'-diamino-diphenyl ether"
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1021912',
                    name: '1,1-Dichloroethane'
                },
                {
                    id: '1022009',
                    name: 'Gentian violet (Crystal violet)'
                },
                {
                    id: '1021913',
                    name: 'Dichloromethane (Methylene chloride)'
                },
                {
                    id: '1022010',
                    name: 'Glass wool fibers (inhalable and biopersistent)'
                },
                {
                    id: '1021914',
                    name: '1,4-Dichloro-2-nitrobenzene'
                },
                {
                    id: '1022011',
                    name: "Glu-P-1 (2-Amino-6-methyldipyrido[1,2- a:3',2'-d]imidazole)"
                },
                {
                    id: '1021915',
                    name: '2,4-Dichloro-1-nitrobenzene'
                },
                {
                    id: '1022012',
                    name: "Glu-P-2 (2-Aminodipyrido[1,2-a:3',2'-d]imidazole)"
                },
                {
                    id: '1021916',
                    name: '1,2-Dichloropropane'
                },
                {
                    id: '1022013',
                    name: 'Glycidaldehyde'
                },
                {
                    id: '1021917',
                    name: '1,3-Dichloro-2-propanol (1,3-DCP)'
                },
                {
                    id: '1022014',
                    name: 'Glycidol'
                },
                {
                    id: '1021918',
                    name: '1,3-Dichloropropene'
                },
                {
                    id: '1022015',
                    name: 'Glycidyl methacrylate'
                },
                {
                    id: '1021919',
                    name: 'Diclofop-methyl'
                },
                {
                    id: '1022016',
                    name: 'Glyphosate'
                },
                {
                    id: '1021920',
                    name: 'Dieldrin'
                },
                {
                    id: '1022017',
                    name: 'Goldenseal root powder'
                },
                {
                    id: '1021921',
                    name: 'Diepoxybutane'
                },
                {
                    id: '1022018',
                    name: 'Griseofulvin'
                },
                {
                    id: '1021922',
                    name: 'Diesel engine exhaust'
                },
                {
                    id: '1022019',
                    name: 'Gyromitrin (Acetaldehyde methylformylhydrazone)'
                },
                {
                    id: '1021923',
                    name: 'Diethanolamine'
                },
                {
                    id: '1022020',
                    name: 'HC Blue 1'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1021925',
                    name: '1,2-Diethylhydrazine'
                },
                {
                    id: '1022022',
                    name: 'Heptachlor epoxide'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1022023',
                    name: 'Herbal remedies containing plant species of the genus Aristolochia'
                },
                {
                    id: '1021927',
                    name: 'Diethyl sulfate'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1021928',
                    name: 'Diglycidyl resorcinol ether (DGRE)'
                },
                {
                    id: '1022025',
                    name: 'Hexachlorobutadiene'
                },
                {
                    id: '1021929',
                    name: 'Dihydrosafrole'
                },
                {
                    id: '1022026',
                    name: 'Hexachlorocyclohexane (technical grade)'
                },
                {
                    id: '1021930',
                    name: 'Diisononyl phthalate (DINP)'
                },
                {
                    id: '1022027',
                    name: 'Hexachlorocyclohexane (alpha isomer)'
                },
                {
                    id: '1021931',
                    name: 'Diisopropyl sulfate'
                },
                {
                    id: '1022028',
                    name: 'Hexachlorocyclohexane (beta isomer)'
                },
                {
                    id: '1021932',
                    name: "3,3'-Dimethoxybenzidine (o-Dianisidine)"
                },
                {
                    id: '1022029',
                    name: 'Hexachlorocyclohexane (gamma isomer)'
                },
                {
                    id: '1021933',
                    name: "3,3'-Dimethoxybenzidine dihydrochloride"
                },
                {
                    id: '1022030',
                    name: 'Hexachlorodibenzodioxin'
                },
                {
                    id: '1021934',
                    name: "3,3'-Dimethoxybenzidine-based dyes metabolized to 3,3'-dimethoxybenzidine"
                },
                {
                    id: '1022031',
                    name: 'Hexachloroethane'
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1022032',
                    name: '2,4-Hexadienal (89% trans, trans isomer; 11% cis, trans isomer)'
                },
                {
                    id: '1021936',
                    name: '4-Dimethylaminoazobenzene'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1021937',
                    name: 'trans-2-[(Dimethylamino)methylimino]-5-[2-(5-nitro-2-furyl)vinyl]-1,3,4-oxadiazole'
                },
                {
                    id: '1022034',
                    name: 'Hydrazine'
                },
                {
                    id: '1021938',
                    name: '7,12-Dimethylbenz(a)anthracene'
                },
                {
                    id: '1022035',
                    name: 'Hydrazine sulfate'
                },
                {
                    id: '1021939',
                    name: "3,3'-Dimethylbenzidine (ortho-Tolidine)"
                },
                {
                    id: '1022036',
                    name: 'Hydrazobenzene (1,2-Diphenylhydrazine)'
                },
                {
                    id: '1021940',
                    name: "3,3'-Dimethylbenzidine-based dyes metabolized to 3,3'-dimethylbenzidine"
                },
                {
                    id: '1022037',
                    name: '1-Hydroxyanthraquinone'
                },
                {
                    id: '1021941',
                    name: "3,3'-Dimethylbenzidine dihydrochloride"
                },
                {
                    id: '1022038',
                    name: 'Imazalil'
                },
                {
                    id: '1021942',
                    name: 'Dimethylcarbamoyl chloride'
                },
                {
                    id: '1022039',
                    name: 'Indeno[1,2,3-cd]pyrene'
                },
                {
                    id: '1021943',
                    name: 'N,N-Dimethylformamide'
                },
                {
                    id: '1022040',
                    name: 'Indium phosphide'
                },
                {
                    id: '1021944',
                    name: '1,1-Dimethylhydrazine (UDMH)'
                },
                {
                    id: '1022041',
                    name: 'Indium tin oxide'
                },
                {
                    id: '1021945',
                    name: '1,2-Dimethylhydrazine'
                },
                {
                    id: '1022042',
                    name: 'IQ (2-Amino-3-methylimidazo[4,5-f] quinoline)'
                },
                {
                    id: '1021946',
                    name: 'Dimethyl hydrogen phosphite'
                },
                {
                    id: '1022043',
                    name: 'Iprodione'
                },
                {
                    id: '1021947',
                    name: '2,6-Dimethyl-N-nitrosomorpholine (DMNM)'
                },
                {
                    id: '1022044',
                    name: 'Iprovalicarb'
                },
                {
                    id: '1021948',
                    name: 'Dimethyl sulfate'
                },
                {
                    id: '1022045',
                    name: 'Iron dextran complex'
                },
                {
                    id: '1021949',
                    name: 'N,N-Dimethyl-p-toluidine'
                },
                {
                    id: '1022046',
                    name: 'Isobutyl nitrite'
                },
                {
                    id: '1021950',
                    name: 'Dimethylvinylchloride'
                },
                {
                    id: '1022047',
                    name: 'Isoprene'
                },
                {
                    id: '1021951',
                    name: '3,7-Dinitrofluoranthene'
                },
                {
                    id: '1022048',
                    name: 'Isopyrazam'
                },
                {
                    id: '1021952',
                    name: '3,9-Dinitrofluoranthene'
                },
                {
                    id: '1022049',
                    name: 'Isoxaflutole'
                },
                {
                    id: '1021953',
                    name: '1,3-Dinitropyrene'
                },
                {
                    id: '1022050',
                    name: 'Kresoxim-methyl'
                },
                {
                    id: '1021954',
                    name: '1,6-Dinitropyrene'
                },
                {
                    id: '1022051',
                    name: 'Lactofen'
                },
                {
                    id: '1021955',
                    name: '1,8-Dinitropyrene'
                },
                {
                    id: '1022052',
                    name: 'Lasiocarpine'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1022053',
                    name: 'Lead and lead compounds'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1021958',
                    name: 'Dinitrotoluene mixture, 2,4-/2,6-'
                },
                {
                    id: '1022055',
                    name: 'Lead acetate'
                },
                {
                    id: '1021959',
                    name: 'Di-n-propyl isocinchomeronate (MGK Repellent 326)'
                },
                {
                    id: '1022056',
                    name: 'Lead phosphate'
                },
                {
                    id: '1021960',
                    name: '1,4-Dioxane'
                },
                {
                    id: '1022057',
                    name: 'Lead subacetate'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022058',
                    name: 'Leather dust'
                },
                {
                    id: '1021962',
                    name: 'Diphenylhydantoin (Phenytoin), sodium salt'
                },
                {
                    id: '1022059',
                    name: 'Leucomalachite green'
                },
                {
                    id: '1021963',
                    name: 'Direct Black 38 (technical grade)'
                },
                {
                    id: '1022060',
                    name: 'Lindane and other hexachlorocyclohexane isomers'
                },
                {
                    id: '1021964',
                    name: 'Direct Blue 6 (technical grade)'
                },
                {
                    id: '1022061',
                    name: 'Lynestrenol'
                },
                {
                    id: '1021965',
                    name: 'Direct Brown 95 (technical grade)'
                },
                {
                    id: '1022062',
                    name: 'Malathion'
                },
                {
                    id: '1021966',
                    name: 'Disperse Blue 1'
                },
                {
                    id: '1022063',
                    name: 'Malonaldehyde, sodium salt'
                },
                {
                    id: '1021967',
                    name: 'Diuron'
                },
                {
                    id: '1022064',
                    name: 'Mancozeb'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022065',
                    name: 'Maneb'
                },
                {
                    id: '1021969',
                    name: 'Emissions from combustion of coal'
                },
                {
                    id: '1022066',
                    name: 'Marijuana smoke'
                },
                {
                    id: '1021970',
                    name: 'Emissions from high-temperature unrefined rapeseed oil'
                },
                {
                    id: '1022067',
                    name: 'Me-A-alpha-C (2-Amino-3-methyl-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1021972',
                    name: 'Epoxiconazole'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1021973',
                    name: 'Erionite'
                },
                {
                    id: '1022070',
                    name: 'MeIQ (2-Amino-3,4-dimethylimidazo[4,5-f]quinoline)'
                },
                {
                    id: '1021974',
                    name: 'Estradiol 17B'
                },
                {
                    id: '1022071',
                    name: 'MeIQx (2-Amino-3,8-dimethylimidazo[4,5-f]quinoxaline)'
                },
                {
                    id: '1021975',
                    name: 'Estragole'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1021976',
                    name: 'Estrogens, steroidal'
                },
                {
                    id: '1022073',
                    name: 'Mepanipyrim'
                },
                {
                    id: '1021977',
                    name: 'Estrogen-progestogen (combined) used as menopausal therapy'
                },
                {
                    id: '1022074',
                    name: '2‑Mercaptobenzothiazole'
                },
                {
                    id: '1021978',
                    name: 'Estrone'
                },
                {
                    id: '1022075',
                    name: 'Merphalan'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022076',
                    name: 'Mestranol'
                },
                {
                    id: '1021980',
                    name: 'Ethinylestradiol'
                },
                {
                    id: '1022077',
                    name: 'Metam potassium'
                },
                {
                    id: '1021981',
                    name: 'Ethoprop'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1021982',
                    name: 'Ethyl acrylate'
                },
                {
                    id: '1022079',
                    name: '5-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1021983',
                    name: 'Ethylbenzene'
                },
                {
                    id: '1022080',
                    name: '8-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1021984',
                    name: "Ethyl-4,4'-dichlorobenzilate"
                },
                {
                    id: '1022081',
                    name: 'Methyl acrylate'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022082',
                    name: '2-Methylaziridine (Propyleneimine)'
                },
                {
                    id: '1021986',
                    name: 'Ethylene dichloride (1,2-Dichloroethane)'
                },
                {
                    id: '1022083',
                    name: 'Methylazoxymethanol'
                },
                {
                    id: '1021987',
                    name: 'Ethyleneimine (Aziridine)'
                },
                {
                    id: '1022084',
                    name: 'Methylazoxymethanol acetate'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1022085',
                    name: 'Methyl carbamate'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022086',
                    name: '3-Methylcholanthrene'
                },
                {
                    id: '1021990',
                    name: '2-Ethylhexyl acrylate'
                },
                {
                    id: '1022087',
                    name: '5-Methylchrysene'
                },
                {
                    id: '1021991',
                    name: 'Ethyl methanesulfonate'
                },
                {
                    id: '1022088',
                    name: "4,4'-Methylene bis(2-chloroaniline)"
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022089',
                    name: "4,4'-Methylene bis(N,N-dimethyl)benzenamine"
                },
                {
                    id: '1021993',
                    name: 'Etoposide in combination with cisplatin and bleomycin'
                },
                {
                    id: '1022090',
                    name: "4,4'-Methylene bis(2-methylaniline)"
                },
                {
                    id: '1021994',
                    name: 'Fenoxycarb'
                },
                {
                    id: '1022091',
                    name: "4,4'-Methylenedianiline"
                },
                {
                    id: '1021995',
                    name: 'Fluoro-edenite fibrous amphibole'
                },
                {
                    id: '1022092',
                    name: "4,4'-Methylenedianiline dihydrochloride"
                },
                {
                    id: '1021996',
                    name: 'Folpet'
                },
                {
                    id: '1022093',
                    name: 'Methyleugenol'
                },
                {
                    id: '1021997',
                    name: 'Formaldehyde (gas)'
                },
                {
                    id: '1022094',
                    name: 'Methylhydrazine and its salts'
                },
                {
                    id: '1022095',
                    name: 'Methylhydrazine'
                },
                {
                    id: '1022096',
                    name: 'Methylhydrazine sulfate'
                },
                {
                    id: '1022097',
                    name: '2-Methylimidazole'
                },
                {
                    id: '1022098',
                    name: '4-Methylimidazole'
                },
                {
                    id: '1022099',
                    name: 'Methyl iodide'
                },
                {
                    id: '1022100',
                    name: 'Methyl isobutyl ketone'
                },
                {
                    id: '1022101',
                    name: 'Methylmercury compounds'
                },
                {
                    id: '1022102',
                    name: 'Methyl methanesulfonate'
                },
                {
                    id: '1022103',
                    name: '2-Methyl-1-nitroanthraquinone (of uncertain purity)'
                },
                {
                    id: '1022104',
                    name: "N-Methyl-N'-nitro-N-nitrosoguanidine"
                },
                {
                    id: '1022105',
                    name: 'N-Methylolacrylamide'
                },
                {
                    id: '1022106',
                    name: 'α-Methyl styrene (alpha-Methylstyrene)'
                },
                {
                    id: '1022107',
                    name: 'Methylthiouracil'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022109',
                    name: 'Metronidazole'
                },
                {
                    id: '1022110',
                    name: "Michler's ketone"
                },
                {
                    id: '1022111',
                    name: 'Mirex'
                },
                {
                    id: '1022112',
                    name: 'Mitomycin C'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022114',
                    name: 'Molybdenum trioxide'
                },
                {
                    id: '1022115',
                    name: 'MON 4660 (dichloroacetyl-1-oxa-4-azaspiro(4,5)-decane'
                },
                {
                    id: '1022116',
                    name: 'MON 13900 (furilazole)'
                },
                {
                    id: '1022117',
                    name: '3-Monochloropropane-1,2-diol (3-MCPD)'
                },
                {
                    id: '1022118',
                    name: 'Monocrotaline'
                },
                {
                    id: '1022119',
                    name: 'MOPP (vincristine-prednisone-nitrogen mustard-procarbazine mixture)'
                },
                {
                    id: '1022120',
                    name: '5-(Morpholinomethyl)-3-[(5-nitrofurfuryl-idene)-amino]-2-oxazolidinone'
                },
                {
                    id: '1022121',
                    name: 'Mustard Gas'
                },
                {
                    id: '1022122',
                    name: 'MX (3-chloro-4-dichloromethyl-5-hydroxy-2(5H)-furanone)'
                },
                {
                    id: '1022123',
                    name: 'beta-Myrcene'
                },
                {
                    id: '1022124',
                    name: 'Nafenopin'
                },
                {
                    id: '1022125',
                    name: 'Nalidixic acid'
                },
                {
                    id: '1022126',
                    name: 'Naphthalene'
                },
                {
                    id: '1022127',
                    name: '1-Naphthylamine'
                },
                {
                    id: '1022128',
                    name: '2-Naphthylamine'
                },
                {
                    id: '1022129',
                    name: 'Nickel (Metallic)'
                },
                {
                    id: '1022130',
                    name: 'Nickel acetate'
                },
                {
                    id: '1022131',
                    name: 'Nickel carbonate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022133',
                    name: 'Nickel compounds'
                },
                {
                    id: '1022134',
                    name: 'Nickel hydroxide'
                },
                {
                    id: '1022135',
                    name: 'Nickelocene'
                },
                {
                    id: '1022136',
                    name: 'Nickel oxide'
                },
                {
                    id: '1022137',
                    name: 'Nickel refinery dust from the pyrometallurgical process'
                },
                {
                    id: '1022138',
                    name: 'Nickel subsulfide'
                },
                {
                    id: '1022139',
                    name: 'Niridazole'
                },
                {
                    id: '1022140',
                    name: 'Nitrapyrin [Basis for listing changed effective on November 4, 2015]'
                },
                {
                    id: '1022141',
                    name: 'Nitrilotriacetic acid'
                },
                {
                    id: '1022142',
                    name: 'Nitrilotriacetic acid, trisodium salt monohydrate'
                },
                {
                    id: '1022143',
                    name: '5-Nitroacenaphthene'
                },
                {
                    id: '1022144',
                    name: 'o-Nitroanisole'
                },
                {
                    id: '1022145',
                    name: 'para-Nitroanisole'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022147',
                    name: '4-Nitrobiphenyl'
                },
                {
                    id: '1022148',
                    name: '6-Nitrochrysene'
                },
                {
                    id: '1022149',
                    name: 'Nitrofen (technical grade)'
                },
                {
                    id: '1022150',
                    name: '2-Nitrofluorene'
                },
                {
                    id: '1022151',
                    name: 'Nitrofurazone'
                },
                {
                    id: '1022152',
                    name: '1-[(5-Nitrofurfurylidene)-amino]-2-imidazolidinone'
                },
                {
                    id: '1022153',
                    name: 'N-[4-(5-Nitro-2-furyl)-2-thiazolyl]acetamide'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022156',
                    name: 'Nitrogen mustard N-oxide'
                },
                {
                    id: '1022157',
                    name: 'Nitrogen mustard N-oxide hydrochloride'
                },
                {
                    id: '1022158',
                    name: 'Nitromethane'
                },
                {
                    id: '1022159',
                    name: '2-Nitropropane'
                },
                {
                    id: '1022160',
                    name: '1-Nitropyrene'
                },
                {
                    id: '1022161',
                    name: '4-Nitropyrene'
                },
                {
                    id: '1022162',
                    name: 'N-Nitrosodi-n-butylamine'
                },
                {
                    id: '1022163',
                    name: 'N-Nitrosodiethanolamine'
                },
                {
                    id: '1022164',
                    name: 'N-Nitrosodiethylamine'
                },
                {
                    id: '1022165',
                    name: 'N-Nitrosodimethylamine'
                },
                {
                    id: '1022166',
                    name: 'p-Nitrosodiphenylamine'
                },
                {
                    id: '1022167',
                    name: 'N-Nitrosodiphenylamine'
                },
                {
                    id: '1022168',
                    name: 'N-Nitrosodi-n-propylamine'
                },
                {
                    id: '1022169',
                    name: 'N-Nitroso-N-ethylurea'
                },
                {
                    id: '1022170',
                    name: 'N-Nitrosohexamethyleneimine'
                },
                {
                    id: '1022171',
                    name: '3-(N-Nitrosomethylamino) propionitrile'
                },
                {
                    id: '1022172',
                    name: '4-(N-Nitrosomethylamino)-1-(3-pyridyl)1-butanone'
                },
                {
                    id: '1022173',
                    name: 'N-Nitrosomethyl-n-butylamine'
                },
                {
                    id: '1022174',
                    name: 'N-Nitrosomethyl-n-decylamine'
                },
                {
                    id: '1022175',
                    name: 'N-Nitrosomethyl-n-dodecylamine'
                },
                {
                    id: '1022176',
                    name: 'N-Nitrosomethylethylamine'
                },
                {
                    id: '1022177',
                    name: 'N-Nitrosomethyl-n-heptylamine'
                },
                {
                    id: '1022178',
                    name: 'N-Nitrosomethyl-n-hexylamine'
                },
                {
                    id: '1022179',
                    name: 'N-Nitrosomethyl-n-nonylamine'
                },
                {
                    id: '1022180',
                    name: 'N-Nitrosomethyl-n-octylamine'
                },
                {
                    id: '1022181',
                    name: 'N-Nitrosomethyl-n-pentylamine'
                },
                {
                    id: '1022182',
                    name: 'N-Nitrosomethyl-n-propylamine'
                },
                {
                    id: '1022183',
                    name: 'N-Nitrosomethyl-n-tetradecylamine'
                },
                {
                    id: '1022184',
                    name: 'N-Nitrosomethyl-n-undecylamine'
                },
                {
                    id: '1022185',
                    name: 'N-Nitroso-N-methylurea'
                },
                {
                    id: '1022186',
                    name: 'N-Nitroso-N-methylurethane'
                },
                {
                    id: '1022187',
                    name: 'N-Nitrosomethylvinylamine'
                },
                {
                    id: '1022188',
                    name: 'N-Nitrosomorpholine'
                },
                {
                    id: '1022189',
                    name: 'N-Nitrosonornicotine'
                },
                {
                    id: '1022190',
                    name: 'N-Nitrosopiperidine'
                },
                {
                    id: '1022191',
                    name: 'N-Nitrosopyrrolidine'
                },
                {
                    id: '1022192',
                    name: 'N-Nitrososarcosine'
                },
                {
                    id: '1022193',
                    name: 'o-Nitrotoluene'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022195',
                    name: 'Norethynodrel'
                },
                {
                    id: '1022196',
                    name: 'Ochratoxin A'
                },
                {
                    id: '1022197',
                    name: 'Oil Orange SS'
                },
                {
                    id: '1022198',
                    name: 'Oral contraceptives, combined'
                },
                {
                    id: '1022199',
                    name: 'Oral contraceptives, sequential'
                },
                {
                    id: '1022200',
                    name: 'Oryzalin'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022202',
                    name: 'Oxazepam'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022205',
                    name: 'Palygorskite fibers (> 5µm in length)'
                },
                {
                    id: '1022206',
                    name: 'Panfuran S'
                },
                {
                    id: '1022207',
                    name: 'Parathion'
                },
                {
                    id: '1022208',
                    name: 'pentabromodiphenyl ether mixture [DE-71 (technical grade)]'
                },
                {
                    id: '1022209',
                    name: 'Pentachlorophenol'
                },
                {
                    id: '1022210',
                    name: 'Pentachlorophenol and by-products of its synthesis (complex mixture)'
                },
                {
                    id: '1022211',
                    name: 'Pentosan polysulfate sodium'
                },
                {
                    id: '1022212',
                    name: 'Perfluorooctane sulfonic acid (PFOS) and its salts and transformation and degradation precursors'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022214',
                    name: 'Phenacetin'
                },
                {
                    id: '1022215',
                    name: 'Phenazopyridine'
                },
                {
                    id: '1022216',
                    name: 'Phenazopyridine hydrochloride'
                },
                {
                    id: '1022217',
                    name: 'Phenesterin'
                },
                {
                    id: '1022218',
                    name: 'Phenobarbital'
                },
                {
                    id: '1022219',
                    name: 'Phenolphthalein'
                },
                {
                    id: '1022220',
                    name: 'Phenoxybenzamine'
                },
                {
                    id: '1022221',
                    name: 'Phenoxybenzamine hydrochloride'
                },
                {
                    id: '1022222',
                    name: 'o-Phenylenediamine and its salts'
                },
                {
                    id: '1022223',
                    name: 'o-Phenylenediamine'
                },
                {
                    id: '1022224',
                    name: 'o-Phenylenediamine dihydrochloride'
                },
                {
                    id: '1022225',
                    name: 'Phenyl glycidyl ether'
                },
                {
                    id: '1022226',
                    name: 'Phenylhydrazine and its salts'
                },
                {
                    id: '1022227',
                    name: 'Phenylhydrazine'
                },
                {
                    id: '1022228',
                    name: 'Phenylhydrazine hydrochloride'
                },
                {
                    id: '1022229',
                    name: 'o-Phenylphenate, sodium'
                },
                {
                    id: '1022230',
                    name: 'o-Phenylphenol'
                },
                {
                    id: '1022231',
                    name: 'PhiP(2-Amino-1-methyl-6-phenylimidazol[4,5-b]pyridine)'
                },
                {
                    id: '1022232',
                    name: 'Pioglitazone'
                },
                {
                    id: '1022233',
                    name: 'Pirimicarb'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022236',
                    name: 'Polychlorinated biphenyls (containing 60 or more percent chlorine by molecular weight)'
                },
                {
                    id: '1022237',
                    name: 'Polychlorinated dibenzo-p-dioxins'
                },
                {
                    id: '1022238',
                    name: 'Polychlorinated dibenzofurans'
                },
                {
                    id: '1022239',
                    name: 'Polygeenan'
                },
                {
                    id: '1022240',
                    name: 'Ponceau MX'
                },
                {
                    id: '1022241',
                    name: 'Ponceau 3R'
                },
                {
                    id: '1022242',
                    name: 'Potassium bromate'
                },
                {
                    id: '1022243',
                    name: 'Primidone'
                },
                {
                    id: '1022244',
                    name: 'Procarbazine'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022246',
                    name: 'Procymidone'
                },
                {
                    id: '1022247',
                    name: 'Progesterone'
                },
                {
                    id: '1022248',
                    name: 'Pronamide'
                },
                {
                    id: '1022249',
                    name: 'Propachlor'
                },
                {
                    id: '1022250',
                    name: '1,3-Propane sultone'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022252',
                    name: 'beta-Propiolactone'
                },
                {
                    id: '1022253',
                    name: 'Propoxur'
                },
                {
                    id: '1022254',
                    name: 'Propylene glycol mono-t-butyl ether'
                },
                {
                    id: '1022255',
                    name: 'Propylene oxide'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022257',
                    name: 'Pulegone'
                },
                {
                    id: '1022258',
                    name: 'Pymetrozine'
                },
                {
                    id: '1022259',
                    name: 'Pyridine'
                },
                {
                    id: '1022260',
                    name: 'Quinoline and its strong acid salts'
                },
                {
                    id: '1022261',
                    name: 'Radionuclides'
                },
                {
                    id: '1022262',
                    name: 'Reserpine'
                },
                {
                    id: '1022263',
                    name: 'Residual (heavy) fuel oils'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022265',
                    name: 'Riddelliine'
                },
                {
                    id: '1022266',
                    name: 'Safrole'
                },
                {
                    id: '1022267',
                    name: 'Salted fish, Chinese-style'
                },
                {
                    id: '1022268',
                    name: 'Sedaxane'
                },
                {
                    id: '1022269',
                    name: 'Selenium sulfide'
                },
                {
                    id: '1022270',
                    name: 'Shale-oils'
                },
                {
                    id: '1022271',
                    name: 'Silica, crystalline (airborne particles of respirable size)'
                },
                {
                    id: '1022272',
                    name: 'Silicon carbide whiskers'
                },
                {
                    id: '1022273',
                    name: 'Soots, tars, and mineral oils (untreated and mildly treated oils and used engine oils)'
                },
                {
                    id: '1022274',
                    name: 'Spirodiclofen'
                },
                {
                    id: '1022275',
                    name: 'Spironolactone'
                },
                {
                    id: '1022276',
                    name: 'Stanozolol'
                },
                {
                    id: '1022277',
                    name: 'Sterigmatocystin'
                },
                {
                    id: '1022278',
                    name: 'Streptozotocin (streptozocin)'
                },
                {
                    id: '1022279',
                    name: 'Strong inorganic acid mists containing sulfuric acid'
                },
                {
                    id: '1022280',
                    name: 'Styrene'
                },
                {
                    id: '1022281',
                    name: 'Styrene oxide'
                },
                {
                    id: '1022282',
                    name: 'Sulfallate'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022284',
                    name: 'Talc containing asbestiform fibers'
                },
                {
                    id: '1022285',
                    name: 'Tamoxifen and its salts'
                },
                {
                    id: '1022286',
                    name: 'Teriparatide'
                },
                {
                    id: '1022287',
                    name: 'Terrazole'
                },
                {
                    id: '1022288',
                    name: 'Testosterone and its esters'
                },
                {
                    id: '1022289',
                    name: 'Tetrabromobisphenol A'
                },
                {
                    id: '1022290',
                    name: "3,3',4,4'-Tetrachloroazobenzene"
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022292',
                    name: '1,1,1,2-Tetrachloroethane'
                },
                {
                    id: '1022293',
                    name: '1,1,2,2-Tetrachloroethane'
                },
                {
                    id: '1022294',
                    name: 'Tetrachloroethylene (Perchloroethylene)'
                },
                {
                    id: '1022295',
                    name: 'p-a,a,a-Tetrachlorotoluene'
                },
                {
                    id: '1022296',
                    name: 'Tetrachlorvinphos'
                },
                {
                    id: '1022297',
                    name: 'Tetrafluoroethylene'
                },
                {
                    id: '1022298',
                    name: 'Tetrahydrofuran'
                },
                {
                    id: '1022299',
                    name: 'Tetranitromethane'
                },
                {
                    id: '1022300',
                    name: 'Thioacetamide'
                },
                {
                    id: '1022301',
                    name: "4,4'-Thiodianiline"
                },
                {
                    id: '1022302',
                    name: 'Thiodicarb'
                },
                {
                    id: '1022303',
                    name: 'Thiouracil'
                },
                {
                    id: '1022304',
                    name: 'Thiourea'
                },
                {
                    id: '1022305',
                    name: 'Thorium dioxide'
                },
                {
                    id: '1022306',
                    name: 'Titanium dioxide (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1022307',
                    name: 'Tobacco, oral use of smokeless products'
                },
                {
                    id: '1022308',
                    name: 'Tobacco smoke'
                },
                {
                    id: '1022309',
                    name: 'Toluene diisocyanate'
                },
                {
                    id: '1022310',
                    name: 'o-Toluidine'
                },
                {
                    id: '1022311',
                    name: 'o-Toluidine hydrochloride'
                },
                {
                    id: '1022312',
                    name: 'Toxaphene (Polychlorinated camphenes)'
                },
                {
                    id: '1022313',
                    name: 'Toxins derived from Fusarium moniliforme (Fusarium verticillioides)'
                },
                {
                    id: '1022314',
                    name: 'Treosulfan'
                },
                {
                    id: '1022315',
                    name: 'Triamterene'
                },
                {
                    id: '1022316',
                    name: 'S,S,S-Tributyl phosphorotrithioate (Tribufos, DEF)'
                },
                {
                    id: '1022317',
                    name: 'Trichlormethine (Trimustine hydrochloride)'
                },
                {
                    id: '1022318',
                    name: 'Trichloroacetic acid'
                },
                {
                    id: '1022319',
                    name: '1,1,1-Trichloroethane'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022321',
                    name: '2,4,6-Trichlorophenol'
                },
                {
                    id: '1022322',
                    name: '1,2,3-Trichloropropane'
                },
                {
                    id: '1022323',
                    name: '2,4,5-Trimethylaniline and its strong acid salts'
                },
                {
                    id: '1022324',
                    name: 'Trimethylolpropane triacrylate, technical grade'
                },
                {
                    id: '1022325',
                    name: 'Trimethyl phosphate'
                },
                {
                    id: '1022326',
                    name: 'TRIM® VX'
                },
                {
                    id: '1022327',
                    name: '2,4,6-Trinitrotoluene (TNT)'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022329',
                    name: 'Tris(1-aziridinyl)phosphine sulfide (Thiotepa)'
                },
                {
                    id: '1022330',
                    name: 'Tris(2-chloroethyl) phosphate'
                },
                {
                    id: '1022331',
                    name: 'Tris(2,3-dibromopropyl)phosphate'
                },
                {
                    id: '1022332',
                    name: 'Tris(1,3-dichloro-2-propyl) phosphate (TDCPP)'
                },
                {
                    id: '1022333',
                    name: 'Trp-P-1 (Tryptophan-P-1)'
                },
                {
                    id: '1022334',
                    name: 'Trp-P-2 (Tryptophan-P-2)'
                },
                {
                    id: '1022335',
                    name: 'Trypan blue (commercial grade)'
                },
                {
                    id: '1022336',
                    name: 'Unleaded gasoline (wholly vaporized)'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022339',
                    name: 'Vanadium pentoxide (orthorhombic crystalline form)'
                },
                {
                    id: '1022340',
                    name: 'Vinclozolin [basis for listing changed on November 16, 2006]'
                },
                {
                    id: '1022341',
                    name: 'Vinyl bromide'
                },
                {
                    id: '1022342',
                    name: 'Vinyl chloride'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022344',
                    name: '4-Vinyl-1-cyclohexene diepoxide (Vinyl cyclohexenedioxide)'
                },
                {
                    id: '1022345',
                    name: 'Vinyl fluoride'
                },
                {
                    id: '1022346',
                    name: 'Vinylidene chloride \n(1,1-Dichloroethylene)'
                },
                {
                    id: '1022347',
                    name: 'Vinyl trichloride (1,1,2-Trichloroethane)'
                },
                {
                    id: '1022348',
                    name: 'Wood dust'
                },
                {
                    id: '1022349',
                    name: '2,6-Xylidine (2,6-Dimethylaniline)'
                },
                {
                    id: '1022350',
                    name: 'Zalcitabine'
                },
                {
                    id: '1022351',
                    name: 'Zidovudine (AZT)'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        }
    ],
    MEN_HOODIES_SWEATER: [
        {
            id: '101610',
            name: 'Contains Batteries or Cells?',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: true,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101610',
                description:
                    'We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            values: [
                {
                    id: '1024358',
                    name: 'Batteries'
                },
                {
                    id: '1024359',
                    name: 'Cells'
                },
                {
                    id: '1000325',
                    name: 'None'
                },
                {
                    id: '1024360',
                    name: 'Not Sure'
                }
            ],
            cascade_values: [
                {
                    value_id: '1024359',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: true
                            }
                        }
                    ],
                    value_id_to_qualifications: [
                        {
                            id: '1729439947134305535',
                            title: {
                                key: 'productlisting_qualification_msds_for_batteries',
                                name: 'Safety Data Sheet (SDS) for products with batteries'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_batteries_tip',
                                name: "Upload the SDS for products with batteries. Find them on the manufacturer's website. View example."
                            },
                            sample_image: {
                                height: 1552,
                                width: 806,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101610,
                                    value_list: ['1024358', '1024359'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                },
                {
                    value_id: '1024360',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1000325',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1024358',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: true
                            }
                        }
                    ],
                    value_id_to_qualifications: [
                        {
                            id: '1729439947134305535',
                            title: {
                                key: 'productlisting_qualification_msds_for_batteries',
                                name: 'Safety Data Sheet (SDS) for products with batteries'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_batteries_tip',
                                name: "Upload the SDS for products with batteries. Find them on the manufacturer's website. View example."
                            },
                            sample_image: {
                                height: 1552,
                                width: 806,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101610,
                                    value_list: ['1024358', '1024359'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101619',
            name: 'Other Dangerous Goods or Hazardous Materials',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: true,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101619',
                description:
                    'We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                },
                {
                    id: '1024360',
                    name: 'Not Sure'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    value_id_to_qualifications: [
                        {
                            id: '8647636475739801353',
                            title: {
                                key: 'productlisting_qualification_msds_for_other_hazmar',
                                name: 'Safety Data Sheet (SDS) for other dangerous goods or hazardous materials'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_other_Hazmat_tip',
                                name: 'Upload the SDS for any other hazardous materials or dangerous goods in your product. Example.'
                            },
                            sample_image: {
                                height: 1466,
                                width: 666,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101619,
                                    value_list: ['1000058'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101395',
            name: 'CA Prop 65: Repro. Chems',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101395',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101398',
            name: 'Reprotoxic Chemicals',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022353',
                    name: 'Abiraterone acetate'
                },
                {
                    id: '1022354',
                    name: 'Acetazolamide'
                },
                {
                    id: '1022355',
                    name: 'Acetohydroxamic acid'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022357',
                    name: 'Actinomycin D'
                },
                {
                    id: '1022358',
                    name: 'All-trans retinoic acid'
                },
                {
                    id: '1022359',
                    name: 'Alprazolam'
                },
                {
                    id: '1022360',
                    name: 'Altretamine'
                },
                {
                    id: '1022361',
                    name: 'Amantadine hydrochloride'
                },
                {
                    id: '1022362',
                    name: 'Amikacin sulfate'
                },
                {
                    id: '1022363',
                    name: 'Aminoglutethimide'
                },
                {
                    id: '1022364',
                    name: 'Aminoglycosides'
                },
                {
                    id: '1022365',
                    name: 'Aminopterin'
                },
                {
                    id: '1022366',
                    name: 'Amiodarone hydrochloride'
                },
                {
                    id: '1022367',
                    name: 'Amitraz'
                },
                {
                    id: '1022368',
                    name: 'Amoxapine'
                },
                {
                    id: '1022369',
                    name: 'Anabolic steroids'
                },
                {
                    id: '1022370',
                    name: 'Angiotensin converting enzyme (ACE) inhibitors'
                },
                {
                    id: '1022371',
                    name: 'Anisindione'
                },
                {
                    id: '1022372',
                    name: 'Arsenic (inorganic oxides)'
                },
                {
                    id: '1022610',
                    name: 'Aspirin'
                },
                {
                    id: '1022373',
                    name: 'Atenolol'
                },
                {
                    id: '1022374',
                    name: 'Atrazine'
                },
                {
                    id: '1022375',
                    name: 'Auranofin'
                },
                {
                    id: '1022376',
                    name: 'Avermectin B1 (Abamectin)'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1022377',
                    name: 'Barbiturates'
                },
                {
                    id: '1022378',
                    name: 'Beclomethasone dipropionate'
                },
                {
                    id: '1022379',
                    name: 'Benomyl'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1022380',
                    name: 'Benzodiazepines'
                },
                {
                    id: '1022381',
                    name: 'Benzphetamine hydrochloride'
                },
                {
                    id: '1022382',
                    name: 'Bevacizumab'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1022383',
                    name: 'Bisphenol A (BPA)'
                },
                {
                    id: '1022384',
                    name: 'Bromacil lithium salt'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1022385',
                    name: 'Bromoxynil'
                },
                {
                    id: '1022386',
                    name: 'Bromoxynil octanoate'
                },
                {
                    id: '1022387',
                    name: 'Butabarbital sodium'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1022388',
                    name: 'Butyl benzyl phthalate (BBP)d'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1022389',
                    name: 'Cannabis (marijuana) smoke'
                },
                {
                    id: '1022390',
                    name: 'Carbamazepine'
                },
                {
                    id: '1022391',
                    name: 'Carbaryl [Basis for listing changed effective December 27, 2013]'
                },
                {
                    id: '1022392',
                    name: 'Carbon disulfide'
                },
                {
                    id: '1022393',
                    name: 'Carbon monoxide'
                },
                {
                    id: '1022394',
                    name: 'Carboplatin'
                },
                {
                    id: '1022395',
                    name: 'Chenodiol'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1022396',
                    name: 'Chlorcyclizine hydrochloride'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1022397',
                    name: 'Chlordiazepoxide'
                },
                {
                    id: '1022398',
                    name: 'Chlordiazepoxide hydrochloride'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1022399',
                    name: 'Chloroform [Basis for listing changed effective December 23, 2016]'
                },
                {
                    id: '1022400',
                    name: '2-Chloropropionic acid [Basis for listing changed effective December 20, 2013]'
                },
                {
                    id: '1022401',
                    name: 'Chlorpyrifos'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1022402',
                    name: 'Cladribine'
                },
                {
                    id: '1022403',
                    name: 'Clarithromycin'
                },
                {
                    id: '1022404',
                    name: 'Clobetasol propionate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1022405',
                    name: 'Clorazepate dipotassium'
                },
                {
                    id: '1022406',
                    name: 'Cocaine'
                },
                {
                    id: '1022407',
                    name: 'Codeine phosphate'
                },
                {
                    id: '1022408',
                    name: 'Colchicine'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1022409',
                    name: 'Cyanazine'
                },
                {
                    id: '1022410',
                    name: 'Cycloate'
                },
                {
                    id: '1022411',
                    name: 'Cycloheximide'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1022412',
                    name: 'Cyhexatin'
                },
                {
                    id: '1022413',
                    name: 'Cytarabine'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1022414',
                    name: 'Danazol'
                },
                {
                    id: '1022415',
                    name: 'Daunorubicin hydrochloride'
                },
                {
                    id: '1022416',
                    name: '2,4-D butyric acid'
                },
                {
                    id: '1022417',
                    name: "o,p'-DDT"
                },
                {
                    id: '1022418',
                    name: "p,p'-DDT"
                },
                {
                    id: '1022419',
                    name: 'Des-ethyl atrazine (DEA)'
                },
                {
                    id: '1022420',
                    name: 'Des-isopropyl atrazine (DIA)'
                },
                {
                    id: '1022421',
                    name: '2,4-Diamino-6-chloro-s-triazine (DACT)'
                },
                {
                    id: '1022422',
                    name: 'Diazepam'
                },
                {
                    id: '1022423',
                    name: 'Diazoxide'
                },
                {
                    id: '1022424',
                    name: '1,2-Dibromo-3-chloropropane (DBCP) [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1022425',
                    name: '1,1-Dichloro-2,2-bis(p-chloropheny)ethylene (DDE)'
                },
                {
                    id: '1022426',
                    name: 'Dichlorophene'
                },
                {
                    id: '1022427',
                    name: 'Dichlorphenamide'
                },
                {
                    id: '1022428',
                    name: 'Diclofop methyl'
                },
                {
                    id: '1022429',
                    name: 'Dicumarol'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1022430',
                    name: 'Diflunisal'
                },
                {
                    id: '1022431',
                    name: 'Dihydroergotamine mesylate'
                },
                {
                    id: '1022432',
                    name: 'Di-isodecyl phthalate (DIDP)'
                },
                {
                    id: '1022433',
                    name: 'Diltiazem hydrochloride'
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1022434',
                    name: 'Di-n-butyl phthalate (DBP)'
                },
                {
                    id: '1022435',
                    name: 'Di-n-hexyl phthalate (DnHP)'
                },
                {
                    id: '1022436',
                    name: 'm-Dinitrobenzene'
                },
                {
                    id: '1022437',
                    name: 'o-Dinitrobenzene'
                },
                {
                    id: '1022438',
                    name: 'p-Dinitrobenzene'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022439',
                    name: 'Dinitrotoluene (technical grade)'
                },
                {
                    id: '1022440',
                    name: 'Dinocap'
                },
                {
                    id: '1022441',
                    name: 'Dinoseb'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022442',
                    name: 'Disodium cyanodithioimidocarbonate'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022443',
                    name: 'Endrin'
                },
                {
                    id: '1022444',
                    name: 'Environmental tobacco smoke (ETS)'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022445',
                    name: 'Ergotamine tartrate'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022446',
                    name: 'Ethionamide'
                },
                {
                    id: '1022447',
                    name: 'Ethyl alcohol in alcoholic beverages'
                },
                {
                    id: '1022448',
                    name: 'Ethyl dipropylthiocarbamate'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022449',
                    name: 'Ethylene glycol (ingested)'
                },
                {
                    id: '1022450',
                    name: 'Ethylene glycol monoethyl ether'
                },
                {
                    id: '1022451',
                    name: 'Ethylene glycol monoethyl ether acetate'
                },
                {
                    id: '1022452',
                    name: 'Ethylene glycol monomethyl ether'
                },
                {
                    id: '1022453',
                    name: 'Ethylene glycol monomethyl ether acetate'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022454',
                    name: 'Etodolac'
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022455',
                    name: 'Etretinate'
                },
                {
                    id: '1022456',
                    name: 'Fenoxaprop ethyl'
                },
                {
                    id: '1022457',
                    name: 'Filgrastim'
                },
                {
                    id: '1022458',
                    name: 'Fluazifop butyl'
                },
                {
                    id: '1022459',
                    name: 'Flunisolide'
                },
                {
                    id: '1022460',
                    name: 'Fluorouracil'
                },
                {
                    id: '1022461',
                    name: 'Fluoxymesterone'
                },
                {
                    id: '1022462',
                    name: 'Flurazepam hydrochloride'
                },
                {
                    id: '1022463',
                    name: 'Flurbiprofen'
                },
                {
                    id: '1022464',
                    name: 'Flutamide'
                },
                {
                    id: '1022465',
                    name: 'Fluticasone propionate'
                },
                {
                    id: '1022466',
                    name: 'Fluvalinate'
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1022467',
                    name: 'Ganciclovir sodium'
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1022468',
                    name: 'Goserelin acetate'
                },
                {
                    id: '1022469',
                    name: 'Halazepam'
                },
                {
                    id: '1022470',
                    name: 'Halobetasol propionate'
                },
                {
                    id: '1022471',
                    name: 'Haloperidol'
                },
                {
                    id: '1022472',
                    name: 'Halothane'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1022473',
                    name: 'Hexafluoroacetone [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1022474',
                    name: 'n-Hexane'
                },
                {
                    id: '1022475',
                    name: '2,5-Hexanedione'
                },
                {
                    id: '1022476',
                    name: 'Histrelin acetate'
                },
                {
                    id: '1022477',
                    name: 'Hydramethylnon'
                },
                {
                    id: '1022478',
                    name: 'Hydrogen cyanide (HCN) and cyanide salts (CN salts)'
                },
                {
                    id: '1022479',
                    name: 'Cyanide salts that readily dissociate in solution (expressed as cyanide)f'
                },
                {
                    id: '1022480',
                    name: 'Hydrogen cyanidef'
                },
                {
                    id: '1022481',
                    name: 'Sodium cyanidef'
                },
                {
                    id: '1022482',
                    name: 'Potassium cyanidef'
                },
                {
                    id: '1022483',
                    name: 'Hydroxyurea'
                },
                {
                    id: '1022484',
                    name: 'Idarubicin hydrochloride'
                },
                {
                    id: '1022485',
                    name: 'Ifosfamide'
                },
                {
                    id: '1022486',
                    name: 'Iodine-131'
                },
                {
                    id: '1022487',
                    name: 'Isotretinoin'
                },
                {
                    id: '1022488',
                    name: 'Lead [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1022489',
                    name: 'Leuprolide acetate'
                },
                {
                    id: '1022490',
                    name: 'Levodopa'
                },
                {
                    id: '1022491',
                    name: 'Levonorgestrel implants'
                },
                {
                    id: '1022492',
                    name: 'Linuron'
                },
                {
                    id: '1022493',
                    name: 'Lithium carbonate'
                },
                {
                    id: '1022494',
                    name: 'Lithium citrate'
                },
                {
                    id: '1022495',
                    name: 'Lorazepam'
                },
                {
                    id: '1022496',
                    name: 'Lovastatin'
                },
                {
                    id: '1022497',
                    name: 'Mebendazole'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1022498',
                    name: 'Menotropins'
                },
                {
                    id: '1022499',
                    name: 'Meprobamate'
                },
                {
                    id: '1022500',
                    name: 'Mercaptopurine'
                },
                {
                    id: '1022501',
                    name: 'Mercury and mercury compounds'
                },
                {
                    id: '1022502',
                    name: 'Methacycline hydrochloride'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1022503',
                    name: 'Methanol'
                },
                {
                    id: '1022504',
                    name: 'Methazole'
                },
                {
                    id: '1022505',
                    name: 'Methimazole'
                },
                {
                    id: '1022506',
                    name: 'Methotrexate'
                },
                {
                    id: '1022507',
                    name: 'Methotrexate sodium'
                },
                {
                    id: '1022508',
                    name: 'Methyl bromide, as a structural fumigant'
                },
                {
                    id: '1022509',
                    name: 'Methyl chloride'
                },
                {
                    id: '1022510',
                    name: 'Methyl isobutyl ketone (MIBK)'
                },
                {
                    id: '1022511',
                    name: 'Methyl isocyanate (MIC)'
                },
                {
                    id: '1022512',
                    name: 'Methyl mercury'
                },
                {
                    id: '1022513',
                    name: 'Methyl-n-butyl ketone'
                },
                {
                    id: '1022514',
                    name: 'N-Methylpyrrolidone'
                },
                {
                    id: '1022515',
                    name: 'Methyltestosterone'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022516',
                    name: 'Midazolam hydrochloride'
                },
                {
                    id: '1022517',
                    name: 'Misoprostol'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022518',
                    name: 'Molinate'
                },
                {
                    id: '1022519',
                    name: 'Myclobutanil'
                },
                {
                    id: '1022520',
                    name: 'Nabam'
                },
                {
                    id: '1022521',
                    name: 'Nafarelin acetate'
                },
                {
                    id: '1022522',
                    name: 'Netilmicin sulfate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022523',
                    name: 'Nickel (soluble compounds)'
                },
                {
                    id: '1022524',
                    name: 'Nicotine'
                },
                {
                    id: '1022525',
                    name: 'Nifedipine'
                },
                {
                    id: '1022526',
                    name: 'Nimodipine'
                },
                {
                    id: '1022527',
                    name: 'Nitrapyrin'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022528',
                    name: 'Nitrofurantoin'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022529',
                    name: 'Nitrous oxide [Basis for listing changed effective November 8, 2013]'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022530',
                    name: 'Norethisterone acetate (Norethindrone acetate)'
                },
                {
                    id: '1022531',
                    name: 'Norethisterone (Norethindrone) /Ethinyl estradiol'
                },
                {
                    id: '1022532',
                    name: 'Norethisterone (Norethindrone) /Mestranol'
                },
                {
                    id: '1022533',
                    name: 'Norgestrel'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022534',
                    name: 'Oxydemeton methyl'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022535',
                    name: 'Oxytetracycline hydrochloride'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022536',
                    name: 'Paclitaxel'
                },
                {
                    id: '1022537',
                    name: 'Paramethadione'
                },
                {
                    id: '1022538',
                    name: 'Penicillamine'
                },
                {
                    id: '1022539',
                    name: 'Pentobarbital sodium'
                },
                {
                    id: '1022540',
                    name: 'Pentostatin'
                },
                {
                    id: '1022541',
                    name: 'Perfluorononanoic acid (PFNA) and its salts'
                },
                {
                    id: '1022542',
                    name: 'Perfluorooctane sulfonate (PFOS)'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022543',
                    name: 'Pertuzumab'
                },
                {
                    id: '1022544',
                    name: 'Phenacemide'
                },
                {
                    id: '1022545',
                    name: 'Phenprocoumon'
                },
                {
                    id: '1022546',
                    name: 'Phenylphosphine [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022547',
                    name: 'Pimozide'
                },
                {
                    id: '1022548',
                    name: 'Pipobroman'
                },
                {
                    id: '1022549',
                    name: 'Plicamycin'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022550',
                    name: 'Potassium dimethyldithiocarbamate'
                },
                {
                    id: '1022551',
                    name: 'Pravastatin sodium'
                },
                {
                    id: '1022552',
                    name: 'Prednisolone sodium phosphate'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022553',
                    name: 'Propazine'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022554',
                    name: 'Pyrimethamine'
                },
                {
                    id: '1022555',
                    name: 'Quazepam'
                },
                {
                    id: '1022556',
                    name: 'Quizalofop-ethyl'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022611',
                    name: 'Retinol/retinyl esters, when in daily dosages in excess of 10,000 IU, or 3,000 retinol equivalents.'
                },
                {
                    id: '1022557',
                    name: 'Ribavirin'
                },
                {
                    id: '1022558',
                    name: 'Rifampin'
                },
                {
                    id: '1022559',
                    name: 'Secobarbital sodium'
                },
                {
                    id: '1022560',
                    name: 'Sermorelin acetate'
                },
                {
                    id: '1022561',
                    name: 'Simazine'
                },
                {
                    id: '1022562',
                    name: 'Sodium dimethyldithiocarbamate'
                },
                {
                    id: '1022563',
                    name: 'Sodium fluoroacetate'
                },
                {
                    id: '1022564',
                    name: 'Streptomycin sulfate'
                },
                {
                    id: '1022565',
                    name: 'Streptozocin (streptozotocin)'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022566',
                    name: 'Sulfur dioxidee'
                },
                {
                    id: '1022567',
                    name: 'Sulindac'
                },
                {
                    id: '1022568',
                    name: 'Tamoxifen citrate'
                },
                {
                    id: '1022569',
                    name: 'Temazepam'
                },
                {
                    id: '1022570',
                    name: 'Teniposide'
                },
                {
                    id: '1022571',
                    name: 'Terbacil'
                },
                {
                    id: '1022572',
                    name: 'Testosterone cypionate'
                },
                {
                    id: '1022573',
                    name: 'Testosterone enanthate'
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022574',
                    name: 'Δ9-Tetrahydrocannabinol (Δ9-THC)'
                },
                {
                    id: '1022575',
                    name: 'Thalidomide'
                },
                {
                    id: '1022576',
                    name: 'Thioguanine'
                },
                {
                    id: '1022577',
                    name: 'Thiophanate methyl'
                },
                {
                    id: '1022578',
                    name: 'Tobacco smoke (primary)'
                },
                {
                    id: '1022579',
                    name: 'Tobramycin sulfate'
                },
                {
                    id: '1022580',
                    name: 'Toluene'
                },
                {
                    id: '1022581',
                    name: 'Topiramate'
                },
                {
                    id: '1022582',
                    name: 'Triadimefon'
                },
                {
                    id: '1022583',
                    name: 'Triazolam'
                },
                {
                    id: '1022584',
                    name: 'Tributyltin methacrylate'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022585',
                    name: 'Trientine hydrochloride'
                },
                {
                    id: '1022586',
                    name: 'Triforine'
                },
                {
                    id: '1022587',
                    name: 'Trilostane'
                },
                {
                    id: '1022588',
                    name: 'Trimethadione'
                },
                {
                    id: '1022589',
                    name: 'Trimetrexate glucuronate'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022590',
                    name: 'Urofollitropin'
                },
                {
                    id: '1022591',
                    name: 'Valproate (Valproic acid)'
                },
                {
                    id: '1022592',
                    name: 'Vinblastine sulfate'
                },
                {
                    id: '1022593',
                    name: 'Vinclozolin'
                },
                {
                    id: '1022594',
                    name: 'Vincristine sulfate'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022595',
                    name: 'Vinyl cyclohexene dioxide (4-Vinyl-1-cyclohexene diepoxide)'
                },
                {
                    id: '1022596',
                    name: 'Vismodegib'
                },
                {
                    id: '1022597',
                    name: 'Warfarin'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        },
        {
            id: '101400',
            name: 'CA Prop 65: Carcinogens',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101400',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101397',
            name: 'Carcinogen',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022600',
                    name: 'A-alpha-C (2-Amino-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1022605',
                    name: 'Acetaldehyde'
                },
                {
                    id: '1022601',
                    name: 'Acetamide'
                },
                {
                    id: '1022602',
                    name: 'Acetochlor'
                },
                {
                    id: '1022603',
                    name: '2-Acetylaminofluorene'
                },
                {
                    id: '1022604',
                    name: 'Acifluorfen sodium'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022606',
                    name: 'Acrylonitrile'
                },
                {
                    id: '1022607',
                    name: 'Actinomycin D [Basis for listing changed effective February 22, 2013]'
                },
                {
                    id: '1022608',
                    name: 'AF-2;[2-(2-furyl)-3-(5-nitro-2-furyl)]acrylamide'
                },
                {
                    id: '1022609',
                    name: 'Aflatoxins'
                },
                {
                    id: '1021717',
                    name: 'Alachlor'
                },
                {
                    id: '1021718',
                    name: 'Alcoholic beverages'
                },
                {
                    id: '1021719',
                    name: 'Alcoholic beverages, when associated with alcohol abuse'
                },
                {
                    id: '1021720',
                    name: 'Aldrin'
                },
                {
                    id: '1021721',
                    name: 'Aloe vera, non-decolorized whole leaf extract'
                },
                {
                    id: '1021727',
                    name: '2-Aminoanthraquinone'
                },
                {
                    id: '1021722',
                    name: 'p-Aminoazobenzene'
                },
                {
                    id: '1021723',
                    name: 'o-Aminoazotoluene'
                },
                {
                    id: '1021724',
                    name: '4-Aminobiphenyl (4-aminodiphenyl)'
                },
                {
                    id: '1021725',
                    name: '2-Amino-4-chlorophenol'
                },
                {
                    id: '1021726',
                    name: '1-Amino-2,4-dibromoanthraquinone'
                },
                {
                    id: '1021728',
                    name: '3-Amino-9-ethylcarbazole hydrochloride'
                },
                {
                    id: '1021729',
                    name: '2-Aminofluorene'
                },
                {
                    id: '1021730',
                    name: '1-Amino-2-methylanthraquinone'
                },
                {
                    id: '1021731',
                    name: '2-Amino-5-(5-nitro-2-furyl)-1,3,4-thiadiazole'
                },
                {
                    id: '1021732',
                    name: '4-Amino-2-nitrophenol'
                },
                {
                    id: '1021733',
                    name: 'Amitrole'
                },
                {
                    id: '1021734',
                    name: 'Amsacrine'
                },
                {
                    id: '1021735',
                    name: 'Analgesic mixtures containing Phenacetin'
                },
                {
                    id: '1021736',
                    name: 'Androstenedione'
                },
                {
                    id: '1021737',
                    name: 'Aniline'
                },
                {
                    id: '1021738',
                    name: 'Aniline hydrochloride'
                },
                {
                    id: '1021739',
                    name: 'o-Anisidine'
                },
                {
                    id: '1021740',
                    name: 'o-Anisidine hydrochloride'
                },
                {
                    id: '1021741',
                    name: 'Anthracene'
                },
                {
                    id: '1021742',
                    name: 'Anthraquinone'
                },
                {
                    id: '1021743',
                    name: 'Antimony oxide (Antimony trioxide)'
                },
                {
                    id: '1021744',
                    name: 'Aramite'
                },
                {
                    id: '1021745',
                    name: 'Areca nut'
                },
                {
                    id: '1021746',
                    name: 'Aristolochic acids'
                },
                {
                    id: '1021747',
                    name: 'Arsenic (inorganic arsenic compounds)'
                },
                {
                    id: '1021748',
                    name: 'Asbestos'
                },
                {
                    id: '1021749',
                    name: 'Auramine'
                },
                {
                    id: '1021750',
                    name: 'Azacitidine'
                },
                {
                    id: '1021751',
                    name: 'Azaserine'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1021753',
                    name: 'Azobenzene'
                },
                {
                    id: '1021754',
                    name: 'Benthiavalicarb-isopropyl'
                },
                {
                    id: '1021755',
                    name: 'Benz[a]anthracene'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1021757',
                    name: 'Benzidine [and its salts]'
                },
                {
                    id: '1021758',
                    name: 'Benzidine-based dyes'
                },
                {
                    id: '1021759',
                    name: 'Benzo[b]fluoranthene'
                },
                {
                    id: '1021760',
                    name: 'Benzo[j]fluoranthene'
                },
                {
                    id: '1021761',
                    name: 'Benzo[k]fluoranthene'
                },
                {
                    id: '1021762',
                    name: 'Benzofuran'
                },
                {
                    id: '1021763',
                    name: 'Benzophenone'
                },
                {
                    id: '1021764',
                    name: 'Benzo[a]pyrene'
                },
                {
                    id: '1021765',
                    name: 'Benzotrichloride'
                },
                {
                    id: '1021766',
                    name: 'Benzyl chloride'
                },
                {
                    id: '1021767',
                    name: 'Benzyl violet 4B'
                },
                {
                    id: '1021768',
                    name: 'Beryllium and beryllium compounds'
                },
                {
                    id: '1021769',
                    name: 'Beryllium'
                },
                {
                    id: '1021770',
                    name: 'Beryllium oxide'
                },
                {
                    id: '1021771',
                    name: 'Beryllium sulfate'
                },
                {
                    id: '1021772',
                    name: 'Betel quid with tobacco'
                },
                {
                    id: '1021773',
                    name: 'Betel quid without tobacco'
                },
                {
                    id: '1021774',
                    name: '2,2-Bis(bromomethyl)-1,3-propanediol'
                },
                {
                    id: '1021775',
                    name: 'Bis(2-chloroethyl)ether'
                },
                {
                    id: '1021776',
                    name: 'N,N-Bis(2-chloroethyl)-2-naphthylamine (Chlornapazine)'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1021778',
                    name: 'Bis(chloromethyl)ether'
                },
                {
                    id: '1021779',
                    name: 'Bis(2-chloro-1-methylethyl)ether, technical grade'
                },
                {
                    id: '1021780',
                    name: 'Bitumens, extracts of steam-refined and air refined'
                },
                {
                    id: '1021781',
                    name: 'Bracken fern'
                },
                {
                    id: '1021782',
                    name: 'Bromate'
                },
                {
                    id: '1021783',
                    name: 'Bromochloroacetic acid'
                },
                {
                    id: '1021784',
                    name: '1-Bromo-3-chloropropane'
                },
                {
                    id: '1021785',
                    name: 'Bromodichloroacetic acid'
                },
                {
                    id: '1021786',
                    name: 'Bromodichloromethane'
                },
                {
                    id: '1021787',
                    name: 'Bromoethane'
                },
                {
                    id: '1021788',
                    name: 'Bromoform'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1021793',
                    name: 'Butylated hydroxyanisole'
                },
                {
                    id: '1021794',
                    name: '1-Butyl glycidyl ether'
                },
                {
                    id: '1021795',
                    name: 'beta-Butyrolactone'
                },
                {
                    id: '1021796',
                    name: 'Cacodylic acid'
                },
                {
                    id: '1021797',
                    name: 'Cadmium and cadmium compounds'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1021799',
                    name: 'Caffeic acid'
                },
                {
                    id: '1021800',
                    name: 'Captafol'
                },
                {
                    id: '1021801',
                    name: 'Captan'
                },
                {
                    id: '1021802',
                    name: 'Carbaryl'
                },
                {
                    id: '1021803',
                    name: 'Carbazole'
                },
                {
                    id: '1021804',
                    name: 'Carbon black (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1021805',
                    name: 'Carbon-black extracts'
                },
                {
                    id: '1021806',
                    name: 'Carbon tetrachloride'
                },
                {
                    id: '1021807',
                    name: 'N-Carboxymethyl-N-nitrosourea'
                },
                {
                    id: '1021808',
                    name: 'Catechol'
                },
                {
                    id: '1021809',
                    name: 'Ceramic fibers (airborne particles of respirable size)'
                },
                {
                    id: '1021810',
                    name: 'Certain combined chemotherapy for lymphomas'
                },
                {
                    id: '1021811',
                    name: 'Chloral'
                },
                {
                    id: '1021812',
                    name: 'Chloral hydrate'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1021814',
                    name: 'Chloramphenicol sodium succinate'
                },
                {
                    id: '1021815',
                    name: 'Chlordane'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1021817',
                    name: 'Chlordimeform'
                },
                {
                    id: '1021818',
                    name: 'Chlorendic acid'
                },
                {
                    id: '1021819',
                    name: 'Chlorinated paraffins (Average chain length, C12;approximately 60 percent chlorine by weight)'
                },
                {
                    id: '1021820',
                    name: 'p-Chloroaniline'
                },
                {
                    id: '1021821',
                    name: 'p-Chloroaniline hydrochloride'
                },
                {
                    id: '1021822',
                    name: 'Chloroethane (Ethyl chloride)'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1021824',
                    name: '1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea (Methyl-CCNU)'
                },
                {
                    id: '1021825',
                    name: 'Chloroform'
                },
                {
                    id: '1021826',
                    name: 'Chloromethyl methyl ether (technical grade)'
                },
                {
                    id: '1021827',
                    name: '3-Chloro-2-methylpropene'
                },
                {
                    id: '1021828',
                    name: '1-Chloro-4-nitrobenzene'
                },
                {
                    id: '1021829',
                    name: '2-Chloronitrobenzene'
                },
                {
                    id: '1021830',
                    name: '4-Chloro-o-phenylenediamine'
                },
                {
                    id: '1021831',
                    name: 'Chloroprene'
                },
                {
                    id: '1021832',
                    name: 'Chlorothalonil'
                },
                {
                    id: '1021833',
                    name: 'p-Chloro-o-toluidine'
                },
                {
                    id: '1021834',
                    name: 'p-Chloro-o-toluidine, strong acid salts of'
                },
                {
                    id: '1021835',
                    name: 'p-Chloro-o-toluidine, hydrochloride'
                },
                {
                    id: '1021836',
                    name: '5-Chloro-o-toluidine and its strong acid salts'
                },
                {
                    id: '1021837',
                    name: 'Chlorotrianisene'
                },
                {
                    id: '1021838',
                    name: 'p-chloro-α,α,α-trifluorotoluene (para-Chlorobenzotrifluoride, PCBTF)'
                },
                {
                    id: '1021839',
                    name: 'Chlorozotocin'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021841',
                    name: 'Chrysene'
                },
                {
                    id: '1021842',
                    name: 'C.I. Acid Red 114'
                },
                {
                    id: '1021843',
                    name: 'C.I. Basic Red 9 monohydrochloride'
                },
                {
                    id: '1021844',
                    name: 'C.I. Direct Blue 15'
                },
                {
                    id: '1021845',
                    name: 'C.I. Direct Blue 218'
                },
                {
                    id: '1021846',
                    name: 'C.I. Disperse Yellow 3'
                },
                {
                    id: '1021847',
                    name: 'C.I. Solvent Yellow 14'
                },
                {
                    id: '1021848',
                    name: 'Ciclosporin (Cyclosporin A; Cyclosporine)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1021850',
                    name: 'Cinnamyl anthranilate'
                },
                {
                    id: '1021851',
                    name: 'Cisplatin'
                },
                {
                    id: '1021852',
                    name: 'Citrus Red No. 2'
                },
                {
                    id: '1021853',
                    name: 'Clofibrate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1021855',
                    name: 'CMNP (pyrazachlor)'
                },
                {
                    id: '1021856',
                    name: 'Coal-tar pitch'
                },
                {
                    id: '1021857',
                    name: 'Cobalt metal powder'
                },
                {
                    id: '1021858',
                    name: 'Cobalt [II] oxide'
                },
                {
                    id: '1021859',
                    name: 'Cobalt sulfate'
                },
                {
                    id: '1021860',
                    name: 'Cobalt sulfate heptahydrate'
                },
                {
                    id: '1021861',
                    name: 'Coconut oil diethanolamine condensate (cocamide diethanolamine)'
                },
                {
                    id: '1021862',
                    name: 'Coke oven emissions'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1021864',
                    name: 'Creosotes'
                },
                {
                    id: '1021865',
                    name: 'p-Cresidine'
                },
                {
                    id: '1021866',
                    name: 'Cumene'
                },
                {
                    id: '1021867',
                    name: 'Cupferron'
                },
                {
                    id: '1021868',
                    name: 'Cycasin'
                },
                {
                    id: '1021869',
                    name: 'Cyclopenta[cd]pyrene'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1021872',
                    name: 'Cytembena'
                },
                {
                    id: '1021873',
                    name: 'D&C Orange No. 17'
                },
                {
                    id: '1021874',
                    name: 'D&C Red No. 8'
                },
                {
                    id: '1021875',
                    name: 'D&C Red No. 9'
                },
                {
                    id: '1021876',
                    name: 'D&C Red No. 19'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1021878',
                    name: 'Daminozide'
                },
                {
                    id: '1021879',
                    name: 'Dantron (Chrysazin; 1,8-Dihydroxyanthraquinone)'
                },
                {
                    id: '1021880',
                    name: 'Daunomycin'
                },
                {
                    id: '1021881',
                    name: 'DDD (Dichlorodiphenyl-dichloroethane)'
                },
                {
                    id: '1021882',
                    name: 'DDE (Dichlorodiphenyl-dichloroethylene)'
                },
                {
                    id: '1021883',
                    name: 'DDT (Dichlorodiphenyl-trichloroethane)'
                },
                {
                    id: '1021884',
                    name: 'DDVP (Dichlorvos)'
                },
                {
                    id: '1021885',
                    name: "N,N'-Diacetylbenzidine"
                },
                {
                    id: '1021886',
                    name: '2,4-Diaminoanisole'
                },
                {
                    id: '1021887',
                    name: '2,4-Diaminoanisole sulfate'
                },
                {
                    id: '1021888',
                    name: "4,4'-Diaminodiphenyl ether (4,4'-Oxydianiline)"
                },
                {
                    id: '1021889',
                    name: '2,4-Diaminotoluene'
                },
                {
                    id: '1021890',
                    name: 'Diazoaminobenzene'
                },
                {
                    id: '1021891',
                    name: 'Dibenz[a,h]acridine'
                },
                {
                    id: '1021892',
                    name: 'Dibenz[a,j]acridine'
                },
                {
                    id: '1021893',
                    name: 'Dibenzanthracenes'
                },
                {
                    id: '1021894',
                    name: 'Dibenz[a,c]anthracene'
                },
                {
                    id: '1021895',
                    name: 'Dibenz[a,h]anthracene'
                },
                {
                    id: '1021896',
                    name: 'Dibenz[a,j]anthracene'
                },
                {
                    id: '1021897',
                    name: '7H-Dibenzo[c,g]carbazole'
                },
                {
                    id: '1021898',
                    name: 'Dibenzo[a,e]pyrene'
                },
                {
                    id: '1021899',
                    name: 'Dibenzo[a,h]pyrene'
                },
                {
                    id: '1021900',
                    name: 'Dibenzo[a,i]pyrene'
                },
                {
                    id: '1021901',
                    name: 'Dibenzo[a,l]pyrene'
                },
                {
                    id: '1021902',
                    name: 'Dibromoacetic acid'
                },
                {
                    id: '1021903',
                    name: 'Dibromoacetonitrile'
                },
                {
                    id: '1021904',
                    name: '1,2-Dibromo-3-chloropropane (DBCP)'
                },
                {
                    id: '1021905',
                    name: '2,3-Dibromo-1-propanol'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1021907',
                    name: 'p-Dichlorobenzene'
                },
                {
                    id: '1021908',
                    name: "3,3'-Dichlorobenzidine"
                },
                {
                    id: '1021909',
                    name: "3,3'-Dichlorobenzidine dihydrochloride"
                },
                {
                    id: '1021910',
                    name: '1,4-Dichloro-2-butene'
                },
                {
                    id: '1021911',
                    name: "3,3'-Dichloro-4,4'-diamino-diphenyl ether"
                },
                {
                    id: '1021912',
                    name: '1,1-Dichloroethane'
                },
                {
                    id: '1021913',
                    name: 'Dichloromethane (Methylene chloride)'
                },
                {
                    id: '1021914',
                    name: '1,4-Dichloro-2-nitrobenzene'
                },
                {
                    id: '1021915',
                    name: '2,4-Dichloro-1-nitrobenzene'
                },
                {
                    id: '1021916',
                    name: '1,2-Dichloropropane'
                },
                {
                    id: '1021917',
                    name: '1,3-Dichloro-2-propanol (1,3-DCP)'
                },
                {
                    id: '1021918',
                    name: '1,3-Dichloropropene'
                },
                {
                    id: '1021919',
                    name: 'Diclofop-methyl'
                },
                {
                    id: '1021920',
                    name: 'Dieldrin'
                },
                {
                    id: '1021921',
                    name: 'Diepoxybutane'
                },
                {
                    id: '1021922',
                    name: 'Diesel engine exhaust'
                },
                {
                    id: '1021923',
                    name: 'Diethanolamine'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1021925',
                    name: '1,2-Diethylhydrazine'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1021927',
                    name: 'Diethyl sulfate'
                },
                {
                    id: '1021928',
                    name: 'Diglycidyl resorcinol ether (DGRE)'
                },
                {
                    id: '1021929',
                    name: 'Dihydrosafrole'
                },
                {
                    id: '1021930',
                    name: 'Diisononyl phthalate (DINP)'
                },
                {
                    id: '1021931',
                    name: 'Diisopropyl sulfate'
                },
                {
                    id: '1021932',
                    name: "3,3'-Dimethoxybenzidine (o-Dianisidine)"
                },
                {
                    id: '1021933',
                    name: "3,3'-Dimethoxybenzidine dihydrochloride"
                },
                {
                    id: '1021934',
                    name: "3,3'-Dimethoxybenzidine-based dyes metabolized to 3,3'-dimethoxybenzidine"
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1021936',
                    name: '4-Dimethylaminoazobenzene'
                },
                {
                    id: '1021937',
                    name: 'trans-2-[(Dimethylamino)methylimino]-5-[2-(5-nitro-2-furyl)vinyl]-1,3,4-oxadiazole'
                },
                {
                    id: '1021938',
                    name: '7,12-Dimethylbenz(a)anthracene'
                },
                {
                    id: '1021939',
                    name: "3,3'-Dimethylbenzidine (ortho-Tolidine)"
                },
                {
                    id: '1021940',
                    name: "3,3'-Dimethylbenzidine-based dyes metabolized to 3,3'-dimethylbenzidine"
                },
                {
                    id: '1021941',
                    name: "3,3'-Dimethylbenzidine dihydrochloride"
                },
                {
                    id: '1021942',
                    name: 'Dimethylcarbamoyl chloride'
                },
                {
                    id: '1021943',
                    name: 'N,N-Dimethylformamide'
                },
                {
                    id: '1021944',
                    name: '1,1-Dimethylhydrazine (UDMH)'
                },
                {
                    id: '1021945',
                    name: '1,2-Dimethylhydrazine'
                },
                {
                    id: '1021946',
                    name: 'Dimethyl hydrogen phosphite'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1021947',
                    name: '2,6-Dimethyl-N-nitrosomorpholine (DMNM)'
                },
                {
                    id: '1022025',
                    name: 'Hexachlorobutadiene'
                },
                {
                    id: '1021948',
                    name: 'Dimethyl sulfate'
                },
                {
                    id: '1022026',
                    name: 'Hexachlorocyclohexane (technical grade)'
                },
                {
                    id: '1021949',
                    name: 'N,N-Dimethyl-p-toluidine'
                },
                {
                    id: '1022027',
                    name: 'Hexachlorocyclohexane (alpha isomer)'
                },
                {
                    id: '1021950',
                    name: 'Dimethylvinylchloride'
                },
                {
                    id: '1022028',
                    name: 'Hexachlorocyclohexane (beta isomer)'
                },
                {
                    id: '1021951',
                    name: '3,7-Dinitrofluoranthene'
                },
                {
                    id: '1022029',
                    name: 'Hexachlorocyclohexane (gamma isomer)'
                },
                {
                    id: '1021952',
                    name: '3,9-Dinitrofluoranthene'
                },
                {
                    id: '1022030',
                    name: 'Hexachlorodibenzodioxin'
                },
                {
                    id: '1021953',
                    name: '1,3-Dinitropyrene'
                },
                {
                    id: '1022031',
                    name: 'Hexachloroethane'
                },
                {
                    id: '1021954',
                    name: '1,6-Dinitropyrene'
                },
                {
                    id: '1022032',
                    name: '2,4-Hexadienal (89% trans, trans isomer; 11% cis, trans isomer)'
                },
                {
                    id: '1021955',
                    name: '1,8-Dinitropyrene'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1022034',
                    name: 'Hydrazine'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022035',
                    name: 'Hydrazine sulfate'
                },
                {
                    id: '1021958',
                    name: 'Dinitrotoluene mixture, 2,4-/2,6-'
                },
                {
                    id: '1022036',
                    name: 'Hydrazobenzene (1,2-Diphenylhydrazine)'
                },
                {
                    id: '1021959',
                    name: 'Di-n-propyl isocinchomeronate (MGK Repellent 326)'
                },
                {
                    id: '1022037',
                    name: '1-Hydroxyanthraquinone'
                },
                {
                    id: '1021960',
                    name: '1,4-Dioxane'
                },
                {
                    id: '1022038',
                    name: 'Imazalil'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022039',
                    name: 'Indeno[1,2,3-cd]pyrene'
                },
                {
                    id: '1021962',
                    name: 'Diphenylhydantoin (Phenytoin), sodium salt'
                },
                {
                    id: '1022040',
                    name: 'Indium phosphide'
                },
                {
                    id: '1021963',
                    name: 'Direct Black 38 (technical grade)'
                },
                {
                    id: '1022041',
                    name: 'Indium tin oxide'
                },
                {
                    id: '1021964',
                    name: 'Direct Blue 6 (technical grade)'
                },
                {
                    id: '1022042',
                    name: 'IQ (2-Amino-3-methylimidazo[4,5-f] quinoline)'
                },
                {
                    id: '1021965',
                    name: 'Direct Brown 95 (technical grade)'
                },
                {
                    id: '1022043',
                    name: 'Iprodione'
                },
                {
                    id: '1021966',
                    name: 'Disperse Blue 1'
                },
                {
                    id: '1022044',
                    name: 'Iprovalicarb'
                },
                {
                    id: '1021967',
                    name: 'Diuron'
                },
                {
                    id: '1022045',
                    name: 'Iron dextran complex'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022046',
                    name: 'Isobutyl nitrite'
                },
                {
                    id: '1021969',
                    name: 'Emissions from combustion of coal'
                },
                {
                    id: '1022047',
                    name: 'Isoprene'
                },
                {
                    id: '1021970',
                    name: 'Emissions from high-temperature unrefined rapeseed oil'
                },
                {
                    id: '1022048',
                    name: 'Isopyrazam'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022049',
                    name: 'Isoxaflutole'
                },
                {
                    id: '1021972',
                    name: 'Epoxiconazole'
                },
                {
                    id: '1022050',
                    name: 'Kresoxim-methyl'
                },
                {
                    id: '1021973',
                    name: 'Erionite'
                },
                {
                    id: '1022051',
                    name: 'Lactofen'
                },
                {
                    id: '1021974',
                    name: 'Estradiol 17B'
                },
                {
                    id: '1022052',
                    name: 'Lasiocarpine'
                },
                {
                    id: '1021975',
                    name: 'Estragole'
                },
                {
                    id: '1022053',
                    name: 'Lead and lead compounds'
                },
                {
                    id: '1021976',
                    name: 'Estrogens, steroidal'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1021977',
                    name: 'Estrogen-progestogen (combined) used as menopausal therapy'
                },
                {
                    id: '1022055',
                    name: 'Lead acetate'
                },
                {
                    id: '1021978',
                    name: 'Estrone'
                },
                {
                    id: '1022056',
                    name: 'Lead phosphate'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022057',
                    name: 'Lead subacetate'
                },
                {
                    id: '1021980',
                    name: 'Ethinylestradiol'
                },
                {
                    id: '1022058',
                    name: 'Leather dust'
                },
                {
                    id: '1021981',
                    name: 'Ethoprop'
                },
                {
                    id: '1022059',
                    name: 'Leucomalachite green'
                },
                {
                    id: '1021982',
                    name: 'Ethyl acrylate'
                },
                {
                    id: '1022060',
                    name: 'Lindane and other hexachlorocyclohexane isomers'
                },
                {
                    id: '1021983',
                    name: 'Ethylbenzene'
                },
                {
                    id: '1022061',
                    name: 'Lynestrenol'
                },
                {
                    id: '1021984',
                    name: "Ethyl-4,4'-dichlorobenzilate"
                },
                {
                    id: '1022062',
                    name: 'Malathion'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022063',
                    name: 'Malonaldehyde, sodium salt'
                },
                {
                    id: '1021986',
                    name: 'Ethylene dichloride (1,2-Dichloroethane)'
                },
                {
                    id: '1022064',
                    name: 'Mancozeb'
                },
                {
                    id: '1021987',
                    name: 'Ethyleneimine (Aziridine)'
                },
                {
                    id: '1022065',
                    name: 'Maneb'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1022066',
                    name: 'Marijuana smoke'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022067',
                    name: 'Me-A-alpha-C (2-Amino-3-methyl-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1021990',
                    name: '2-Ethylhexyl acrylate'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1021991',
                    name: 'Ethyl methanesulfonate'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022070',
                    name: 'MeIQ (2-Amino-3,4-dimethylimidazo[4,5-f]quinoline)'
                },
                {
                    id: '1021993',
                    name: 'Etoposide in combination with cisplatin and bleomycin'
                },
                {
                    id: '1022071',
                    name: 'MeIQx (2-Amino-3,8-dimethylimidazo[4,5-f]quinoxaline)'
                },
                {
                    id: '1021994',
                    name: 'Fenoxycarb'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1021995',
                    name: 'Fluoro-edenite fibrous amphibole'
                },
                {
                    id: '1022073',
                    name: 'Mepanipyrim'
                },
                {
                    id: '1021996',
                    name: 'Folpet'
                },
                {
                    id: '1022074',
                    name: '2‑Mercaptobenzothiazole'
                },
                {
                    id: '1021997',
                    name: 'Formaldehyde (gas)'
                },
                {
                    id: '1022075',
                    name: 'Merphalan'
                },
                {
                    id: '1021998',
                    name: '2-(2-Formylhydrazino)-4-(5-nitro-2-furyl)thiazole'
                },
                {
                    id: '1022076',
                    name: 'Mestranol'
                },
                {
                    id: '1021999',
                    name: 'Fumonisin B1'
                },
                {
                    id: '1022077',
                    name: 'Metam potassium'
                },
                {
                    id: '1022000',
                    name: 'Furan'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1022001',
                    name: 'Furazolidone'
                },
                {
                    id: '1022079',
                    name: '5-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1022002',
                    name: 'Furfuryl alcohol'
                },
                {
                    id: '1022080',
                    name: '8-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1022003',
                    name: 'Furmecyclox'
                },
                {
                    id: '1022081',
                    name: 'Methyl acrylate'
                },
                {
                    id: '1022004',
                    name: 'Fusarin C'
                },
                {
                    id: '1022082',
                    name: '2-Methylaziridine (Propyleneimine)'
                },
                {
                    id: '1022005',
                    name: 'Gallium arsenide'
                },
                {
                    id: '1022083',
                    name: 'Methylazoxymethanol'
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1022084',
                    name: 'Methylazoxymethanol acetate'
                },
                {
                    id: '1022007',
                    name: 'Gasoline engine exhaust (condensates/extracts)'
                },
                {
                    id: '1022085',
                    name: 'Methyl carbamate'
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1022086',
                    name: '3-Methylcholanthrene'
                },
                {
                    id: '1022009',
                    name: 'Gentian violet (Crystal violet)'
                },
                {
                    id: '1022087',
                    name: '5-Methylchrysene'
                },
                {
                    id: '1022010',
                    name: 'Glass wool fibers (inhalable and biopersistent)'
                },
                {
                    id: '1022088',
                    name: "4,4'-Methylene bis(2-chloroaniline)"
                },
                {
                    id: '1022011',
                    name: "Glu-P-1 (2-Amino-6-methyldipyrido[1,2- a:3',2'-d]imidazole)"
                },
                {
                    id: '1022089',
                    name: "4,4'-Methylene bis(N,N-dimethyl)benzenamine"
                },
                {
                    id: '1022012',
                    name: "Glu-P-2 (2-Aminodipyrido[1,2-a:3',2'-d]imidazole)"
                },
                {
                    id: '1022090',
                    name: "4,4'-Methylene bis(2-methylaniline)"
                },
                {
                    id: '1022013',
                    name: 'Glycidaldehyde'
                },
                {
                    id: '1022091',
                    name: "4,4'-Methylenedianiline"
                },
                {
                    id: '1022014',
                    name: 'Glycidol'
                },
                {
                    id: '1022092',
                    name: "4,4'-Methylenedianiline dihydrochloride"
                },
                {
                    id: '1022015',
                    name: 'Glycidyl methacrylate'
                },
                {
                    id: '1022093',
                    name: 'Methyleugenol'
                },
                {
                    id: '1022016',
                    name: 'Glyphosate'
                },
                {
                    id: '1022094',
                    name: 'Methylhydrazine and its salts'
                },
                {
                    id: '1022017',
                    name: 'Goldenseal root powder'
                },
                {
                    id: '1022095',
                    name: 'Methylhydrazine'
                },
                {
                    id: '1022018',
                    name: 'Griseofulvin'
                },
                {
                    id: '1022096',
                    name: 'Methylhydrazine sulfate'
                },
                {
                    id: '1022019',
                    name: 'Gyromitrin (Acetaldehyde methylformylhydrazone)'
                },
                {
                    id: '1022097',
                    name: '2-Methylimidazole'
                },
                {
                    id: '1022020',
                    name: 'HC Blue 1'
                },
                {
                    id: '1022098',
                    name: '4-Methylimidazole'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1022099',
                    name: 'Methyl iodide'
                },
                {
                    id: '1022022',
                    name: 'Heptachlor epoxide'
                },
                {
                    id: '1022100',
                    name: 'Methyl isobutyl ketone'
                },
                {
                    id: '1022023',
                    name: 'Herbal remedies containing plant species of the genus Aristolochia'
                },
                {
                    id: '1022101',
                    name: 'Methylmercury compounds'
                },
                {
                    id: '1022102',
                    name: 'Methyl methanesulfonate'
                },
                {
                    id: '1022103',
                    name: '2-Methyl-1-nitroanthraquinone (of uncertain purity)'
                },
                {
                    id: '1022104',
                    name: "N-Methyl-N'-nitro-N-nitrosoguanidine"
                },
                {
                    id: '1022105',
                    name: 'N-Methylolacrylamide'
                },
                {
                    id: '1022106',
                    name: 'α-Methyl styrene (alpha-Methylstyrene)'
                },
                {
                    id: '1022107',
                    name: 'Methylthiouracil'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022109',
                    name: 'Metronidazole'
                },
                {
                    id: '1022110',
                    name: "Michler's ketone"
                },
                {
                    id: '1022111',
                    name: 'Mirex'
                },
                {
                    id: '1022112',
                    name: 'Mitomycin C'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022114',
                    name: 'Molybdenum trioxide'
                },
                {
                    id: '1022115',
                    name: 'MON 4660 (dichloroacetyl-1-oxa-4-azaspiro(4,5)-decane'
                },
                {
                    id: '1022116',
                    name: 'MON 13900 (furilazole)'
                },
                {
                    id: '1022117',
                    name: '3-Monochloropropane-1,2-diol (3-MCPD)'
                },
                {
                    id: '1022118',
                    name: 'Monocrotaline'
                },
                {
                    id: '1022119',
                    name: 'MOPP (vincristine-prednisone-nitrogen mustard-procarbazine mixture)'
                },
                {
                    id: '1022120',
                    name: '5-(Morpholinomethyl)-3-[(5-nitrofurfuryl-idene)-amino]-2-oxazolidinone'
                },
                {
                    id: '1022121',
                    name: 'Mustard Gas'
                },
                {
                    id: '1022122',
                    name: 'MX (3-chloro-4-dichloromethyl-5-hydroxy-2(5H)-furanone)'
                },
                {
                    id: '1022123',
                    name: 'beta-Myrcene'
                },
                {
                    id: '1022124',
                    name: 'Nafenopin'
                },
                {
                    id: '1022125',
                    name: 'Nalidixic acid'
                },
                {
                    id: '1022126',
                    name: 'Naphthalene'
                },
                {
                    id: '1022127',
                    name: '1-Naphthylamine'
                },
                {
                    id: '1022128',
                    name: '2-Naphthylamine'
                },
                {
                    id: '1022129',
                    name: 'Nickel (Metallic)'
                },
                {
                    id: '1022130',
                    name: 'Nickel acetate'
                },
                {
                    id: '1022131',
                    name: 'Nickel carbonate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022133',
                    name: 'Nickel compounds'
                },
                {
                    id: '1022134',
                    name: 'Nickel hydroxide'
                },
                {
                    id: '1022135',
                    name: 'Nickelocene'
                },
                {
                    id: '1022136',
                    name: 'Nickel oxide'
                },
                {
                    id: '1022137',
                    name: 'Nickel refinery dust from the pyrometallurgical process'
                },
                {
                    id: '1022138',
                    name: 'Nickel subsulfide'
                },
                {
                    id: '1022139',
                    name: 'Niridazole'
                },
                {
                    id: '1022140',
                    name: 'Nitrapyrin [Basis for listing changed effective on November 4, 2015]'
                },
                {
                    id: '1022141',
                    name: 'Nitrilotriacetic acid'
                },
                {
                    id: '1022142',
                    name: 'Nitrilotriacetic acid, trisodium salt monohydrate'
                },
                {
                    id: '1022143',
                    name: '5-Nitroacenaphthene'
                },
                {
                    id: '1022144',
                    name: 'o-Nitroanisole'
                },
                {
                    id: '1022145',
                    name: 'para-Nitroanisole'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022147',
                    name: '4-Nitrobiphenyl'
                },
                {
                    id: '1022148',
                    name: '6-Nitrochrysene'
                },
                {
                    id: '1022149',
                    name: 'Nitrofen (technical grade)'
                },
                {
                    id: '1022150',
                    name: '2-Nitrofluorene'
                },
                {
                    id: '1022151',
                    name: 'Nitrofurazone'
                },
                {
                    id: '1022152',
                    name: '1-[(5-Nitrofurfurylidene)-amino]-2-imidazolidinone'
                },
                {
                    id: '1022153',
                    name: 'N-[4-(5-Nitro-2-furyl)-2-thiazolyl]acetamide'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022156',
                    name: 'Nitrogen mustard N-oxide'
                },
                {
                    id: '1022157',
                    name: 'Nitrogen mustard N-oxide hydrochloride'
                },
                {
                    id: '1022158',
                    name: 'Nitromethane'
                },
                {
                    id: '1022159',
                    name: '2-Nitropropane'
                },
                {
                    id: '1022160',
                    name: '1-Nitropyrene'
                },
                {
                    id: '1022161',
                    name: '4-Nitropyrene'
                },
                {
                    id: '1022224',
                    name: 'o-Phenylenediamine dihydrochloride'
                },
                {
                    id: '1022162',
                    name: 'N-Nitrosodi-n-butylamine'
                },
                {
                    id: '1022225',
                    name: 'Phenyl glycidyl ether'
                },
                {
                    id: '1022163',
                    name: 'N-Nitrosodiethanolamine'
                },
                {
                    id: '1022226',
                    name: 'Phenylhydrazine and its salts'
                },
                {
                    id: '1022164',
                    name: 'N-Nitrosodiethylamine'
                },
                {
                    id: '1022227',
                    name: 'Phenylhydrazine'
                },
                {
                    id: '1022165',
                    name: 'N-Nitrosodimethylamine'
                },
                {
                    id: '1022228',
                    name: 'Phenylhydrazine hydrochloride'
                },
                {
                    id: '1022166',
                    name: 'p-Nitrosodiphenylamine'
                },
                {
                    id: '1022229',
                    name: 'o-Phenylphenate, sodium'
                },
                {
                    id: '1022167',
                    name: 'N-Nitrosodiphenylamine'
                },
                {
                    id: '1022230',
                    name: 'o-Phenylphenol'
                },
                {
                    id: '1022168',
                    name: 'N-Nitrosodi-n-propylamine'
                },
                {
                    id: '1022231',
                    name: 'PhiP(2-Amino-1-methyl-6-phenylimidazol[4,5-b]pyridine)'
                },
                {
                    id: '1022169',
                    name: 'N-Nitroso-N-ethylurea'
                },
                {
                    id: '1022232',
                    name: 'Pioglitazone'
                },
                {
                    id: '1022170',
                    name: 'N-Nitrosohexamethyleneimine'
                },
                {
                    id: '1022233',
                    name: 'Pirimicarb'
                },
                {
                    id: '1022171',
                    name: '3-(N-Nitrosomethylamino) propionitrile'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022172',
                    name: '4-(N-Nitrosomethylamino)-1-(3-pyridyl)1-butanone'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022173',
                    name: 'N-Nitrosomethyl-n-butylamine'
                },
                {
                    id: '1022236',
                    name: 'Polychlorinated biphenyls (containing 60 or more percent chlorine by molecular weight)'
                },
                {
                    id: '1022174',
                    name: 'N-Nitrosomethyl-n-decylamine'
                },
                {
                    id: '1022237',
                    name: 'Polychlorinated dibenzo-p-dioxins'
                },
                {
                    id: '1022175',
                    name: 'N-Nitrosomethyl-n-dodecylamine'
                },
                {
                    id: '1022238',
                    name: 'Polychlorinated dibenzofurans'
                },
                {
                    id: '1022176',
                    name: 'N-Nitrosomethylethylamine'
                },
                {
                    id: '1022239',
                    name: 'Polygeenan'
                },
                {
                    id: '1022177',
                    name: 'N-Nitrosomethyl-n-heptylamine'
                },
                {
                    id: '1022240',
                    name: 'Ponceau MX'
                },
                {
                    id: '1022178',
                    name: 'N-Nitrosomethyl-n-hexylamine'
                },
                {
                    id: '1022241',
                    name: 'Ponceau 3R'
                },
                {
                    id: '1022179',
                    name: 'N-Nitrosomethyl-n-nonylamine'
                },
                {
                    id: '1022242',
                    name: 'Potassium bromate'
                },
                {
                    id: '1022180',
                    name: 'N-Nitrosomethyl-n-octylamine'
                },
                {
                    id: '1022243',
                    name: 'Primidone'
                },
                {
                    id: '1022181',
                    name: 'N-Nitrosomethyl-n-pentylamine'
                },
                {
                    id: '1022244',
                    name: 'Procarbazine'
                },
                {
                    id: '1022182',
                    name: 'N-Nitrosomethyl-n-propylamine'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022183',
                    name: 'N-Nitrosomethyl-n-tetradecylamine'
                },
                {
                    id: '1022246',
                    name: 'Procymidone'
                },
                {
                    id: '1022184',
                    name: 'N-Nitrosomethyl-n-undecylamine'
                },
                {
                    id: '1022247',
                    name: 'Progesterone'
                },
                {
                    id: '1022185',
                    name: 'N-Nitroso-N-methylurea'
                },
                {
                    id: '1022248',
                    name: 'Pronamide'
                },
                {
                    id: '1022186',
                    name: 'N-Nitroso-N-methylurethane'
                },
                {
                    id: '1022249',
                    name: 'Propachlor'
                },
                {
                    id: '1022187',
                    name: 'N-Nitrosomethylvinylamine'
                },
                {
                    id: '1022250',
                    name: '1,3-Propane sultone'
                },
                {
                    id: '1022188',
                    name: 'N-Nitrosomorpholine'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022189',
                    name: 'N-Nitrosonornicotine'
                },
                {
                    id: '1022252',
                    name: 'beta-Propiolactone'
                },
                {
                    id: '1022190',
                    name: 'N-Nitrosopiperidine'
                },
                {
                    id: '1022253',
                    name: 'Propoxur'
                },
                {
                    id: '1022191',
                    name: 'N-Nitrosopyrrolidine'
                },
                {
                    id: '1022254',
                    name: 'Propylene glycol mono-t-butyl ether'
                },
                {
                    id: '1022192',
                    name: 'N-Nitrososarcosine'
                },
                {
                    id: '1022255',
                    name: 'Propylene oxide'
                },
                {
                    id: '1022193',
                    name: 'o-Nitrotoluene'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022257',
                    name: 'Pulegone'
                },
                {
                    id: '1022195',
                    name: 'Norethynodrel'
                },
                {
                    id: '1022258',
                    name: 'Pymetrozine'
                },
                {
                    id: '1022196',
                    name: 'Ochratoxin A'
                },
                {
                    id: '1022259',
                    name: 'Pyridine'
                },
                {
                    id: '1022197',
                    name: 'Oil Orange SS'
                },
                {
                    id: '1022260',
                    name: 'Quinoline and its strong acid salts'
                },
                {
                    id: '1022198',
                    name: 'Oral contraceptives, combined'
                },
                {
                    id: '1022261',
                    name: 'Radionuclides'
                },
                {
                    id: '1022199',
                    name: 'Oral contraceptives, sequential'
                },
                {
                    id: '1022262',
                    name: 'Reserpine'
                },
                {
                    id: '1022200',
                    name: 'Oryzalin'
                },
                {
                    id: '1022263',
                    name: 'Residual (heavy) fuel oils'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022202',
                    name: 'Oxazepam'
                },
                {
                    id: '1022265',
                    name: 'Riddelliine'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022266',
                    name: 'Safrole'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022267',
                    name: 'Salted fish, Chinese-style'
                },
                {
                    id: '1022205',
                    name: 'Palygorskite fibers (> 5µm in length)'
                },
                {
                    id: '1022268',
                    name: 'Sedaxane'
                },
                {
                    id: '1022206',
                    name: 'Panfuran S'
                },
                {
                    id: '1022269',
                    name: 'Selenium sulfide'
                },
                {
                    id: '1022207',
                    name: 'Parathion'
                },
                {
                    id: '1022270',
                    name: 'Shale-oils'
                },
                {
                    id: '1022208',
                    name: 'pentabromodiphenyl ether mixture [DE-71 (technical grade)]'
                },
                {
                    id: '1022271',
                    name: 'Silica, crystalline (airborne particles of respirable size)'
                },
                {
                    id: '1022209',
                    name: 'Pentachlorophenol'
                },
                {
                    id: '1022272',
                    name: 'Silicon carbide whiskers'
                },
                {
                    id: '1022210',
                    name: 'Pentachlorophenol and by-products of its synthesis (complex mixture)'
                },
                {
                    id: '1022273',
                    name: 'Soots, tars, and mineral oils (untreated and mildly treated oils and used engine oils)'
                },
                {
                    id: '1022211',
                    name: 'Pentosan polysulfate sodium'
                },
                {
                    id: '1022274',
                    name: 'Spirodiclofen'
                },
                {
                    id: '1022212',
                    name: 'Perfluorooctane sulfonic acid (PFOS) and its salts and transformation and degradation precursors'
                },
                {
                    id: '1022275',
                    name: 'Spironolactone'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022276',
                    name: 'Stanozolol'
                },
                {
                    id: '1022214',
                    name: 'Phenacetin'
                },
                {
                    id: '1022277',
                    name: 'Sterigmatocystin'
                },
                {
                    id: '1022215',
                    name: 'Phenazopyridine'
                },
                {
                    id: '1022278',
                    name: 'Streptozotocin (streptozocin)'
                },
                {
                    id: '1022216',
                    name: 'Phenazopyridine hydrochloride'
                },
                {
                    id: '1022279',
                    name: 'Strong inorganic acid mists containing sulfuric acid'
                },
                {
                    id: '1022217',
                    name: 'Phenesterin'
                },
                {
                    id: '1022280',
                    name: 'Styrene'
                },
                {
                    id: '1022218',
                    name: 'Phenobarbital'
                },
                {
                    id: '1022281',
                    name: 'Styrene oxide'
                },
                {
                    id: '1022219',
                    name: 'Phenolphthalein'
                },
                {
                    id: '1022282',
                    name: 'Sulfallate'
                },
                {
                    id: '1022220',
                    name: 'Phenoxybenzamine'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022221',
                    name: 'Phenoxybenzamine hydrochloride'
                },
                {
                    id: '1022284',
                    name: 'Talc containing asbestiform fibers'
                },
                {
                    id: '1022222',
                    name: 'o-Phenylenediamine and its salts'
                },
                {
                    id: '1022285',
                    name: 'Tamoxifen and its salts'
                },
                {
                    id: '1022223',
                    name: 'o-Phenylenediamine'
                },
                {
                    id: '1022286',
                    name: 'Teriparatide'
                },
                {
                    id: '1022287',
                    name: 'Terrazole'
                },
                {
                    id: '1022288',
                    name: 'Testosterone and its esters'
                },
                {
                    id: '1022289',
                    name: 'Tetrabromobisphenol A'
                },
                {
                    id: '1022290',
                    name: "3,3',4,4'-Tetrachloroazobenzene"
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022292',
                    name: '1,1,1,2-Tetrachloroethane'
                },
                {
                    id: '1022293',
                    name: '1,1,2,2-Tetrachloroethane'
                },
                {
                    id: '1022294',
                    name: 'Tetrachloroethylene (Perchloroethylene)'
                },
                {
                    id: '1022295',
                    name: 'p-a,a,a-Tetrachlorotoluene'
                },
                {
                    id: '1022296',
                    name: 'Tetrachlorvinphos'
                },
                {
                    id: '1022297',
                    name: 'Tetrafluoroethylene'
                },
                {
                    id: '1022298',
                    name: 'Tetrahydrofuran'
                },
                {
                    id: '1022299',
                    name: 'Tetranitromethane'
                },
                {
                    id: '1022300',
                    name: 'Thioacetamide'
                },
                {
                    id: '1022301',
                    name: "4,4'-Thiodianiline"
                },
                {
                    id: '1022302',
                    name: 'Thiodicarb'
                },
                {
                    id: '1022303',
                    name: 'Thiouracil'
                },
                {
                    id: '1022304',
                    name: 'Thiourea'
                },
                {
                    id: '1022305',
                    name: 'Thorium dioxide'
                },
                {
                    id: '1022306',
                    name: 'Titanium dioxide (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1022307',
                    name: 'Tobacco, oral use of smokeless products'
                },
                {
                    id: '1022308',
                    name: 'Tobacco smoke'
                },
                {
                    id: '1022309',
                    name: 'Toluene diisocyanate'
                },
                {
                    id: '1022310',
                    name: 'o-Toluidine'
                },
                {
                    id: '1022311',
                    name: 'o-Toluidine hydrochloride'
                },
                {
                    id: '1022312',
                    name: 'Toxaphene (Polychlorinated camphenes)'
                },
                {
                    id: '1022313',
                    name: 'Toxins derived from Fusarium moniliforme (Fusarium verticillioides)'
                },
                {
                    id: '1022314',
                    name: 'Treosulfan'
                },
                {
                    id: '1022315',
                    name: 'Triamterene'
                },
                {
                    id: '1022316',
                    name: 'S,S,S-Tributyl phosphorotrithioate (Tribufos, DEF)'
                },
                {
                    id: '1022317',
                    name: 'Trichlormethine (Trimustine hydrochloride)'
                },
                {
                    id: '1022318',
                    name: 'Trichloroacetic acid'
                },
                {
                    id: '1022319',
                    name: '1,1,1-Trichloroethane'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022321',
                    name: '2,4,6-Trichlorophenol'
                },
                {
                    id: '1022322',
                    name: '1,2,3-Trichloropropane'
                },
                {
                    id: '1022323',
                    name: '2,4,5-Trimethylaniline and its strong acid salts'
                },
                {
                    id: '1022324',
                    name: 'Trimethylolpropane triacrylate, technical grade'
                },
                {
                    id: '1022325',
                    name: 'Trimethyl phosphate'
                },
                {
                    id: '1022326',
                    name: 'TRIM® VX'
                },
                {
                    id: '1022327',
                    name: '2,4,6-Trinitrotoluene (TNT)'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022329',
                    name: 'Tris(1-aziridinyl)phosphine sulfide (Thiotepa)'
                },
                {
                    id: '1022330',
                    name: 'Tris(2-chloroethyl) phosphate'
                },
                {
                    id: '1022331',
                    name: 'Tris(2,3-dibromopropyl)phosphate'
                },
                {
                    id: '1022332',
                    name: 'Tris(1,3-dichloro-2-propyl) phosphate (TDCPP)'
                },
                {
                    id: '1022333',
                    name: 'Trp-P-1 (Tryptophan-P-1)'
                },
                {
                    id: '1022334',
                    name: 'Trp-P-2 (Tryptophan-P-2)'
                },
                {
                    id: '1022335',
                    name: 'Trypan blue (commercial grade)'
                },
                {
                    id: '1022336',
                    name: 'Unleaded gasoline (wholly vaporized)'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022339',
                    name: 'Vanadium pentoxide (orthorhombic crystalline form)'
                },
                {
                    id: '1022340',
                    name: 'Vinclozolin [basis for listing changed on November 16, 2006]'
                },
                {
                    id: '1022341',
                    name: 'Vinyl bromide'
                },
                {
                    id: '1022342',
                    name: 'Vinyl chloride'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022344',
                    name: '4-Vinyl-1-cyclohexene diepoxide (Vinyl cyclohexenedioxide)'
                },
                {
                    id: '1022345',
                    name: 'Vinyl fluoride'
                },
                {
                    id: '1022346',
                    name: 'Vinylidene chloride \n(1,1-Dichloroethylene)'
                },
                {
                    id: '1022347',
                    name: 'Vinyl trichloride (1,1,2-Trichloroethane)'
                },
                {
                    id: '1022348',
                    name: 'Wood dust'
                },
                {
                    id: '1022349',
                    name: '2,6-Xylidine (2,6-Dimethylaniline)'
                },
                {
                    id: '1022350',
                    name: 'Zalcitabine'
                },
                {
                    id: '1022351',
                    name: 'Zidovudine (AZT)'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        }
    ],
    UNKNOWN: [
        {
            id: '101610',
            name: 'Contains Batteries or Cells?',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: true,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101610',
                description:
                    'We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            values: [
                {
                    id: '1024358',
                    name: 'Batteries'
                },
                {
                    id: '1024359',
                    name: 'Cells'
                },
                {
                    id: '1000325',
                    name: 'None'
                },
                {
                    id: '1024360',
                    name: 'Not Sure'
                }
            ],
            cascade_values: [
                {
                    value_id: '1024359',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: true
                            }
                        }
                    ],
                    value_id_to_qualifications: [
                        {
                            id: '1729439947134305535',
                            title: {
                                key: 'productlisting_qualification_msds_for_batteries',
                                name: 'Safety Data Sheet (SDS) for products with batteries'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_batteries_tip',
                                name: "Upload the SDS for products with batteries. Find them on the manufacturer's website. View example."
                            },
                            sample_image: {
                                height: 1552,
                                width: 806,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101610,
                                    value_list: ['1024358', '1024359'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                },
                {
                    value_id: '1024360',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1000325',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1024358',
                    cascade_sub_properties: [
                        {
                            property_id: '100216',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '100216',
                                is_necessary: true
                            }
                        }
                    ],
                    value_id_to_qualifications: [
                        {
                            id: '1729439947134305535',
                            title: {
                                key: 'productlisting_qualification_msds_for_batteries',
                                name: 'Safety Data Sheet (SDS) for products with batteries'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_batteries_tip',
                                name: "Upload the SDS for products with batteries. Find them on the manufacturer's website. View example."
                            },
                            sample_image: {
                                height: 1552,
                                width: 806,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/7af18e942cb247e29c738e7398a68f48~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101610,
                                    value_list: ['1024358', '1024359'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101619',
            name: 'Other Dangerous Goods or Hazardous Materials',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: true,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101619',
                description:
                    'We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                },
                {
                    id: '1024360',
                    name: 'Not Sure'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    value_id_to_qualifications: [
                        {
                            id: '8647636475739801353',
                            title: {
                                key: 'productlisting_qualification_msds_for_other_hazmar',
                                name: 'Safety Data Sheet (SDS) for other dangerous goods or hazardous materials'
                            },
                            tip: {
                                key: 'productlisting_qualification_msds_for_other_Hazmat_tip',
                                name: 'Upload the SDS for any other hazardous materials or dangerous goods in your product. Example.'
                            },
                            sample_image: {
                                height: 1466,
                                width: 666,
                                uri: 'tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3',
                                url_list: [
                                    'https://p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722',
                                    'https://p19-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/c3644764e09e405a8d065677c02d73e3~tplv-aphluv4xwc-origin-jpeg.jpeg?from=739964722'
                                ]
                            },
                            is_required: true,
                            max_num: 10,
                            is_post_coa: false,
                            any_conditions: [
                                {
                                    property_id: 101619,
                                    value_list: ['1000058'],
                                    op_type: 1
                                }
                            ]
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '100216',
            name: 'Battery type',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_100216',
                description:
                    'We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1024361',
                    name: 'Lithium Ion'
                },
                {
                    id: '1023433',
                    name: 'Lithium Metal'
                },
                {
                    id: '1024362',
                    name: 'Lead/Cadmium/Mercury'
                },
                {
                    id: '1024363',
                    name: 'Alkaline'
                },
                {
                    id: '1020869',
                    name: 'Other'
                }
            ],
            cascade_values: [
                {
                    value_id: '1020869',
                    cascade_sub_properties: [
                        {
                            property_id: '101611',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101611',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101614',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101614',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101623',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101623',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101624',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101624',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101625',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101625',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1023433',
                    cascade_sub_properties: [
                        {
                            property_id: '101611',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101611',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101614',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101614',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101623',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101623',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101624',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101624',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101625',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101625',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1024361',
                    cascade_sub_properties: [
                        {
                            property_id: '101611',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101611',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101614',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101614',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101623',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101623',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101624',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101624',
                                is_necessary: true
                            }
                        },
                        {
                            property_id: '101625',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101625',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1024362',
                    cascade_sub_properties: [
                        {
                            property_id: '101611',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101611',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101614',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101614',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101623',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101623',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101624',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101624',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101625',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101625',
                                is_necessary: false
                            }
                        }
                    ]
                },
                {
                    value_id: '1024363',
                    cascade_sub_properties: [
                        {
                            property_id: '101611',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101611',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101614',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101614',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101623',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101623',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101624',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101624',
                                is_necessary: false
                            }
                        },
                        {
                            property_id: '101625',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101625',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101611',
            name: 'How Batteries Are Packed',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101611',
                description:
                    'There are 3 types of products: "Built-in" (such as phones), "Packed separately" (such as TV remotes), and "Battery-only" (such as power banks). We Need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1024364',
                    name: 'Batteries Built-In'
                },
                {
                    id: '1024365',
                    name: 'Batteries Packed Separately'
                },
                {
                    id: '1024366',
                    name: 'Batteries Only'
                }
            ],
            is_number: false
        },
        {
            id: '101614',
            name: 'Battery or Cell Weight in grams',
            is_multi_choice: false,
            is_enum: false,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101614',
                description:
                    'Indicate the total weight of batteries or cells in the package. We need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            is_number: true
        },
        {
            id: '101623',
            name: 'Number of Batteries or cells',
            is_multi_choice: false,
            is_enum: false,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101623',
                description:
                    'Indicate the total number of batteries or cells in the package. We need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            is_number: true
        },
        {
            id: '101624',
            name: 'Battery or Cell Capacity in Wh',
            is_multi_choice: false,
            is_enum: false,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101624',
                description:
                    'Indicate the capacity of Lithium-ion batteries in watt-hours. We need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            is_number: true
        },
        {
            id: '101625',
            name: 'Battery or Cell Capacity in grams',
            is_multi_choice: false,
            is_enum: false,
            is_necessary: false,
            is_input: true,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101625',
                description:
                    'Indicate the capacity of Lithium-metal batteries in grams. We need this info to ensure the right safety measures during shipping and handling. To learn more about our Hazmat Policy, click %{"type":"link","text":"here","url":"https://seller-us.tiktok.com/university/essay?identity=1&role=1&knowledge_id=2297870046414638&from=policy"}.'
            },
            is_sub_cascade_properties: true,
            is_number: true
        },
        {
            id: '101395',
            name: 'CA Prop 65: Repro. Chems',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101395',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101398',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101398',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101398',
            name: 'Reprotoxic Chemicals',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022353',
                    name: 'Abiraterone acetate'
                },
                {
                    id: '1022354',
                    name: 'Acetazolamide'
                },
                {
                    id: '1022355',
                    name: 'Acetohydroxamic acid'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022357',
                    name: 'Actinomycin D'
                },
                {
                    id: '1022358',
                    name: 'All-trans retinoic acid'
                },
                {
                    id: '1022359',
                    name: 'Alprazolam'
                },
                {
                    id: '1022360',
                    name: 'Altretamine'
                },
                {
                    id: '1022361',
                    name: 'Amantadine hydrochloride'
                },
                {
                    id: '1022362',
                    name: 'Amikacin sulfate'
                },
                {
                    id: '1022363',
                    name: 'Aminoglutethimide'
                },
                {
                    id: '1022364',
                    name: 'Aminoglycosides'
                },
                {
                    id: '1022365',
                    name: 'Aminopterin'
                },
                {
                    id: '1022366',
                    name: 'Amiodarone hydrochloride'
                },
                {
                    id: '1022367',
                    name: 'Amitraz'
                },
                {
                    id: '1022368',
                    name: 'Amoxapine'
                },
                {
                    id: '1022369',
                    name: 'Anabolic steroids'
                },
                {
                    id: '1022370',
                    name: 'Angiotensin converting enzyme (ACE) inhibitors'
                },
                {
                    id: '1022371',
                    name: 'Anisindione'
                },
                {
                    id: '1022372',
                    name: 'Arsenic (inorganic oxides)'
                },
                {
                    id: '1022610',
                    name: 'Aspirin'
                },
                {
                    id: '1022373',
                    name: 'Atenolol'
                },
                {
                    id: '1022374',
                    name: 'Atrazine'
                },
                {
                    id: '1022375',
                    name: 'Auranofin'
                },
                {
                    id: '1022376',
                    name: 'Avermectin B1 (Abamectin)'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1022377',
                    name: 'Barbiturates'
                },
                {
                    id: '1022378',
                    name: 'Beclomethasone dipropionate'
                },
                {
                    id: '1022379',
                    name: 'Benomyl'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1022380',
                    name: 'Benzodiazepines'
                },
                {
                    id: '1022381',
                    name: 'Benzphetamine hydrochloride'
                },
                {
                    id: '1022382',
                    name: 'Bevacizumab'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1022383',
                    name: 'Bisphenol A (BPA)'
                },
                {
                    id: '1022384',
                    name: 'Bromacil lithium salt'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1022385',
                    name: 'Bromoxynil'
                },
                {
                    id: '1022386',
                    name: 'Bromoxynil octanoate'
                },
                {
                    id: '1022387',
                    name: 'Butabarbital sodium'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1022388',
                    name: 'Butyl benzyl phthalate (BBP)d'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1022389',
                    name: 'Cannabis (marijuana) smoke'
                },
                {
                    id: '1022390',
                    name: 'Carbamazepine'
                },
                {
                    id: '1022391',
                    name: 'Carbaryl [Basis for listing changed effective December 27, 2013]'
                },
                {
                    id: '1022392',
                    name: 'Carbon disulfide'
                },
                {
                    id: '1022393',
                    name: 'Carbon monoxide'
                },
                {
                    id: '1022394',
                    name: 'Carboplatin'
                },
                {
                    id: '1022395',
                    name: 'Chenodiol'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1022396',
                    name: 'Chlorcyclizine hydrochloride'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1022397',
                    name: 'Chlordiazepoxide'
                },
                {
                    id: '1022398',
                    name: 'Chlordiazepoxide hydrochloride'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1022399',
                    name: 'Chloroform [Basis for listing changed effective December 23, 2016]'
                },
                {
                    id: '1022400',
                    name: '2-Chloropropionic acid [Basis for listing changed effective December 20, 2013]'
                },
                {
                    id: '1022401',
                    name: 'Chlorpyrifos'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1022402',
                    name: 'Cladribine'
                },
                {
                    id: '1022403',
                    name: 'Clarithromycin'
                },
                {
                    id: '1022404',
                    name: 'Clobetasol propionate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1022405',
                    name: 'Clorazepate dipotassium'
                },
                {
                    id: '1022406',
                    name: 'Cocaine'
                },
                {
                    id: '1022407',
                    name: 'Codeine phosphate'
                },
                {
                    id: '1022408',
                    name: 'Colchicine'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1022409',
                    name: 'Cyanazine'
                },
                {
                    id: '1022410',
                    name: 'Cycloate'
                },
                {
                    id: '1022411',
                    name: 'Cycloheximide'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1022412',
                    name: 'Cyhexatin'
                },
                {
                    id: '1022413',
                    name: 'Cytarabine'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1022414',
                    name: 'Danazol'
                },
                {
                    id: '1022415',
                    name: 'Daunorubicin hydrochloride'
                },
                {
                    id: '1022416',
                    name: '2,4-D butyric acid'
                },
                {
                    id: '1022417',
                    name: "o,p'-DDT"
                },
                {
                    id: '1022418',
                    name: "p,p'-DDT"
                },
                {
                    id: '1022419',
                    name: 'Des-ethyl atrazine (DEA)'
                },
                {
                    id: '1022420',
                    name: 'Des-isopropyl atrazine (DIA)'
                },
                {
                    id: '1022421',
                    name: '2,4-Diamino-6-chloro-s-triazine (DACT)'
                },
                {
                    id: '1022422',
                    name: 'Diazepam'
                },
                {
                    id: '1022423',
                    name: 'Diazoxide'
                },
                {
                    id: '1022424',
                    name: '1,2-Dibromo-3-chloropropane (DBCP) [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1022425',
                    name: '1,1-Dichloro-2,2-bis(p-chloropheny)ethylene (DDE)'
                },
                {
                    id: '1022426',
                    name: 'Dichlorophene'
                },
                {
                    id: '1022427',
                    name: 'Dichlorphenamide'
                },
                {
                    id: '1022428',
                    name: 'Diclofop methyl'
                },
                {
                    id: '1022429',
                    name: 'Dicumarol'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1022430',
                    name: 'Diflunisal'
                },
                {
                    id: '1022431',
                    name: 'Dihydroergotamine mesylate'
                },
                {
                    id: '1022432',
                    name: 'Di-isodecyl phthalate (DIDP)'
                },
                {
                    id: '1022433',
                    name: 'Diltiazem hydrochloride'
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1022434',
                    name: 'Di-n-butyl phthalate (DBP)'
                },
                {
                    id: '1022435',
                    name: 'Di-n-hexyl phthalate (DnHP)'
                },
                {
                    id: '1022436',
                    name: 'm-Dinitrobenzene'
                },
                {
                    id: '1022437',
                    name: 'o-Dinitrobenzene'
                },
                {
                    id: '1022438',
                    name: 'p-Dinitrobenzene'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022439',
                    name: 'Dinitrotoluene (technical grade)'
                },
                {
                    id: '1022440',
                    name: 'Dinocap'
                },
                {
                    id: '1022441',
                    name: 'Dinoseb'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022442',
                    name: 'Disodium cyanodithioimidocarbonate'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022443',
                    name: 'Endrin'
                },
                {
                    id: '1022444',
                    name: 'Environmental tobacco smoke (ETS)'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022445',
                    name: 'Ergotamine tartrate'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022446',
                    name: 'Ethionamide'
                },
                {
                    id: '1022447',
                    name: 'Ethyl alcohol in alcoholic beverages'
                },
                {
                    id: '1022448',
                    name: 'Ethyl dipropylthiocarbamate'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022449',
                    name: 'Ethylene glycol (ingested)'
                },
                {
                    id: '1022450',
                    name: 'Ethylene glycol monoethyl ether'
                },
                {
                    id: '1022451',
                    name: 'Ethylene glycol monoethyl ether acetate'
                },
                {
                    id: '1022452',
                    name: 'Ethylene glycol monomethyl ether'
                },
                {
                    id: '1022453',
                    name: 'Ethylene glycol monomethyl ether acetate'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022454',
                    name: 'Etodolac'
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022455',
                    name: 'Etretinate'
                },
                {
                    id: '1022456',
                    name: 'Fenoxaprop ethyl'
                },
                {
                    id: '1022457',
                    name: 'Filgrastim'
                },
                {
                    id: '1022458',
                    name: 'Fluazifop butyl'
                },
                {
                    id: '1022459',
                    name: 'Flunisolide'
                },
                {
                    id: '1022460',
                    name: 'Fluorouracil'
                },
                {
                    id: '1022461',
                    name: 'Fluoxymesterone'
                },
                {
                    id: '1022462',
                    name: 'Flurazepam hydrochloride'
                },
                {
                    id: '1022463',
                    name: 'Flurbiprofen'
                },
                {
                    id: '1022464',
                    name: 'Flutamide'
                },
                {
                    id: '1022465',
                    name: 'Fluticasone propionate'
                },
                {
                    id: '1022466',
                    name: 'Fluvalinate'
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1022467',
                    name: 'Ganciclovir sodium'
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1022468',
                    name: 'Goserelin acetate'
                },
                {
                    id: '1022469',
                    name: 'Halazepam'
                },
                {
                    id: '1022470',
                    name: 'Halobetasol propionate'
                },
                {
                    id: '1022471',
                    name: 'Haloperidol'
                },
                {
                    id: '1022472',
                    name: 'Halothane'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1022473',
                    name: 'Hexafluoroacetone [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1022474',
                    name: 'n-Hexane'
                },
                {
                    id: '1022475',
                    name: '2,5-Hexanedione'
                },
                {
                    id: '1022476',
                    name: 'Histrelin acetate'
                },
                {
                    id: '1022477',
                    name: 'Hydramethylnon'
                },
                {
                    id: '1022478',
                    name: 'Hydrogen cyanide (HCN) and cyanide salts (CN salts)'
                },
                {
                    id: '1022479',
                    name: 'Cyanide salts that readily dissociate in solution (expressed as cyanide)f'
                },
                {
                    id: '1022480',
                    name: 'Hydrogen cyanidef'
                },
                {
                    id: '1022481',
                    name: 'Sodium cyanidef'
                },
                {
                    id: '1022482',
                    name: 'Potassium cyanidef'
                },
                {
                    id: '1022483',
                    name: 'Hydroxyurea'
                },
                {
                    id: '1022484',
                    name: 'Idarubicin hydrochloride'
                },
                {
                    id: '1022485',
                    name: 'Ifosfamide'
                },
                {
                    id: '1022486',
                    name: 'Iodine-131'
                },
                {
                    id: '1022487',
                    name: 'Isotretinoin'
                },
                {
                    id: '1022488',
                    name: 'Lead [Basis for listing changed effective November 22, 2013]'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1022489',
                    name: 'Leuprolide acetate'
                },
                {
                    id: '1022490',
                    name: 'Levodopa'
                },
                {
                    id: '1022491',
                    name: 'Levonorgestrel implants'
                },
                {
                    id: '1022492',
                    name: 'Linuron'
                },
                {
                    id: '1022493',
                    name: 'Lithium carbonate'
                },
                {
                    id: '1022494',
                    name: 'Lithium citrate'
                },
                {
                    id: '1022495',
                    name: 'Lorazepam'
                },
                {
                    id: '1022496',
                    name: 'Lovastatin'
                },
                {
                    id: '1022497',
                    name: 'Mebendazole'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1022498',
                    name: 'Menotropins'
                },
                {
                    id: '1022499',
                    name: 'Meprobamate'
                },
                {
                    id: '1022500',
                    name: 'Mercaptopurine'
                },
                {
                    id: '1022501',
                    name: 'Mercury and mercury compounds'
                },
                {
                    id: '1022502',
                    name: 'Methacycline hydrochloride'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1022503',
                    name: 'Methanol'
                },
                {
                    id: '1022504',
                    name: 'Methazole'
                },
                {
                    id: '1022505',
                    name: 'Methimazole'
                },
                {
                    id: '1022506',
                    name: 'Methotrexate'
                },
                {
                    id: '1022507',
                    name: 'Methotrexate sodium'
                },
                {
                    id: '1022508',
                    name: 'Methyl bromide, as a structural fumigant'
                },
                {
                    id: '1022509',
                    name: 'Methyl chloride'
                },
                {
                    id: '1022510',
                    name: 'Methyl isobutyl ketone (MIBK)'
                },
                {
                    id: '1022511',
                    name: 'Methyl isocyanate (MIC)'
                },
                {
                    id: '1022512',
                    name: 'Methyl mercury'
                },
                {
                    id: '1022513',
                    name: 'Methyl-n-butyl ketone'
                },
                {
                    id: '1022514',
                    name: 'N-Methylpyrrolidone'
                },
                {
                    id: '1022515',
                    name: 'Methyltestosterone'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022516',
                    name: 'Midazolam hydrochloride'
                },
                {
                    id: '1022517',
                    name: 'Misoprostol'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022518',
                    name: 'Molinate'
                },
                {
                    id: '1022519',
                    name: 'Myclobutanil'
                },
                {
                    id: '1022520',
                    name: 'Nabam'
                },
                {
                    id: '1022521',
                    name: 'Nafarelin acetate'
                },
                {
                    id: '1022522',
                    name: 'Netilmicin sulfate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022523',
                    name: 'Nickel (soluble compounds)'
                },
                {
                    id: '1022524',
                    name: 'Nicotine'
                },
                {
                    id: '1022525',
                    name: 'Nifedipine'
                },
                {
                    id: '1022526',
                    name: 'Nimodipine'
                },
                {
                    id: '1022527',
                    name: 'Nitrapyrin'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022528',
                    name: 'Nitrofurantoin'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022529',
                    name: 'Nitrous oxide [Basis for listing changed effective November 8, 2013]'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022530',
                    name: 'Norethisterone acetate (Norethindrone acetate)'
                },
                {
                    id: '1022531',
                    name: 'Norethisterone (Norethindrone) /Ethinyl estradiol'
                },
                {
                    id: '1022532',
                    name: 'Norethisterone (Norethindrone) /Mestranol'
                },
                {
                    id: '1022533',
                    name: 'Norgestrel'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022534',
                    name: 'Oxydemeton methyl'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022535',
                    name: 'Oxytetracycline hydrochloride'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022536',
                    name: 'Paclitaxel'
                },
                {
                    id: '1022537',
                    name: 'Paramethadione'
                },
                {
                    id: '1022538',
                    name: 'Penicillamine'
                },
                {
                    id: '1022539',
                    name: 'Pentobarbital sodium'
                },
                {
                    id: '1022540',
                    name: 'Pentostatin'
                },
                {
                    id: '1022541',
                    name: 'Perfluorononanoic acid (PFNA) and its salts'
                },
                {
                    id: '1022542',
                    name: 'Perfluorooctane sulfonate (PFOS)'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022543',
                    name: 'Pertuzumab'
                },
                {
                    id: '1022544',
                    name: 'Phenacemide'
                },
                {
                    id: '1022545',
                    name: 'Phenprocoumon'
                },
                {
                    id: '1022546',
                    name: 'Phenylphosphine [Basis for listing changed effective June 6, 2014]'
                },
                {
                    id: '1022547',
                    name: 'Pimozide'
                },
                {
                    id: '1022548',
                    name: 'Pipobroman'
                },
                {
                    id: '1022549',
                    name: 'Plicamycin'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022550',
                    name: 'Potassium dimethyldithiocarbamate'
                },
                {
                    id: '1022551',
                    name: 'Pravastatin sodium'
                },
                {
                    id: '1022552',
                    name: 'Prednisolone sodium phosphate'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022553',
                    name: 'Propazine'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022554',
                    name: 'Pyrimethamine'
                },
                {
                    id: '1022555',
                    name: 'Quazepam'
                },
                {
                    id: '1022556',
                    name: 'Quizalofop-ethyl'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022611',
                    name: 'Retinol/retinyl esters, when in daily dosages in excess of 10,000 IU, or 3,000 retinol equivalents.'
                },
                {
                    id: '1022557',
                    name: 'Ribavirin'
                },
                {
                    id: '1022558',
                    name: 'Rifampin'
                },
                {
                    id: '1022559',
                    name: 'Secobarbital sodium'
                },
                {
                    id: '1022560',
                    name: 'Sermorelin acetate'
                },
                {
                    id: '1022561',
                    name: 'Simazine'
                },
                {
                    id: '1022562',
                    name: 'Sodium dimethyldithiocarbamate'
                },
                {
                    id: '1022563',
                    name: 'Sodium fluoroacetate'
                },
                {
                    id: '1022564',
                    name: 'Streptomycin sulfate'
                },
                {
                    id: '1022565',
                    name: 'Streptozocin (streptozotocin)'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022566',
                    name: 'Sulfur dioxidee'
                },
                {
                    id: '1022567',
                    name: 'Sulindac'
                },
                {
                    id: '1022568',
                    name: 'Tamoxifen citrate'
                },
                {
                    id: '1022569',
                    name: 'Temazepam'
                },
                {
                    id: '1022570',
                    name: 'Teniposide'
                },
                {
                    id: '1022571',
                    name: 'Terbacil'
                },
                {
                    id: '1022572',
                    name: 'Testosterone cypionate'
                },
                {
                    id: '1022573',
                    name: 'Testosterone enanthate'
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022574',
                    name: 'Δ9-Tetrahydrocannabinol (Δ9-THC)'
                },
                {
                    id: '1022575',
                    name: 'Thalidomide'
                },
                {
                    id: '1022576',
                    name: 'Thioguanine'
                },
                {
                    id: '1022577',
                    name: 'Thiophanate methyl'
                },
                {
                    id: '1022578',
                    name: 'Tobacco smoke (primary)'
                },
                {
                    id: '1022579',
                    name: 'Tobramycin sulfate'
                },
                {
                    id: '1022580',
                    name: 'Toluene'
                },
                {
                    id: '1022581',
                    name: 'Topiramate'
                },
                {
                    id: '1022582',
                    name: 'Triadimefon'
                },
                {
                    id: '1022583',
                    name: 'Triazolam'
                },
                {
                    id: '1022584',
                    name: 'Tributyltin methacrylate'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022585',
                    name: 'Trientine hydrochloride'
                },
                {
                    id: '1022586',
                    name: 'Triforine'
                },
                {
                    id: '1022587',
                    name: 'Trilostane'
                },
                {
                    id: '1022588',
                    name: 'Trimethadione'
                },
                {
                    id: '1022589',
                    name: 'Trimetrexate glucuronate'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022590',
                    name: 'Urofollitropin'
                },
                {
                    id: '1022591',
                    name: 'Valproate (Valproic acid)'
                },
                {
                    id: '1022592',
                    name: 'Vinblastine sulfate'
                },
                {
                    id: '1022593',
                    name: 'Vinclozolin'
                },
                {
                    id: '1022594',
                    name: 'Vincristine sulfate'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022595',
                    name: 'Vinyl cyclohexene dioxide (4-Vinyl-1-cyclohexene diepoxide)'
                },
                {
                    id: '1022596',
                    name: 'Vismodegib'
                },
                {
                    id: '1022597',
                    name: 'Warfarin'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        },
        {
            id: '101400',
            name: 'CA Prop 65: Carcinogens',
            is_multi_choice: false,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            tip: {
                desc_starling_key: 'property_tip_desc_601213_101400',
                description:
                    'It is your obligation to adhere to all relevant regulations imposed by California\'s Proposition 65 (Prop 65). This includes the duty to affix a Prop 65 warning to the products you offer for sale on TikTok Shop, regardless of whether you employ fewer than 10 individuals and even if another party is listing the identical product. For more information, see %{"type":"link","text":"here","url":"https://www.p65warnings.ca.gov/"}.'
            },
            values: [
                {
                    id: '1000058',
                    name: 'Yes'
                },
                {
                    id: '1000059',
                    name: 'No'
                }
            ],
            cascade_values: [
                {
                    value_id: '1000058',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 1,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: true
                            }
                        }
                    ]
                },
                {
                    value_id: '1000059',
                    cascade_sub_properties: [
                        {
                            property_id: '101397',
                            cascade_type: 2,
                            overwrite_property: {
                                id: '101397',
                                is_necessary: false
                            }
                        }
                    ]
                }
            ],
            is_number: false
        },
        {
            id: '101397',
            name: 'Carcinogen',
            is_multi_choice: true,
            is_enum: true,
            is_necessary: false,
            is_input: false,
            label: {
                key_property: false,
                binding_property: false,
                shopping_guide_property: false,
                legal_property: true
            },
            is_sub_cascade_properties: true,
            values: [
                {
                    id: '1022600',
                    name: 'A-alpha-C (2-Amino-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1022605',
                    name: 'Acetaldehyde'
                },
                {
                    id: '1022601',
                    name: 'Acetamide'
                },
                {
                    id: '1022602',
                    name: 'Acetochlor'
                },
                {
                    id: '1022603',
                    name: '2-Acetylaminofluorene'
                },
                {
                    id: '1022604',
                    name: 'Acifluorfen sodium'
                },
                {
                    id: '1022356',
                    name: 'acrylamide'
                },
                {
                    id: '1022606',
                    name: 'Acrylonitrile'
                },
                {
                    id: '1022607',
                    name: 'Actinomycin D [Basis for listing changed effective February 22, 2013]'
                },
                {
                    id: '1022608',
                    name: 'AF-2;[2-(2-furyl)-3-(5-nitro-2-furyl)]acrylamide'
                },
                {
                    id: '1022609',
                    name: 'Aflatoxins'
                },
                {
                    id: '1021717',
                    name: 'Alachlor'
                },
                {
                    id: '1021718',
                    name: 'Alcoholic beverages'
                },
                {
                    id: '1021719',
                    name: 'Alcoholic beverages, when associated with alcohol abuse'
                },
                {
                    id: '1021720',
                    name: 'Aldrin'
                },
                {
                    id: '1021721',
                    name: 'Aloe vera, non-decolorized whole leaf extract'
                },
                {
                    id: '1021727',
                    name: '2-Aminoanthraquinone'
                },
                {
                    id: '1021722',
                    name: 'p-Aminoazobenzene'
                },
                {
                    id: '1021723',
                    name: 'o-Aminoazotoluene'
                },
                {
                    id: '1021724',
                    name: '4-Aminobiphenyl (4-aminodiphenyl)'
                },
                {
                    id: '1021725',
                    name: '2-Amino-4-chlorophenol'
                },
                {
                    id: '1021726',
                    name: '1-Amino-2,4-dibromoanthraquinone'
                },
                {
                    id: '1021728',
                    name: '3-Amino-9-ethylcarbazole hydrochloride'
                },
                {
                    id: '1021729',
                    name: '2-Aminofluorene'
                },
                {
                    id: '1021730',
                    name: '1-Amino-2-methylanthraquinone'
                },
                {
                    id: '1021731',
                    name: '2-Amino-5-(5-nitro-2-furyl)-1,3,4-thiadiazole'
                },
                {
                    id: '1021732',
                    name: '4-Amino-2-nitrophenol'
                },
                {
                    id: '1021733',
                    name: 'Amitrole'
                },
                {
                    id: '1021734',
                    name: 'Amsacrine'
                },
                {
                    id: '1021735',
                    name: 'Analgesic mixtures containing Phenacetin'
                },
                {
                    id: '1021736',
                    name: 'Androstenedione'
                },
                {
                    id: '1021737',
                    name: 'Aniline'
                },
                {
                    id: '1021738',
                    name: 'Aniline hydrochloride'
                },
                {
                    id: '1021739',
                    name: 'o-Anisidine'
                },
                {
                    id: '1021740',
                    name: 'o-Anisidine hydrochloride'
                },
                {
                    id: '1021741',
                    name: 'Anthracene'
                },
                {
                    id: '1021742',
                    name: 'Anthraquinone'
                },
                {
                    id: '1021743',
                    name: 'Antimony oxide (Antimony trioxide)'
                },
                {
                    id: '1021744',
                    name: 'Aramite'
                },
                {
                    id: '1021745',
                    name: 'Areca nut'
                },
                {
                    id: '1021746',
                    name: 'Aristolochic acids'
                },
                {
                    id: '1021747',
                    name: 'Arsenic (inorganic arsenic compounds)'
                },
                {
                    id: '1021748',
                    name: 'Asbestos'
                },
                {
                    id: '1021749',
                    name: 'Auramine'
                },
                {
                    id: '1021750',
                    name: 'Azacitidine'
                },
                {
                    id: '1021751',
                    name: 'Azaserine'
                },
                {
                    id: '1021752',
                    name: 'azathioprine'
                },
                {
                    id: '1021753',
                    name: 'Azobenzene'
                },
                {
                    id: '1021754',
                    name: 'Benthiavalicarb-isopropyl'
                },
                {
                    id: '1021755',
                    name: 'Benz[a]anthracene'
                },
                {
                    id: '1021756',
                    name: 'benzene'
                },
                {
                    id: '1021757',
                    name: 'Benzidine [and its salts]'
                },
                {
                    id: '1021758',
                    name: 'Benzidine-based dyes'
                },
                {
                    id: '1021759',
                    name: 'Benzo[b]fluoranthene'
                },
                {
                    id: '1021760',
                    name: 'Benzo[j]fluoranthene'
                },
                {
                    id: '1021761',
                    name: 'Benzo[k]fluoranthene'
                },
                {
                    id: '1021762',
                    name: 'Benzofuran'
                },
                {
                    id: '1021763',
                    name: 'Benzophenone'
                },
                {
                    id: '1021764',
                    name: 'Benzo[a]pyrene'
                },
                {
                    id: '1021765',
                    name: 'Benzotrichloride'
                },
                {
                    id: '1021766',
                    name: 'Benzyl chloride'
                },
                {
                    id: '1021767',
                    name: 'Benzyl violet 4B'
                },
                {
                    id: '1021768',
                    name: 'Beryllium and beryllium compounds'
                },
                {
                    id: '1021769',
                    name: 'Beryllium'
                },
                {
                    id: '1021770',
                    name: 'Beryllium oxide'
                },
                {
                    id: '1021771',
                    name: 'Beryllium sulfate'
                },
                {
                    id: '1021772',
                    name: 'Betel quid with tobacco'
                },
                {
                    id: '1021773',
                    name: 'Betel quid without tobacco'
                },
                {
                    id: '1021774',
                    name: '2,2-Bis(bromomethyl)-1,3-propanediol'
                },
                {
                    id: '1021775',
                    name: 'Bis(2-chloroethyl)ether'
                },
                {
                    id: '1021776',
                    name: 'N,N-Bis(2-chloroethyl)-2-naphthylamine (Chlornapazine)'
                },
                {
                    id: '1021777',
                    name: 'bischloroethyl nitrosourea (bcnu) (carmustine)'
                },
                {
                    id: '1021778',
                    name: 'Bis(chloromethyl)ether'
                },
                {
                    id: '1021779',
                    name: 'Bis(2-chloro-1-methylethyl)ether, technical grade'
                },
                {
                    id: '1021780',
                    name: 'Bitumens, extracts of steam-refined and air refined'
                },
                {
                    id: '1021781',
                    name: 'Bracken fern'
                },
                {
                    id: '1021782',
                    name: 'Bromate'
                },
                {
                    id: '1021783',
                    name: 'Bromochloroacetic acid'
                },
                {
                    id: '1021784',
                    name: '1-Bromo-3-chloropropane'
                },
                {
                    id: '1021785',
                    name: 'Bromodichloroacetic acid'
                },
                {
                    id: '1021786',
                    name: 'Bromodichloromethane'
                },
                {
                    id: '1021787',
                    name: 'Bromoethane'
                },
                {
                    id: '1021788',
                    name: 'Bromoform'
                },
                {
                    id: '1021789',
                    name: '1-bromopropane (1-bp)'
                },
                {
                    id: '1021790',
                    name: '2-bromopropane (2-bp)'
                },
                {
                    id: '1021791',
                    name: '1,3-butadiene'
                },
                {
                    id: '1021792',
                    name: '1,4-butanediol dimethanesulfonate (busulfan)'
                },
                {
                    id: '1021793',
                    name: 'Butylated hydroxyanisole'
                },
                {
                    id: '1021794',
                    name: '1-Butyl glycidyl ether'
                },
                {
                    id: '1021795',
                    name: 'beta-Butyrolactone'
                },
                {
                    id: '1021796',
                    name: 'Cacodylic acid'
                },
                {
                    id: '1021797',
                    name: 'Cadmium and cadmium compounds'
                },
                {
                    id: '1021798',
                    name: 'cadmium'
                },
                {
                    id: '1021799',
                    name: 'Caffeic acid'
                },
                {
                    id: '1021800',
                    name: 'Captafol'
                },
                {
                    id: '1021801',
                    name: 'Captan'
                },
                {
                    id: '1021802',
                    name: 'Carbaryl'
                },
                {
                    id: '1021803',
                    name: 'Carbazole'
                },
                {
                    id: '1021804',
                    name: 'Carbon black (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1021805',
                    name: 'Carbon-black extracts'
                },
                {
                    id: '1021806',
                    name: 'Carbon tetrachloride'
                },
                {
                    id: '1021807',
                    name: 'N-Carboxymethyl-N-nitrosourea'
                },
                {
                    id: '1021808',
                    name: 'Catechol'
                },
                {
                    id: '1021809',
                    name: 'Ceramic fibers (airborne particles of respirable size)'
                },
                {
                    id: '1021810',
                    name: 'Certain combined chemotherapy for lymphomas'
                },
                {
                    id: '1021811',
                    name: 'Chloral'
                },
                {
                    id: '1021812',
                    name: 'Chloral hydrate'
                },
                {
                    id: '1021813',
                    name: 'chlorambucil'
                },
                {
                    id: '1021814',
                    name: 'Chloramphenicol sodium succinate'
                },
                {
                    id: '1021815',
                    name: 'Chlordane'
                },
                {
                    id: '1021816',
                    name: 'chlordecone (kepone)'
                },
                {
                    id: '1021817',
                    name: 'Chlordimeform'
                },
                {
                    id: '1021818',
                    name: 'Chlorendic acid'
                },
                {
                    id: '1021819',
                    name: 'Chlorinated paraffins (Average chain length, C12;approximately 60 percent chlorine by weight)'
                },
                {
                    id: '1021820',
                    name: 'p-Chloroaniline'
                },
                {
                    id: '1021821',
                    name: 'p-Chloroaniline hydrochloride'
                },
                {
                    id: '1021822',
                    name: 'Chloroethane (Ethyl chloride)'
                },
                {
                    id: '1021823',
                    name: '1-(2-chloroethyl)-3-cyclohexyl-1-nitrosourea (ccnu) (lomustine)'
                },
                {
                    id: '1021824',
                    name: '1-(2-Chloroethyl)-3-(4-methylcyclohexyl)-1-nitrosourea (Methyl-CCNU)'
                },
                {
                    id: '1021825',
                    name: 'Chloroform'
                },
                {
                    id: '1021826',
                    name: 'Chloromethyl methyl ether (technical grade)'
                },
                {
                    id: '1021827',
                    name: '3-Chloro-2-methylpropene'
                },
                {
                    id: '1021828',
                    name: '1-Chloro-4-nitrobenzene'
                },
                {
                    id: '1021829',
                    name: '2-Chloronitrobenzene'
                },
                {
                    id: '1021830',
                    name: '4-Chloro-o-phenylenediamine'
                },
                {
                    id: '1021831',
                    name: 'Chloroprene'
                },
                {
                    id: '1021832',
                    name: 'Chlorothalonil'
                },
                {
                    id: '1021833',
                    name: 'p-Chloro-o-toluidine'
                },
                {
                    id: '1021834',
                    name: 'p-Chloro-o-toluidine, strong acid salts of'
                },
                {
                    id: '1021835',
                    name: 'p-Chloro-o-toluidine, hydrochloride'
                },
                {
                    id: '1021836',
                    name: '5-Chloro-o-toluidine and its strong acid salts'
                },
                {
                    id: '1021837',
                    name: 'Chlorotrianisene'
                },
                {
                    id: '1021838',
                    name: 'p-chloro-α,α,α-trifluorotoluene (para-Chlorobenzotrifluoride, PCBTF)'
                },
                {
                    id: '1021839',
                    name: 'Chlorozotocin'
                },
                {
                    id: '1021840',
                    name: 'chromium (hexavalent compounds)'
                },
                {
                    id: '1021841',
                    name: 'Chrysene'
                },
                {
                    id: '1021842',
                    name: 'C.I. Acid Red 114'
                },
                {
                    id: '1021843',
                    name: 'C.I. Basic Red 9 monohydrochloride'
                },
                {
                    id: '1021844',
                    name: 'C.I. Direct Blue 15'
                },
                {
                    id: '1021845',
                    name: 'C.I. Direct Blue 218'
                },
                {
                    id: '1021846',
                    name: 'C.I. Disperse Yellow 3'
                },
                {
                    id: '1021847',
                    name: 'C.I. Solvent Yellow 14'
                },
                {
                    id: '1021848',
                    name: 'Ciclosporin (Cyclosporin A; Cyclosporine)'
                },
                {
                    id: '1021849',
                    name: 'cidofovir'
                },
                {
                    id: '1021850',
                    name: 'Cinnamyl anthranilate'
                },
                {
                    id: '1021851',
                    name: 'Cisplatin'
                },
                {
                    id: '1021852',
                    name: 'Citrus Red No. 2'
                },
                {
                    id: '1021853',
                    name: 'Clofibrate'
                },
                {
                    id: '1021854',
                    name: 'clomiphene citrate'
                },
                {
                    id: '1021855',
                    name: 'CMNP (pyrazachlor)'
                },
                {
                    id: '1021856',
                    name: 'Coal-tar pitch'
                },
                {
                    id: '1021857',
                    name: 'Cobalt metal powder'
                },
                {
                    id: '1021858',
                    name: 'Cobalt [II] oxide'
                },
                {
                    id: '1021859',
                    name: 'Cobalt sulfate'
                },
                {
                    id: '1021860',
                    name: 'Cobalt sulfate heptahydrate'
                },
                {
                    id: '1021861',
                    name: 'Coconut oil diethanolamine condensate (cocamide diethanolamine)'
                },
                {
                    id: '1021862',
                    name: 'Coke oven emissions'
                },
                {
                    id: '1021863',
                    name: 'conjugated estrogens'
                },
                {
                    id: '1021864',
                    name: 'Creosotes'
                },
                {
                    id: '1021865',
                    name: 'p-Cresidine'
                },
                {
                    id: '1021866',
                    name: 'Cumene'
                },
                {
                    id: '1021867',
                    name: 'Cupferron'
                },
                {
                    id: '1021868',
                    name: 'Cycasin'
                },
                {
                    id: '1021869',
                    name: 'Cyclopenta[cd]pyrene'
                },
                {
                    id: '1021870',
                    name: 'cyclophosphamide (anhydrous)'
                },
                {
                    id: '1021871',
                    name: 'cyclophosphamide (hydrated)'
                },
                {
                    id: '1021872',
                    name: 'Cytembena'
                },
                {
                    id: '1021873',
                    name: 'D&C Orange No. 17'
                },
                {
                    id: '1021874',
                    name: 'D&C Red No. 8'
                },
                {
                    id: '1021875',
                    name: 'D&C Red No. 9'
                },
                {
                    id: '1021876',
                    name: 'D&C Red No. 19'
                },
                {
                    id: '1021877',
                    name: 'dacarbazine'
                },
                {
                    id: '1021878',
                    name: 'Daminozide'
                },
                {
                    id: '1021879',
                    name: 'Dantron (Chrysazin; 1,8-Dihydroxyanthraquinone)'
                },
                {
                    id: '1021880',
                    name: 'Daunomycin'
                },
                {
                    id: '1021881',
                    name: 'DDD (Dichlorodiphenyl-dichloroethane)'
                },
                {
                    id: '1021882',
                    name: 'DDE (Dichlorodiphenyl-dichloroethylene)'
                },
                {
                    id: '1021883',
                    name: 'DDT (Dichlorodiphenyl-trichloroethane)'
                },
                {
                    id: '1021884',
                    name: 'DDVP (Dichlorvos)'
                },
                {
                    id: '1021885',
                    name: "N,N'-Diacetylbenzidine"
                },
                {
                    id: '1021886',
                    name: '2,4-Diaminoanisole'
                },
                {
                    id: '1021887',
                    name: '2,4-Diaminoanisole sulfate'
                },
                {
                    id: '1021888',
                    name: "4,4'-Diaminodiphenyl ether (4,4'-Oxydianiline)"
                },
                {
                    id: '1021889',
                    name: '2,4-Diaminotoluene'
                },
                {
                    id: '1021890',
                    name: 'Diazoaminobenzene'
                },
                {
                    id: '1021891',
                    name: 'Dibenz[a,h]acridine'
                },
                {
                    id: '1021892',
                    name: 'Dibenz[a,j]acridine'
                },
                {
                    id: '1021893',
                    name: 'Dibenzanthracenes'
                },
                {
                    id: '1021894',
                    name: 'Dibenz[a,c]anthracene'
                },
                {
                    id: '1021895',
                    name: 'Dibenz[a,h]anthracene'
                },
                {
                    id: '1021896',
                    name: 'Dibenz[a,j]anthracene'
                },
                {
                    id: '1021897',
                    name: '7H-Dibenzo[c,g]carbazole'
                },
                {
                    id: '1021898',
                    name: 'Dibenzo[a,e]pyrene'
                },
                {
                    id: '1021899',
                    name: 'Dibenzo[a,h]pyrene'
                },
                {
                    id: '1021900',
                    name: 'Dibenzo[a,i]pyrene'
                },
                {
                    id: '1021901',
                    name: 'Dibenzo[a,l]pyrene'
                },
                {
                    id: '1021902',
                    name: 'Dibromoacetic acid'
                },
                {
                    id: '1021903',
                    name: 'Dibromoacetonitrile'
                },
                {
                    id: '1021904',
                    name: '1,2-Dibromo-3-chloropropane (DBCP)'
                },
                {
                    id: '1021905',
                    name: '2,3-Dibromo-1-propanol'
                },
                {
                    id: '1021906',
                    name: 'dichloroacetic acid'
                },
                {
                    id: '1021907',
                    name: 'p-Dichlorobenzene'
                },
                {
                    id: '1021908',
                    name: "3,3'-Dichlorobenzidine"
                },
                {
                    id: '1021909',
                    name: "3,3'-Dichlorobenzidine dihydrochloride"
                },
                {
                    id: '1021910',
                    name: '1,4-Dichloro-2-butene'
                },
                {
                    id: '1021911',
                    name: "3,3'-Dichloro-4,4'-diamino-diphenyl ether"
                },
                {
                    id: '1021912',
                    name: '1,1-Dichloroethane'
                },
                {
                    id: '1021913',
                    name: 'Dichloromethane (Methylene chloride)'
                },
                {
                    id: '1021914',
                    name: '1,4-Dichloro-2-nitrobenzene'
                },
                {
                    id: '1021915',
                    name: '2,4-Dichloro-1-nitrobenzene'
                },
                {
                    id: '1021916',
                    name: '1,2-Dichloropropane'
                },
                {
                    id: '1021917',
                    name: '1,3-Dichloro-2-propanol (1,3-DCP)'
                },
                {
                    id: '1021918',
                    name: '1,3-Dichloropropene'
                },
                {
                    id: '1021919',
                    name: 'Diclofop-methyl'
                },
                {
                    id: '1021920',
                    name: 'Dieldrin'
                },
                {
                    id: '1021921',
                    name: 'Diepoxybutane'
                },
                {
                    id: '1021922',
                    name: 'Diesel engine exhaust'
                },
                {
                    id: '1021923',
                    name: 'Diethanolamine'
                },
                {
                    id: '1021924',
                    name: 'di(2-ethylhexyl)phthalate (dehp)'
                },
                {
                    id: '1021925',
                    name: '1,2-Diethylhydrazine'
                },
                {
                    id: '1021926',
                    name: 'diethylstilbestrol (des)'
                },
                {
                    id: '1021927',
                    name: 'Diethyl sulfate'
                },
                {
                    id: '1021928',
                    name: 'Diglycidyl resorcinol ether (DGRE)'
                },
                {
                    id: '1021929',
                    name: 'Dihydrosafrole'
                },
                {
                    id: '1021930',
                    name: 'Diisononyl phthalate (DINP)'
                },
                {
                    id: '1021931',
                    name: 'Diisopropyl sulfate'
                },
                {
                    id: '1021932',
                    name: "3,3'-Dimethoxybenzidine (o-Dianisidine)"
                },
                {
                    id: '1021933',
                    name: "3,3'-Dimethoxybenzidine dihydrochloride"
                },
                {
                    id: '1021934',
                    name: "3,3'-Dimethoxybenzidine-based dyes metabolized to 3,3'-dimethoxybenzidine"
                },
                {
                    id: '1021935',
                    name: 'n,n-dimethylacetamide'
                },
                {
                    id: '1021936',
                    name: '4-Dimethylaminoazobenzene'
                },
                {
                    id: '1021937',
                    name: 'trans-2-[(Dimethylamino)methylimino]-5-[2-(5-nitro-2-furyl)vinyl]-1,3,4-oxadiazole'
                },
                {
                    id: '1021938',
                    name: '7,12-Dimethylbenz(a)anthracene'
                },
                {
                    id: '1021939',
                    name: "3,3'-Dimethylbenzidine (ortho-Tolidine)"
                },
                {
                    id: '1021940',
                    name: "3,3'-Dimethylbenzidine-based dyes metabolized to 3,3'-dimethylbenzidine"
                },
                {
                    id: '1021941',
                    name: "3,3'-Dimethylbenzidine dihydrochloride"
                },
                {
                    id: '1021942',
                    name: 'Dimethylcarbamoyl chloride'
                },
                {
                    id: '1021943',
                    name: 'N,N-Dimethylformamide'
                },
                {
                    id: '1021944',
                    name: '1,1-Dimethylhydrazine (UDMH)'
                },
                {
                    id: '1021945',
                    name: '1,2-Dimethylhydrazine'
                },
                {
                    id: '1021946',
                    name: 'Dimethyl hydrogen phosphite'
                },
                {
                    id: '1022024',
                    name: 'hexachlorobenzene'
                },
                {
                    id: '1021947',
                    name: '2,6-Dimethyl-N-nitrosomorpholine (DMNM)'
                },
                {
                    id: '1022025',
                    name: 'Hexachlorobutadiene'
                },
                {
                    id: '1021948',
                    name: 'Dimethyl sulfate'
                },
                {
                    id: '1022026',
                    name: 'Hexachlorocyclohexane (technical grade)'
                },
                {
                    id: '1021949',
                    name: 'N,N-Dimethyl-p-toluidine'
                },
                {
                    id: '1022027',
                    name: 'Hexachlorocyclohexane (alpha isomer)'
                },
                {
                    id: '1021950',
                    name: 'Dimethylvinylchloride'
                },
                {
                    id: '1022028',
                    name: 'Hexachlorocyclohexane (beta isomer)'
                },
                {
                    id: '1021951',
                    name: '3,7-Dinitrofluoranthene'
                },
                {
                    id: '1022029',
                    name: 'Hexachlorocyclohexane (gamma isomer)'
                },
                {
                    id: '1021952',
                    name: '3,9-Dinitrofluoranthene'
                },
                {
                    id: '1022030',
                    name: 'Hexachlorodibenzodioxin'
                },
                {
                    id: '1021953',
                    name: '1,3-Dinitropyrene'
                },
                {
                    id: '1022031',
                    name: 'Hexachloroethane'
                },
                {
                    id: '1021954',
                    name: '1,6-Dinitropyrene'
                },
                {
                    id: '1022032',
                    name: '2,4-Hexadienal (89% trans, trans isomer; 11% cis, trans isomer)'
                },
                {
                    id: '1021955',
                    name: '1,8-Dinitropyrene'
                },
                {
                    id: '1022033',
                    name: 'hexamethylphosphoramide'
                },
                {
                    id: '1021956',
                    name: '2,4-dinitrotoluene'
                },
                {
                    id: '1022034',
                    name: 'Hydrazine'
                },
                {
                    id: '1021957',
                    name: '2,6-dinitrotoluene'
                },
                {
                    id: '1022035',
                    name: 'Hydrazine sulfate'
                },
                {
                    id: '1021958',
                    name: 'Dinitrotoluene mixture, 2,4-/2,6-'
                },
                {
                    id: '1022036',
                    name: 'Hydrazobenzene (1,2-Diphenylhydrazine)'
                },
                {
                    id: '1021959',
                    name: 'Di-n-propyl isocinchomeronate (MGK Repellent 326)'
                },
                {
                    id: '1022037',
                    name: '1-Hydroxyanthraquinone'
                },
                {
                    id: '1021960',
                    name: '1,4-Dioxane'
                },
                {
                    id: '1022038',
                    name: 'Imazalil'
                },
                {
                    id: '1021961',
                    name: 'diphenylhydantoin (phenytoin)'
                },
                {
                    id: '1022039',
                    name: 'Indeno[1,2,3-cd]pyrene'
                },
                {
                    id: '1021962',
                    name: 'Diphenylhydantoin (Phenytoin), sodium salt'
                },
                {
                    id: '1022040',
                    name: 'Indium phosphide'
                },
                {
                    id: '1021963',
                    name: 'Direct Black 38 (technical grade)'
                },
                {
                    id: '1022041',
                    name: 'Indium tin oxide'
                },
                {
                    id: '1021964',
                    name: 'Direct Blue 6 (technical grade)'
                },
                {
                    id: '1022042',
                    name: 'IQ (2-Amino-3-methylimidazo[4,5-f] quinoline)'
                },
                {
                    id: '1021965',
                    name: 'Direct Brown 95 (technical grade)'
                },
                {
                    id: '1022043',
                    name: 'Iprodione'
                },
                {
                    id: '1021966',
                    name: 'Disperse Blue 1'
                },
                {
                    id: '1022044',
                    name: 'Iprovalicarb'
                },
                {
                    id: '1021967',
                    name: 'Diuron'
                },
                {
                    id: '1022045',
                    name: 'Iron dextran complex'
                },
                {
                    id: '1021968',
                    name: 'doxorubicin hydrochloride (adriamycin)'
                },
                {
                    id: '1022046',
                    name: 'Isobutyl nitrite'
                },
                {
                    id: '1021969',
                    name: 'Emissions from combustion of coal'
                },
                {
                    id: '1022047',
                    name: 'Isoprene'
                },
                {
                    id: '1021970',
                    name: 'Emissions from high-temperature unrefined rapeseed oil'
                },
                {
                    id: '1022048',
                    name: 'Isopyrazam'
                },
                {
                    id: '1021971',
                    name: 'epichlorohydrin'
                },
                {
                    id: '1022049',
                    name: 'Isoxaflutole'
                },
                {
                    id: '1021972',
                    name: 'Epoxiconazole'
                },
                {
                    id: '1022050',
                    name: 'Kresoxim-methyl'
                },
                {
                    id: '1021973',
                    name: 'Erionite'
                },
                {
                    id: '1022051',
                    name: 'Lactofen'
                },
                {
                    id: '1021974',
                    name: 'Estradiol 17B'
                },
                {
                    id: '1022052',
                    name: 'Lasiocarpine'
                },
                {
                    id: '1021975',
                    name: 'Estragole'
                },
                {
                    id: '1022053',
                    name: 'Lead and lead compounds'
                },
                {
                    id: '1021976',
                    name: 'Estrogens, steroidal'
                },
                {
                    id: '1022054',
                    name: 'lead'
                },
                {
                    id: '1021977',
                    name: 'Estrogen-progestogen (combined) used as menopausal therapy'
                },
                {
                    id: '1022055',
                    name: 'Lead acetate'
                },
                {
                    id: '1021978',
                    name: 'Estrone'
                },
                {
                    id: '1022056',
                    name: 'Lead phosphate'
                },
                {
                    id: '1021979',
                    name: 'estropipate'
                },
                {
                    id: '1022057',
                    name: 'Lead subacetate'
                },
                {
                    id: '1021980',
                    name: 'Ethinylestradiol'
                },
                {
                    id: '1022058',
                    name: 'Leather dust'
                },
                {
                    id: '1021981',
                    name: 'Ethoprop'
                },
                {
                    id: '1022059',
                    name: 'Leucomalachite green'
                },
                {
                    id: '1021982',
                    name: 'Ethyl acrylate'
                },
                {
                    id: '1022060',
                    name: 'Lindane and other hexachlorocyclohexane isomers'
                },
                {
                    id: '1021983',
                    name: 'Ethylbenzene'
                },
                {
                    id: '1022061',
                    name: 'Lynestrenol'
                },
                {
                    id: '1021984',
                    name: "Ethyl-4,4'-dichlorobenzilate"
                },
                {
                    id: '1022062',
                    name: 'Malathion'
                },
                {
                    id: '1021985',
                    name: 'ethylene dibromide'
                },
                {
                    id: '1022063',
                    name: 'Malonaldehyde, sodium salt'
                },
                {
                    id: '1021986',
                    name: 'Ethylene dichloride (1,2-Dichloroethane)'
                },
                {
                    id: '1022064',
                    name: 'Mancozeb'
                },
                {
                    id: '1021987',
                    name: 'Ethyleneimine (Aziridine)'
                },
                {
                    id: '1022065',
                    name: 'Maneb'
                },
                {
                    id: '1021988',
                    name: 'ethylene oxide'
                },
                {
                    id: '1022066',
                    name: 'Marijuana smoke'
                },
                {
                    id: '1021989',
                    name: 'ethylene thiourea'
                },
                {
                    id: '1022067',
                    name: 'Me-A-alpha-C (2-Amino-3-methyl-9H-pyrido[2,3-b]indole)'
                },
                {
                    id: '1021990',
                    name: '2-Ethylhexyl acrylate'
                },
                {
                    id: '1022068',
                    name: 'medroxyprogesterone acetate'
                },
                {
                    id: '1021991',
                    name: 'Ethyl methanesulfonate'
                },
                {
                    id: '1022069',
                    name: 'megestrol acetate'
                },
                {
                    id: '1021992',
                    name: 'etoposide'
                },
                {
                    id: '1022070',
                    name: 'MeIQ (2-Amino-3,4-dimethylimidazo[4,5-f]quinoline)'
                },
                {
                    id: '1021993',
                    name: 'Etoposide in combination with cisplatin and bleomycin'
                },
                {
                    id: '1022071',
                    name: 'MeIQx (2-Amino-3,8-dimethylimidazo[4,5-f]quinoxaline)'
                },
                {
                    id: '1021994',
                    name: 'Fenoxycarb'
                },
                {
                    id: '1022072',
                    name: 'melphalan'
                },
                {
                    id: '1021995',
                    name: 'Fluoro-edenite fibrous amphibole'
                },
                {
                    id: '1022073',
                    name: 'Mepanipyrim'
                },
                {
                    id: '1021996',
                    name: 'Folpet'
                },
                {
                    id: '1022074',
                    name: '2‑Mercaptobenzothiazole'
                },
                {
                    id: '1021997',
                    name: 'Formaldehyde (gas)'
                },
                {
                    id: '1022075',
                    name: 'Merphalan'
                },
                {
                    id: '1021998',
                    name: '2-(2-Formylhydrazino)-4-(5-nitro-2-furyl)thiazole'
                },
                {
                    id: '1022076',
                    name: 'Mestranol'
                },
                {
                    id: '1021999',
                    name: 'Fumonisin B1'
                },
                {
                    id: '1022077',
                    name: 'Metam potassium'
                },
                {
                    id: '1022000',
                    name: 'Furan'
                },
                {
                    id: '1022078',
                    name: 'metham sodium'
                },
                {
                    id: '1022001',
                    name: 'Furazolidone'
                },
                {
                    id: '1022079',
                    name: '5-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1022002',
                    name: 'Furfuryl alcohol'
                },
                {
                    id: '1022080',
                    name: '8-Methoxypsoralen with ultraviolet A therapy'
                },
                {
                    id: '1022003',
                    name: 'Furmecyclox'
                },
                {
                    id: '1022081',
                    name: 'Methyl acrylate'
                },
                {
                    id: '1022004',
                    name: 'Fusarin C'
                },
                {
                    id: '1022082',
                    name: '2-Methylaziridine (Propyleneimine)'
                },
                {
                    id: '1022005',
                    name: 'Gallium arsenide'
                },
                {
                    id: '1022083',
                    name: 'Methylazoxymethanol'
                },
                {
                    id: '1022006',
                    name: 'ganciclovir'
                },
                {
                    id: '1022084',
                    name: 'Methylazoxymethanol acetate'
                },
                {
                    id: '1022007',
                    name: 'Gasoline engine exhaust (condensates/extracts)'
                },
                {
                    id: '1022085',
                    name: 'Methyl carbamate'
                },
                {
                    id: '1022008',
                    name: 'gemfibrozil'
                },
                {
                    id: '1022086',
                    name: '3-Methylcholanthrene'
                },
                {
                    id: '1022009',
                    name: 'Gentian violet (Crystal violet)'
                },
                {
                    id: '1022087',
                    name: '5-Methylchrysene'
                },
                {
                    id: '1022010',
                    name: 'Glass wool fibers (inhalable and biopersistent)'
                },
                {
                    id: '1022088',
                    name: "4,4'-Methylene bis(2-chloroaniline)"
                },
                {
                    id: '1022011',
                    name: "Glu-P-1 (2-Amino-6-methyldipyrido[1,2- a:3',2'-d]imidazole)"
                },
                {
                    id: '1022089',
                    name: "4,4'-Methylene bis(N,N-dimethyl)benzenamine"
                },
                {
                    id: '1022012',
                    name: "Glu-P-2 (2-Aminodipyrido[1,2-a:3',2'-d]imidazole)"
                },
                {
                    id: '1022090',
                    name: "4,4'-Methylene bis(2-methylaniline)"
                },
                {
                    id: '1022013',
                    name: 'Glycidaldehyde'
                },
                {
                    id: '1022091',
                    name: "4,4'-Methylenedianiline"
                },
                {
                    id: '1022014',
                    name: 'Glycidol'
                },
                {
                    id: '1022092',
                    name: "4,4'-Methylenedianiline dihydrochloride"
                },
                {
                    id: '1022015',
                    name: 'Glycidyl methacrylate'
                },
                {
                    id: '1022093',
                    name: 'Methyleugenol'
                },
                {
                    id: '1022016',
                    name: 'Glyphosate'
                },
                {
                    id: '1022094',
                    name: 'Methylhydrazine and its salts'
                },
                {
                    id: '1022017',
                    name: 'Goldenseal root powder'
                },
                {
                    id: '1022095',
                    name: 'Methylhydrazine'
                },
                {
                    id: '1022018',
                    name: 'Griseofulvin'
                },
                {
                    id: '1022096',
                    name: 'Methylhydrazine sulfate'
                },
                {
                    id: '1022019',
                    name: 'Gyromitrin (Acetaldehyde methylformylhydrazone)'
                },
                {
                    id: '1022097',
                    name: '2-Methylimidazole'
                },
                {
                    id: '1022020',
                    name: 'HC Blue 1'
                },
                {
                    id: '1022098',
                    name: '4-Methylimidazole'
                },
                {
                    id: '1022021',
                    name: 'heptachlor'
                },
                {
                    id: '1022099',
                    name: 'Methyl iodide'
                },
                {
                    id: '1022022',
                    name: 'Heptachlor epoxide'
                },
                {
                    id: '1022100',
                    name: 'Methyl isobutyl ketone'
                },
                {
                    id: '1022023',
                    name: 'Herbal remedies containing plant species of the genus Aristolochia'
                },
                {
                    id: '1022101',
                    name: 'Methylmercury compounds'
                },
                {
                    id: '1022102',
                    name: 'Methyl methanesulfonate'
                },
                {
                    id: '1022103',
                    name: '2-Methyl-1-nitroanthraquinone (of uncertain purity)'
                },
                {
                    id: '1022104',
                    name: "N-Methyl-N'-nitro-N-nitrosoguanidine"
                },
                {
                    id: '1022105',
                    name: 'N-Methylolacrylamide'
                },
                {
                    id: '1022106',
                    name: 'α-Methyl styrene (alpha-Methylstyrene)'
                },
                {
                    id: '1022107',
                    name: 'Methylthiouracil'
                },
                {
                    id: '1022108',
                    name: 'metiram'
                },
                {
                    id: '1022109',
                    name: 'Metronidazole'
                },
                {
                    id: '1022110',
                    name: "Michler's ketone"
                },
                {
                    id: '1022111',
                    name: 'Mirex'
                },
                {
                    id: '1022112',
                    name: 'Mitomycin C'
                },
                {
                    id: '1022113',
                    name: 'mitoxantrone hydrochloride'
                },
                {
                    id: '1022114',
                    name: 'Molybdenum trioxide'
                },
                {
                    id: '1022115',
                    name: 'MON 4660 (dichloroacetyl-1-oxa-4-azaspiro(4,5)-decane'
                },
                {
                    id: '1022116',
                    name: 'MON 13900 (furilazole)'
                },
                {
                    id: '1022117',
                    name: '3-Monochloropropane-1,2-diol (3-MCPD)'
                },
                {
                    id: '1022118',
                    name: 'Monocrotaline'
                },
                {
                    id: '1022119',
                    name: 'MOPP (vincristine-prednisone-nitrogen mustard-procarbazine mixture)'
                },
                {
                    id: '1022120',
                    name: '5-(Morpholinomethyl)-3-[(5-nitrofurfuryl-idene)-amino]-2-oxazolidinone'
                },
                {
                    id: '1022121',
                    name: 'Mustard Gas'
                },
                {
                    id: '1022122',
                    name: 'MX (3-chloro-4-dichloromethyl-5-hydroxy-2(5H)-furanone)'
                },
                {
                    id: '1022123',
                    name: 'beta-Myrcene'
                },
                {
                    id: '1022124',
                    name: 'Nafenopin'
                },
                {
                    id: '1022125',
                    name: 'Nalidixic acid'
                },
                {
                    id: '1022126',
                    name: 'Naphthalene'
                },
                {
                    id: '1022127',
                    name: '1-Naphthylamine'
                },
                {
                    id: '1022128',
                    name: '2-Naphthylamine'
                },
                {
                    id: '1022129',
                    name: 'Nickel (Metallic)'
                },
                {
                    id: '1022130',
                    name: 'Nickel acetate'
                },
                {
                    id: '1022131',
                    name: 'Nickel carbonate'
                },
                {
                    id: '1022132',
                    name: 'nickel carbonyl'
                },
                {
                    id: '1022133',
                    name: 'Nickel compounds'
                },
                {
                    id: '1022134',
                    name: 'Nickel hydroxide'
                },
                {
                    id: '1022135',
                    name: 'Nickelocene'
                },
                {
                    id: '1022136',
                    name: 'Nickel oxide'
                },
                {
                    id: '1022137',
                    name: 'Nickel refinery dust from the pyrometallurgical process'
                },
                {
                    id: '1022138',
                    name: 'Nickel subsulfide'
                },
                {
                    id: '1022139',
                    name: 'Niridazole'
                },
                {
                    id: '1022140',
                    name: 'Nitrapyrin [Basis for listing changed effective on November 4, 2015]'
                },
                {
                    id: '1022141',
                    name: 'Nitrilotriacetic acid'
                },
                {
                    id: '1022142',
                    name: 'Nitrilotriacetic acid, trisodium salt monohydrate'
                },
                {
                    id: '1022143',
                    name: '5-Nitroacenaphthene'
                },
                {
                    id: '1022144',
                    name: 'o-Nitroanisole'
                },
                {
                    id: '1022145',
                    name: 'para-Nitroanisole'
                },
                {
                    id: '1022146',
                    name: 'nitrobenzene'
                },
                {
                    id: '1022147',
                    name: '4-Nitrobiphenyl'
                },
                {
                    id: '1022148',
                    name: '6-Nitrochrysene'
                },
                {
                    id: '1022149',
                    name: 'Nitrofen (technical grade)'
                },
                {
                    id: '1022150',
                    name: '2-Nitrofluorene'
                },
                {
                    id: '1022151',
                    name: 'Nitrofurazone'
                },
                {
                    id: '1022152',
                    name: '1-[(5-Nitrofurfurylidene)-amino]-2-imidazolidinone'
                },
                {
                    id: '1022153',
                    name: 'N-[4-(5-Nitro-2-furyl)-2-thiazolyl]acetamide'
                },
                {
                    id: '1022154',
                    name: 'nitrogen mustard (mechlorethamine)'
                },
                {
                    id: '1022155',
                    name: 'nitrogen mustard hydrochloride (mechlorethamine hydrochloride)'
                },
                {
                    id: '1022156',
                    name: 'Nitrogen mustard N-oxide'
                },
                {
                    id: '1022157',
                    name: 'Nitrogen mustard N-oxide hydrochloride'
                },
                {
                    id: '1022158',
                    name: 'Nitromethane'
                },
                {
                    id: '1022159',
                    name: '2-Nitropropane'
                },
                {
                    id: '1022160',
                    name: '1-Nitropyrene'
                },
                {
                    id: '1022161',
                    name: '4-Nitropyrene'
                },
                {
                    id: '1022224',
                    name: 'o-Phenylenediamine dihydrochloride'
                },
                {
                    id: '1022162',
                    name: 'N-Nitrosodi-n-butylamine'
                },
                {
                    id: '1022225',
                    name: 'Phenyl glycidyl ether'
                },
                {
                    id: '1022163',
                    name: 'N-Nitrosodiethanolamine'
                },
                {
                    id: '1022226',
                    name: 'Phenylhydrazine and its salts'
                },
                {
                    id: '1022164',
                    name: 'N-Nitrosodiethylamine'
                },
                {
                    id: '1022227',
                    name: 'Phenylhydrazine'
                },
                {
                    id: '1022165',
                    name: 'N-Nitrosodimethylamine'
                },
                {
                    id: '1022228',
                    name: 'Phenylhydrazine hydrochloride'
                },
                {
                    id: '1022166',
                    name: 'p-Nitrosodiphenylamine'
                },
                {
                    id: '1022229',
                    name: 'o-Phenylphenate, sodium'
                },
                {
                    id: '1022167',
                    name: 'N-Nitrosodiphenylamine'
                },
                {
                    id: '1022230',
                    name: 'o-Phenylphenol'
                },
                {
                    id: '1022168',
                    name: 'N-Nitrosodi-n-propylamine'
                },
                {
                    id: '1022231',
                    name: 'PhiP(2-Amino-1-methyl-6-phenylimidazol[4,5-b]pyridine)'
                },
                {
                    id: '1022169',
                    name: 'N-Nitroso-N-ethylurea'
                },
                {
                    id: '1022232',
                    name: 'Pioglitazone'
                },
                {
                    id: '1022170',
                    name: 'N-Nitrosohexamethyleneimine'
                },
                {
                    id: '1022233',
                    name: 'Pirimicarb'
                },
                {
                    id: '1022171',
                    name: '3-(N-Nitrosomethylamino) propionitrile'
                },
                {
                    id: '1022234',
                    name: 'polybrominated biphenyls'
                },
                {
                    id: '1022172',
                    name: '4-(N-Nitrosomethylamino)-1-(3-pyridyl)1-butanone'
                },
                {
                    id: '1022235',
                    name: 'polychlorinated biphenyls'
                },
                {
                    id: '1022173',
                    name: 'N-Nitrosomethyl-n-butylamine'
                },
                {
                    id: '1022236',
                    name: 'Polychlorinated biphenyls (containing 60 or more percent chlorine by molecular weight)'
                },
                {
                    id: '1022174',
                    name: 'N-Nitrosomethyl-n-decylamine'
                },
                {
                    id: '1022237',
                    name: 'Polychlorinated dibenzo-p-dioxins'
                },
                {
                    id: '1022175',
                    name: 'N-Nitrosomethyl-n-dodecylamine'
                },
                {
                    id: '1022238',
                    name: 'Polychlorinated dibenzofurans'
                },
                {
                    id: '1022176',
                    name: 'N-Nitrosomethylethylamine'
                },
                {
                    id: '1022239',
                    name: 'Polygeenan'
                },
                {
                    id: '1022177',
                    name: 'N-Nitrosomethyl-n-heptylamine'
                },
                {
                    id: '1022240',
                    name: 'Ponceau MX'
                },
                {
                    id: '1022178',
                    name: 'N-Nitrosomethyl-n-hexylamine'
                },
                {
                    id: '1022241',
                    name: 'Ponceau 3R'
                },
                {
                    id: '1022179',
                    name: 'N-Nitrosomethyl-n-nonylamine'
                },
                {
                    id: '1022242',
                    name: 'Potassium bromate'
                },
                {
                    id: '1022180',
                    name: 'N-Nitrosomethyl-n-octylamine'
                },
                {
                    id: '1022243',
                    name: 'Primidone'
                },
                {
                    id: '1022181',
                    name: 'N-Nitrosomethyl-n-pentylamine'
                },
                {
                    id: '1022244',
                    name: 'Procarbazine'
                },
                {
                    id: '1022182',
                    name: 'N-Nitrosomethyl-n-propylamine'
                },
                {
                    id: '1022245',
                    name: 'procarbazine hydrochloride'
                },
                {
                    id: '1022183',
                    name: 'N-Nitrosomethyl-n-tetradecylamine'
                },
                {
                    id: '1022246',
                    name: 'Procymidone'
                },
                {
                    id: '1022184',
                    name: 'N-Nitrosomethyl-n-undecylamine'
                },
                {
                    id: '1022247',
                    name: 'Progesterone'
                },
                {
                    id: '1022185',
                    name: 'N-Nitroso-N-methylurea'
                },
                {
                    id: '1022248',
                    name: 'Pronamide'
                },
                {
                    id: '1022186',
                    name: 'N-Nitroso-N-methylurethane'
                },
                {
                    id: '1022249',
                    name: 'Propachlor'
                },
                {
                    id: '1022187',
                    name: 'N-Nitrosomethylvinylamine'
                },
                {
                    id: '1022250',
                    name: '1,3-Propane sultone'
                },
                {
                    id: '1022188',
                    name: 'N-Nitrosomorpholine'
                },
                {
                    id: '1022251',
                    name: 'propargite'
                },
                {
                    id: '1022189',
                    name: 'N-Nitrosonornicotine'
                },
                {
                    id: '1022252',
                    name: 'beta-Propiolactone'
                },
                {
                    id: '1022190',
                    name: 'N-Nitrosopiperidine'
                },
                {
                    id: '1022253',
                    name: 'Propoxur'
                },
                {
                    id: '1022191',
                    name: 'N-Nitrosopyrrolidine'
                },
                {
                    id: '1022254',
                    name: 'Propylene glycol mono-t-butyl ether'
                },
                {
                    id: '1022192',
                    name: 'N-Nitrososarcosine'
                },
                {
                    id: '1022255',
                    name: 'Propylene oxide'
                },
                {
                    id: '1022193',
                    name: 'o-Nitrotoluene'
                },
                {
                    id: '1022256',
                    name: 'propylthiouracil'
                },
                {
                    id: '1022194',
                    name: 'norethisterone (norethindrone)'
                },
                {
                    id: '1022257',
                    name: 'Pulegone'
                },
                {
                    id: '1022195',
                    name: 'Norethynodrel'
                },
                {
                    id: '1022258',
                    name: 'Pymetrozine'
                },
                {
                    id: '1022196',
                    name: 'Ochratoxin A'
                },
                {
                    id: '1022259',
                    name: 'Pyridine'
                },
                {
                    id: '1022197',
                    name: 'Oil Orange SS'
                },
                {
                    id: '1022260',
                    name: 'Quinoline and its strong acid salts'
                },
                {
                    id: '1022198',
                    name: 'Oral contraceptives, combined'
                },
                {
                    id: '1022261',
                    name: 'Radionuclides'
                },
                {
                    id: '1022199',
                    name: 'Oral contraceptives, sequential'
                },
                {
                    id: '1022262',
                    name: 'Reserpine'
                },
                {
                    id: '1022200',
                    name: 'Oryzalin'
                },
                {
                    id: '1022263',
                    name: 'Residual (heavy) fuel oils'
                },
                {
                    id: '1022201',
                    name: 'oxadiazon'
                },
                {
                    id: '1022264',
                    name: 'resmethrin'
                },
                {
                    id: '1022202',
                    name: 'Oxazepam'
                },
                {
                    id: '1022265',
                    name: 'Riddelliine'
                },
                {
                    id: '1022203',
                    name: 'oxymetholone'
                },
                {
                    id: '1022266',
                    name: 'Safrole'
                },
                {
                    id: '1022204',
                    name: 'oxythioquinox (chinomethionat)'
                },
                {
                    id: '1022267',
                    name: 'Salted fish, Chinese-style'
                },
                {
                    id: '1022205',
                    name: 'Palygorskite fibers (> 5µm in length)'
                },
                {
                    id: '1022268',
                    name: 'Sedaxane'
                },
                {
                    id: '1022206',
                    name: 'Panfuran S'
                },
                {
                    id: '1022269',
                    name: 'Selenium sulfide'
                },
                {
                    id: '1022207',
                    name: 'Parathion'
                },
                {
                    id: '1022270',
                    name: 'Shale-oils'
                },
                {
                    id: '1022208',
                    name: 'pentabromodiphenyl ether mixture [DE-71 (technical grade)]'
                },
                {
                    id: '1022271',
                    name: 'Silica, crystalline (airborne particles of respirable size)'
                },
                {
                    id: '1022209',
                    name: 'Pentachlorophenol'
                },
                {
                    id: '1022272',
                    name: 'Silicon carbide whiskers'
                },
                {
                    id: '1022210',
                    name: 'Pentachlorophenol and by-products of its synthesis (complex mixture)'
                },
                {
                    id: '1022273',
                    name: 'Soots, tars, and mineral oils (untreated and mildly treated oils and used engine oils)'
                },
                {
                    id: '1022211',
                    name: 'Pentosan polysulfate sodium'
                },
                {
                    id: '1022274',
                    name: 'Spirodiclofen'
                },
                {
                    id: '1022212',
                    name: 'Perfluorooctane sulfonic acid (PFOS) and its salts and transformation and degradation precursors'
                },
                {
                    id: '1022275',
                    name: 'Spironolactone'
                },
                {
                    id: '1022213',
                    name: 'perfluorooctanoic acid (pfoa)'
                },
                {
                    id: '1022276',
                    name: 'Stanozolol'
                },
                {
                    id: '1022214',
                    name: 'Phenacetin'
                },
                {
                    id: '1022277',
                    name: 'Sterigmatocystin'
                },
                {
                    id: '1022215',
                    name: 'Phenazopyridine'
                },
                {
                    id: '1022278',
                    name: 'Streptozotocin (streptozocin)'
                },
                {
                    id: '1022216',
                    name: 'Phenazopyridine hydrochloride'
                },
                {
                    id: '1022279',
                    name: 'Strong inorganic acid mists containing sulfuric acid'
                },
                {
                    id: '1022217',
                    name: 'Phenesterin'
                },
                {
                    id: '1022280',
                    name: 'Styrene'
                },
                {
                    id: '1022218',
                    name: 'Phenobarbital'
                },
                {
                    id: '1022281',
                    name: 'Styrene oxide'
                },
                {
                    id: '1022219',
                    name: 'Phenolphthalein'
                },
                {
                    id: '1022282',
                    name: 'Sulfallate'
                },
                {
                    id: '1022220',
                    name: 'Phenoxybenzamine'
                },
                {
                    id: '1022283',
                    name: 'sulfasalazine (salicylazosulfapyridine)'
                },
                {
                    id: '1022221',
                    name: 'Phenoxybenzamine hydrochloride'
                },
                {
                    id: '1022284',
                    name: 'Talc containing asbestiform fibers'
                },
                {
                    id: '1022222',
                    name: 'o-Phenylenediamine and its salts'
                },
                {
                    id: '1022285',
                    name: 'Tamoxifen and its salts'
                },
                {
                    id: '1022223',
                    name: 'o-Phenylenediamine'
                },
                {
                    id: '1022286',
                    name: 'Teriparatide'
                },
                {
                    id: '1022287',
                    name: 'Terrazole'
                },
                {
                    id: '1022288',
                    name: 'Testosterone and its esters'
                },
                {
                    id: '1022289',
                    name: 'Tetrabromobisphenol A'
                },
                {
                    id: '1022290',
                    name: "3,3',4,4'-Tetrachloroazobenzene"
                },
                {
                    id: '1022291',
                    name: '2,3,7,8-tetrachlorodibenzo-p-dioxin (tcdd)'
                },
                {
                    id: '1022292',
                    name: '1,1,1,2-Tetrachloroethane'
                },
                {
                    id: '1022293',
                    name: '1,1,2,2-Tetrachloroethane'
                },
                {
                    id: '1022294',
                    name: 'Tetrachloroethylene (Perchloroethylene)'
                },
                {
                    id: '1022295',
                    name: 'p-a,a,a-Tetrachlorotoluene'
                },
                {
                    id: '1022296',
                    name: 'Tetrachlorvinphos'
                },
                {
                    id: '1022297',
                    name: 'Tetrafluoroethylene'
                },
                {
                    id: '1022298',
                    name: 'Tetrahydrofuran'
                },
                {
                    id: '1022299',
                    name: 'Tetranitromethane'
                },
                {
                    id: '1022300',
                    name: 'Thioacetamide'
                },
                {
                    id: '1022301',
                    name: "4,4'-Thiodianiline"
                },
                {
                    id: '1022302',
                    name: 'Thiodicarb'
                },
                {
                    id: '1022303',
                    name: 'Thiouracil'
                },
                {
                    id: '1022304',
                    name: 'Thiourea'
                },
                {
                    id: '1022305',
                    name: 'Thorium dioxide'
                },
                {
                    id: '1022306',
                    name: 'Titanium dioxide (airborne, unbound particles of respirable size)'
                },
                {
                    id: '1022307',
                    name: 'Tobacco, oral use of smokeless products'
                },
                {
                    id: '1022308',
                    name: 'Tobacco smoke'
                },
                {
                    id: '1022309',
                    name: 'Toluene diisocyanate'
                },
                {
                    id: '1022310',
                    name: 'o-Toluidine'
                },
                {
                    id: '1022311',
                    name: 'o-Toluidine hydrochloride'
                },
                {
                    id: '1022312',
                    name: 'Toxaphene (Polychlorinated camphenes)'
                },
                {
                    id: '1022313',
                    name: 'Toxins derived from Fusarium moniliforme (Fusarium verticillioides)'
                },
                {
                    id: '1022314',
                    name: 'Treosulfan'
                },
                {
                    id: '1022315',
                    name: 'Triamterene'
                },
                {
                    id: '1022316',
                    name: 'S,S,S-Tributyl phosphorotrithioate (Tribufos, DEF)'
                },
                {
                    id: '1022317',
                    name: 'Trichlormethine (Trimustine hydrochloride)'
                },
                {
                    id: '1022318',
                    name: 'Trichloroacetic acid'
                },
                {
                    id: '1022319',
                    name: '1,1,1-Trichloroethane'
                },
                {
                    id: '1022320',
                    name: 'trichloroethylene'
                },
                {
                    id: '1022321',
                    name: '2,4,6-Trichlorophenol'
                },
                {
                    id: '1022322',
                    name: '1,2,3-Trichloropropane'
                },
                {
                    id: '1022323',
                    name: '2,4,5-Trimethylaniline and its strong acid salts'
                },
                {
                    id: '1022324',
                    name: 'Trimethylolpropane triacrylate, technical grade'
                },
                {
                    id: '1022325',
                    name: 'Trimethyl phosphate'
                },
                {
                    id: '1022326',
                    name: 'TRIM® VX'
                },
                {
                    id: '1022327',
                    name: '2,4,6-Trinitrotoluene (TNT)'
                },
                {
                    id: '1022328',
                    name: 'triphenyltin hydroxide'
                },
                {
                    id: '1022329',
                    name: 'Tris(1-aziridinyl)phosphine sulfide (Thiotepa)'
                },
                {
                    id: '1022330',
                    name: 'Tris(2-chloroethyl) phosphate'
                },
                {
                    id: '1022331',
                    name: 'Tris(2,3-dibromopropyl)phosphate'
                },
                {
                    id: '1022332',
                    name: 'Tris(1,3-dichloro-2-propyl) phosphate (TDCPP)'
                },
                {
                    id: '1022333',
                    name: 'Trp-P-1 (Tryptophan-P-1)'
                },
                {
                    id: '1022334',
                    name: 'Trp-P-2 (Tryptophan-P-2)'
                },
                {
                    id: '1022335',
                    name: 'Trypan blue (commercial grade)'
                },
                {
                    id: '1022336',
                    name: 'Unleaded gasoline (wholly vaporized)'
                },
                {
                    id: '1022337',
                    name: 'uracil mustard'
                },
                {
                    id: '1022338',
                    name: 'urethane (ethyl carbamate)'
                },
                {
                    id: '1022339',
                    name: 'Vanadium pentoxide (orthorhombic crystalline form)'
                },
                {
                    id: '1022340',
                    name: 'Vinclozolin [basis for listing changed on November 16, 2006]'
                },
                {
                    id: '1022341',
                    name: 'Vinyl bromide'
                },
                {
                    id: '1022342',
                    name: 'Vinyl chloride'
                },
                {
                    id: '1022343',
                    name: '4-vinylcyclohexene'
                },
                {
                    id: '1022344',
                    name: '4-Vinyl-1-cyclohexene diepoxide (Vinyl cyclohexenedioxide)'
                },
                {
                    id: '1022345',
                    name: 'Vinyl fluoride'
                },
                {
                    id: '1022346',
                    name: 'Vinylidene chloride \n(1,1-Dichloroethylene)'
                },
                {
                    id: '1022347',
                    name: 'Vinyl trichloride (1,1,2-Trichloroethane)'
                },
                {
                    id: '1022348',
                    name: 'Wood dust'
                },
                {
                    id: '1022349',
                    name: '2,6-Xylidine (2,6-Dimethylaniline)'
                },
                {
                    id: '1022350',
                    name: 'Zalcitabine'
                },
                {
                    id: '1022351',
                    name: 'Zidovudine (AZT)'
                },
                {
                    id: '1022352',
                    name: 'zileuton'
                }
            ],
            is_number: false
        }
    ]
}

export const PRODUCT_COMPLAIN_DEFAULT = {
    MEN_SLEEVELESS_TSHIRT: [
        {
            id: '101395',
            name: 'CA Prop 65: Repro. Chems',
            values: [
                {
                    id: '1000059',
                    name: 'No',
                    is_custom: false
                }
            ]
        },
        {
            id: '101400',
            name: 'CA Prop 65: Carcinogens',
            values: [
                {
                    id: '1000059',
                    name: 'No',
                    is_custom: false
                }
            ]
        }
    ],
    MEN_HOODIES_SWEATER: [
        {
            id: '101610',
            name: 'Contains Batteries or Cells?',
            values: [
                {
                    id: '1000325',
                    name: 'None',
                    is_custom: false
                }
            ]
        },
        {
            id: '101619',
            name: 'Other Dangerous Goods or Hazardous Materials',
            values: [
                {
                    id: '1000059',
                    name: 'No',
                    is_custom: false
                }
            ]
        },
        {
            id: '101395',
            name: 'CA Prop 65: Repro. Chems',
            values: [
                {
                    id: '1000059',
                    name: 'No',
                    is_custom: false
                }
            ]
        },
        {
            id: '101400',
            name: 'CA Prop 65: Carcinogens',
            values: [
                {
                    id: '1000059',
                    name: 'No',
                    is_custom: false
                }
            ]
        }
    ]
}

export const PRODUCT_COMPLIANCE_IDS = ['1000325', '101619', '101395', '101400', '101610']

export const CHILD_PRODUCT_COMPLAIN_IDS = ['101398', '101397']

export const CATEGORY_DEFAULT = ['824328', '839944', '601226', '1165840']
