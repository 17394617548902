import { useEffect, useRef, useState } from 'react';

export default function useDebounce<T>(value: T, delay = 600, fn?: (value: T) => void) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const ref = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => {
            setDebouncedValue(value);
            fn && fn(value);
        }, delay);

        return () => {
            clearTimeout(ref.current);
        };
    }, [value, delay]);

    return debouncedValue;
}
