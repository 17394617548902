import React from 'react'
import './styles/aff.style.scss'
import {Flex, Table} from 'antd'
import { useAuth } from '../../contexts/Authenticate'
import { notify } from '../../contexts/Notification'
import {CheckOutlined, CloseOutlined, CopyOutlined, UserOutlined} from '@ant-design/icons'
import { useUserInvitedQuery } from './queries/user-invited.query'
import {IUser} from "../../types/IUser";
import {ColumnsType} from "antd/lib/table";
import { useModal } from '../../contexts/Modal'
import AffiliateQrCodeModal from './modals/AffiliateQrCodeModal'
import './styles/qr-modal.style.scss'
import CopyToClipboard from 'core/CopyToClipboard'

const COIN_URL = 'https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1734665568276'

const analyzeAffiliateData = (invitedUsers: IUser[]) => {
    const totalInviteSuccess = invitedUsers.filter(user => !!user.package).length
    const totalInviteUpgradeUsers = invitedUsers.filter(user => user.package?.package_detail?.level > 1).length

    return {
        totalInviteSuccess,
        totalInviteUpgradeUsers
    }
}

function SettingAffiliate() {
    const { user } = useAuth()
    const { data: affiliateData } = useUserInvitedQuery()
    const { openModal } = useModal()

    const openShareQrModel = () => {
        if (user) {
            openModal(<AffiliateQrCodeModal content={`${window.location.origin}/join/${user.identifier}`} user={user} />)
        }
    }

    const {totalInviteSuccess, totalInviteUpgradeUsers} = analyzeAffiliateData(affiliateData?.invited_users ?? [])

    if (!user) return null

    return (
        <Flex gap={24}>
            <Table
                bordered
                dataSource={affiliateData?.invited_users ?? []}
                columns={AFF_COLUMNS}
                rowKey={(record) => record._id}
                pagination={false}
                className='w-100'
            />
            <div className='invite-friends'>
                <div className='invite-header'>
                    <Flex align='center' gap={8}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
                            <path
                                d='M10.6667 18.6668C4.784 18.6668 0 21.8815 0 25.8335V30.6668H21.3333V25.8335C21.3333 21.8815 16.548 18.6668 10.6667 18.6668ZM10.6667 16.0002C14.7093 16.0002 18 12.7108 18 8.66683C18 4.62283 14.7093 1.3335 10.6667 1.3335C6.624 1.3335 3.33333 4.62283 3.33333 8.66683C3.33333 12.7108 6.624 16.0002 10.6667 16.0002ZM30.6667 14.6668H28V12.0002C28 11.2668 27.4 10.6668 26.6667 10.6668C25.9333 10.6668 25.3333 11.2668 25.3333 12.0002V14.6668H22.6667C21.9333 14.6668 21.3333 15.2668 21.3333 16.0002C21.3333 16.7335 21.9333 17.3335 22.6667 17.3335H25.3333V20.0002C25.3333 20.7335 25.9333 21.3335 26.6667 21.3335C27.4 21.3335 28 20.7335 28 20.0002V17.3335H30.6667C31.4 17.3335 32 16.7335 32 16.0002C32 15.2668 31.4 14.6668 30.6667 14.6668Z'
                                fill='url(#paint0_linear_8757_212244)'
                            ></path>
                            <defs>
                                <linearGradient
                                    id='paint0_linear_8757_212244'
                                    x1='0'
                                    y1='1.3335'
                                    x2='28.7303'
                                    y2='32.2837'
                                    gradientUnits='userSpaceOnUse'
                                >
                                    <stop stop-color='#FFD64D'></stop>
                                    <stop offset='1' stop-color='#FFAD33'></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                        <span className='font-weight-bolder text-lg'>Mời bạn bè</span>
                    </Flex>
                    <span className='earned'>
                    Kiếm được <span>{affiliateData?.affiliate_data?.commission_received ?? 0}</span>
                </span>
                </div>

                <div className='invite-section'>
                    <div className='invite-item'>
                        <Flex align='center' gap={8}>
                            <img src={COIN_URL} alt='coin' width={24}/>
                            {/*<span className='exp'>0%</span>*/}
                        </Flex>
                        <span className='label'>Mời thành công</span>
                        <Flex align='center' gap={8}>
                            <span className='count'>{totalInviteSuccess}</span>
                            <UserOutlined/>
                        </Flex>
                    </div>

                    <div className='invite-item'>
                        <Flex align='center' gap={8}>
                            <img src={COIN_URL} alt='coin' width={24}/>
                            {/*<span className='exp'>{affiliateData?.affiliate_data?.commission_rate}%</span>*/}
                        </Flex>
                        <span className='label'>Ngươi được mời nâng cấp gói cước</span>
                        <Flex align='center' gap={8}>
                            <span className='count'>{totalInviteUpgradeUsers}</span>
                            <UserOutlined/>
                        </Flex>
                    </div>
                </div>

                <div className='referral-section'>
                    <div className='referral-section-code'>
                        <span className='referral-section-code__label'>Mã giới thiệu</span>
                        <CopyToClipboard text={user.identifier} onCopy={() => notify('success', 'Sao chép thành công')}>
                            <Flex align='center' gap={8} className='cursor-pointer'>
                                <span className='referral-section-code__code'>{user.identifier}</span>
                                <CopyOutlined/>
                            </Flex>
                        </CopyToClipboard>
                    </div>
                    <div className='actions'>
                        <CopyToClipboard
                            text={`Bạn sẽ nhận nhiều quyền lợi hấp dẫn khi đăng ký qua link tiếp thị 🎉 Đăng ký TikCRM và mở khóa tất cả các tính năng mạnh mẽ để quản lý cửa hàng Tiktok ngay 🚀 ${window.location.origin}/join/${user.identifier}`}
                            onCopy={() => notify('success', 'Sao chép thành công')}
                        >
                            <button className='copy-link'>
                                <CopyOutlined/>
                                <span className='pl-2'>Sao chép link tiếp thị</span>
                            </button>
                        </CopyToClipboard>
                        <button className='share-qr' onClick={openShareQrModel}>
                            <i className='fa fa-qrcode'/>
                            <span className='pl-2'>Chia sẻ QR Code</span>
                        </button>
                    </div>
                    {/*<input type='text' placeholder='Enter referral code for bonus' className='referral-input'/>*/}
                    {/*<button className='confirm'>Confirm</button>*/}
                </div>
            </div>
        </Flex>
    )
}

export default SettingAffiliate

const AFF_COLUMNS: ColumnsType<IUser> = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // width: 500,
    },
    {
        title: 'Full name',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'Gói cước',
        dataIndex: 'package',
        key: 'package',
        align: 'center',
        render: (_, record) => {
            return record.package?.package_detail?.name || 'Free'
        }
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: 'center',
        render: (_, record) => {
            return !!record.package ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
        }
    }
]
