export const PAGINATION_CONFIG = {
    bordered: true,
    scroll: {
        x: 500,
        y: 768
    },
    sticky: true
}

export const TEACHER_ALLOWED_ABSENCE_CLASS = 4

export const DEFAULT_OPTION_ALL_VALUE = ''

export const DEFAULT_PAGINATION_CONFIGS = {
    pageSize: 50,
    pageNumber: 1
}
