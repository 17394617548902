import { Button, Divider, InputNumber, Select, Space } from 'antd'
import _ from 'lodash'
import { memo, useEffect, useState } from 'react'

function QuantityPerUserColumn({
    product_id,
    quantity_per_user,
    onChange
}: {
    product_id: string
    quantity_per_user: number
    onChange: (product_id: string, quantity_per_user: number) => void
}) {
    console.log('QuantityPerUserColumn:', product_id, quantity_per_user)
    const [itemsQuantityPerUser, setItemsQuantityPerUser] = useState([{ label: 'No limit', value: -1 }])
    const [quantityPerUser, setQuantityPerUser] = useState(-1)
    useEffect(() => {
        if (quantity_per_user) {
            const limit = _.toInteger(quantity_per_user || -1)
            if (limit) {
                setQuantityPerUser(limit)
                if (limit !== -1) {
                    const newItems = [...itemsQuantityPerUser]
                    if (newItems.length === 1) {
                        newItems.push({ label: `${limit}`, value: limit })
                        setItemsQuantityPerUser(newItems)
                    } else {
                        newItems[1] = { label: `${limit}`, value: limit }
                        setItemsQuantityPerUser(newItems)
                    }
                }
            }
        }
    }, [quantity_per_user])

    const applyCustomPurchaseLimit = () => {
        if (quantityPerUser) {
            const limit = _.toInteger(quantityPerUser)
            setQuantityPerUser(limit)
            const newItems = [...itemsQuantityPerUser]
            if (newItems.length === 1) {
                newItems.push({ label: `${limit}`, value: limit })
                setItemsQuantityPerUser(newItems)
            } else {
                newItems[1] = { label: `${limit}`, value: limit }
                setItemsQuantityPerUser(newItems)
            }
            if (onChange) {
                onChange(product_id, limit)
            }
        }
    }

    const onChangeQuantityPerUser = (value: number | null) => {
        if (value === null) return
        setQuantityPerUser(value)
        if (onChange) {
            onChange(product_id, value)
        }
    }
    return (
        <>
            <Select
                placeholder='Custom purchase limit'
                size='large'
                style={{ width: '100%',  marginBottom: '26px' }}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <InputNumber
                                placeholder='Please enter item'
                                value={quantityPerUser}
                                onChange={(value) => setQuantityPerUser(Number(value))}
                                min={1}
                            />
                            <Button type='primary' onClick={applyCustomPurchaseLimit}>
                                Apply
                            </Button>
                        </Space>
                    </>
                )}
                options={itemsQuantityPerUser}
                value={quantityPerUser}
                onChange={onChangeQuantityPerUser}
            />
        </>
    )
}

export default memo(QuantityPerUserColumn)
