import {Flex, message, Tag, Tooltip} from 'antd'
import { FC } from 'react'
import RedesignBadge from '../../../pages/ListingProduct/AutoIdea/components/RedesignBadge'
import { ITikTokShop } from 'types/ITikTokShop'
import { TAG_COLOR } from 'utils/get-color'
import AccountQualification from "../../../pages/ShopManagement/ShopHealth/Table/AccountQualification";
import {ACCOUNT_QUALIFICATION_FIELDS} from "../../../pages/ShopManagement/ShopHealth/utils/constant";
import {getCommonProps} from "../../../pages/ShopManagement/ShopHealth/Table/_healthColumnDefined";
import CopyToClipboard from 'core/CopyToClipboard'
type Props = {
    product_name?: string
    product_id?: string
    skus?: any[]
    tiktok_shop?: Partial<Pick<ITikTokShop, 'name' | 'profile_name' | 'main_niche' | 'sub_niches' | 'assignee' | 'profile_id' | 'shop_health'>>
    is_clone?: boolean
    removed?: boolean
}

const CellProductName: FC<Props> = ({ product_name, product_id, skus, tiktok_shop, is_clone, removed = false }) => {
    const renderSubNiches = (sub_niches: any) => {
        return sub_niches.map((niche: any, idx: number) => {
            return (
                <span key={niche._id}>
                    {niche.name}
                    {idx !== sub_niches.length - 1 && ';'}
                </span>
            )
        })
    }
    const onCopy = (value: string) => {
        message.success(`Đã sao chép: ${value}`)
    }
    return (
        <>
            {is_clone && (
                <>
                    <RedesignBadge />
                    <div className='mt-2' />
                </>
            )}
            {product_name && (
                    <CopyToClipboard text={product_name} onCopy={() => onCopy(product_name)}>
                        <p
                            className='clickable'
                            style={{
                                ...(removed && {
                                    textDecoration: 'line-through'
                                })
                            }}
                        >
                            {product_name && product_name.length > 255 ? product_name.substring(0, 255) + '...' : product_name}
                        </p>
                    </CopyToClipboard>
            )}

            {product_id && (
                <CopyToClipboard text={product_id} onCopy={() => onCopy(product_id)}>
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-1 clickable d-flex justify-content-between align-items-center'>
                        <b style={{ color: '#000' }}>ID:</b>
                        <i>{product_id}</i>
                    </p>
                </CopyToClipboard>
            )}
            {skus && skus[0]?.seller_sku && (
                <p style={{ color: '#000' }} className='mb-1 d-flex justify-content-between align-items-center'>
                    <b style={{ color: '#000' }}>SKU:</b>
                    <i>{skus[0]?.seller_sku}</i>
                </p>
            )}
            {tiktok_shop?.profile_name && (
                <CopyToClipboard text={tiktok_shop?.profile_name} onCopy={() => onCopy(tiktok_shop?.profile_name as string)}>
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable d-flex justify-content-between align-items-center'>
                        <b style={{ color: '#000' }}>Profile: </b>
                        <Tag color={TAG_COLOR.PROFILE_NAME}>{tiktok_shop?.profile_name}</Tag>
                    </p>
                </CopyToClipboard>
            )}
            {tiktok_shop?.profile_id && (
                <CopyToClipboard text={tiktok_shop?.profile_id} onCopy={() => onCopy(tiktok_shop?.profile_id as string)}>
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable d-flex justify-content-between align-items-center'>
                        <b style={{ color: '#000' }}>Profile ID: </b>
                        <Tag color={TAG_COLOR.PROFILE_NAME}>{tiktok_shop?.profile_id}</Tag>
                    </p>
                </CopyToClipboard>
            )}
            {tiktok_shop?.name && (
                <CopyToClipboard text={tiktok_shop?.name} onCopy={() => onCopy(tiktok_shop?.name as string)}>
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable d-flex justify-content-between align-items-center'>
                        <b style={{ color: '#000' }}>Shop: </b>
                        <Tag color={TAG_COLOR.SHOP_NAME}>{tiktok_shop?.name}</Tag>
                    </p>
                </CopyToClipboard>
            )}
            {tiktok_shop?.main_niche && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 d-flex justify-content-between align-items-center'>
                    <b style={{ color: '#000' }}>Niche: </b> <Tag color={TAG_COLOR.MAIN_NICHE}>{tiktok_shop?.main_niche?.name}</Tag>
                </p>
            )}
            {tiktok_shop?.sub_niches && tiktok_shop?.sub_niches.length > 0 && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='d-flex justify-content-between align-items-center'>
                    <b style={{ color: '#000' }}>Sub-Niche: </b> <Tag color={TAG_COLOR.SUB_NICHE}>{renderSubNiches(tiktok_shop?.sub_niches)}</Tag>
                </p>
            )}
            {tiktok_shop?.assignee && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mt-2 d-flex justify-content-between align-items-center'>
                    <b style={{ color: '#000' }}>Nhân viên: </b> <Tag color={TAG_COLOR.ASSIGNEE}>{tiktok_shop?.assignee.full_name}</Tag>
                </p>
            )}
            {
                tiktok_shop?.shop_health && tiktok_shop?.shop_health?.rawData && (
                    <Flex justify='space-between' align='center' className='mt-2'>
                        <p> <b style={{ color: '#000' }}>Trạng thái shop: </b></p>
                        <AccountQualification
                            {...getCommonProps(tiktok_shop.shop_health.rawData)}
                            qualification={tiktok_shop.shop_health.rawData.qualification}
                            violation={tiktok_shop.shop_health.rawData.violation}
                            tableField={ACCOUNT_QUALIFICATION_FIELDS.qualification}
                            errorField='qualification'
                            hasData={!!tiktok_shop.shop_health.rawData.qualification && !!tiktok_shop.shop_health.rawData.violation}
                        />
                    </Flex>
                )
            }
        </>
    )
}

export default CellProductName
