import { useEffect, useState, FC, memo, useMemo, useCallback } from 'react'
import { Button, Modal, Divider, Input, Table, Image, Tooltip } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { gray } from '@ant-design/colors'
import _ from 'lodash'
import { useProductQuery } from 'hooks/queries/product'
import { ColumnsType } from 'antd/es/table'
import { EnumProductStatus, IProduct } from 'types/IProduct'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import { ISelectProductPromotion } from 'types/IPromotion'
import ProductAPI from 'api/ProductAPI'
import { notify } from 'contexts/Notification'
import { TablePaginationConfig } from 'antd/lib'
import { usePromotionContext } from '../PromotionProvider'
import { getRangePrice } from 'utils/sku-utils'
import ExpandSKUTable from '../Table/ExpandSKUTable'
const { Search } = Input
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    product_id: string | undefined
    skus: any[] | undefined
    quantity_per_user: number | undefined
}

const SKUTableModal: FC<Props> = ({ visible, toggleModal, product_id, skus, quantity_per_user }) => {
    const { selectedProducts, setSelectedProducts } = usePromotionContext()
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    useEffect(() => {
        if (visible && _.isArray(selectedProducts)) {
            setSelectedRowKeys(selectedProducts.map((product: ISelectProductPromotion) => product._id) as React.Key[])
        }
    }, [visible])

    const onClose = () => {
        setSelectedRowKeys([])
        setPageNumber(1)
        toggleModal(false)
        setSearch('')
    }

    const handleDone = useCallback(() => {}, [])

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Chỉnh sửa Flash Sale SKU'
            // footer={[
            //     <Button key='back' onClick={onClose}>
            //         Cancel
            //     </Button>,
            //     <Button key='submit' type='primary' loading={false} onClick={handleDone}>
            //         Done
            //     </Button>
            // ]}
            footer={null}
            width={1280}
            centered
        >
            {product_id && <ExpandSKUTable product_id={product_id} skus={skus} quantity_per_user={quantity_per_user} />}
        </Modal>
    )
}

export default memo(SKUTableModal)
