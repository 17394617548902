import { useQuery } from '@tanstack/react-query'
import ConfigProductAPI from 'api/ConfigProductAPI'
import { IConfigProduct } from 'types/IConfigProduct'

export const ALL_CONFIG_PRODUCT_TEMPLATE_QUERY_KEY = 'all_config_product_template_list'


export const useAllConfigProductTemplateQuery = () => {
    return useQuery<IConfigProduct[]>({
        queryKey: [ALL_CONFIG_PRODUCT_TEMPLATE_QUERY_KEY],
        queryFn: () => ConfigProductAPI.getAllConfigProducts()
    })
}
