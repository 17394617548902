import { useEffect, useState, FC } from 'react'
import { Row, Select, Button, Form, Input, Modal, Divider, Space, InputNumber, Col, Flex } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd'
import { message, Upload } from 'antd'
import PriceTemplateAPI from 'api/PriceTemplateAPI'
import { IPriceTemplate } from 'types/IPriceTemplate'

const { Option } = Select

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item?: IPriceTemplate
}

const PriceTemplateModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [type, setType] = useState('MANUAL') // MANUAL, IMPORT
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [jsonObject, setJsonObject] = useState([])
    const [hasColor, setHasColor] = useState(false)

    useEffect(() => {
        if (!visible) return
        if (item) {
            form.setFieldsValue({
                name: item.name,
                colors: item.colors.join('|'),
                sizes: item.sizes.join('|'),
                mixed_attributes: item.mixed_attributes,
                position: item.position || 1
            })
            setHasColor(item.colors.length > 0)
        } else {
            form.setFieldsValue({
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL'
            })
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        toggleModal(false)
        setFileList([])
        setJsonObject([])
        setType('MANUAL')
        setHasColor(false)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!item) {
                setLoading(true)
                const colors = values.colors.split('|')
                const sizes = values.sizes.split('|')
                let payload = {
                    ...values,
                    colors,
                    sizes
                }
                console.log('payload', payload)
                await PriceTemplateAPI.createPriceTemplate(payload)
                notify('success', 'Tạo mẫu thành công')
            } else {
                setLoading(true)
                const colors = values.colors.split('|')
                const sizes = values.sizes.split('|')
                let payload = {
                    ...values,
                    colors,
                    sizes
                }
                await PriceTemplateAPI.editPriceTemplate(item._id, payload)
                notify('success', 'Lưu mẫu thành công')
            }
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const handleFileChange = (info: any) => {
        try {
            setFileList([info.fileList[info.fileList.length - 1]])
            const reader = new FileReader()
            reader.onload = () => {
                const content = reader.result
                setJsonObject(JSON.parse(content as string))
            }
            reader.readAsText(info.fileList[info.fileList.length - 1].originFileObj)
        } catch (error) {
            message.error('Error reading file:')
        }
    }
    const handleImport = () => {
        if (!jsonObject) {
            message.error('Vui lòng chọn file cấu hình')
            return
        }
        if (!Array.isArray(jsonObject)) {
            message.error('File cấu hình không đúng định dạng')
            return
        }
        if (jsonObject.length === 0) {
            message.error('File cấu hình không có dữ liệu')
            return
        }
        setLoading(true)
        PriceTemplateAPI.importPriceTemplate({ configs: jsonObject as unknown as any[] })
            .then(() => {
                notify('success', 'Import thành công')
                onClose()
                refetch()
            })
            .catch((err: any) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const generateSKUS = () => {
        let { colors, sizes, price } = form.getFieldsValue(['colors', 'sizes', 'price'])
        console.log(colors, sizes, price)
        let mixed_attributes: any = []
        colors = colors.split('|')
        sizes = sizes.split('|')
        if (!sizes || sizes.length === 0) {
            notify('error', 'Vui lòng nhập Sizes!')
            return
        }
        if (!price) {
            notify('error', 'Vui lòng nhập Price!')
            return
        }
        if (colors.length > 0) {
            setHasColor(true)
            for (let i = 0; i < sizes.length; i++) {
                for (let j = 0; j < colors.length; j++) {
                    mixed_attributes.push({
                        size: sizes[i],
                        color: colors[j],
                        price: price
                    })
                }
            }
        } else {
            setHasColor(false)
            for (let i = 0; i < sizes.length; i++) {
                mixed_attributes.push({
                    size: sizes[i],
                    price: price
                })
            }
        }

        form.setFieldValue('mixed_attributes', mixed_attributes)
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Cấu hình Size, Color, Price mẫu'
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Select placeholder='Chọn' value={type} size='large' onChange={(val) => setType(val)} style={{ width: '100%' }} className='mb-3'>
                <Option value='MANUAL'>THỦ CÔNG</Option>
                <Option value='IMPORT'>IMPORT FILE</Option>
            </Select>
            {type === 'MANUAL' ? (
                <Form name='PriceTemplateModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                    <Form.Item label={<b>Name</b>} name='name' rules={[{ required: true, message: 'Vui lòng nhập tên mẫu!' }]}>
                        <Input placeholder='Nhập Tên mẫu' size='large' />
                    </Form.Item>
                    <Form.Item label={<b>Vị trí hiển thị</b>} name='position'>
                        <InputNumber placeholder='Nhập vị trí hiển thị' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.List name='mixed_attributes'>
                        {(fields) => (
                            <div>
                                {fields.map(({ key, name, ...restField }, idx) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                        {hasColor && (
                                            <Form.Item {...restField} name={[name, 'color']}>
                                                <Input placeholder='Color' />
                                            </Form.Item>
                                        )}
                                        <Form.Item {...restField} name={[name, 'size']} rules={[{ required: true, message: 'Missing Size Name' }]}>
                                            <Input placeholder='Size' />
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, 'price']} rules={[{ required: true, message: 'Missing Price' }]}>
                                            <InputNumber placeholder='Price' min={0} />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                    <>
                        <Row gutter={[20, 20]}>
                            <Col span={6}>
                                <Form.Item label={<b>Variations 1 (Color)</b>} name='colors'>
                                    <Input style={{ width: '100%' }} placeholder='Nhập Colors' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<b>Variations 2 (Size)</b>} name='sizes'>
                                    <Input style={{ width: '100%' }} placeholder='Nhập Sizes' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<b>Price</b>} name='price'>
                                    <InputNumber placeholder='Nhập giá sản phẩm' min={0} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<b>Action</b>}>
                                    <Button type='primary' onClick={generateSKUS}>
                                        Generate
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                    <Flex align='center' justify='center'>
                        <Button type='primary' htmlType='submit' loading={loading} size='large'>
                            {item ? 'Lưu mẫu' : 'Tạo mẫu'}
                        </Button>
                    </Flex>
                </Form>
            ) : (
                <div className='mt-2'>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Upload
                            name='file'
                            beforeUpload={() => false}
                            multiple={false}
                            fileList={fileList}
                            onChange={handleFileChange}
                            accept='.json'
                            showUploadList={true}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Row>
                    <Row gutter={[20, 20]} className='justify-content-center mb-3'>
                        <Button type='primary' loading={loading} size='large' onClick={handleImport}>
                            Import mẫu
                        </Button>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default PriceTemplateModal
