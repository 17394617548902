import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import _ from 'lodash'
import { ISelectProductPromotion } from 'types/IPromotion'

const FailedSKUTable = (product: ISelectProductPromotion) => {
    console.log('product:', product)
    
    const columns: ColumnsType<any> = [
        {
            title: 'SKU ID',
            dataIndex: 'sku_id',
            key: 'sku_id',
            render: (text, record) => {
                return text
            },
            width: 300
        },
        {
            title: 'Deal Price',
            dataIndex: 'failed_detail',
            key: 'failed_detail',
            render: (text) => {
                return text
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Lowest price in 30 days',
            dataIndex: 'thirty_days_lowest_price',
            key: 'thirty_days_lowest_price',
            render: (text) => {
                return text
            },
            width: 100,
            align: 'center'
        }
    ]

    return (
        <Table
            bordered
            columns={columns}
            dataSource={product.failed_skus}
            rowKey={(record) => record?.sku_id}
            scroll={{
                x: 500,
                y: 500
            }}
            pagination={false}
        />
    )
}

export default FailedSKUTable
