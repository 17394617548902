import { ColumnsType } from 'antd/lib/table'
import { IOrder } from '../../../types/IOrder'
import {Flex, message, Tag} from 'antd'
import OrderNameColumn from './OrderNameColumn'
import OrderSellerColumn from './OrderSellerColumn'
import dayjs from 'dayjs'
import OrderPushToFulFillColumn from "./OrderPushToFulFillColumn";
import {normalizeString} from "../../../utils/format";
import React from "react";
import CopyToClipboard from 'core/CopyToClipboard'

const REFERRAL_FEE_RATE = 0.06

export const ORDER_COLUMNS: ColumnsType<IOrder> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Account / Seller',
        dataIndex: 'account',
        key: 'account',
        width: 260,
        render: (_, order) => <OrderSellerColumn {...order} />
    },
    {
        title: 'Order',
        dataIndex: 'order_id',
        key: 'order_id',
        render: (orderId, order) => (
            <div>
                <CopyToClipboard text={orderId} onCopy={() => message.success(`Đã sao chép: ${orderId}`)}>
                    <p className='clickable'>{orderId}</p>
                </CopyToClipboard>
                <p>{dayjs.unix(order.create_time).format('DD/MM/YYYY hh:mm')}</p>
                <Flex gap={1}>
                    <Tag color='blue'>Tiktok</Tag>
                    <Tag color='blue'>{normalizeString(order.status)}</Tag>
                </Flex>
            </div>
        )
    },
    {
        title: 'Order info',
        dataIndex: 'order_info',
        key: 'order_info',
        render: (_, order) => <OrderNameColumn order={order} />
    },
    {
        title: 'Price',
        dataIndex: 'payment_total_amount',
        key: 'payment_total_amount',
        width: 250,
        render: (value, order) => (
            <div>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    Tax:
                    <strong>
                        {' '}
                        {order.more_info?.payment?.tax ?? '-'} {order.payment_currency}
                    </strong>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    Shipping fee:
                    <strong>
                        {' '}
                        {order.payment_shipping_fee} {order.payment_currency}
                    </strong>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    {' '}
                    Sub total:{' '}
                    <strong>
                        {order.more_info?.payment?.sub_total ?? '-'} {order.payment_currency}
                    </strong>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    Total amount:{' '}
                    <strong>
                        {value} {order.payment_currency}
                    </strong>
                </div>
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    Est. amount:
                    <strong>
                        {(Number(`${order.more_info?.payment?.sub_total ?? 0}`) -
                            Number(((Number(value) - Number(`${order.more_info?.payment?.tax ?? 0}`)) * REFERRAL_FEE_RATE).toFixed(2))).toFixed(2)}{' '}
                        {order.payment_currency}
                    </strong>
                </div>
            </div>
        )
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_, order) => <OrderPushToFulFillColumn order={order} />
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (status) => <Tag color='blue'>{status}</Tag>
    // }
]
