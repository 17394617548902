import React, { useMemo } from 'react'
import { Select, Tag, Flex } from 'antd'
import _ from 'lodash'
import { SelectProps } from 'antd/lib'
import { IUser } from 'types/IUser'

interface StaffSelectProps extends SelectProps {
    data: IUser[]
}

const StaffsSelect: React.FC<StaffSelectProps> = ({ data, ...restProps }) => {
    const listOptions = useMemo(() => {
        return _.map(data ?? [], (item) => ({
            value: item._id,
            label: (
                <Flex justify='space-between' align='center'>
                    <Flex justify='space-between' align='center'>
                        {item.full_name}
                    </Flex>
                </Flex>
            ),
            textSearch: `${item.full_name}`
        }))
    }, [data])

    return <Select optionFilterProp='textSearch' options={listOptions} {...restProps} />
}

export default StaffsSelect
