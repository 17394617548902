import { useEffect, useState, FC, useCallback } from 'react'
import { Button, Form, Input, DatePicker, Row, Col, Select, Tag, Flex } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IPayloadCreatePromotion } from 'types/IPromotion'
import ActivityAPI from 'api/ActivityAPI'
import dayjs from 'dayjs'
import SelectProducts from './modals/SelectProducts'
import momentTz from 'moment-timezone'
import { TIME_ZONE } from 'const'
import ProductPromotionTable from './Table/ProductPromotionTable'
import { usePromotionContext } from './PromotionProvider'
import FailedProductsModal from './modals/FailedProductsModal'
import './Promotion.style.scss'
import { useAuth } from 'contexts/Authenticate'
import ButtonFixedLayout from 'core/ButtonFixedLayout'
const { Option } = Select

const CreatePromotion = ({ tab, shop_id }: { tab: number; shop_id: string }) => {
    const { collapsed } = useAuth()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [visibleSelectProducts, setVisibleSelectProducts] = useState(false)
    const [visibleFailedProducts, setVisibleFailedProducts] = useState(false)
    const { selectedProducts, setSelectedProducts } = usePromotionContext()
    const [failedProducts, setFailedProducts] = useState<{
        message: string
        failed_products: any[]
    }>({} as any)
    const [productLevel, setProductLevel] = useState('PRODUCT')
    useEffect(() => {
        if (tab === 1) {
            onReset()
        }
    }, [tab])

    useEffect(() => {
        if (shop_id) {
            onReset()
        }
    }, [shop_id])
    const onReset = () => {
        form.resetFields()
        setSelectedProducts([])
        setProductLevel('PRODUCT')
    }
    const onFinish = async (values: IPayloadCreatePromotion) => {
        console.log('Success:', values)
        console.log('selectedProducts:', selectedProducts)
        if (!shop_id) {
            notify('error', 'Vui lòng chọn cửa hàng!')
            return
        }
        const payload = {
            ...values,
            shop_id,
            begin_time: dayjs(values.begin_time).unix(),
            end_time: dayjs(values.end_time).unix(),
            products: selectedProducts
        }
        try {
            if (dayjs(values.end_time).diff(dayjs(values.begin_time), 'days') > 3) throw new Error('Khoảng thời gian tối đa cho Promotion là 3 ngày')
            const newProducts = selectedProducts.map((_product) => {
                const product = {
                    ..._product
                }
                if (values.product_level === 'PRODUCT') {
                    if (!product.activity_price_amount) {
                        throw new Error('Vui lòng nhập giá khuyến mãi cho tất cả sản phẩm!')
                    }
                    if (!product.quantity_limit) {
                        product.quantity_limit = -1
                    }
                    if (!product.quantity_per_user) {
                        product.quantity_per_user = -1
                    }
                    return product
                } else {
                    product.skus = product.skus.map((_sku) => {
                        let sku = {
                            ..._sku,
                            is_on_sale: _sku.is_on_sale ?? true
                        }
                        if (sku.is_on_sale && !sku.activity_price_amount) {
                            throw new Error('Vui lòng nhập giá khuyến mãi cho tất cả SKU!')
                        }
                        if (!sku.quantity_limit) {
                            sku.quantity_limit = -1
                        }
                        if (!sku.quantity_per_user) {
                            sku.quantity_per_user = -1
                        }
                        return sku
                    })
                    return product
                }
            })
            console.log('newProducts:', newProducts)
            payload.products = newProducts
            setLoading(true)
            ActivityAPI.createPromotion(payload)
                .then((res) => {
                    console.log('res:', res)
                    if (res.failed_products && res.failed_products.length > 0) {
                        setFailedProducts(res)
                        toggleFailedProducts(true)
                    } else {
                        notify('success', 'Tạo Promotion thành công')
                        onReset()
                    }
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        } catch (error: any) {
            console.log('Error during save:', error)
            notify('error', error?.message)
        }
    }

    const toggleSelectProducts = useCallback((val: boolean) => {
        setVisibleSelectProducts(val)
    }, [])

    const toggleFailedProducts = useCallback((val: boolean) => {
        setVisibleFailedProducts(val)
    }, [])

    const onValuesChange = (changedValues: any, allValues: any) => {
        if (changedValues.product_level) {
            setProductLevel(changedValues.product_level)
        }
    }
    return (
        <>
            <Form
                name='PromotionModal'
                layout='vertical'
                onFinish={onFinish}
                autoComplete='off'
                form={form}
                initialValues={{
                    activity_type: 'FLASHSALE',
                    product_level: 'PRODUCT'
                }}
                onValuesChange={onValuesChange}
            >
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item label={<b>Promotion name</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên Promotion!' }]}>
                            <Input placeholder='Nhập Tên Promotion' size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<b>Loại Promotion</b>}
                            name='activity_type'
                            rules={[{ required: true, message: 'Vui lòng chọn loại Promotion!' }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại promotion'
                                size='large'
                            >
                                <Option value='FLASHSALE'>FLASH SALE</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian bắt đầu</b>}
                            name='begin_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian bắt đầu' }]}
                            extra={
                                <>
                                    <p>Timezone: {TIME_ZONE}</p>
                                    <p style={{ color: 'gray' }}>
                                        <i>Khoảng thời gian tối đa cho Promotion là 3 ngày</i>
                                    </p>
                                </>
                            }
                        >
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian kết thúc</b>}
                            name='end_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian kết thúc' }]}
                            extra={`Timezone: ${TIME_ZONE}`}
                        >
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label={<b>Products</b>} name='product_level' rules={[{ required: true, message: 'Vui lòng chọn loại Products!' }]}>
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại Products'
                                size='large'
                            >
                                <Option value='PRODUCT'>PRODUCT-LEVEL</Option>
                                <Option value='VARIATION'>VARIATION-LEVEL</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {shop_id && (
                    <Flex className='mb-3' align='center'>
                        <Button size='large' onClick={() => toggleSelectProducts(true)}>
                            Chọn sản phẩm
                        </Button>
                        <p className='mx-3' style={{ fontSize: '16px' }}>
                            Tổng số sản phẩm: <b style={{ color: 'red' }}>{selectedProducts.length}</b>
                        </p>
                    </Flex>
                )}
                <ProductPromotionTable productLevel={productLevel} />
                <ButtonFixedLayout collapsed={collapsed}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        Tạo Promotion
                    </Button>
                </ButtonFixedLayout>
            </Form>
            <SelectProducts visible={visibleSelectProducts} toggleModal={toggleSelectProducts} shop_id={shop_id} />
            <FailedProductsModal visible={visibleFailedProducts} toggleModal={toggleFailedProducts} failedProducts={failedProducts} />
        </>
    )
}

export default CreatePromotion
