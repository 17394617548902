import useClipboardCopy from 'hooks/useCopy'
import { FC } from 'react'

interface Props {
    text: string
    onCopy?(text: string, result: boolean): void
    children: any
}
const CopyToClipboard: FC<Props> = ({ text, onCopy, children }) => {
    const copy = useClipboardCopy()

    const handleCopy = (val: string) => {
        copy(val)
        if (onCopy) {
            onCopy(val, true)
        }
    }
    return <div onClick={() => handleCopy(text)}>{children}</div>
}

export default CopyToClipboard
