import React from 'react'
import { Button, Flex, Input } from 'antd'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../const'
import { useOrderStore } from '../store/order.store'
import { useAuth } from '../../../contexts/Authenticate'
import OrderTableToolbarStaff from './OrderTableToolbarStaff'
import OrderTableToolbarFulfill from './OrderTableToolbarFulfill'
import OrderTableToolbarDate from './OrderTableToolbarDate'
import { useMutation, UseQueryResult } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import { IListApiResponse } from '../../../types/IApiResponse'
import { IOrder } from '../../../types/IOrder'
import dayjs from 'dayjs'
import { unparse } from 'papaparse'
import OrderTableToolbarStatus from './OrderTableToolbarStatus'
import ShopSelect from '../../../core/ShopSelect'

function OrderTableToolbar({ queryResponse }: { queryResponse: UseQueryResult<IListApiResponse<IOrder>> | undefined }) {
    const { user } = useAuth()
    const filter = useOrderStore((state) => state.filter)

    const exportCSVMutation = useMutation({
        mutationFn: OrderAPI.exportOrdersToCsv,
        onSuccess: (data) => {
            console.log('data', data)
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    const syncOrderMutation = useMutation({
        mutationFn: OrderAPI.triggerSyncData,
        onSuccess: (data) => {
            notify('success', `Đồng bộ thành công ${data?.count} shops`)
            void queryResponse?.refetch()
        },
        onError: (err) => notify('error', (err as Error).message)
    })

    const onFilterNoDesignOrders = () => {
        if (filter.others.has_fulfill_artworks === false && filter.others.push_to_fulfill_at !== false) {
            filter.addOthers({})
        } else {
            filter.addOthers({
                has_fulfill_artworks: false
            })
        }
    }

    const onFilterHasDesignOrders = () => {
        if (filter.others.has_fulfill_artworks === true && filter.others.push_to_fulfill_at === false) {
            filter.addOthers({})
        } else {
            filter.addOthers({
                has_fulfill_artworks: true,
                push_to_fulfill_at: false
            })
        }
    }

    const onFilterOverdueOrders = () => {
        if (filter.others.overdue) {
            filter.addOthers({})
        } else {
            filter.addOthers({
                overdue: true
            })
        }
    }

    const onFilterCheatOrders = () => {
        if (filter.others.platform_paid_bank_account_valid === false) {
            filter.addOthers({})
        } else {
            filter.addOthers({
                platform_paid_bank_account_valid: false
            })
        }
    }

    const exportToCSV = () => {
        const jsonData = queryResponse?.data?.data?.map((item) => {
            const itemInfo: Record<string, any> = {}
            item.items.forEach((product, idx) => {
                itemInfo[`Product ${idx + 1}`] = product.product_name
                itemInfo[`Variant ${idx + 1}`] = product.sku_name
                itemInfo[`Quantity ${idx + 1}`] = product.quantity
                itemInfo[`Artworks ${idx + 1}`] =
                    product.artworks
                        ?.map((artwork) => artwork.value.preview)
                        ?.filter(Boolean)
                        ?.join(' | ') ?? ''
            })
            return {
                ID: item._id,
                'Order ID': item.order_id,
                'Shipping Label': item.items.find((product) => !!product.shipping_label)?.shipping_label ?? '',
                'Create time': dayjs.unix(item.create_time).format('DD-MM-YYYY HH:mm'),
                'Shop Name': item.shop.name,
                ...itemInfo
            }
        })

        // @ts-ignore
        const csv = unparse(jsonData)
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'TikCRM_Orders_' + dayjs().format('DD-MM-YYYY HH_mm') + '.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            <Flex gap={12} wrap>
                <Button
                    type={filter.others.has_fulfill_artworks === false && filter.others.push_to_fulfill_at !== false ? 'primary' : 'default'}
                    size='large'
                    onClick={onFilterNoDesignOrders}
                >
                    Đơn chưa có design
                </Button>
                <Button
                    type={filter.others.has_fulfill_artworks === true && filter.others.push_to_fulfill_at === false ? 'primary' : 'default'}
                    size='large'
                    onClick={onFilterHasDesignOrders}
                >
                    Đơn đã có design (Chưa đẩy fulfill)
                </Button>
                <Button type={filter.others.overdue === true ? 'primary' : 'default'} size='large' onClick={onFilterOverdueOrders}>
                    Đơn overdue
                </Button>
                <Button
                    type={filter.others.platform_paid_bank_account_valid === false ? 'primary' : 'default'}
                    size='large'
                    onClick={onFilterCheatOrders}
                >
                    Đơn nghi ngờ gian lận
                </Button>
                <Button type='primary' size='large' onClick={exportToCSV} loading={exportCSVMutation.isLoading}>
                    Xuất CSV
                </Button>
                <Button type='primary' size='large' onClick={() => syncOrderMutation.mutateAsync()} loading={syncOrderMutation.isLoading}>
                    Đồng bộ dữ liệu mới
                </Button>
            </Flex>
            <Flex className='mb-3 mt-3' gap={12} wrap>
                <Input
                    size='large'
                    placeholder='Search order_id / bank account'
                    value={filter.order_id}
                    onChange={filter.addOrderId}
                    style={{ maxWidth: 318 }}
                />
                <ShopSelect
                    showSearch
                    optionFilterProp='textSearch'
                    style={{ width: '100%', maxWidth: 340 }}
                    placeholder='Tất cả cửa hàng'
                    onChange={filter.addShop}
                    value={filter.shop || ''}
                    allowClear
                    size='large'
                    onClear={() => filter.addShop(DEFAULT_OPTION_ALL_VALUE)}
                    supportExternalShop
                />

                {user.isOwner && (
                    <>
                        <OrderTableToolbarStaff />
                    </>
                )}
                <OrderTableToolbarFulfill />
                <OrderTableToolbarStatus />
                <OrderTableToolbarDate />
            </Flex>
        </div>
    )
}

export default OrderTableToolbar
