import { IProduct, IProductQueryParams } from 'types/IProduct'
import ApiSender from './ApiSender'
import { IListApiResponse } from 'types/IApiResponse'

export default class ProductAPI {
    public static getProducts(params: IProductQueryParams): Promise<IListApiResponse<IProduct>> {
        const route = `/core/user/products`
        return ApiSender.get(route, params)
    }

    public static syncProductsFromTikTokAccount(payload: { shop_id: string }) {
        const route = `/core/user/sync-products`
        return ApiSender.post(route, payload)
    }

    public static getMultimediaUploadToken(payload: { shop_id: string }) {
        const route = `/core/user/products/multimedia/image/upload_token`
        return ApiSender.post(route, payload)
    }

    public static editProduct(_id: string, payload: { is_template_listing?: boolean; template_name?: string }) {
        const route = `/core/user/products/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeProducts(product_ids: string[]) {
        const route = `/core/user/products`
        return ApiSender.post(route, { product_ids })
    }
    public static getProductsTemplate(shop_id: string) {
        const route = `/core/user/shops/${shop_id}/products/template`
        return ApiSender.get(route)
    }

    public static uploadProductToTikTok(payload: any) {
        const route = `/core/user/shops/products/upload`
        return ApiSender.post(route, payload)
    }

    public static syncProductFromTikTok(payload: { product_id: string }) {
        const route = `/core/user/sync-product`
        return ApiSender.post(route, payload)
    }
}
