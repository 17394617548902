import { Button, Checkbox, Col, Divider, Form, Image, InputNumber, Row, Select, Space, Table, Typography } from 'antd'
import { usePromotionContext } from '../PromotionProvider'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import DealPriceColumn from './Columns/DealPriceColumn'
import { ISelectProductPromotion } from 'types/IPromotion'
import QuantityLimitColumn from './Columns/QuantityLimitColumn'
import QuantityPerUserColumn from './Columns/QuantityPerUserColumn'
import ExpandSKUTable from './ExpandSKUTable'
import { getRangePrice } from 'utils/sku-utils'
import SKUTableModal from '../modals/SKUTableModal'

const ProductPromotionTable = ({ productLevel }: { productLevel: string }) => {
    const { selectedProducts, saveDataSource, setSelectedProducts } = usePromotionContext()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [itemsQuantityLimit, setItemsQuantityLimit] = useState([
        { label: 'No update', value: -2 },
        { label: 'No limit', value: -1 }
    ])
    const [itemsQuantityPerUser, setItemsQuantityPerUser] = useState([
        { label: 'No update', value: -2 },
        { label: 'No limit', value: -1 }
    ])
    const [quantityLimit, setQuantityLimit] = useState(1)
    const [quantityPerUser, setQuantityPerUser] = useState(1)
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [form] = Form.useForm()
    const [visibleSKUModal, setVisibleSKUModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<ISelectProductPromotion | null>(null)
    const prevSelectedProductsRef = useRef<ISelectProductPromotion[]>([])

    useEffect(() => {
        console.log('selectedProducts:', selectedProducts)
        console.log('prevSelectedProductsRef.current', prevSelectedProductsRef.current)
        if (!_.isEqual(prevSelectedProductsRef.current, selectedProducts) && productLevel === 'VARIATION') {
            const newExpandedKeys = selectedProducts.map((d) => d._id)
            setExpandedKeys(newExpandedKeys)
            prevSelectedProductsRef.current = selectedProducts
        }
    }, [selectedProducts, productLevel])

    const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }, [])

    const handleSelect = (record: any, selected: any) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record._id])
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record._id)
                return [...keys.slice(0, index), ...keys.slice(index + 1)]
            })
        }
    }

    const toggleSelectAll = () => {
        setSelectedRowKeys((keys) => (keys.length === selectedProducts.length ? [] : selectedProducts.map((r) => r._id)))
    }

    const toggleSKUModal = useCallback(
        (val: boolean) => {
            setVisibleSKUModal(val)
        },
        [visibleSKUModal]
    )

    const headerCheckbox = (
        <Checkbox
            checked={selectedRowKeys.length > 0}
            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < selectedProducts.length}
            onChange={toggleSelectAll}
        />
    )
    const rowSelection = {
        selectedRowKeys,
        type: 'checkbox' as any,
        fixed: true,
        onSelect: handleSelect,
        columnTitle: headerCheckbox
    }
    const applyPurchaseLimit = () => {
        if (quantityLimit) {
            const limit = _.toInteger(quantityLimit)

            setItemsQuantityLimit((prev) => {
                const defaultItems = prev.filter((item) => item.value === -2 || item.value === -1)

                if (limit === -2 || limit === -1) {
                    return defaultItems
                }

                const existingIndex = prev.findIndex((item) => item.value === limit)
                if (existingIndex > -1) {
                    const updatedItems = [...prev]
                    updatedItems[existingIndex] = { label: `${limit}`, value: limit }
                    return updatedItems
                }
                return [...defaultItems, { label: `${limit}`, value: limit }]
            })

            form.setFieldsValue({ quantity_limit: limit })
        }
    }
    const applyCustomPurchaseLimit = () => {
        if (quantityPerUser) {
            const limit = _.toInteger(quantityPerUser)
            setItemsQuantityPerUser((prev) => {
                const defaultItems = prev.filter((item) => item.value === -2 || item.value === -1)

                if (limit === -2 || limit === -1) {
                    return defaultItems
                }

                const existingIndex = prev.findIndex((item) => item.value === limit)
                if (existingIndex > -1) {
                    const updatedItems = [...prev]
                    updatedItems[existingIndex] = { label: `${limit}`, value: limit }
                    return updatedItems
                }
                return [...defaultItems, { label: `${limit}`, value: limit }]
            })
            form.setFieldsValue({ quantity_per_user: limit })
        }
    }
    const onBatchUpdate = () => {
        const diff: any = {
            ...((form.getFieldValue('quantity_limit') === -1 || form.getFieldValue('quantity_limit') > 0) && {
                quantity_limit: form.getFieldValue('quantity_limit')
            }),
            ...((form.getFieldValue('quantity_per_user') === -1 || form.getFieldValue('quantity_per_user') > 0) && {
                quantity_per_user: form.getFieldValue('quantity_per_user')
            }),
            ...(form.getFieldValue('deal_price_percent') > 0 && {
                deal_price_percent: form.getFieldValue('deal_price_percent')
            })
        }

        const finalProducts = selectedProducts.map((product) => {
            if (!selectedRowKeys.includes(product._id)) {
                return product
            }

            const updatedProduct = _.cloneDeep(product)
            if (productLevel === 'PRODUCT') {
                if (diff.deal_price_percent) {
                    const basePrice = getBasePrice(updatedProduct.skus)
                    diff.activity_price_amount = _.round(basePrice * (1 - diff.deal_price_percent / 100), 2)
                }
                Object.assign(updatedProduct, diff)
            } else {
                updatedProduct.skus = updatedProduct.skus.map((sku) => {
                    if (diff.deal_price_percent) {
                        const basePrice = Number(sku.base_price.sale_price)
                        sku.activity_price_amount = _.round(basePrice * (1 - diff.deal_price_percent / 100), 2)
                    }
                    return {
                        ...sku,
                        ...diff
                    }
                })
            }

            return updatedProduct
        })

        setSelectedProducts(finalProducts)
    }
    const onBatchDelete = () => {
        const newSelectedProducts = selectedProducts.filter((product) => !selectedRowKeys.includes(product._id))
        setSelectedProducts([...newSelectedProducts])
    }
    const onDeleteProduct = (product_id: string) => {
        const newSelectedProducts = selectedProducts.filter((product) => product.product_id !== product_id)
        console.log('newSelectedProducts', newSelectedProducts)
        setSelectedProducts([...newSelectedProducts])
    }

    const onEdit = (record: ISelectProductPromotion) => {
        setSelectedProduct(record)
        toggleSKUModal(true)
    }
    const onExpandedRowsChange = useCallback(
        _.debounce((keys) => setExpandedKeys(keys as string[]), 200),
        [expandedKeys]
    )
    const onReset = () => {
        form.resetFields()
        setSelectedProducts([])
        setSelectedRowKeys([])
        setItemsQuantityLimit([{ label: 'No limit', value: -1 }])
        setItemsQuantityPerUser([{ label: 'No limit', value: -1 }])
        setQuantityLimit(0)
        setQuantityPerUser(0)
    }

    const onDealPriceChange = useCallback(
        (product_id: string, activity_price_amount: number, deal_price_percent: number) => {
            console.log('onDealPriceChange', product_id, deal_price_percent)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            record.deal_price_percent = deal_price_percent
            record.activity_price_amount = activity_price_amount
            saveDataSource(record)
        },
        [selectedProducts]
    )

    const onQuantityLimitChange = useCallback(
        (product_id: string, quantity_limit: number) => {
            console.log('onQuantityLimitChange', product_id, quantity_limit)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            record.quantity_limit = quantity_limit
            saveDataSource(record)
        },
        [selectedProducts] // TODO: component re-rendered when selectedProducts changed. Need optimize
    )

    const onQuantityPerUserChange = useCallback(
        (product_id: string, quantity_per_user: number) => {
            console.log('onQuantityPerUserChange', product_id, quantity_per_user)
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx]
            record.quantity_per_user = quantity_per_user
            saveDataSource(record)
        },
        [selectedProducts]
    )

    const getBasePrice = (skus: any[]) => {
        const lowestSalePrice = _.chain(skus)
            .map((sku) => parseFloat(sku.base_price.sale_price))
            .filter((price) => !isNaN(price))
            .min()
            .value()
        return lowestSalePrice
    }

    const validateSetDealPriceSKUDisplay = (skus: any[]) => {
        let validated = 0
        console.log('skus', skus)
        let skusSelected = skus.filter((x) => x.is_on_sale !== false)
        skusSelected.map((sku) => {
            if (sku.activity_price_amount) {
                validated++
            }
        })
        return (
            <>
                <p style={{ color: 'green' }}>SKU đã chọn FLash Sale: {skusSelected.length}</p>
                <p style={{ color: validated === skusSelected.length ? 'green' : 'red' }}>
                    Đã Set Deal Price: {validated}/{skusSelected.length}
                </p>
            </>
        )
    }
    const columns: ColumnsType<ISelectProductPromotion> = [
        {
            title: 'Thông tin sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record) => {
                return (
                    <Space>
                        <Link to={record.images[0] && _.isArray(record.images[0]?.url_list) && record.images[0]?.url_list[0]} target='_blank'>
                            <Image src={record.images && record.images[0] && record.images[0]?.url_list[0]} width={80} preview={false} />
                        </Link>
                        <CellProductName
                            product_id={record.product_id}
                            product_name={text}
                            skus={record.skus}
                            tiktok_shop={_.pick(record.tiktok_shop, ['name'])}
                        />
                    </Space>
                )
            },
            width: 300
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any, record: any, index: number) => {
                return (
                    text[0]?.base_price && (
                        <span>
                            {getRangePrice(text)}
                            <b>$</b>
                        </span>
                    )
                )
            },
            width: 80,
            align: 'center'
        },
        {
            title: 'Deal Price',
            dataIndex: 'activity_price_amount',
            key: 'activity_price_amount',
            width: 350,
            align: 'center',
            render: (text, record) => {
                return productLevel === 'PRODUCT' ? (
                    <DealPriceColumn
                        product_id={record.product_id}
                        base_price={getBasePrice(record.skus)}
                        deal_price_percent={record.deal_price_percent}
                        onChange={onDealPriceChange}
                    />
                ) : (
                    validateSetDealPriceSKUDisplay(record.skus)
                )
            }
        },
        {
            title: 'Total Purchase Limit',
            dataIndex: 'quantity_limit',
            key: 'quantity_limit',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return (
                    productLevel === 'PRODUCT' && (
                        <QuantityLimitColumn quantity_limit={record.quantity_limit} product_id={record.product_id} onChange={onQuantityLimitChange} />
                    )
                )
            }
        },
        {
            title: 'Customer Purchase Limit',
            dataIndex: 'quantity_per_user',
            key: 'quantity_per_user',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return (
                    <QuantityPerUserColumn
                        quantity_per_user={record.quantity_per_user}
                        product_id={record.product_id}
                        onChange={onQuantityPerUserChange}
                    />
                )
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => {
                return <CellProductProductStatus status={text} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size='large'>
                        {productLevel === 'VARIATION' && (
                            <Typography.Link onClick={() => onEdit(record)}>
                                <span style={{ color: 'blue', cursor: 'pointer' }}>
                                    <b>Edit</b>
                                </span>
                            </Typography.Link>
                        )}
                        <Typography.Link onClick={() => onDeleteProduct(record.product_id)}>
                            <span style={{ color: 'red', cursor: 'pointer' }}>
                                <b>Delete</b>
                            </span>
                        </Typography.Link>
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <Form form={form} component={false} initialValues={{ quantity_limit: -2, quantity_per_user: -2 }}>
            {selectedProducts.length > 0 && (
                <Row gutter={[20, 20]} className='mb-4'>
                    <Col span={3}>
                        <div>
                            <Form.Item name='deal_price_percent' label={<b>Deal Price</b>}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    size='large'
                                    addonAfter='%off'
                                    min={1}
                                    max={90}
                                    disabled={selectedRowKeys.length === 0}
                                />
                            </Form.Item>
                        </div>
                        {selectedRowKeys.length > 0 && (
                            <span style={{ fontSize: '16px' }}>
                                Đã chọn: <b style={{ color: 'red' }}>{selectedRowKeys.length}</b>
                            </span>
                        )}
                    </Col>
                    <Col span={4}>
                        <div>
                            <Form.Item name='quantity_limit' label={<b>Total Purchase Limit</b>}>
                                <Select
                                    placeholder='Total purchase limit'
                                    size='large'
                                    disabled={selectedRowKeys.length === 0}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 4px' }}>
                                                <InputNumber value={quantityLimit} onChange={(value) => setQuantityLimit(Number(value))} min={1} />
                                                <Button type='text' onClick={applyPurchaseLimit}>
                                                    Apply
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={itemsQuantityLimit}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    {productLevel === 'PRODUCT' && (
                        <Col span={4}>
                            <div>
                                <Form.Item name='quantity_per_user' label={<b>Custom Purchase Limit</b>}>
                                    <Select
                                        placeholder='Custom Purchase Limit'
                                        size='large'
                                        disabled={selectedRowKeys.length === 0}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space style={{ padding: '0 8px 4px' }}>
                                                    <InputNumber
                                                        value={quantityPerUser}
                                                        onChange={(value) => setQuantityPerUser(Number(value))}
                                                        min={1}
                                                    />
                                                    <Button type='text' onClick={applyCustomPurchaseLimit}>
                                                        Apply
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                        options={itemsQuantityPerUser}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    )}

                    <Col span={8}>
                        <Space size='large'>
                            <Button size='large' style={{ marginTop: '30px' }} disabled={selectedRowKeys.length === 0} onClick={onBatchUpdate}>
                                Batch update
                            </Button>
                            <Button size='large' style={{ marginTop: '30px' }} disabled={selectedRowKeys.length === 0} onClick={onBatchDelete}>
                                Batch delete
                            </Button>
                        </Space>
                    </Col>
                </Row>
            )}

            <Table
                bordered
                dataSource={selectedProducts}
                columns={columns}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
                pagination={{
                    pageSize: 10,
                    position: ['bottomRight', 'topRight']
                }}
                rowSelection={rowSelection}
                // expandable={
                //     productLevel === 'VARIATION'
                //         ? {
                //               expandedRowRender: (record) => (
                //                   <ExpandSKUTable skus={record.skus} product_id={record.product_id} quantity_per_user={record.quantity_per_user} />
                //               ),
                //               expandedRowKeys: expandedKeys,
                //               onExpandedRowsChange
                //           }
                //         : undefined
                // }
                className={selectedProducts.length > 0 ? 'mb-4' : ''}
            />
            <SKUTableModal
                visible={visibleSKUModal}
                toggleModal={toggleSKUModal}
                product_id={selectedProduct?.product_id}
                skus={selectedProduct?.skus}
                quantity_per_user={selectedProduct?.quantity_per_user}
            />
        </Form>
    )
}

export default memo(ProductPromotionTable)
