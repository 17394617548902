import useDebounce from '../../../../hooks/useDebounce'
import { useQuery } from '@tanstack/react-query'
import FinanceAPI from '../../../../api/FinanceAPI'
import { useShopPaidStore } from '../store/shop-paid.store'
import { IShopPaidQueryParams } from '../interface/shop-paid.interface'
import {useShopStatementGeneralStore} from "../store/shop-statement.store";

export const LIST_SHOPS_PAID_QUERY_KEY = 'LIST_SHOPS_PAID_QUERY_KEY'

export const useShopPaidQuery = (queryParams: IShopPaidQueryParams) => {
    const filter = useShopPaidStore((state) => state.filter)
    const currentTab = useShopStatementGeneralStore((state) => state.currentTab)
    const search = useDebounce(filter.search)
    const query = {
        shop: filter.shop,
        ...queryParams,
        search
    }
    return useQuery({
        queryKey: [LIST_SHOPS_PAID_QUERY_KEY, query, currentTab],
        queryFn: () => FinanceAPI.getShopsPaid(query)
    })
}
