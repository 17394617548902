import { ITableParams } from 'hooks/useTableConfigs'
import { ITikTokShop } from './ITikTokShop'
import { IUser } from './IUser';
import { EnumProductStatus } from './IProduct';
import { IAutoPromotion } from './IAutoPromotion';

export enum EnumActivityStatus {
    DRAFT = 'DRAFT',
    NOT_START = 'NOT_START',
    ONGOING = 'ONGOING',
    EXPIRED = 'EXPIRED',
    DEACTIVATED = 'DEACTIVATED',
    NOT_EFFECTIVE = 'NOT_EFFECTIVE',
}
export enum EnumActivityType {
    FIXED_PRICE = 'FIXED_PRICE',
    DIRECT_DISCOUNT = 'DIRECT_DISCOUNT',
    FLASHSALE = 'FLASHSALE'
}

export enum EnumProductLevel {
    PRODUCT = 'PRODUCT',
    VARIATION = 'VARIATION',
}

export enum EnumPromotionStatus {
    DRAFT = 1,
    ONGOING = 2,
    UPCOMING = 3,
    DEACTIVATED = 4,
    EXPIRED = 5,
}
export enum EnumUpdateProductStatus {
    WAITING = 1,
    PROCESSING,
    SUCCESS,
    FAIL
}

interface IProductActivity {
    id: string;
    activity_price: [
        {
            amount: string;
            currency: string;
        }
    ];
    quantity_limit: number;
    quantity_per_user: number;
    discount: string;
    skus?: [
        {
            id: string;
            discount: string;
            quantity_limit: number;
            quantity_per_user: number;
            activity_price: {
                amount: string;
                currency: string;
            };
        }
    ];
}
export interface IPromotion {
    _id: string
    tiktok_shop: ITikTokShop;
    owner: IUser;
    assignee: IUser;
    id: string;
    name: string;
    activity_type: EnumActivityType;
    start_time: number;
    end_time: number;
    status: EnumActivityStatus;
    create_time: number;
    last_update_time: number;
    product_level: EnumProductLevel
    products: IProductActivity[];
    auto_promotion: IAutoPromotion
    promotion_type: number
    update_product_status: EnumUpdateProductStatus;
    pending_products: IProductActivity[];
    note: string
    created_time: Date;
    updated_time: Date;
}


export interface IPromotionQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    assignee_id?: string
}

export interface IPayloadCreatePromotion {
    title: string
    activity_type: EnumActivityType
    begin_time: number
    end_time: number
    product_level: EnumProductLevel
}

export interface IPayloadUpdatePromotion {
    title: string
    begin_time: number
    end_time: number
}

export interface ISKUItemProductPromotion {
    id: string
    base_price: {
        region: string
        currency: string
        sale_price: string
        sale_price_display: string
    }
    properties: [{
        id: string
        name: string
        value_id: string
        value_name: string
    }]
    activity_price_amount: number
    deal_price_percent: number
    quantity_limit: number
    quantity_per_user: number
    is_on_sale: boolean
}
export interface ISelectProductPromotion {
    _id: string
    product_id: string
    product_name: string
    images: [{
        url_list: string
    }]
    status: EnumProductStatus
    sale_price_ranges: any[]
    tiktok_shop: ITikTokShop
    skus: ISKUItemProductPromotion[]
    activity_price_amount: number
    deal_price_percent: number
    quantity_limit: number
    quantity_per_user: number
    failed_skus?: {
        sku_id: string
        thirty_days_lowest_price: string
        failed_detail: string
        failed_reason: number
        sku_name: string
    }[]

}
export interface IPromotionProvider {
    selectedProducts: ISelectProductPromotion[],
    setSelectedProducts: (products: ISelectProductPromotion[]) => void
    saveDataSource: (record: ISelectProductPromotion) => void
}