import React, { useState } from 'react'
import { IBankAccount } from '../interface/bank.interface'
import { Flex, message, Spin } from 'antd'
import { useMutation } from '@tanstack/react-query'
import FinanceAPI from '../../../../api/FinanceAPI'
import { notify } from '../../../../contexts/Notification'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import CopyToClipboard from '../../../../core/CopyToClipboard'

function BankAccountSensitiveLabelColumn({
    data,
    prop
}: {
    data: IBankAccount
    prop: keyof Pick<IBankAccount, 'account_number' | 'swift_code' | 'routing_number'>
}) {
    const [text, setText] = useState<string | undefined>()
    const [isShowFull, setIsShowFull] = useState(false)

    const viewMutation = useMutation({
        mutationFn: FinanceAPI.viewSensitiveData,
        onSuccess: (data) => {
            setText(data[prop])
            setIsShowFull(true)
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const view = () =>
        viewMutation.mutateAsync({
            bankAccountId: data._id,
            props: [prop]
        })

    const toggleShowFull = () => setIsShowFull(!isShowFull)

    if (text) {
        return (
            <Flex gap={12} align='center'>
                <CopyToClipboard text={text} onCopy={() => message.success(`Đã sao chép: ${text}`)}>
                    <p className='cursor-pointer'>{isShowFull ? text : data[prop]}</p>
                </CopyToClipboard>
                {
                    isShowFull ? (
                        <EyeOutlined className='cursor-pointer' onClick={toggleShowFull} />
                    ) : (
                        <EyeInvisibleOutlined className='cursor-pointer' onClick={toggleShowFull} />
                    )
                }
            </Flex>
        )
    }

    return (
        <Spin spinning={viewMutation.isLoading}>
            <Flex gap={12} align='center'>
                <p>{data[prop]}</p>
                <EyeOutlined className='cursor-pointer' onClick={view} />
            </Flex>
        </Spin>
    )
}

export default BankAccountSensitiveLabelColumn
