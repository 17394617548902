import React from 'react';
import {useTableConfigs} from "../../../hooks/useTableConfigs";
import {useShopHealthListQuery} from "./queries/shop-health.query";
import {Button, Card, Flex, Table} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import {Helmet} from "react-helmet";
import {SHOP_HEALTH_COLUMNS} from "./Table/_healthColumnDefined";

function ShopHealthV2() {
    const { queryResponse, pagination } = useTableConfigs(useShopHealthListQuery)

    return (
        <Card
            title={
                <Flex justify='space-between' align='center'>
                    <span style={{ fontSize: 16 }}>Tình trạng cửa hàng</span>
                    <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='middle'>
                        Refresh
                    </Button>
                </Flex>
            }
        >
            <Helmet>
                <title>Order</title>
            </Helmet>
            <Table
                bordered
                dataSource={queryResponse?.data?.data?.map(item => ({
                    ...item.rawData,
                    _id: item._id,
                })) ?? []}
                columns={SHOP_HEALTH_COLUMNS}
                loading={queryResponse?.isFetching}
                pagination={{
                    defaultCurrent: pagination.pageNumber,
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: pagination.handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Total <strong>{total}</strong> products
                        </span>
                    )
                }}
                rowKey={(record) => record._id}
            />
        </Card>
    );
}

export default ShopHealthV2;
