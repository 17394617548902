import { Card, Tabs, Select, Tag, Flex, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useState, FC } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import ListPromotion from './ListPromotion'
import AutoPromotion from './AutoPromotion'
import CreatePromotion from './CreatePromotion'
import PromotionProvider from './PromotionProvider'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import ShopSelect from 'core/ShopSelect'
import { ITikTokShop } from 'types/ITikTokShop'
const TITLE = 'Quản lý Promotions'

const PromotionManagement: FC = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const [selectedShop, setSelectedShop] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const shopsQueryResponse = useAllTikTokShopQuery()
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        console.log('key', key)
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Tạo Promotions',
            children: <CreatePromotion tab={selectedTab} shop_id={selectedShop} />
        },
        {
            id: 2,
            label: 'Danh sách Promotions',
            children: <ListPromotion tab={selectedTab} shop_id={selectedShop} />
        },
        {
            id: 3,
            label: 'Cấu hình đã lưu',
            children: <AutoPromotion tab={selectedTab} shop_id={selectedShop} />
        }
    ]
    const onSyncKikiLogin = () => {
        setLoading(true)
        TikTokShopAPI.syncKikiProfile(selectedShop)
            .then(() => {
                notify('success', 'Đồng bộ dữ liệu từ KikiLogin thành công!')
                shopsQueryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Card title='Quản lý Promotions'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <PromotionProvider>
                <ShopSelect
                    showSearch
                    optionFilterProp='textSearch'
                    style={{ width: '440px' }}
                    placeholder='Chọn cửa hàng'
                    size='large'
                    className='mb-4'
                    value={selectedShop}
                    onChange={(val) => setSelectedShop(val)}
                    loading={shopsQueryResponse.isLoading || loading}
                    disabled={shopsQueryResponse.isLoading || loading}
                    data={shopsQueryResponse?.data ?? []}
                    supportExternalShop
                    show_authorize_status
                />
                <Tabs
                    activeKey={selectedTab.toString()}
                    defaultActiveKey='1'
                    type='card'
                    items={LIST_TABS.map((tab, i) => {
                        return {
                            key: tab.id.toString(),
                            label: tab.label,
                            children: tab.children
                        }
                    })}
                    onChange={onChangeTab}
                />
            </PromotionProvider>
        </Card>
    )
}

export default PromotionManagement
