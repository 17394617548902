import { Card, Table, Row, Col, Select, Space, Button, Tag, Switch, Tabs, Form, Dropdown, message, Tooltip } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import { DownOutlined, ReloadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import Search from 'antd/es/input/Search'
import { Helmet } from 'react-helmet'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { ColumnsType } from 'antd/es/table'
import { EnumActivityStatus, EnumUpdateProductStatus, IPromotion } from 'types/IPromotion'
import SyncPromotionModal from './modals/SyncPromotionModal'
import PromotionModal from './modals/PromotionModal'
import ActivityAPI from 'api/ActivityAPI'
import type { MenuProps } from 'antd'
import AutoPromotionAPI from 'api/AutoPromotionAPI'
import dayjs from 'dayjs'
import UpdateProductPromotionModal from './modals/UpdateProductPromotionModal'
import { TIME_ZONE } from 'const'
import CellProductName from 'core/Table/CellProductName'
import { useActivityQuery } from 'hooks/queries/activity'
import AutoActivityAPI from 'api/AutoActivityAPI'
const { Option } = Select
const TITLE = 'Quản lý Promotion'

const ListPromotion = ({ tab, shop_id }: { tab: number; shop_id: string }) => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleProductModal, setVisibleProductModal] = useState(false)
    const [visibleSyncModal, setVisibleSyncModal] = useState(false)
    const [selectedPromotion, setSelectedPromotion] = useState<IPromotion>()
    const [form] = Form.useForm()
    const formWatch = Form.useWatch([], form)
    const staffsQueryResponse = useAllStaffQuery()
    const promotionQueryResponse = useActivityQuery(
        {
            page_size: pageSize,
            page_number: pageNumber,
            search,
            shop_id,
            ...formWatch
        },
        true
    )

    useEffect(() => {
        if (tab === 2) {
            form.resetFields()
            promotionQueryResponse.refetch()
        }
    }, [tab])
    const refetchData = () => {
        promotionQueryResponse.refetch()
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setSelectedPromotion(undefined)
            }
        },
        [visibleModal, selectedPromotion]
    )
    const toggleProductModal = useCallback(
        (value: boolean) => {
            setVisibleProductModal(value)
            if (!value) {
                setSelectedPromotion(undefined)
            }
        },
        [visibleProductModal, selectedPromotion]
    )
    const toggleSyncModal = useCallback(
        (value: boolean) => {
            setVisibleSyncModal(value)
        },
        [visibleSyncModal]
    )

    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff) => {
            return (
                <Option value={staff._id} key={staff._id} textSearch={staff.full_name}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const onEditPromotion = (promotion: IPromotion) => {
        setSelectedPromotion(promotion)
        toggleModal(true)
    }

    const onEditProductPromotion = (promotion: IPromotion) => {
        setSelectedPromotion(promotion)
        toggleProductModal(true)
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onChangeValues = (values: any) => {
        setPageNumber(1)
    }

    const handleDeactivate = (_id: string) => {
        setLoading(true)
        ActivityAPI.deactivatePromotion(_id)
            .then((res) => {
                notify('success', 'Deactivate promotion success')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const handleSaveAutoPromotion = (_id: string) => {
        setLoading(true)
        AutoActivityAPI.createAutoActivity({ activity_id: _id })
            .then((res) => {
                notify('success', 'Save auto activity success')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const handleMenuClick = (e: any, record: IPromotion) => {
        if (e.key === '1') {
            onEditPromotion(record)
        } else if (e.key === '2') {
        } else if (e.key === '3') {
            handleSaveAutoPromotion(record._id)
        } else if (e.key === '4') {
            onEditProductPromotion(record)
        }
    }

    const renderMenuItem = (record: IPromotion) => {
        const ListMenus = [
            // {
            //     label: 'Chỉnh sửa',
            //     key: '1',
            //     isShown: ['ONGOING', 'NOT_START'].includes(record.status)
            // },
            // {
            //     label: 'Chỉnh sửa sản phẩm',
            //     key: '4',
            //     isShown: ['ONGOING', 'NOT_START'].includes(record.status)
            // },
            // {
            //     label: 'Duplicate',
            //     key: '2',
            //     isShown: false
            // },
            {
                label: 'Lưu file mẫu',
                key: '3',
                isShown: !record?.auto_promotion
            }
        ]
        return ListMenus.filter((item) => item.isShown).map((item) => _.pick(item, ['label', 'key']))
    }
    const columns: ColumnsType<IPromotion> = [
        {
            title: 'Thông tin cửa hàng',
            dataIndex: 'tiktok_shop',
            key: 'tiktok_shop',
            width: 250,
            align: 'left',
            render: (tiktok_shop, record) => {
                return <CellProductName tiktok_shop={_.pick(tiktok_shop, ['name', 'profile_name', 'assignee'])} />
            }
        },
        {
            title: 'Promotion name',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            render: (name, record) => {
                return (
                    <>
                        <p style={{ fontSize: '15px' }}>
                            <b>{name}</b>
                        </p>
                        <p style={{ color: 'rgba(0,0,0,.55)' }}>
                            <i>{record.id}</i>
                        </p>
                        {record?.auto_promotion && (
                            <p className='mt-2'>
                                <b>Cấu hình tự động: </b>
                                {record?.auto_promotion?.is_active ? <Tag color='#389e0d'>ĐANG BẬT</Tag> : <Tag color='#e14653'>ĐANG TẮT</Tag>}
                            </p>
                        )}
                    </>
                )
            }
        },
        {
            title: 'Loại',
            dataIndex: 'activity_type',
            key: 'activity_type',
            width: 120,
            align: 'center',
            render: (activity_type, record) => {
                return (
                    <>
                        <p>{activity_type}</p>
                        <p>{record.product_level}</p>
                    </>
                )
            }
        },
        {
            title: (
                <span>
                    Số lượng sản phẩm{' '}
                    <Tooltip placement='bottom' title='Hệ thống sẽ tự động cập nhật sản phẩm 5 phút/lần'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'products',
            key: 'products',
            width: 150,
            align: 'center',
            render: (products, record) => {
                if (
                    record.update_product_status === EnumUpdateProductStatus.WAITING ||
                    record.update_product_status === EnumUpdateProductStatus.PROCESSING
                ) {
                    let color = blue[5]
                    let text = ''
                    if (record.update_product_status === EnumUpdateProductStatus.WAITING) {
                        color = '#faad14'
                        text = 'WAITING'
                    } else if (record.update_product_status === EnumUpdateProductStatus.PROCESSING) {
                        color = '#0958d9'
                        text = 'PROCESSING'
                    }
                    return (
                        <>
                            <span>
                                {products?.length}/{record.pending_products.length}
                            </span>
                            <p className='mt-2'>
                                <Tag color={color}>{text}</Tag>
                            </p>
                        </>
                    )
                } else if (record.update_product_status === EnumUpdateProductStatus.FAIL) {
                    return (
                        <>
                            <span>{products?.length}</span>
                            <Tooltip title={record?.note}>
                                <p className='mt-2'>
                                    <Tag color='#cf1322'>FAIL</Tag>
                                </p>
                            </Tooltip>
                        </>
                    )
                } else if (record.update_product_status === EnumUpdateProductStatus.SUCCESS) {
                    return (
                        <>
                            <span>{products?.length}</span>
                            <p className='mt-2'>
                                <Tag color='#389e0d'>SUCCESS</Tag>
                            </p>
                        </>
                    )
                } else {
                    return <span>{products?.length}</span>
                }
            }
        },
        {
            title: (
                <span>
                    Trạng thái{' '}
                    <Tooltip placement='bottom' title='Hệ thống sẽ tự động cập nhật trạng thái 30 phút/lần'>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            ),
            dataIndex: 'status',
            key: 'status',
            width: 140,
            align: 'center',
            render: (status) => {
                let color = blue[5]
                let text = ''
                if (status === EnumActivityStatus.DEACTIVATED) {
                    color = '#e14653'
                    text = 'DEACTIVATED'
                } else if (status === EnumActivityStatus.ONGOING) {
                    color = 'rgb(0,158,61)'
                    text = 'ONGOING'
                } else if (status === EnumActivityStatus.EXPIRED) {
                    color = '#8c8c8c'
                    text = 'EXPIRED'
                } else if (status === EnumActivityStatus.NOT_START) {
                    color = '#369afe'
                    text = 'UPCOMING'
                }
                return <Tag color={color}>{text}</Tag>
            }
        },
        {
            title: (
                <span>
                    Ngày bắt đầu
                    <br />
                    (TZ: {TIME_ZONE})
                </span>
            ),
            dataIndex: 'begin_time',
            key: 'begin_time',
            render: (text) => text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: (
                <span>
                    Ngày kết thúc
                    <br />
                    (TZ: {TIME_ZONE})
                </span>
            ),
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) => text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },

        {
            title: 'Bật/Tắt',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            align: 'center',
            render: (text, record) => {
                return (
                    <Switch
                        checked={text !== EnumActivityStatus.DEACTIVATED}
                        disabled={[EnumActivityStatus.DEACTIVATED, EnumActivityStatus.EXPIRED].includes(text) || loading}
                        onClick={() => handleDeactivate(record._id)}
                    />
                )
            }
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Dropdown.Button
                        icon={<DownOutlined />}
                        menu={{
                            items: renderMenuItem(record),
                            onClick: (e) => handleMenuClick(e, record)
                        }}
                        trigger={['click']}
                    >
                        Actions
                    </Dropdown.Button>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viên</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            <Button type='primary' onClick={() => toggleSyncModal(true)} size='large'>
                                Đồng bộ Promotion
                            </Button>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Table
                bordered
                dataSource={promotionQueryResponse?.data?.data ?? []}
                columns={columns}
                loading={promotionQueryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: promotionQueryResponse?.data?.pagination?.total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Tổng số promotions: <strong>{total}</strong>
                        </span>
                    )
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
            />
            <SyncPromotionModal visible={visibleSyncModal} toggleModal={toggleSyncModal} />
            <PromotionModal visible={visibleModal} toggleModal={toggleModal} refetch={refetchData} item={selectedPromotion} />
            <UpdateProductPromotionModal
                visible={visibleProductModal}
                toggleModal={toggleProductModal}
                refetch={refetchData}
                item={selectedPromotion}
            />
        </>
    )
}

export default ListPromotion
