import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Input, Form, Select, Tabs } from 'antd'
import { CloudSyncOutlined } from '@ant-design/icons'
import _ from 'lodash'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import NicheAPI from 'api/NicheAPI'
import {useModal} from "../../../../contexts/Modal";
import ImportFromKiki from '../ConnectShopContainer/ImportFromKiki'
import AuthorizationLink from '../ConnectShopContainer/AuthorizationLink'
const { Option } = Select
type Props = {
    refetchData: () => void
}

const ImportKikiModal: FC<Props> = ({ refetchData }) => {
    const { closeModal } = useModal();

    const onClose = () => {
        closeModal()
    }
    const [selectedTab, setSelectedTab] = useState(1)
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Kiki Login',
            children: <ImportFromKiki />
        },
        {
            id: 2,
            label: 'Authorization',
            children: <AuthorizationLink />
        },
    ]
    

    return (
        <Modal maskClosable={false} closable open onCancel={onClose} title='Kết nối tài khoản TikTok' footer={null} width={600} centered>
            <Divider />
            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: tab.children
                    }
                })}
                onChange={onChangeTab}
            />
        </Modal>
    )
}

export default ImportKikiModal
