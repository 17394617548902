import { Col, Form, InputNumber, Row } from 'antd'
import _ from 'lodash'
import { memo, useCallback, useEffect } from 'react'

function DealPriceColumn({
    product_id,
    deal_price_percent,
    base_price,
    onChange
}: {
    product_id: string
    deal_price_percent: number
    base_price: number
    onChange: (product_id: string, activity_price_amount: number, deal_price_percent: number) => void
}) {
    console.log('DealPriceColumn:', product_id, deal_price_percent, base_price)
    const [form] = Form.useForm()

    useEffect(() => {
        if (deal_price_percent) {
            form.setFieldsValue({ dealPricePercent: deal_price_percent })
            const price = _.round(base_price * (1 - deal_price_percent / 100), 2)
            form.setFieldsValue({ activityPrice: price })
        }
    }, [deal_price_percent])

    const handleBlur = useCallback(async (key: string) => {
        try {
            if (key === 'activityPrice') {
                const price = form.getFieldValue('activityPrice')
                if (price >= 0) {
                    const percent = _.round((base_price - price) / base_price, 2) * 100
                    form.setFieldsValue({ dealPricePercent: percent })
                    if (onChange) {
                        onChange(product_id, price, percent)
                    }
                }
            } else if (key === 'dealPricePercent') {
                const percent = form.getFieldValue('dealPricePercent')
                if (percent >= 0) {
                    const price = _.round(base_price * (1 - percent / 100), 2)
                    form.setFieldsValue({ activityPrice: price })
                    if (onChange) {
                        onChange(product_id, price, percent)
                    }
                }
            }
            await form.validateFields()
        } catch (error) {
            console.log('Error during save:', error)
        }
    }, [form])

    return (
        <Form layout='horizontal' form={form} name={`DealPriceColumn_${product_id}`} className='mb-4 mt-2' component={false}>
            <Row gutter={[10, 10]}>
                <Col span={10}>
                    <Form.Item name='activityPrice' rules={[{ required: true, message: 'Please input activity price!' }]}>
                        <InputNumber
                            addonBefore='$'
                            style={{ width: '100%' }}
                            onBlur={() => handleBlur('activityPrice')}
                            size='large'
                            key={product_id}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <span> or </span>
                </Col>
                <Col span={10}>
                    <Form.Item name='dealPricePercent' rules={[{ required: true, message: 'Please input deal price percent!' }]}>
                        <InputNumber
                            addonAfter='%off'
                            style={{ width: '100%' }}
                            onBlur={() => handleBlur('dealPricePercent')}
                            size='large'
                            key={product_id}
                            min={1}
                            max={100}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default memo(DealPriceColumn)
