import React, {useState} from 'react';
import {IOrder} from "../../../types/IOrder";
import {useModal} from "../../../contexts/Modal";
import {Button, Checkbox, Divider, Flex, GetProp, Modal} from "antd";
import {useUserFulfillServicesQuery} from "../../AccountManagement/queries/user-fulfill.query";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import OrderAPI from "../../../api/OrderAPI";
import {notify} from "../../../contexts/Notification";
import {LIST_ORDER_QUERY_KEY} from "../queries/order.query";

function OrderMoreActionsModal({ order, title }: { order: IOrder, type: string, title: string }) {
    const { closeModal } = useModal();
    const { data: userFulfillServices } = useUserFulfillServicesQuery()
    const [services, setServices] = useState<any[]>([])
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: OrderAPI.rePushToFulfill,
        onSuccess: () => {
            notify('success', 'Đẩy lại thành công')
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
            closeModal()
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
        setServices(checkedValues)
    };

    const options = order.fulfilled_services?.map(key => {
        const userService = userFulfillServices?.find(service => service._id === key)?.service
        return ({
            value: key,
            label: `${userService?.service?.toUpperCase() ?? ''} ${!userService?.more_info?.is_support_re_push_order ? ' (Không hỗ trợ)' : ''}`,
            disabled: !userService?.more_info?.is_support_re_push_order
        })
    }) ?? []

    const confirm = async () => {
        void mutation.mutateAsync({
            orderId: order._id,
            services
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={title}
            footer={null}
            width={500}
        >
            <Flex gap={12} vertical className='mt-3'>
                <p className='font-weight-bold'>Chọn dịch vụ fulfill</p>
                <Checkbox.Group options={options} value={services} onChange={onChange}/>
            </Flex>
            <Button type='primary' className='w-100 mt-3' onClick={confirm} disabled={services.length === 0} loading={mutation.isLoading}>Xác nhận</Button>
        </Modal>
    );
}

export default OrderMoreActionsModal;
