import React from 'react'
import { useOrderStore } from '../store/order.store'
import { useShallow } from 'zustand/react/shallow'
import { Select } from 'antd'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../const'
import { ORDER_STATUS } from './OrderProductInfoColumn'
import { normalizeString } from '../../../utils/format'

function OrderTableToolbarStatus() {
    const { status, addStatus } = useOrderStore(
        useShallow((state) => ({
            status: state.filter.status,
            addStatus: state.filter.addStatus
        }))
    )

    const options =
        Object.values(ORDER_STATUS)?.map((status) => ({
            value: status,
            label: normalizeString(status)
        })) ?? []

    return (
        <Select
            showSearch
            optionFilterProp='textSearch'
            style={{ width: '100%', maxWidth: 260 }}
            placeholder='Chọn trạng thái'
            onChange={addStatus}
            value={status || ''}
            allowClear
            size='large'
            onClear={() => addStatus(DEFAULT_OPTION_ALL_VALUE)}
            options={[
                {
                    value: DEFAULT_OPTION_ALL_VALUE,
                    label: 'Tất cả trạng thái',
                    renderLabel: <span>Tất cả trạng thái</span>,
                    searchTerm: `Tất cả trạng thái`
                },
                ...options
            ]}
        />
    )
}

export default OrderTableToolbarStatus
