import React, {useState} from 'react';
import {useModal} from "../../../../contexts/Modal";
import {Button, Flex, Modal, Select, Tag} from "antd";
import {IBankAccount} from "../interface/bank.interface";
import {useAllTikTokShopQuery} from "../../../../hooks/queries/tiktok-shop";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import FinanceAPI from "../../../../api/FinanceAPI";
import {notify} from "../../../../contexts/Notification";
import {LIST_BANK_ACCOUNT_QUERY_KEY} from "../queries/bank.query";

interface Props {
    bankAccount: IBankAccount
}

function BankAccountSetupShopModal({ bankAccount }: Props) {
    const { closeModal } = useModal()
    const { data } = useAllTikTokShopQuery();
    const [selectedShop, setSelectedShop] = useState<string | undefined>()
    const queryClient = useQueryClient();

    const setupMutation = useMutation({
        mutationFn: FinanceAPI.assignShopForBankAccount,
        onSuccess: (data) => {
            console.log('ddd', data)
            notify('success', `Setup shop thành công`)
            void queryClient.refetchQueries([LIST_BANK_ACCOUNT_QUERY_KEY])
            closeModal()
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const onChangeSelectedShop = (value: string) => {
        setSelectedShop(value)
    }

    const onSubmit = () => setupMutation.mutateAsync({
        bank_account_id: bankAccount._id,
        shop: selectedShop
    })

    const options =
        data?.map((shop) => ({
                value: shop._id,
                label: (
                    <Flex align='center'>
                        <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                        {shop.name}
                    </Flex>
                ),
                renderLabel: (
                    <Flex align='center'>
                        <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                        {shop.name}
                    </Flex>
                ),
                searchTerm: `${shop.name} ${shop.profile_name}`
            })) ?? []

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Setup bank account' footer={null}
               width={600}>
            <p className='mt-3 mb-2 font-weight-bold'>Platform</p>
            <Select
                showSearch
                optionFilterProp='textSearch'
                style={{width: '100%'}}
                size='middle'
                disabled
                defaultValue='tiktok'
                options={[{ value: 'tiktok', label: 'Tiktok' }]}
            />
            <p className='mt-2 mb-2 font-weight-bold'>Chọn shop</p>
            <Select
                showSearch
                optionFilterProp='textSearch'
                style={{width: '100%'}}
                placeholder='Chọn shop'
                onChange={onChangeSelectedShop}
                value={selectedShop || ''}
                size='middle'
                options={options}
                optionRender={(option) => option.data.renderLabel}
                filterOption={(input, option) => (option?.searchTerm ?? '').toLowerCase().includes(input.toLowerCase())}
            />

            <Button type='primary' className='w-100 mt-3' disabled={!selectedShop} onClick={onSubmit} loading={setupMutation.isLoading}>Xác nhận</Button>
        </Modal>
    );
}

export default BankAccountSetupShopModal;
