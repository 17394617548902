import React from 'react'
import { Card, Table } from 'antd'
import { useTableConfigs } from '../../../hooks/useTableConfigs'
import { useBankAccountQuery } from './queries/bank.query'
import BankAccountHeader from './components/BankAccountHeader'
import { Helmet } from 'react-helmet'
import { BANK_ACCOUNT_COLUMNS } from './components/_bankAccountDefined'
import BankAccountTableToolbar from "./components/BankAccountTableToolbar";

function BankManagement() {
    const { queryResponse, pagination } = useTableConfigs(useBankAccountQuery)

    return (
        <Card title={<BankAccountHeader queryResponse={queryResponse} />}>
            <Helmet>
                <title>Bank accounts</title>
            </Helmet>
            <BankAccountTableToolbar />
            <Table
                bordered
                dataSource={queryResponse?.data?.data ?? []}
                columns={BANK_ACCOUNT_COLUMNS}
                loading={queryResponse?.isFetching}
                pagination={{
                    defaultCurrent: pagination.pageNumber,
                    current: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: pagination.handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Total <strong>{total}</strong> items
                        </span>
                    )
                }}
                rowKey={(record) => record._id}
            />
        </Card>
    )
}

export default BankManagement
