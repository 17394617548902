import { FC, memo, useEffect, useState } from 'react'
import { Modal, Button, Row, Divider } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import ProductAPI from 'api/ProductAPI'
import TikTokShopAPI from 'api/TikTokShopAPI'
import ShopSelect from 'core/ShopSelect'
import { ITikTokShop } from 'types/ITikTokShop'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
}

const SyncProductModal: FC<Props> = ({ visible, toggleModal }) => {
    const [loading, setLoading] = useState(false)
    const [selectedShop, setSelectedShop] = useState<string>()
    const shopsQueryResponse = useAllTikTokShopQuery()
  
    const onChangeShop = (value: string) => {
        setSelectedShop(value)
    }
    const onClose = () => {
        toggleModal(false)
        setSelectedShop(undefined)
    }

    const handleSyncProduct = async () => {
        try {
            setLoading(true)
            await ProductAPI.syncProductsFromTikTokAccount({
                shop_id: selectedShop as string
            })
            notify('success', 'Đồng bộ dữ liệu đã được đưa vào hàng chờ')
            onClose()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Đồng bộ sản phẩm' footer={null} width={720} centered>
            <Divider />
            <Row gutter={[24, 24]} justify={'center'} className='m-2'>
                <p>
                    Đồng bộ toàn bộ sản phẩm từ cửa hàng TikTok của bạn lên hệ thống. Việc này sẽ diễn ra mất thời gian một vài phút và không thể hoàn
                    tác.
                </p>
            </Row>
            <Row justify={'center'} className='mt-4'>
                <ShopSelect
                    allowClear
                    showSearch
                    optionFilterProp='textSearch'
                    style={{ width: '100%' }}
                    placeholder='Chọn cửa hàng để đồng bộ'
                    onChange={onChangeShop}
                    size='large'
                    value={selectedShop}
                    data={shopsQueryResponse?.data ?? []}
                    show_authorize_status={true}
                    supportExternalShop
                />
            </Row>
            <Row gutter={[24, 24]} justify={'center'} className='mt-4'>
                <Button size='large' onClick={handleSyncProduct} loading={loading}>
                    Đồng bộ ngay
                </Button>
            </Row>
        </Modal>
    )
}

export default memo(SyncProductModal)
