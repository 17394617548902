import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function Join() {
    const { inviteCode } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (inviteCode) {
            navigate(`/register?inviteCode=${inviteCode}`, { replace: true })
        }
    }, [inviteCode, navigate])

    return null
}

export default Join
