import React from 'react'
import { useGetAllStaffsQuery } from '../../StaffManagement/queries/staff.query'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../const'
import { Flex, Select, Tag } from 'antd'
import { useOrderStore } from '../store/order.store'
import { useShallow } from 'zustand/react/shallow'

function OrderTableToolbarStaff() {
    const { data } = useGetAllStaffsQuery()
    const { user, addUser } = useOrderStore(
        useShallow((state) => ({
            user: state.filter.user,
            addUser: state.filter.addUser
        }))
    )

    const options =
        data
            ?.sort((a, b) => Number(b.is_active) - Number(a.is_active))
            ?.map((user) => ({
                value: user._id,
                label: (
                    <Flex align='center'>
                        <Tag color={user.is_active ? '#1ace23' : '#f62727'}>{user.is_active ? 'ACTIVE' : 'INACTIVE'}</Tag>
                        <span>{user.email}</span>
                    </Flex>
                ),
                renderLabel: (
                    <Flex justify='space-between' align='center'>
                        <Flex vertical gap={3}>
                            <span>{user.email}</span>
                            <span
                                style={{
                                    fontSize: 11,
                                    color: '#918d8d'
                                }}
                            >
                                {user.first_name} {user.last_name}
                            </span>
                        </Flex>
                        <Tag color={user.is_active ? '#1ace23' : '#f62727'}>{user.is_active ? 'ACTIVE' : 'INACTIVE'}</Tag>
                    </Flex>
                ),
                searchTerm: `${user.email} ${user.full_name}`
            })) ?? []

    return (
        <Select
            showSearch
            optionFilterProp='textSearch'
            style={{ width: '100%', maxWidth: 340 }}
            placeholder='Chọn nhân viên'
            onChange={addUser}
            value={user || ''}
            allowClear
            size='large'
            onClear={() => addUser(DEFAULT_OPTION_ALL_VALUE)}
            options={[
                {
                    value: DEFAULT_OPTION_ALL_VALUE,
                    label: 'Tất cả nhân viên',
                    renderLabel: <span>Tất cả nhân viên</span>,
                    searchTerm: `Tất cả nhân viên`
                },
                ...options
            ]}
            optionRender={(option) => option.data.renderLabel}
            filterOption={(input, option) => (option?.searchTerm ?? '').toLowerCase().includes(input.toLowerCase())}
        />
    )
}

export default OrderTableToolbarStaff
