export const getRangePrice = (sku: any[]) => {
    if (!sku || !sku.length) {
        return '';
    }
    const prices: any[] = [];
    sku.forEach((item) => {
        if (item?.base_price?.sale_price && !prices.includes(item?.base_price?.sale_price)) prices.push(item.base_price.sale_price);
    })
    prices.sort((a, b) => a - b);
    if (prices.length === 0) return '0';
    if (prices.length === 1) return prices[0];
    let rangePrice = `${prices[0]} - ${prices[prices.length - 1]}`;
    return rangePrice;
}