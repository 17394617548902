import React from 'react'
import { IOrder, OrderItem } from '../../../types/IOrder'
import {Button, Col, Divider, Flex, Form, Input, message, Row, Select, Space, Tag} from 'antd'
import { OrderProductFormValue } from '../interfaces/order-product.interface'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import { useFulfillServiceQuery } from '../queries/fulfill.query'
import { LIST_ORDER_QUERY_KEY } from '../queries/order.query'
import {formatCurrency, normalizeString} from '../../../utils/format'
import { Link } from 'react-router-dom'
import { useUserFulfillServicesQuery } from '../../AccountManagement/queries/user-fulfill.query'
import { mergeFulfillData } from '../../AccountManagement/SettingFulfill'
import CopyToClipboard from 'core/CopyToClipboard'

const { Option } = Select

export const ORDER_STATUS = {
    AWAITING_SHIPMENT: 'AWAITING_SHIPMENT',
    AWAITING_COLLECTION: 'AWAITING_COLLECTION',
    IN_TRANSIT: 'IN_TRANSIT',
    DELIVERED: 'DELIVERED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    UNPAID: 'UNPAID',
    ON_HOLD: 'ON_HOLD',
    PARTIALLY_SHIPPING: 'PARTIALLY_SHIPPING',
};

const checkVariant = ({ productType, color, size, selectedFulfill }: { productType: string; color: string; size: string; selectedFulfill: any }) => {
    if (!selectedFulfill)
        return {
            isValidVariant: true
        }
    const findProductType = selectedFulfill?.more_info?.product_type?.find((item: { title: string }) => item.title === productType)
    if (!findProductType)
        return {
            isValidVariant: false
        }
    return {
        isValidVariant: findProductType.colors.includes(color) && findProductType.sizes.includes(size),
        findProductType
    }
}

const getTrackingURL = (company?: string, trackingNumber?: string) => {
    if (!company || !trackingNumber) return;
    switch (company) {
        case 'USPS':
            return `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingNumber}`
        case 'DHL eCommerce':
            return `https://webtrack.dhlglobalmail.com/orders?trackingNumber=${trackingNumber}`
        default:
            return;
    }
}

function OrderProductInfoColumn({ product, order }: { product: OrderItem; order: IOrder }) {
    const [form] = Form.useForm()
    const queryClient = useQueryClient()
    const updateLineItemMutation = useMutation({
        mutationFn: OrderAPI.updateLineItem,
        onSuccess: () => notify('success', 'Update item successfully'),
        onError: (err) => notify('error', 'Update item failed: ' + (err as Error).message)
    })

    const pushStatusToFulfillServiceMutation = useMutation({
        mutationFn: OrderAPI.pushStatusToFulfillService
    })
    const { data: fulfillServices } = useFulfillServiceQuery()
    const { data: userFulfillServices } = useUserFulfillServicesQuery()

    const ffService = Form.useWatch(['ff_info', 'service'], form)
    const _productType = Form.useWatch(['ff_info', 'product_type'], form)
    const _size = Form.useWatch('size', form)
    const _color = Form.useWatch('color', form)

    const userFulfillServicesData = mergeFulfillData(fulfillServices, userFulfillServices)

    const selectedFulfill = userFulfillServicesData?.find((ff) => ff.userData?._id === ffService)

    const { size, color } = extractVariant(product.sku_name)

    const onFinish = async (values: OrderProductFormValue) => {
        if (values.seller_sku && values.seller_sku.includes('/')) {
            notify('error', 'Seller sku chứa ký tự không hợp lệ. Chỉ chấp nhận "_", "-"')
            return
        }
        const payload = {
            lineItemId: product._id,
            sku_name: values.color + ', ' + values.size,
            sku_id: values.sku_front,
            seller_sku: values.seller_sku?.trim() ?? '',
            ff_info: {
                ...values.ff_info,
                color: values.color,
                size: values.size
            }
        }
        await updateLineItemMutation.mutateAsync(payload)
        // if (values.ff_info?.fulfill_status !== product.ff_info?.fulfill_status) {
        //     await pushStatusToFulfillServiceMutation.mutateAsync({
        //         orderId: product.order_id,
        //         fulfillServiceOrderId: product.ff_info?.detail?._id,
        //         fulfillService: product.ff_info?.service,
        //         newStatus: values.ff_info?.fulfill_status,
        //         itemsIds: [product.ff_info?.item?.ff_item_id]
        //     })
        // }
        await queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
    }

    // const onReset = () => {
    //     form.resetFields()
    // }

    const { isValidVariant, findProductType } = checkVariant({
        color: _color,
        size: _size,
        productType: _productType,
        selectedFulfill
    })

    const fulfillData = product.ff_info?.service ? order.more_info?.ff_info?.[product.ff_info.service] : undefined

    // const blockEditForm = !!order.cancel_fulfill_at ? false : fulfillData?.detail?.success === false ? false : !!product.push_to_fulfill_at
    const blockEditForm = product.display_status !== ORDER_STATUS.AWAITING_COLLECTION && product.display_status !== ORDER_STATUS.AWAITING_SHIPMENT
    const blockEditFulfill = !!order.cancel_fulfill_at ? false : !!product.push_to_fulfill_at

    const trackingNumber = fulfillData?.tracking?.tracking_number ?? product.ff_info?.item?.tracking?.number
    const trackingCompany = fulfillData?.tracking?.tracking_company ?? product.ff_info?.item?.tracking?.company
    const trackingURL = fulfillData?.tracking?.tracking_url ?? getTrackingURL(trackingCompany, trackingNumber)

    return (
        <div>
            <Form
                layout='horizontal'
                form={form}
                onFinish={onFinish}
                style={{}}
                initialValues={{
                    sku_front: product.sku_id,
                    seller_sku: product.seller_sku ?? '',
                    size,
                    color,
                    ff_info: {
                        service: product.ff_info?.service,
                        fulfill_status: product.fulfill_status,
                        product_type: product.ff_info?.product_type,
                        print: product.ff_info?.print
                    }
                }}
            >
                <Row gutter={24}>
                    <Col md={12}>
                        <CopyToClipboard text={product.product_id} onCopy={() => message.success(`Đã sao chép: ${product.product_id}`)}>
                            <p className='clickable'>{product.product_id}</p>
                        </CopyToClipboard>
                        <CopyToClipboard text={product.product_name} onCopy={() => message.success(`Đã sao chép: ${product.product_name}`)}>
                            <p className='clickable'>{product.product_name}</p>
                        </CopyToClipboard>
                        <p>
                            Variants: <strong>{product.sku_name}</strong> - Quantity: <strong>{product.quantity}</strong>
                        </p>
                        <p>
                            Original:{' '}
                            <strong>
                                {product.original_price} {product.currency}
                            </strong>
                        </p>
                        <p>
                            Sale Price:{' '}
                            <strong>
                                {product.sale_price} {product.currency}
                            </strong>
                        </p>
                        <Row gutter={12} className='mt-3'>
                            <Col md={24}>
                                <Form.Item name='seller_sku' label='Seller SKU'>
                                    <Input disabled={blockEditForm} />
                                </Form.Item>
                            </Col>
                            <Col md={24}>
                                <Form.Item name='sku_front' label='Tiktok Shop SKU ID'>
                                    <Input disabled={blockEditForm} />
                                </Form.Item>
                            </Col>
                            {selectedFulfill?.more_info?.product_type && (
                                <Col md={24}>
                                    <Form.Item name={['ff_info', 'product_type']} label='Product type'>
                                        <Select disabled={blockEditForm} showSearch>
                                            {selectedFulfill.more_info.product_type.map(({ title }: { title: string }) => (
                                                <Option value={title} key={title}>
                                                    {title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            <Col span={10}>
                                <Form.Item name='size' label='Size'>
                                    <Select disabled={blockEditForm}>
                                        {(findProductType?.sizes ?? SIZES).map((size: string) => (
                                            <Option value={size} key={size}>
                                                {size}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {selectedFulfill?.more_info?.fulfill_printing_area && (
                                <Col span={14}>
                                    <Form.Item name={['ff_info', 'print']} label='Print'>
                                        <Select disabled={blockEditForm}>
                                            {selectedFulfill.more_info.fulfill_printing_area.map(
                                                ({ value, label }: { value: string; label: string }) => (
                                                    <Option value={value} key={value}>
                                                        {label}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={12}>
                                <Form.Item name='color' label='Color'>
                                    <Select disabled={blockEditForm} showSearch>
                                        {(findProductType?.colors?.sort() ?? selectedFulfill?.more_info?.fulfill_colors?.sort() ?? COLORS).map(
                                            (c: string) => (
                                                <Option value={c} key={c}>
                                                    {c}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        {product.display_status !== ORDER_STATUS.AWAITING_SHIPMENT && product.display_status !== ORDER_STATUS.AWAITING_COLLECTION && (
                            <>
                                <Flex gap={8}>
                                    <span>Trạng thái:</span>
                                    <strong className='clickable'>{normalizeString(product.display_status)}</strong>
                                </Flex>
                                <Divider />
                            </>
                        )}
                        <Flex gap={12}>
                            <Form.Item name={['ff_info', 'service']} label='Dịch vụ fulfill' className='w-50'>
                                <Select disabled={blockEditFulfill || blockEditForm}>
                                    {userFulfillServicesData?.map((fulfill, idx) => (
                                        <Select.Option
                                            value={fulfill.userData?._id}
                                            key={fulfill.userData?._id ?? idx}
                                            disabled={!fulfill.userData?.is_active}
                                        >
                                            <Flex gap={8} align='center'>
                                                <img src={fulfill.icon} width={20} height={20} alt='service icon' className='flex-shrink-1' />
                                                <p className='text-uppercase font-weight-bold text-sm'>{fulfill.service}</p>
                                            </Flex>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {fulfillData && selectedFulfill && (
                                <Form.Item name={['ff_info', 'fulfill_status']} label='Fulfill' className='w-50'>
                                    <Select
                                        disabled={
                                            !fulfillData.detail?.code ||
                                            !!fulfillData?.tracking?.tracking_number ||
                                            !selectedFulfill?.more_info?.is_support_update_order_status
                                        }
                                    >
                                        {(selectedFulfill?.more_info?.fulfill_status ?? ['pending', 'fulfillment']).map((c: string) => (
                                            <Option value={c} key={c}>
                                                {c}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Flex>

                        {fulfillData?.detail?.code && (
                            <Flex gap={8}>
                                <span style={{ width: 140 }}>Mã đơn Fulfill:</span>
                                <CopyToClipboard text={fulfillData.detail?.code} onCopy={() => message.success(`Đã sao chép mã đơn fulfill`)}>
                                    <strong className='clickable'>{fulfillData.detail?.code}</strong>
                                </CopyToClipboard>
                            </Flex>
                        )}
                        {trackingNumber && (
                            <>
                                <Flex gap={8}>
                                    <span style={{ width: 140 }}>Tracking Number:</span>
                                    <CopyToClipboard
                                        text={trackingNumber}
                                        onCopy={() => message.success(`Đã sao chép: ${trackingNumber}`)}
                                    >
                                        <strong className='clickable'>{trackingNumber}</strong>
                                    </CopyToClipboard>
                                </Flex>
                            </>
                        )}
                        {
                            fulfillData?.tracking?.tracking_name && (
                                <>
                                    <Flex gap={8}>
                                        <span style={{ width: 140 }}>Tracking Name:</span>
                                        <CopyToClipboard
                                            text={fulfillData.tracking?.name}
                                            onCopy={() => message.success(`Đã sao chép: ${fulfillData?.tracking?.name}`)}
                                        >
                                            <strong className='clickable'>{fulfillData.tracking?.name}</strong>
                                        </CopyToClipboard>
                                    </Flex>
                                </>
                            )
                        }
                        {
                            trackingCompany && (
                                <>
                                    <Flex gap={8}>
                                        <span style={{ width: 140 }}>Tracking Company:</span>
                                        <CopyToClipboard
                                            text={trackingCompany}
                                            onCopy={() => message.success(`Đã sao chép: ${trackingCompany}`)}
                                        >
                                            <strong className='clickable'>{trackingCompany}</strong>
                                        </CopyToClipboard>
                                    </Flex>
                                </>
                            )
                        }
                        {
                            trackingURL && (
                                <>
                                    <Flex gap={8}>
                                        <span style={{ width: 140 }}>Tracking URL:</span>
                                        <Link to={trackingURL} target='_blank' rel='noopener noreferrer'>
                                            <strong className='clickable'>Nhấn vào đây để truy cập</strong>
                                        </Link>
                                    </Flex>
                                </>
                            )
                        }
                        {product.ff_info?.item?.estimated_base_cost && (
                            <Flex gap={8}>
                                <span style={{ width: 140 }}>Base cost:</span>
                                <strong>
                                    {formatCurrency(product.ff_info.item.estimated_base_cost)} {product.ff_info.item.currency}
                                </strong>
                            </Flex>
                        )}
                    </Col>
                </Row>
                {!isValidVariant && (
                    <p className='text-danger mb-4'>
                        Dịch vụ fulfill đã chọn không cung cấp variant này! Nếu bạn chắc chắn với lựa chọn của mình,{' '}
                        <strong>vui lòng bỏ qua cảnh báo này</strong>
                    </p>
                )}
                {!blockEditForm && (
                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={updateLineItemMutation.isLoading || pushStatusToFulfillServiceMutation.isLoading}
                            >
                                Save
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </Form>
        </div>
    )
}

export default OrderProductInfoColumn

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 }
}

const tailLayout = {
    wrapperCol: { offset: 0, span: 16 }
}

const PRINT_OPTS = ['1 side', '2 side']
const SIZES = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL']
const COLORS = ['Black', 'White', 'Navy', 'Sand', 'Red', 'SportGrey']
const SERVICES = [
    { value: '1', label: 'Printify' },
    { value: '2', label: 'Shopify' },
    { value: '10001', label: 'Geargag' },
    { value: '10002', label: 'Gearment' },
    { value: '10003', label: 'Lenful' },
    { value: '10004', label: 'Fuel' },
    { value: '10005', label: 'ShineOne' },
    { value: '10006', label: 'Flash POD' },
    { value: '10007', label: 'Mango Tea' },
    { value: '10008', label: 'Superspeed' },
    { value: '10009', label: 'Princare' },
    { value: '0', label: 'Other' }
]

const extractVariant = (variant: string) => {
    const arr = variant.split(',')
    if (arr.length === 0)
        return {
            size: '',
            color: ''
        }
    let size = '',
        color = ''
    arr.forEach((item) => {
        if (SIZES.includes(item.trim())) {
            size = item.trim()
        } else {
            color = item.trim()
        }
    })

    return { size, color }
}
