export const TEACHER_ALLOWED_MISSED_CLASS = 4
export const DEFAULT_TRIAL_COURSE_ID = 1
export * from './route'
export * from './date-time'
export * from './status'
export * from './socket'
export * from './common'
export * from './gender'
export * from './product-properties'

export const TIKTOK_METHOD = {
    BY_COOKIE: 1,
    BY_API: 2
}

export enum EnumTikPlatform {
    BY_COOKIE = 1,
    BY_API = 2
}

export enum EnumCreatedPlatform {
    TIKTOK_BROWSER = 1,
    TIKTOK_COOKIE = 2,
    TIKTOK_API = 3
}

export const TIKTOK_SHOP_IMPORT_SOURCES = {
    KIKI_LOGIN: 'kikilogin',
    API: 'api'
}
