import { notify } from 'contexts/Notification'
import { useCallback } from 'react'

const useClipboardCopy = () => {
    const copyToClipboard = useCallback((text: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).catch((err) => {
                notify('error', err.message ?? 'Copy API not support')
            })
        } else {
            notify('error', 'Copy API not support')
        }
    }, [])
    return copyToClipboard
}

export default useClipboardCopy
