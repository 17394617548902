import { Row, Col, ConfigProvider, Form, Select, Space, Input, InputNumber, Button } from 'antd'
import ListingAPI from 'api/ListingAPI'
import { notify } from 'contexts/Notification'
import { useAllPriceTemplateQuery } from 'hooks/queries/price-template'
import _ from 'lodash'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ProductSKUType } from 'types/ICategory'

type ProductSKUProps = {
    value?: ProductSKUType
    onChange?: (value: ProductSKUType) => void
    isReset?: boolean
    onChangePriceTemplate?: (value: string) => void
}
const ProductSKU: FC<ProductSKUProps> = ({ value, onChange, isReset, onChangePriceTemplate }) => {
    const [priceTemplateId, setPriceTemplateId] = useState('')
    const [hasColor, setHasColor] = useState(false)
    const priceTemplatesQueryResponse = useAllPriceTemplateQuery()
    const [form] = Form.useForm()

    useEffect(() => {
        ListingAPI.getSKU()
            .then((res) => {
                form.setFieldsValue({ sku: res })
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }, [])
    useEffect(() => {
        if (isReset) {
            form.resetFields()
            form.setFieldsValue({ colors: 'Black|White|Navy|Sand|Red|SportGrey', sizes: 'S|M|L|XL|2XL|3XL' })
            setPriceTemplateId('')
            setHasColor(false)
            ListingAPI.getSKU()
                .then((res) => {
                    form.setFieldsValue({ sku: res })
                })
                .catch((err) => {
                    notify('error', err.message)
                })
            return
        }
    }, [isReset])
    const templateOptions = useMemo(() => {
        return _.map(priceTemplatesQueryResponse?.data ?? [], (des: any) => ({
            value: des._id,
            label: des.name
        }))
    }, [priceTemplatesQueryResponse?.data])

    const generateSKUS = () => {
        let { colors, sizes, price, sku } = form.getFieldsValue(['colors', 'sizes', 'price', 'sku'])
        console.log(colors, sizes, price, sku)
        let skus: any = []
        colors = colors.split('|')
        sizes = sizes.split('|')
        if (!sizes || sizes.length === 0) {
            notify('error', 'Vui lòng nhập Sizes!')
            return
        }
        if (!price) {
            notify('error', 'Vui lòng nhập Price!')
            return
        }
        if (colors.length > 0) {
            setHasColor(true)
            for (let i = 0; i < sizes.length; i++) {
                for (let j = 0; j < colors.length; j++) {
                    skus.push({
                        size: sizes[i],
                        color: colors[j],
                        price: price,
                        seller_sku: sku
                    })
                }
            }
        } else {
            setHasColor(false)
            for (let i = 0; i < sizes.length; i++) {
                skus.push({
                    size: sizes[i],
                    price: price,
                    seller_sku: sku
                })
            }
        }
        form.setFieldValue('skus', skus)
    }
    const onValuesChange = (changedValues: any) => {
        console.log('changedValues', changedValues)
        if (changedValues.price_template_id) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.price_template_id)
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: form.getFieldValue('sku')
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }

            setPriceTemplateId(changedValues.price_template_id)
            if (onChangePriceTemplate) {
                onChangePriceTemplate(changedValues.price_template_id)
            }
        } else if (changedValues.price_template_id === '') {
            form.setFieldsValue({
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL'
            })
            setHasColor(true)
            form.setFieldValue('skus', [])
            setPriceTemplateId(changedValues.price_template_id)
        }

        if (changedValues.sku) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === form.getFieldValue('price_template_id'))
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: changedValues.sku
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }
        }
        if (Object.keys(changedValues).length > 0 && onChange) {
            onChange({
                ...form.getFieldsValue(),
                default_sku: form.getFieldValue('sku')
            })
        }
    }
    return (
        <Form
            form={form}
            name='FormProductSKU'
            layout='vertical'
            autoComplete='off'
            component={false}
            onValuesChange={onValuesChange}
            initialValues={{
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL'
            }}
        >
            <Row gutter={[20, 20]}>
                <Col span={14}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                    colorBgContainer: '#d9d9d9 !important'
                                }
                            }
                        }}
                    >
                        <Form.Item
                            name='price_template_id'
                            label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu Size, Price có sẵn</b>}
                        >
                            <Select
                                placeholder='Chọn mẫu Size, Price có sẵn'
                                showSearch
                                optionFilterProp='textSearch'
                                size='large'
                                options={[{ value: '', label: 'NHẬP TAY SIZE, PRICE' }, ...templateOptions]}
                                loading={priceTemplatesQueryResponse.isLoading}
                            />
                        </Form.Item>
                    </ConfigProvider>
                </Col>
            </Row>
            <Form.List name='skus'>
                {(fields) => (
                    <div>
                        {fields.map(({ key, name, ...restField }, idx) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                {hasColor && (
                                    <Form.Item {...restField} name={[name, 'color']}>
                                        <Input placeholder='Color' />
                                    </Form.Item>
                                )}
                                <Form.Item {...restField} name={[name, 'size']} rules={[{ required: true, message: 'Missing Size Name' }]}>
                                    <Input placeholder='Size' />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'price']} rules={[{ required: true, message: 'Missing Price' }]}>
                                    <InputNumber placeholder='Price' min={0} />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'seller_sku']}>
                                    <Input placeholder='Seller SKU' />
                                </Form.Item>
                            </Space>
                        ))}
                    </div>
                )}
            </Form.List>
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Form.Item label={<b>Variations 1 (Color)</b>} name='colors'>
                        <Input style={{ width: '100%' }} placeholder='Nhập Colors' size='large' readOnly={!!priceTemplateId} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Variations 2 (Size)</b>} name='sizes'>
                        <Input style={{ width: '100%' }} placeholder='Nhập Sizes' size='large' readOnly={!!priceTemplateId} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={<b>SKU</b>} name='sku'>
                        <Input style={{ width: '100%' }} placeholder='Nhập SKU' size='large' />
                    </Form.Item>
                </Col>
                {!priceTemplateId && (
                    <>
                        <Col span={3}>
                            <Form.Item label={<b>Price</b>} name='price'>
                                <InputNumber placeholder='Nhập giá sản phẩm' min={0} style={{ width: '100%' }} size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={<b>Action</b>}>
                                <Button type='primary' onClick={generateSKUS} size='large'>
                                    Generate SKUS
                                </Button>
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
        </Form>
    )
}

export default memo(ProductSKU)
