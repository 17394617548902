import {ColumnsType} from "antd/lib/table";
import {HealthCheckRowProps} from "../interface/healthcheck.interface";
import AccountQualification from "./AccountQualification";
import {ACCOUNT_QUALIFICATION_FIELDS} from "../utils/constant";

export const getCommonProps = (row: HealthCheckRowProps) => ({
    _id: row._id,
    errorData: row.errorData,
});

export const SHOP_HEALTH_COLUMNS: ColumnsType<HealthCheckRowProps> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 20,
        render: (_, __, index) => index + 1
    },
    {
        title: 'Qualification',
        dataIndex: 'accountQualification',
        key: 'accountQualification',
        render: (_, row) => (
            <AccountQualification
                {...getCommonProps(row)}
                qualification={row.qualification}
                violation={row.violation}
                tableField={ACCOUNT_QUALIFICATION_FIELDS.qualification}
                errorField='qualification'
                hasData={!!row.qualification && !!row.violation}
            />
        )
    }
]
