import React from 'react'
import { useModal } from '../../../../contexts/Modal'
import { Modal, Steps } from 'antd'
import { IBankAccount } from '../interface/bank.interface'
import { formatDate } from '../../../../utils/format'

function BankAccountHistoriesModal({ bankAccount }: { bankAccount: IBankAccount }) {
    const { closeModal } = useModal()

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={`Lịch sử ******${bankAccount.account_number_last_4_digits}`}
            footer={null}
            width={700}
        >
            <Steps
                progressDot
                current={bankAccount.histories.length}
                direction='vertical'
                items={bankAccount.histories.reverse().map((his) => ({
                    title: `${formatDate(his.time)} - ${his.user.email}`,
                    description: his.message
                }))}
            />
        </Modal>
    )
}

export default BankAccountHistoriesModal
