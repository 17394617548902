import React from 'react';
import {Button, Flex, Modal, Typography} from "antd";
import PackageModal from "../../pages/PaymentCenter/ui/Packages/modals/PackageModal";
import {useModal} from "../../contexts/Modal";

function PleaseUpgradePackageModal() {
    const { closeModal } = useModal()
    const { openModal } = useModal()

    const openChangePackageModal = () => openModal(<PackageModal />)

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title='Thông báo'
            footer={null}
            width={600}
        >
            <Flex vertical align='center'>
                <img src='https://kikilogin-internal-files.s3.ap-southeast-1.amazonaws.com/internal-files-1734925226255'
                     alt='sad' width={60}/>
                <Typography className='text-lg text-center px-5 mt-3'>Gói cước của bạn hiện không hỗ trợ tính năng này.</Typography>
                <Typography className='text-lg text-center px-5'>Vui lòng nâng cấp để sử dụng!</Typography>
            <Button type='primary' className='mt-3' size='large' onClick={openChangePackageModal}>Nâng cấp ngay</Button>
            </Flex>
        </Modal>
    );
}

export default PleaseUpgradePackageModal;
