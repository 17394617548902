import ApiSender from './ApiSender'
import { ITikTokShop } from '../types/ITikTokShop'
import { IListApiResponse } from '../types/IApiResponse'
import { HealthCheckDataProps } from '../pages/ShopManagement/ShopHealth/interface/healthcheck.interface'

export default class TikTokShopAPI {
    public static oauthTikTokAccount(params: object) {
        const route = `/core/user/oauth/tiktok-shop`
        return ApiSender.get(route, params)
    }
    public static getTikTokShops(params?: { page_size: number; page_number: number; search?: string }) {
        const route = `/core/user/all-tiktok-shops`
        return ApiSender.get(route, params)
    }

    public static exportTikTokShops() {
        const route = `/core/user/tiktok-shops/export`
        return ApiSender.post(route)
    }

    public static getTikTokShopsWithPaging(params: {
        page_size: number
        page_number: number
        search?: string
        sort?: string
        assignee_id?: string
        status_kbt?: any
        niche_id?: string
    }) {
        const route = `/core/user/tiktok-shops`
        return ApiSender.get(route, params)
    }

    public static importFromKiki(payload: { profile_id: string }) {
        const route = `/core/user/import-kiki`
        return ApiSender.post(route, payload)
    }

    public static syncKikiProfile(shop_id: string) {
        const route = `/core/user/sync-kiki-profile`
        return ApiSender.post(route, { shop_id })
    }
    public static getShopsHealth(params: {
        page_size: number
        page_number: number
        search?: string
    }): Promise<IListApiResponse<HealthCheckDataProps>> {
        const route = `/core/user/shops-health`
        return ApiSender.get(route, params)
    }

    public static editShop(_id: string, payload: Partial<ITikTokShop>) {
        const route = `/core/user/tiktok-shops/${_id}`
        return ApiSender.put(route, payload)
    }
    public static removeShop(_id: string) {
        const route = `/core/user/tiktok-shops/${_id}`
        return ApiSender.delete(route)
    }
    public static syncAuthTokenTiktok(shop_id: string) {
        const route = `/core/user/sync-authorize-token`
        return ApiSender.post(route, { shop_id })
    }
    public static migrateStaff(payload: { src_staff_id: string; dest_staff_id: string }) {
        const route = `/core/user/tiktok-shops/migrate-staff`
        return ApiSender.post(route, payload)
    }

    public static syncKBT(shop_id: string) {
        const route = `/core/user/sync-kbt`
        return ApiSender.post(route, { shop_id })
    }

    public static getAuthorizeToken(payload: { _id: string; authorize_app_id: string; authorize_app_key: string; authorize_app_secret: string }) {
        const route = `/core/user/update-authorize-token`
        return ApiSender.post(route, payload)
    }

    public static getShopAuthData(payload: { authorize_token: string; authorize_app_key: string; authorize_app_secret: string }) {
        const route = `/core/user/tiktok-shops/auth-data`
        return ApiSender.post(route, payload)
    }

    public static assignShop(payload: { shop_id: string; assignee_id: string }) {
        const route = `/core/user/tiktok-shops/assign-staff`
        return ApiSender.post(route, payload)
    }

    public static importFromOtherPlatform(payload: Partial<ITikTokShop>) {
        const route = `/core/user/tiktok-shop/import-external`
        return ApiSender.post(route, payload)
    }

    public static getAuthorizationLink() {
        const route = `/core/user/tiktok-shops/authorize`
        return ApiSender.get(route)
    }

    public static verifyAuthorizeToken(payload: { state: string; app_key: string; code: string }) {
        const route = `/core/tiktok-shops/authorize/verify`
        return ApiSender.post(route, payload)
    }
}
