import { create } from 'zustand'
import React from "react";
import {IShopPaidStore} from "../interface/shop-paid.interface";

export const useShopPaidStore = create<IShopPaidStore>((set) => ({
    filter: {
        shop: '',
        search: '',
        addShop: (shop: string) => set((state) => ({ filter: { ...state.filter, shop } })),
        addSearch: (e: React.ChangeEvent<HTMLInputElement>) => set((state) => ({ filter: { ...state.filter, search: e.target.value } })),
    },
    setFilter: (newFilter) => set({ filter: newFilter }),
    addFilter: (partialFilter) => set((state) => ({ filter: { ...state.filter, ...partialFilter } })),
    removeFilter: (prop) =>
        set((state) => {
            const _filter = { ...state.filter }
            delete _filter[prop]
            return { filter: _filter }
        })
}))
